import { Component, OnInit, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { UploadService } from '@app/_services/upload.service';

import * as JSZipUtils from 'jszip-utils';
import * as JSZip from 'jszip';
import * as saveAs from 'file-saver';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { OrdersService } from '@app/_services/orders.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-download-zip',
  templateUrl: './download-zip.component.html',
  styleUrls: ['./download-zip.component.css']
})
export class DownloadZipComponent implements OnInit {
  isLoggedIn = true;
  @Input('suborderid') suborderID = "";
  @Input("folderName") folderName = "";
  @Output() onFinish = new EventEmitter();
  showProgress = false;
  progressMsg = ""
  progressPercent = 0;
  showMessage = false;
  msgClass = "info";
  globalMessage = "";

  constructor(
    private afAuth: AngularFireAuth,
    public ngZone: NgZone,
    private activeRoute: ActivatedRoute,
    private afs: AngularFirestore,
    private uploadService: UploadService,
    private os: OrdersService
  ) { }

  ngOnInit() {
    const self = this;
    const sid = this.activeRoute.snapshot.params['sid'];
    const dir = this.activeRoute.snapshot.params['dir'];
    const token = this.activeRoute.snapshot.params['token'];
    if ((!this.suborderID || !this.folderName) && !token) {
      self.showMessage = true;
      self.msgClass = "text-error";
      self.globalMessage = "Unauthorized access";
      return;
    }
    if (!this.suborderID) {
      this.suborderID = sid;
    }
    if (!this.folderName) {
      this.folderName = dir;
    }
    if (this.suborderID && this.folderName) {
      const subscription = this.afAuth.authState.subscribe(async user => {
        subscription.unsubscribe();
        if (user) {
          this.isLoggedIn = true;
          this.showProgress = true;
          // this.downloadZip();
          this.getAllFiles();
        } else {
          this.isLoggedIn = false;
          this.login("download@imogent.com", "Imo@1234");
        }
      });
    }
  }

  login(email, password): void {
    this.showProgress = true;
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          // this.downloadZip();
          this.getAllFiles();
        });
      }).catch((error) => {
        window.alert(error.message);
        this.showProgress = false;
      });
  }

  urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  getAllFiles() {
    const self = this;
    const zip = new JSZip();
    const alreadyLoggedIn = this.isLoggedIn;
    this.isLoggedIn = true;
    const folderName = this.folderName;
    const files = [];
    const obs = combineLatest(
      this.os.getSubOrderDetails(this.suborderID),
      this.uploadService.getSubcollection('suborders', this.suborderID, folderName)
    ).pipe(map((response: any[]) => {
      response[0][folderName] = response[1];
      return response[0];
    }));
    const $sub = obs.subscribe(details => {
      $sub.unsubscribe();
      if (folderName === 'originalPhotos' && details.orderType === 'floorplan') {
        files.push({
          file_name: "Grundriss_moebliert.jpeg",
          download_url: details.imgUrl,
          fileSize: 0
        });
        if (details.isometric) {
          files.push({
            file_name: 'style_isometric.jpeg',
            download_url: details['baseurl'] + 'Seitenansicht_moebliert.jpeg',
            fileSize: 0
          });
        }
      }
      if (folderName === 'originalPhotos' && details.logo && details.logoImage && details.logoImage.fileName && details.logoImage.url) {
        files.push({
          file_name: details.logoImage.fileName,
          download_url: details.logoImage.url,
          fileSize: details.logoImage.fileSize || 0
        });
      }
      if (details[folderName] && details[folderName].length > 0) {
        details[folderName].forEach(file => {
          files.push(file);
        });
      }
      if (files.length < 1) {
        self.onFinish.emit('');
        self.progressMsg = "";
        self.progressPercent = 0;
        self.showProgress = false;
        self.showMessage = true;
        self.msgClass = "text-warning";
        self.globalMessage = "Folder does not exist!";
        if (!alreadyLoggedIn) {
          self.afAuth.auth.signOut()
            .then((result) => {
              // window.close();
            }).catch((error) => {
              // window.close();
            });
        }
        return;
      }
      files.forEach((fl, index) => {
        zip.file(fl.file_name, self.urlToPromise(fl.download_url), { binary: true });
      });
      zip.generateAsync({ type: "blob" }, function updateCallback(metadata) {
        self.progressPercent = metadata.percent.toFixed(2);
        self.progressMsg = self.progressPercent + " %";
      }).then(function callback(blob) {
        saveAs(blob, self.suborderID + "_" + self.folderName + ".zip");
        console.log("done !");
        self.onFinish.emit('');
        self.progressMsg = "";
        self.progressPercent = 0;
        self.showProgress = false;
        if (!alreadyLoggedIn) {
          self.afAuth.auth.signOut()
            .then((result) => {
            }).catch((error) => {
            });
        }
      }, function (e) {
        self.onFinish.emit('');
        self.showProgress = false;
        console.log(e);
      });
    });
  }

  /**
   * Not used anymore.
   */
  downloadZip(): void {
    var self = this;
    var zip = new JSZip();
    const alreadyLoggedIn = this.isLoggedIn;
    this.isLoggedIn = true;
    const subscription = this.afs.collection('suborders/' + this.suborderID + "/" + this.folderName).get().pipe(map(response => {
      let services = [];
      response.forEach(a => {
        services.push(a.data());
      });
      return services;
    })).subscribe((files: any[]) => {
      subscription.unsubscribe();
      files.forEach((fl, index) => {
        zip.file(fl.file_name, self.urlToPromise(fl.download_url), { binary: true });
      });
      zip.generateAsync({ type: "blob" }, function updateCallback(metadata) {
        self.progressPercent = metadata.percent.toFixed(2)
        self.progressMsg = self.progressPercent + " %";
      }).then(function callback(blob) {
        saveAs(blob, self.suborderID + ".zip");
        console.log("done !");
        self.onFinish.emit('');
        self.progressMsg = "";
        self.progressPercent = 0;
        self.showProgress = false;
        if (!alreadyLoggedIn) {
          self.afAuth.auth.signOut()
            .then((result) => {
              // window.close();
            }).catch((error) => {
              // window.close();
            });
        }
      }, function (e) {
        this.onFinish.emit('');
        self.showProgress = false;
        console.log(e);
      });
    });
  }
}
