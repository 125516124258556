import { Component, OnInit, ViewChild, Renderer2, forwardRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, FormControl, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'app-select-tiles',
  templateUrl: './select-tiles.component.html',
  styleUrls: ['./select-tiles.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectTilesComponent),
    multi: true,
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SelectTilesComponent),
    multi: true,
  }],
})
export class SelectTilesComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {

  private propagateChange = (_: any) => { };
  value: any;
  @ViewChild('parent') parent;
  @Input('tiles') tiles: {
    'title': string,
    'title2'?: string,
    'title3'?: string,
    'isSelected'?: boolean;
    'isDisabled'?: boolean;
    'value': string;
  }[];
  @Input('multiple') multiple: boolean;
  @Input('delimiter') delimiter: string;
  @Input('clsName') clsName: string;
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() { }

  writeValue(obj: string): void {
    let data = [];
    this.value = obj;
    if (obj) {
      if (this.multiple) {
        data = obj.split(this.delimiter);
      } else {
        data = [obj];
      }
      this.tiles.forEach(tile => {
        if (data.indexOf(tile.value) >= 0) {
          tile.isSelected = true;
        }
      });
    } else {
      this.value = obj;
      this.tiles.forEach(tile => {
        tile.isSelected = false;
      });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.tiles.forEach(tile => tile.isDisabled = isDisabled);
  }

  public validate(c: FormControl) {
    /* return (this.value) ? null : {
      required: {
        valid: false
      },
    }; */
    return null;
  }

  tileSelectHandler(tileIndex) {
    if (this.tiles[tileIndex].isDisabled) {
      return;
    }
    const checked = this.tiles[tileIndex].isSelected;
    let data;
    if (checked) {
      this.tiles[tileIndex].isSelected = !this.tiles[tileIndex].isSelected;
    } else {
      if (!this.multiple) {
        this.tiles.forEach(tile => tile.isSelected = false);
      }
      this.tiles[tileIndex].isSelected = !this.tiles[tileIndex].isSelected;
    }
    data = [];
    this.tiles.forEach(tile => {
      tile.isSelected && data.push(tile.value);
    });
    if (this.multiple) {
      data = data.join(this.delimiter);
    } else {
      data = data[0]
    }
    this.value = data;
    this.propagateChange(data);
    this.change.emit(data);
  }
}
