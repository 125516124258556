import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';
import { HttpClient } from '@angular/common/http';
import { SubOrder } from '@app/interfaces/suborder.interface';
import { SuborderStatusDates } from '@app/interfaces/suborder-status-dates.interface';
import { OrderStatusEnum } from '@app/models/order-status-list';
import { AuthService } from '@app/auth/auth.service';
import { EsoftApiService } from './esoft-api.service';
import { UsersService } from './users.service';
import { GlobalService } from './global.service';
import { OrderTypeEnum } from '@app/models/order-type.enum';
import { FunctionsService } from './functions.service';
import { WebsocketsService } from './websockets.service';

@Injectable({
  providedIn: 'root'
})
export class SubOrdersService {

  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.SUBORDERS;
  subOrdersList: SubOrder[] = [];
  currentSuborder: SubOrder;

  constructor(
    private wss: WebsocketsService,
    private http: HttpClient,
    private auth: AuthService,
    private esClient: EsoftApiService,
    private us: UsersService,
    private gs: GlobalService,
    private fs: FunctionsService
  ) { }

  createSubOrder(data: any) {
    return this.http.post<string>(this.endpoint, data).toPromise();
  }

  updateSubOrder(id: string, data: any) {
    return this.http.put<string>(this.endpoint + '/' + id, data).toPromise();
  }

  deleteSuborder(id: string) {
    return this.http.delete(this.endpoint + '/' + id).toPromise();
  }

  async updateStatusDates(
    suborder: SubOrder,
    statusDates: SuborderStatusDates,
    adminActionRequired: boolean,
    adminUpdate: boolean,
    customerActionRequired: boolean,
    serviceProviderActionRequired: boolean,
    additionalData = {}
  ) {
    let data = {
      statusDates: statusDates,
      adminActionRequired: adminActionRequired,
      adminUpdate: adminUpdate,
      customerActionRequired: customerActionRequired,
      serviceProviderActionRequired: serviceProviderActionRequired
    };
    if (additionalData.constructor === Object && Object.keys(additionalData).length !== 0) {
      data = {
        ...data,
        ...additionalData
      };
    }
    await this.updateSubOrder(suborder.id, data);
    this.updateOrderStatus(suborder.id);
  }

  updateOrderStatus(suborderId: string) {
    let processing = true;
    let completed = true;
    let accepted = true;
    let serviceProviderActionRequired = false;
    let adminActionRequired = false;
    let adminUpdate = false;
    let customerActionRequired = false;

    const subOrder = this.wss.allSuborders.value.find(item => item.id === suborderId);
    const relatedSubOrders = this.wss.allSuborders.value.filter(item => item.orderId === subOrder.orderId);

    relatedSubOrders.forEach(element => {
      if (element.status === OrderStatusEnum.Opened) {
        processing = false;
      }
      if (element.status !== OrderStatusEnum.Completed && element.status !== OrderStatusEnum.Accepted) {
        completed = false;
      }
      if (element.status !== OrderStatusEnum.Accepted) {
        accepted = false;
      }
      if (element.serviceProviderActionRequired) {
        serviceProviderActionRequired = true;
      }
      if (element.adminActionRequired || element.status === OrderStatusEnum.Opened || element.status === OrderStatusEnum.Requested || element.status === OrderStatusEnum.Uploaded || element.status === OrderStatusEnum.Canceled) {
        adminActionRequired = true;
      }
      if (element.adminUpdate) {
        adminUpdate = true;
      }
      if (element.customerActionRequired) {
        customerActionRequired = true;
      }
    });
    let status = OrderStatusEnum.Opened;
    if (processing) {
      status = OrderStatusEnum.Processing;
    }
    if (completed) {
      status = OrderStatusEnum.Completed;
    }
    if (accepted) {
      status = OrderStatusEnum.Accepted;
    }
    // TODO: Refactor: order service should update order. Rearrange dependencies between websocksts.service <--> orders.service <--> sub-orders.service
    this.http.put<string>(environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.ORDERS + '/' + subOrder.orderId, {
      status: status,
      adminActionRequired: adminActionRequired,
      adminUpdate: adminUpdate,
      customerActionRequired: customerActionRequired,
      serviceProviderActionRequired: serviceProviderActionRequired
    }).toPromise();
  }

  changeSuborder(subid: string, fieldToChange: string, value: any, additionalData = {}) {
    console.log(subid, fieldToChange, value);
    let data = {};
    data[fieldToChange] = value;

    if (additionalData.constructor === Object && Object.keys(additionalData).length !== 0) {
      data = {
        ...data,
        ...additionalData
      };
    }

    return this.updateSubOrder(subid, data);
  }

  /**
   * Changes the status of a suborder and automatically triggers the order status update
   * @param suborder The suborder object that will be updated
   * @param status the target status
   * @param videoURL If the suborder has a video output true, else false.
   */
  changeSuborderStatus(suborder: SubOrder, status: string, videoURL?: boolean) {
    return new Promise(async (resolve, reject) => {
      const statusDates = suborder.statusDates;
      statusDates[status] = new Date();
      const data = {
        status: status,
        statusDates: statusDates,
        completedOn: (status === 'completed') ? new Date() : ''
      };
      if (!videoURL && suborder.outputVideoUrl) {
        data['outputVideoUrl'] = suborder.outputVideoUrl;
      } else if (videoURL) {
        data['outputVideoUrl'] = videoURL;
      }

      await this.updateSubOrder(suborder.id, data);
      resolve();
    });
  }

  updatePendingSuborder(suborderId: string) {
    const subOrder = this.wss.fetchSuborder(suborderId);
    let statusDates = subOrder.statusDates;
    const pendingSubOrdersList = this.getPendingSuborders(suborderId);
    pendingSubOrdersList.forEach(subOrder => {
      // create order on esoft at this point if esoft api is enabled
      if (subOrder.status === OrderStatusEnum.Pending) {
        this.auth.showLoader.emit(true);
        this.esClient.createOrder(subOrder).then(() => {
          console.log('order creation on esoft handled');
          statusDates = subOrder.statusDates;
          statusDates['processing'] = new Date();
          this.updateSubOrder(subOrder.id, {
            status: OrderStatusEnum.Processing,
            statusDates: statusDates
          });
          this.auth.showLoader.emit(false);
        }).catch(() => this.auth.showLoader.emit(false));
      } else {
        this.updateSubOrder(subOrder.id, {
          status: OrderStatusEnum.Processing,
          statusDates: statusDates
        });
      }
    });
  }

  getPendingSuborders(suborderId: string) {
    return this.subOrdersList.filter(item => item.pendingOnSuborderId === suborderId);
  }

  sendOrderCompletionEmail(subOrderId: string, outputFolder?: string, comments?: string, withPlayer?: boolean): void {
    const folderName = outputFolder || 'outputPhotos';
    const subOrder = this.wss.fetchSuborder(subOrderId);
    this.us.getUserDetails(subOrder.createdBy).subscribe((customerData: any) => {
      subOrder.id = subOrderId;
      const requestData = {
        subOrderDetails: [],
        attachments: [],
        orderId: subOrder['orderId'],
        customerEmail: customerData.email,
        comments: comments || '',
        isWithPlayer: withPlayer || false
      };
      const orderTypesWithStyle = [OrderTypeEnum.Floor_Plan, OrderTypeEnum.V_Staging];
      subOrder.attachmentsLink = environment.platformUrl + 'downloadzip;sid=' + subOrderId + ';dir=' + folderName + ';token=' + customerData.uid;
      requestData.subOrderDetails.push({
        fields: this.gs.getEmailSubOrderKeyValuePairs(subOrder),
        produkt: subOrder['packageName'] || this.gs.translateAppListStrings('order_type_list', subOrder.orderType),
        orderType: subOrder.orderType,
        referenceFile: orderTypesWithStyle.indexOf(subOrder.orderType) < 0 ? false : {
          'cid': "styleimage.com",
          'filename': subOrder.orderType + '_style' + subOrder.referenceImage.substr(subOrder.referenceImage.lastIndexOf(".")),
          'path': subOrder.referenceImage
        },
        attachmentsLink: (subOrder.selectedPackage === 'Video' || subOrder.selectedPackage === 'Drohnenaufnahmen - Video' || subOrder.orderType === OrderTypeEnum.Virtual_Tour) ? '' : subOrder.attachmentsLink || '',
        isVideo: subOrder.outputVideoUrl ? true : false,
        videoLink: subOrder.outputVideoUrl || '',
        videoLinkMsg: subOrder.orderType === OrderTypeEnum.V_Staging ? 'Link zur Ihrer fertiggestellten virtuellen Tour' : 'Link zur Ihrem fertiggestellten Video',
        subOrder: subOrder
      });
      this.fs.callFunction('sendOrderCompletionEmail', requestData).subscribe();
    });
  }

  async addToDocumentation(suborderId: string, updatedDocumentation: any[]) {
    console.log(suborderId, updatedDocumentation);
    await this.updateSubOrder(suborderId, {
      documentation: updatedDocumentation
    });
  }

  async createStatusDates(suborder: SubOrder, newStatusDates: SuborderStatusDates) {
    await this.updateSubOrder(suborder.id, {
      statusDates: newStatusDates
    });
  }
}
