import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, Inject } from '@angular/core';
import { UploadService } from '@app/_services/upload.service';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-imo-attachment-explorer',
  templateUrl: './imo-attachment-explorer.component.html',
  styleUrls: ['./imo-attachment-explorer.component.css']
})
export class ImoAttachmentExplorerComponent implements OnInit, OnDestroy {

  attachments = [];
  $attachments: Subscription;

  @Input('parentType') parentType: string;
  @Input('id') parentId: string = '';
  @Input('folderName') folderName: string;
  @Input('canDelete') canDelete: boolean;
  @Input('feedbackMode') feedbackMode: boolean;
  @Input('feedbackStep') feedbackStep: number;
  @Input('suborder') suborder: any;
  @Output() onFetch: EventEmitter<any> = new EventEmitter();
  @Output() onFiles: EventEmitter<any> = new EventEmitter();
  @Input('mode') mode: string;
  @Input('feedbackBy') feedbackBy: string; // If feedback by Admin: Admin-Comments will be displayed.
  @ViewChild('carouselContainer') carouselContainer;
  tilesPerSlide: number = 1;
  indicators: boolean = true;
  showCarousel: boolean = false;
  selectedIndex = 0;
  selectedImg = null;
  carouselMessage = '';
  firstIndex = 1;
  lastIndex = 0;
  feedbackDesired: boolean = false;
  feedbackUnwanted: boolean = false;
  img_modal: DialogImageFullsize;
  feedbackStatus: any;
  private fileEnding: string;
  constructor(
    private uploadService: UploadService,
    public auth: AuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loadImages();
  }

  loadImages() {
    this.$attachments = this.uploadService.getSubcollection(this.parentType, this.parentId, this.folderName).subscribe(files => {
      files.forEach(fl => {
        fl['isImage'] = this.isImage(fl['file_name']);
        fl['shortendFilename'] = this.shorten(fl['file_name']);
      });
      this.attachments = files || [];
      this.onFetch.emit(this.attachments.length);
      this.onFiles.emit(this.attachments);
      this.selectedImg = files[0] || {};
      this.selectedIndex = 0;
      const imglength = this.attachments.length;
      const width = window.innerWidth;
      this.tilesPerSlide = Math.min(2, imglength);
      if (width > 1024) {
        this.tilesPerSlide = Math.min(8, imglength);
      } else if (width >= 992) {
        this.tilesPerSlide = Math.min(6, imglength);
      } else if (width >= 768) {
        this.tilesPerSlide = Math.min(4, imglength);
      }
      if (this.tilesPerSlide === imglength) {
        this.indicators = false;
      }
      this.showCarousel = true;
      this.lastIndex = this.tilesPerSlide;
      // this.slidesChangeMessage = this.firstIndex + ' - ' + this.lastIndex + ' of ' + this.attachments.length;
      // console.log(this.attachments);
    });
  }

  startFeedbackProcess() {
    this.feedbackUnwanted = false;
    this.feedbackDesired = false;
  }

  completeFeedback() {
    this.feedbackUnwanted = true;
    this.feedbackDesired = true;
    // this.sdc.completeFeedbackWithoutFeedback();
  }

  deleteAttachment(subColDocId: string, fileName: string, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm('Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!');
    if (confirmed) {
      const path = `${this.parentType}/${this.parentId}/${this.folderName}/${fileName}`;
      this.uploadService.deleteAttachment(this.parentType, this.parentId, this.folderName, subColDocId, path);
    }
    this.selectedImg = null;
    this.selectedIndex = 0;
  }

  onImageExpand(index, image) {
    this.selectedIndex = index;
    this.selectedImg = image;
    this.selectedImg['isImage'] = this.isImage(image.file_name);
  }

  onClick(event: Event) {
    if (event.target['className'] && event.target['className'].indexOf('icon-next ') >= 0) {
      if (this.lastIndex < this.attachments.length) {
        this.lastIndex += 1;
        this.firstIndex += 1;
      }
    }
    if (event.target['className'] && event.target['className'].indexOf('icon-prev ') >= 0) {
      if (this.firstIndex > 1) {
        this.lastIndex -= 1;
        this.firstIndex -= 1;
      }
    }
  }

  isImage(fileName: string): boolean {
    const imageExtns = ['jpeg', 'jpg', 'png'];
    fileName = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    return imageExtns.indexOf(fileName) >= 0;
  }

  ngOnDestroy() {
    this.$attachments && this.$attachments.unsubscribe();
  }

  openDialog(idx: number) {
    // this.img_modal = new DialogImageFullsize();
    // this.img_modal.setFilename(src);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      attachments: this.attachments,
      index: idx,
      canDelete: this.canDelete,
      suborderId: this.parentId,
      folderName: this.folderName
    };

    const dialogRef = this.dialog.open(DialogImageFullsize, dialogConfig);
    console.log('openDialog() called.');
    console.log(this.attachments);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private shorten(flElement: string): string {
    const fileEnding = flElement.substring(flElement.lastIndexOf('.') + 1);
    if (flElement.length >= 13) {
      flElement = flElement.substring(0, 10);
      flElement += '...' + fileEnding;
    }
    return flElement;
  }

  isPdf(fileName: string): boolean {
    const imageExtns = ['pdf'];
    fileName = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    return imageExtns.indexOf(fileName) >= 0;
  }
}

@Component({
  selector: 'app-dialog-image-fullsize',
  templateUrl: 'dialog-image-fullsize.html',
  styleUrls: ['./imo-attachment-explorer.component.css']
})
export class DialogImageFullsize implements OnInit {
  public filename: string;
  public download_url: string;
  public delete: boolean;
  public suborderId: string;
  public folderName: string;
  public attachments: object[];
  public idx: number;
  hideDownload = false;

  // @Input('description') description: string;

  constructor(
    private dialogRef: MatDialogRef<DialogImageFullsize>,
    @Inject(MAT_DIALOG_DATA) data,
    private us: UploadService) {
    this.attachments = data.attachments;
    this.idx = data.index;
    this.download_url = data.attachments[data.index].download_url;
    this.delete = data.canDelete;
    this.suborderId = data.suborderId;
    this.folderName = data.folderName;
    this.filename = data.attachments[data.index].file_name;
    this.hideDownload = data.hideDownload || false;
  }

  ngOnInit() {
  }

  updateImage($event: KeyboardEvent) {
    if ($event.key === 'ArrowLeft' && this.idx >= 1) {
      this.idx -= 1;
      this.download_url = this.attachments[this.idx]['download_url'];
      this.filename = this.attachments[this.idx]['file_name'];
      // console.log("new Index: " + this.idx);
      // console.log("new url:" + this.download_url);
    } else if ($event.key === 'ArrowRight' && this.idx < this.attachments.length - 1) {
      this.idx += 1;
      this.download_url = this.attachments[this.idx]['download_url'];
      this.filename = this.attachments[this.idx]['file_name'];
      // console.log("new Index: " + this.idx);
    }
  }

  isImage(fileName: string): boolean {
    const imageExtns = ['jpeg', 'jpg', 'png'];
    fileName = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    return imageExtns.indexOf(fileName) >= 0;
  }

  deleteAttachement(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const confirmed = confirm('Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!');
    if (confirmed) {
      const path = `${'suborders'}/${this.suborderId}/${this.folderName}/${this.filename}`;
      this.us.deleteAttachment('suborders', this.suborderId, this.folderName, this.attachments[this.idx]['id'], path);
      this.dialogRef.close();
    } else {
      alert('Löschvorgang abgebrochen!');
    }
  }

}
