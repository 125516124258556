import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { InvoiceService } from './invoice.service';
import { Invoice } from '@app/interfaces/invoice.interface';

@Injectable({
  providedIn: 'root'
})
export class InvoiceResolverService implements Resolve<Invoice[]> {

  constructor(
    private is: InvoiceService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.is.getAllInvoices();
  }
}
