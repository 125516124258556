import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FullscreenDialogData } from '@app/interfaces/fullscreen-dialog-data.interface';
import { FullscreenImageData } from '@app/interfaces/fullscreen-image-data.interface';

@Component({
  selector: 'app-fullscreen-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  styleUrls: ['./fullscreen-dialog.component.css']
})
export class FullscreenDialogComponent implements OnInit {
  private readonly logComponent = "FullscreenDialogComponent";

  tilesPerSlide = 1;
  firstIndex: number;
  lastIndex: number;
  showCarousel: boolean;

  constructor(
    public dialogRef: MatDialogRef<FullscreenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FullscreenDialogData
  ) { }

  ngOnInit() {
    const imglength = this.data.imagesList.length;

    if (imglength > 1) {
      this.showCarousel = true;
      this.tilesPerSlide = Math.min(3, imglength); // using 3 value here in order for the images don't get shrunk due to the 600px of width we are using in the fullscreen dialog
      this.firstIndex = 0;
      this.lastIndex = this.tilesPerSlide - 1;
    }
  }

  onSelectThumbnail(image: FullscreenImageData) {
    this.data.selectedImage = image;
  }

  onClick(event: Event) {
    if (event.target['className'] && event.target['className'].indexOf('icon-next ') >= 0) {
      if (this.lastIndex < this.data.imagesList.length - 1) {
        ++this.lastIndex;
        ++this.firstIndex;
      }
    }
    if (event.target['className'] && event.target['className'].indexOf('icon-prev ') >= 0) {
      if (this.firstIndex > 0) {
        --this.lastIndex;
        --this.firstIndex;
      }
    }
  }

  onSelectImage() {
    this.dialogRef.close(this.data.selectedImage);
  }

}
