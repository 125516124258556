import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TileModel } from './imo-tile.model';
import { OrdersService } from '@app/_services/orders.service';
import { GlobalService } from '@app/_services/global.service';
import { DiscountsService } from '@app/_services/discounts.service';
import { AuthService } from '@app/auth/auth.service';
import { DiscountTypeEnum } from '@app/models/discount-type-list';
import { MoneyService } from '@app/_services/money.service';

@Component({
  selector: 'app-imo-tile',
  templateUrl: './imo-tile.component.html',
  styleUrls: ['./imo-tile.component.css']
})
export class ImoTileComponent implements OnInit {

  @Input('tile') tile: TileModel;
  @Input('type') type: string;
  @Input('titleField') titleField: string;
  @Output() onToggle = new EventEmitter<any>();
  price = '0,00';

  constructor(
    public os: OrdersService,
    public gs: GlobalService,
    private ds: DiscountsService,
    public ms: MoneyService
  ) { }

  ngOnInit() {
    if (this.tile.price) {
      const priceData = this.gs.aps.getPositionData(this.tile.price);
      if (priceData) {
        this.price = this.gs.format(priceData.price) + '€';
      }
    }
    this.titleField = this.titleField || 'title';
  }

  toggleHandler(): void {
    /* if(!this.tile.isDisabled){
      const index = this.os.selectedServices.indexOf(this.tile.id);
      const cartIndex = this.os.cartItems.findIndex(item => item.id === this.tile.id);
      if (index >= 0) {
        this.os.selectedServices.splice(index, 1);
        this.os.cartItems.splice(cartIndex, 1);
      } else {
        this.os.selectedServices.push(this.tile.id);
        this.os.cartItems.push(this.tile);
      }
    }else{
      try {
        let service = this.tile;
        let updateDisabledServices = {};
        if (this.conf.disabledServices[service.title]) {
          updateDisabledServices[service.title] = this.conf.disabledServices[service.title] + 1;
        } else {
          updateDisabledServices[service.title] = 1;
        }
        this.afs.collection('configuration').doc('Lt8fpXKYGVNy6qjpi0hU/').update(updateDisabledServices);
      } catch (err) {
        console.error(err);
      }
    } */
    if (!this.tile.isFixed) {
      this.onToggle.emit(this.tile);
    }
  }
}
