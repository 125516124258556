import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";
import { Observable } from "rxjs";
import { AuthService } from "@app/auth/auth.service";
import { LoaderService } from "@app/_services/loader.service";
import { SidebarService } from "@app/sidebar/sidebar.service";
import { TranslateService } from '@ngx-translate/core';

declare const $: any;

//Metadata
export interface RouteInfo {
	path: string;
	title: string;
	type: string;
	icontype: string;
	collapse?: string;
	children?: ChildrenItems[];
	roles: string[]; // roles for which this link is allowed, possible values all, Administrator, Kunde, Dienstleister
}

export interface ChildrenItems {
	path: string;
	title: string;
	ab: string;
	type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
	{
		path: "/home",
		title: "sidebar.home",
		type: "link",
		icontype: "dashboard",
		roles: ["all"]
	},
	{
		path: "/orderoverview/orderlist",
		title: "sidebar.orders",
		type: "link",
		icontype: "business_center",
		roles: ["all"]
	},
	{
		path: "/orderoverview/savedorders",
		title: "sidebar.savedOrders",
		type: "link",
		icontype: "save",
		roles: ["Kunde", "Administrator"]
	},
	{
		path: "/createorder/selectservice",
		title: "Service buchen",
		type: "link",
		icontype: "note_add",
		roles: ["Kunde", "Administrator"]
	},
	{
		path: "/realestate",
		title: "Immobilien",
		type: "link",
		icontype: "store",
		roles: ["Kunde", "Administrator"]
	},
	{
		path: "/payments",
		title: "sidebar.payments",
		type: "link",
		icontype: "attach_money",
		roles: ["Administrator"]
	},
	{
		path: "/invoices",
		title: "Rechnungen",
		type: "link",
		icontype: "credit_card",
		roles: ["Kunde", "Administrator"]
	},
	{
		path: "/faq",
		title: "sidebar.requirements",
		type: "link",
		icontype: "question_answer",
		roles: ["Dienstleister", "Administrator"]
	},
	{
		path: "/users",
		title: "User Management",
		type: "link",
		icontype: "supervisor_account",
		roles: ["Administrator"]
	},
	{
		path: "/companies",
		title: "Unternehmen",
		type: "link",
		icontype: "domain",
		roles: ["all"]
	},
	{
		path: "/accounting-positions",
		title: "Preisverwaltung",
		type: "link",
		icontype: "euro_symbol",
		roles: ["Administrator"]
	},
	{
		path: "/discounts",
		title: "Rabatte",
		type: "link",
		icontype: "local_offer",
		roles: ["Administrator"]
	},
	{
		path: "/threesixtylist",
		title: "360 Degree Views",
		type: "link",
		icontype: "3d_rotation",
		roles: []
	}
];
@Component({
	selector: "app-sidebar-cmp",
	templateUrl: "sidebar.component.html"
})
export class SidebarComponent implements OnInit {
	public menuItems: any[];
	public myUserObservable: Observable<any>;

	constructor(
		private sidebarservice: SidebarService,
		public authService: AuthService,
		public loader: LoaderService,
		public translate: TranslateService
	) { }

	isMobileMenu() {
		if ($(window).width() > 991) {
			return false;
		}
		return true;
	}

	ngOnInit() {
		this.menuItems = ROUTES.filter(menuItem => menuItem);
	}
	updatePS(): void {
		if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
			const elemSidebar = <HTMLElement>document.querySelector(".sidebar .sidebar-wrapper");
			let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
		}
	}
	isMac(): boolean {
		let bool = false;
		if (
			navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
			navigator.platform.toUpperCase().indexOf("IPAD") >= 0
		) {
			bool = true;
		}
		return bool;
	}
	inArray(val: never, arr: []) {
		return arr.indexOf(val) > -1;
	}
}
