import { Injectable } from '@angular/core';
import { SubcollectionFileModel } from '@app/models/subcollectionFileModel';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '@app/util/constants';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { finalize } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  totalFileCount: number;
  currentFileIndex: number;
  public downloadURL = ''; // TODO: Delete when invoices and users tasks merged
  fileRef: AngularFireStorageReference; // TODO: Delete
  task: AngularFireUploadTask; // TODO: Delete
  uploadProgress: Observable<number> = of(0); // TODO: Delete

  constructor(
    private http: HttpClient,
    private afStorage: AngularFireStorage, // TODO: Delete
    private afs: AngularFirestore // TODO: Delete
  ) { }

  /**
   * Returns Subcollection Observable
   * @param docType The main collection name
   * @param docId The id of the document in the main collection
   * @param subColName The name of the subcollection
   */
  getSubcollection(docType: string, docId: string, subColName: string) {
    return this.http.get<SubcollectionFileModel[]>(`${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${docType}/${docId}/${subColName}`);
  }

  /**
   * Adds a document to the specified subcollection
   * @param parentType The main collection name
   * @param parentId The id of the document in the main collection
   * @param subCollectionName The name of the subcollection
   * @param data Document data for the newly added document
   */
  addSubCollectionDocument(parentType: string, parentId: string, subCollectionName: string, data: SubcollectionFileModel) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${parentType}/${parentId}/${subCollectionName}`, data).subscribe(response => {
        resolve(response);
      }, error => {
        reject(error);
      }
      );
    });
  }

  /**
   * Deletes Subcollection Document from firestore and file located under filePath from storage
   * @param docType The main collection name
   * @param docId The id of the document in the main collection
   * @param subColName The name of the subcollection
   * @param subColDocId The id of the doc in the subcollection
   * @param filePath path to the corresponding file which will be deleted from the storage
   */
  deleteAttachment(docType: string, docId: string, subColName: string, subColDocId: string, filePath: string) {
    // Delete Subcollection Document
    this.http.delete(`${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${docType}/${docId}/${subColName}/${subColDocId}`).toPromise()
      .then(() => {
        console.log('Document deleted, now deleting actual file');
        // Delete File
        this.http.request('delete', `${environment.apiUrl}files/`, {
          body: {
            'path': filePath
          }
        }).subscribe();
      })
      .catch(error => console.log(error));
  }

  /**
   * Uploads a file to the specified path. Thumbnails can be created and the target File name can be prefixed.
   * @param file The file that will be uploaded
   * @param path The path to which the file will be uploaded in the storage
   * @param createThumbnails? If true, different sizes of the image will be generated on the server and the urls returned
   * @param prefix? Can be used to add a prefix to the filename
   */
  uploadFile(file: any, path: string, createThumbnails?: boolean, prefix?: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    formData.append('createThumbnails', String(createThumbnails));
    formData.append('targetFilename', prefix + file.name);

    return this.http.post(`${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.FILES}/`, formData, { reportProgress: true, observe: 'events' });
  }

  /**
   * Deletes a file from the specified path.
   * @param filePath Path to the file in the storage
   */
  deleteFile(filePath: string) {
    return this.http.request('delete', `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.FILES}/`, {
      body: {
        'path': filePath
      }
    });
  }
}

