import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { RealEstate } from '@app/interfaces/real-estate.interface';
import { AuthService } from '@app/auth/auth.service';
import { UserRoleEnum } from '@app/models/user-role-list';
import { CompanyRoleEnum } from '@app/models/company-role-list';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';

@Injectable({
  providedIn: 'root'
})
export class RealestateService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.REAL_ESTATES;
  realEstateList: RealEstate[] = [];
  isListDataLoaded = false;

  // View States:
  add = false;
  edit = false;
  showSuborderList = false;
  public title: any = null;
  realEstateListChanged = new Subject();

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {}

  getRealestates() {
    let realEstates$: Observable<RealEstate[]>;

    if (
        this.auth.myUserObservable.role === UserRoleEnum.Customer &&
        this.auth.myUserObservable.companyRole === CompanyRoleEnum.CompanyManager &&
        this.auth.myUserObservable.company
    ) {
      realEstates$ = this.getMyCompanyRealEstates();
    } else {
      realEstates$ = this.getMyRealestates();
    }

    return realEstates$.pipe(
      tap(this.setListData.bind(this))
    );
  }

  private getMyRealestates() {
    return this.http.get<RealEstate[]>(this.endpoint + '/my');
  }

  private getMyCompanyRealEstates() {
    return this.http.get<RealEstate[]>(this.endpoint + '/my-company');
  }

  private setListData(data: RealEstate[]) {
    this.isListDataLoaded = true;
    this.realEstateList = [...data];
  }

  getRealestate(realestateId: string) {
    // search the item locally 1st
    const localRealEstate = this.realEstateList.find(item => item.id === realestateId);

    if (localRealEstate) {
      // returning founded item
      return of(localRealEstate);
    } else {
      // fetching from database because it wasn't found locally
      return this.http.get<RealEstate>(this.endpoint + '/' + realestateId).pipe(
        tap(realEstate => {
          // adding to local list
          this.realEstateList.push(realEstate);
        })
      );
    }
  }

  deleteRealestate(realestateId: string) {
    return this.http.delete<string>(this.endpoint + '/' + realestateId).pipe(
      tap(() => {
        this.realEstateList = this.realEstateList.filter((item) => item.id !== realestateId);
        // inform event subscribers that the list changed
        this.realEstateListChanged.next();
      })
    );
  }

  addRealEstate(data) {
    return this.http.post<RealEstate>(this.endpoint, data).pipe(
      tap((realEstate: RealEstate) => {
        this.realEstateList.push(realEstate);
        // inform event subscribers that the list changed
        this.realEstateListChanged.next();
      })
    );
  }

  updateRealEstate(realEstate: RealEstate) {
    return this.http.put<RealEstate>(this.endpoint + '/' + realEstate.id, realEstate).pipe(
      tap((updatedRealEstate: RealEstate) => {
        const foundIndex = this.realEstateList.findIndex(item => item.id === realEstate.id);

        // replace item with new data, but keep properties already changed in case the update is only partial
        this.realEstateList[foundIndex] = {
          ...this.realEstateList[foundIndex],
          ...updatedRealEstate
        };

        // inform event subscribers that the list changed
        this.realEstateListChanged.next();
      })
    );
  }
}
