import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FixedCartModule } from './fixed-cart/fixed-cart.module';
import { NavbarModule } from './navbar/navbar.module';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImoTileComponent } from './imo-tile/imo-tile.component';
import { LinkifyPipe } from './linkify.pipe';
import { SelectTilesComponent } from './select-tiles/select-tiles.component';
import { ImoFileUploadComponent } from './imo-file-upload/imo-file-upload.component';
import { ImoAttachmentExplorerComponent, DialogImageFullsize } from './imo-attachment-explorer/imo-attachment-explorer.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from '../material/material.module';
import { StatusDatesComponent } from './status-dates/status-dates.component';
import { FullscreenDialogComponent } from './fullscreen-dialog/fullscreen-dialog.component';

@NgModule({
  declarations: [
    UploadTaskComponent,
    FileUploadComponent,
    ImoTileComponent,
    LinkifyPipe,
    SelectTilesComponent,
    ImoFileUploadComponent,
    ImoAttachmentExplorerComponent,
    DialogImageFullsize,
    StatusDatesComponent,
    FullscreenDialogComponent
  ],
  entryComponents: [
    DialogImageFullsize,
    FullscreenDialogComponent
  ],
  imports: [
    CommonModule,
    FixedCartModule,
    NavbarModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    MaterialModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    FixedCartModule,
    NavbarModule,
    UploadTaskComponent,
    FileUploadComponent,
    ImoTileComponent,
    LinkifyPipe,
    SelectTilesComponent,
    ImoFileUploadComponent,
    ImoAttachmentExplorerComponent,
    DialogImageFullsize,
    FileUploadModule,
    StatusDatesComponent
  ]
})
export class SharedModule { }
