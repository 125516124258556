import { ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from "@angular/forms";
import { Observable } from "rxjs/internal/Observable";
import { map, switchMap, first } from "rxjs/operators";
import { timer } from "rxjs";

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const lowerCase = /(.*[a-z].*)/.test(control.value);
        const upperCase = /(.*[A-Z].*)/.test(control.value);
        const digit = /(.*\d.*)/.test(control.value);
        const length = control.value.length >= 6 && control.value.length <= 32;
        let validation = null;

        if (!length || !lowerCase || !upperCase || !digit) {
            validation = {
                'passwordError': {
                    value: 'Password not meeting requirements',
                    length: length,
                    lowerCase: lowerCase,
                    upperCase: upperCase,
                    digit: digit
                }
            }
        }
        return validation;
    };
}

export function repeatPasswordValidator(firstPassword): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let validation = null;
        if (firstPassword.value !== control.value) {
            validation = {
                'repeatPasswordError': {
                    value: 'Passwörter stimmen nicht überein!'
                }
            };
        }
        return validation;
    };
}

export function noRepeatPasswordValidator(firstPassword): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let validation = null;
        if (firstPassword.value === control.value) {
            validation = {
                'noRepeatPasswordError': {
                    value: 'New Password cannot be same as existing.'
                }
            };
        }
        return validation;
    };
}

export function fileLengthValidator(inputElem, fileQueue): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let isInValid = true;
        if (fileQueue.length >= parseInt(inputElem.value)) {
            isInValid = false;
        }
        let inputElemNumber = inputElem.value + ' Dateien';
        if (inputElem.value === '1') {
            inputElemNumber = 'eine Datei';
        }
        return isInValid ? { 'fileLengthInvalid': { value: 'Bitte laden Sie mindestens ' + inputElemNumber + ' hoch.' } } : null;
    };
}

/** A hero's name can't match the given regular expression */
export function forbiddenFileValidator(acceptedFormats: string[], elem): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let files = elem ? elem.files : [];
        let isInValid = false;
        acceptedFormats = acceptedFormats || [];
        acceptedFormats = acceptedFormats.map(format => format.toLowerCase());
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
            // acceptedFormats * means all extensions allowed
            if (acceptedFormats.indexOf(fileType.toLowerCase()) < 0 && acceptedFormats.indexOf('*') < 0) {
                isInValid = true;
                elem.value = "";
                break;
            }
        }
        return isInValid ? { 'forbiddenFile': { value: '' } } : null;
    };
}

export function fileRequiredValidator(fileQueue): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return fileQueue.length <= 0 ? { 'fileRequired': { value: '' } } : null;
    };
}

export function germanZipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const isGermanZipCode = /^\d{5}$/.test(control.value);
        let validation = null;

        if (!isGermanZipCode) {
            validation = {
                'noGermanZipCodeError': {
                    value: 'Postleitzahl ungültig.'
                }
            };
        }

        return validation;
    };
}

export function existingEmailValidator(companyId, userService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        return timer(300).pipe(
            switchMap(() => userService.getUserRefByEMail(control.value)),
            map((response: any) => {
                if (!response || !response.uid) {
                    return {
                        invalidEmail: 'Nutzer existiert nicht.'
                    };
                }
                if (response && response['company'] && companyId === response['company']['id']) {
                    return {
                        invalidEmail: 'Nutzer ist bereits diesem Unternehmen zugeordnet.'
                    };
                }
                return null;
            }),
            first()
        );
    };
}
