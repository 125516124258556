import { Component, OnInit, ViewChild } from '@angular/core';
import { ThreesixtyService } from '@app/_services/threesixty.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThreeSixtyView } from './../threesixty-view-manager/model/threesixtyview.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-threesixty-view-list',
  templateUrl: './threesixty-view-list.component.html',
  styleUrls: ['./threesixty-view-list.component.css']
})
export class ThreesixtyViewListComponent implements OnInit {

  columnsToDisplay = ['id', 'createdOn', 'desc', 'status', 'edit'];
  orderIdSaved = false;
  id = "";
  userDataSrc = new MatTableDataSource([]);
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.userDataSrc.sort = value;
  }
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.userDataSrc.paginator = value;
  }
  filterValues = {};
  statusList = [];
  mode = 'list';
  selectedView;
  createMode = false;

  constructor(
    private viewsService: ThreesixtyService,
    private router: Router
  ) { }

  ngOnInit() {
    const self = this;
    self.setTableFilter();
    self.statusList = [];
    const availableStatuses = [];
    self.viewsService.listAllThreeSixtyViews().subscribe((viewList: ThreeSixtyView[]) => {
      self.userDataSrc.data = viewList;
      viewList.forEach((row: any) => {
        if (availableStatuses.indexOf(row.status) < 0) {
          availableStatuses.push(row.status);
          self.statusList.push({
            'label': row.status,
            'value': row.status
          });
        }
      });
      self.setTableFilter();
    });
  }

  saveSuborderID() {
    this.orderIdSaved = true;
  }

  setTableFilter(): void {
    let self = this;
    self.userDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(self.filterValues).forEach(key => {
        if (self.filterValues[key] && object[key].toString().toLowerCase().indexOf(self.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.userDataSrc.filter = 'test';
  }

  noSort(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onStatusFilter(event): void {
    this.filterValues['status'] = event['value'];
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.userDataSrc.filter = 'test';
  }

  openViewDetailPage(row): void {
    this.selectedView = row;
    this.mode = 'details';
    this.createMode = false;
  }

  closeDetailPage(): void {
    this.selectedView = null;
    this.mode = 'list';
    this.createMode = false;
  }
}
