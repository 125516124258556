import { Injectable } from '@angular/core';
import * as accounting from 'accounting';

@Injectable({
  providedIn: 'root'
})
export class MoneyService {

  constructor() { }
  public floatToString(money) {
    if (typeof money === 'string') {
      return money;
      // money = accounting.unformat(money, ',');
    }
    return accounting.formatMoney(money, '', 2, '.', ',');
  }

  public stringToFloat(money) {
    if (typeof money === 'number') {
      return money;
      // money = accounting.formatMoney(money, '', 2, '.', ',');
    }
    return accounting.unformat(money, ',');
  }
}
