﻿import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from './home';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile';
import { InboxComponent } from './inbox';
import { SettingsComponent } from './settings';
import { RealestateComponent } from './realestate';
import { FaqComponent } from './faq';
import { ForgotComponent } from './forgot/forgot.component';
import { PaymentsComponent } from './payments/payments.component';
import { AuthGuard } from './_guards/auth.guard';
import { TermsComponent } from './terms/terms.component';
import { UsersComponent } from './users/users.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DownloadZipComponent } from "./download-zip-module/download-zip.component";
import { NgModule } from "@angular/core";
import { FloortourComponent } from "./floortour/floortour.component";
import { ThreesixtyViewListComponent } from "./threesixty-view-list/threesixty-view-list.component";
import { AccountingPositionsComponent } from "./accounting-positions/accounting-positions.component";
import { ThreesixtyViewManagerComponent } from "./threesixty-view-manager/threesixty-view-manager.component";
import { DiscountsComponent } from "./discounts/discounts.component";
import { CompaniesComponent } from '@app/companies/companies.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { UsersService } from "./_services/users.service";
import { AccountingPositionResolverService } from "./_services/accounting-positions-resolver.service";
import { InvoiceResolverService } from "./_services/invoices-resolver.service";
import { RealEstatesResolverService } from "./_services/real-estates-resolver.service";
import { CompaniesResolverService } from "./_services/companies-resolver.service";
import { DiscountsResolverService } from "./_services/discounts-resolver.service";
import {WebsocketsService} from '@app/_services/websockets.service';

const appRoutes: Routes = [
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'discounts', component: DiscountsComponent, canActivate: [AuthGuard], data: { roles: ['Administrator'] }, resolve: [CompaniesResolverService, DiscountsResolverService] },
    // { path: 'orderoverview', component: OrderoverviewComponent, canActivate: [AuthGuard] },
    // { path: 'services', component: ServicesComponent, canActivate: [AuthGuard] },
    { path: 'realestate', component: RealestateComponent, canActivate: [AuthGuard], resolve: [RealEstatesResolverService] },
    { path: 'faq', component: FaqComponent, canActivate: [AuthGuard] },
    { path: 'inbox', component: InboxComponent, canActivate: [AuthGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
    { path: 'forgot', component: ForgotComponent },
    { path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard], data: { roles: ['Administrator'] } },
    { path: 'terms', component: TermsComponent },
    { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { roles: ['Administrator'] }, resolve: [CompaniesResolverService, UsersService] },
    { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard], resolve: [CompaniesResolverService] },
    { path: 'accounting-positions', component: AccountingPositionsComponent, canActivate: [AuthGuard], data: { roles: ['Administrator'] }, resolve: [AccountingPositionResolverService] },
    { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard], resolve: [InvoiceResolverService] },
    { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
    {
        path: 'createorder',
        loadChildren: './order-creation-module/order-creation.module#OrderCreationModule',
        data: { roles: ['Kunde', 'Administrator'] },
        canActivate: [AuthGuard]
    },
    {
        path: 'orderoverview',
        loadChildren: './orders-view-module/orders.module#OrdersModule',
        canActivate: [AuthGuard],
        resolve: [WebsocketsService]
    },
    {
        path: "downloadzip",
        component: DownloadZipComponent
    },
    {
        path: "imo360/:sid",
        component: FloortourComponent
    },
    {
        path: "imo360",
        component: FloortourComponent
    },
    /* {
        path: "suborder/:sid",
        component: OrderoverviewComponent,
        canActivate: [AuthGuard]
    }, */
    {
        path: 'threesixtylist',
        component: ThreesixtyViewListComponent
    },
    { path: '**', redirectTo: 'createorder' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
