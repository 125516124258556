import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { LANGUAGE, SUBORDER_DETAILS_MAP } from '@app/language/language';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from './users.service';
import { AccountingPositionsService } from './accounting-positions.service';
import * as accounting from 'accounting';

declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  userId: any;
  languageSelected: string;
  aviableLanguages = [
    {
      'name': 'language.german',
      'tag': 'de'
    },
    {
      'name': 'language.english',
      'tag': 'en'
    }];
  constructor(
    public translate: TranslateService,
    public us: UsersService,
    public aps: AccountingPositionsService) {
    this.translate.addLangs(['de', 'en']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    this.languageSelected = this.translate.currentLang;
  }

  changeLanguage(language: string, uid?: string) {
    uid ? this.userId = uid : '';
    this.translate.use(language);
    this.languageSelected = this.translate.currentLang;
    this.us.updateUser(this.userId, { language: language });
  }

  /**
   * Returns the label_key translation from list_name defined in LANGUAGE.app_list_strings constant in language.ts
   */
  translateAppListStrings(list_name: string, label_key: string): string {
    if (_.isEmpty(list_name) || _.isEmpty(label_key)) {
      return '';
    }
    if (!_.isEmpty(LANGUAGE['app_list_strings'][list_name]) && !_.isEmpty(LANGUAGE['app_list_strings'][list_name][label_key])) {
      return LANGUAGE['app_list_strings'][list_name][label_key];
    }
    return label_key; // return the un translated label if translation not found
  }

  /**
   * Returns the label_key translation from LANGUAGE.app_strings constant in language.ts
   */
  translateAppStrings(label_key: string): string {
    if (_.isEmpty(label_key)) {
      return '';
    }
    if (!_.isEmpty(LANGUAGE['app_strings'][label_key])) {
      return LANGUAGE['app_strings'][label_key];
    }
    return label_key; // return the un translated label if translation not found
  }

  getSubOrderKeyValuePairs(subOrderDetails) {
    let details = [];
    const detailsMap = SUBORDER_DETAILS_MAP[subOrderDetails.orderType];

    this.addDocumentsProcurementDetailsFields(subOrderDetails, details);

    Object.keys(detailsMap).forEach((key: any) => {
      let entry = detailsMap[key];
      if (key === 'logoImage' && subOrderDetails.logo) {
        details.push({
          'label': entry.label,
          'value': subOrderDetails[key].url,
          'type': entry.type
        });
      } else if (key === 'logoImage' && !subOrderDetails.logo) {
        details.push({
          'label': entry.label,
          'value': subOrderDetails.logo,
          'type': 'boolean'
        });
      } else if (!entry.dependentField) {
        details.push({
          'label': entry.label,
          'value': subOrderDetails[key],
          'type': entry.type,
          'isComment': entry.isComment || false
        });
      } else if (entry.dependentField && subOrderDetails[entry.dependentField.field] === entry.dependentField.value) {
        details.push({
          'label': entry.label,
          'value': subOrderDetails[key],
          'type': entry.type,
          'isComment': entry.isComment || false
        });
      }
    });
    return details;
  }

  format(input?: number) {
    if (input) {
      return accounting.formatMoney(input, '', 2, '.', ',');
    } else {
      return '0,00';
    }
  }

  unformat(input?: string) {
    if (input) {
      return accounting.unformat(input, ',');
    } else {
      return 0.0;
    }
  }

  getEmailSubOrderKeyValuePairs(subOrderDetails) {
    let details = [];
    const detailsMap = SUBORDER_DETAILS_MAP[subOrderDetails.orderType];
    details.push({
      'label': 'Objektname',
      'value': subOrderDetails.Desc
    });
    details.push({
      'label': 'Produkt Referenznummer',
      'value': subOrderDetails.id,
    });

    this.addDocumentsProcurementDetailsFields(subOrderDetails, details);

    Object.keys(detailsMap).forEach((key: any) => {
      let entry = detailsMap[key];
      if (entry.label === 'Produkt') {
        return;
      }
      if (key === 'logoImage') {
        details.push({
          'label': entry.label,
          'value': subOrderDetails['logo'] ? 'Ja' : 'Nein',
        });
      } else if (entry.type === 'booleanString') {
        details.push({
          'label': entry.label,
          'value': subOrderDetails[key] ? subOrderDetails[key] : 'Nein',
        });
      } else if (entry.type === 'list') {
        details.push({
          'label': entry.label,
          'value': subOrderDetails[key].sort().join('; '),
        });
      } else if (!entry.dependentField) {
        details.push({
          'label': entry.label,
          'value': entry.type === 'string' ? subOrderDetails[key] : (subOrderDetails[key] ? 'Ja' : 'Nein'),
        });
      } else if (entry.dependentField && subOrderDetails[entry.dependentField.field] === entry.dependentField.value) {
        details.push({
          'label': entry.label,
          'value': entry.type === 'string' ? subOrderDetails[key] : (subOrderDetails[key] ? 'Ja' : 'Nein'),
        });
      }
    });
    return details;
  }

  private addDocumentsProcurementDetailsFields(subOrderDetails, details) {
    /**
     * Fields used specifically for documents_procurement service type
     */
    if (subOrderDetails.orderType !== 'documents_procurement') {
      return;
    }

    const addressDataArray = this.buildDocumentsProcurementAddress(subOrderDetails);

    details.push({
      'label': 'Addresse',
      'value': addressDataArray.join('<br>'),
    });

    this.addLandRegistrySheetList(subOrderDetails, details);
  }

  private buildDocumentsProcurementAddress(subOrderDetails): string[] {
    const addressDataArray: string[] = [];

    addressDataArray.push('Straße: ' + subOrderDetails['street']);
    addressDataArray.push('Nr: ' + subOrderDetails['streetNumber']);
    addressDataArray.push('PLZ: ' + subOrderDetails['postalcode']);
    addressDataArray.push('Ort: ' + subOrderDetails['city']);

    return addressDataArray;
  }

  private addLandRegistrySheetList(subOrderDetails, details) {
    if (subOrderDetails['landRegistrySheetList']) {
      let counter = 0;
      subOrderDetails['landRegistrySheetList'].forEach(landRegistrySheet => {
        const landRegistryDataArray: string[] = [];

        ++counter;

        landRegistryDataArray.push('Grundbuchblatt: ' + landRegistrySheet['name']);
        landRegistryDataArray.push('Gemarkung: ' + landRegistrySheet['district']);

        if (landRegistrySheet['hallList']) {
          landRegistrySheet['hallList'].forEach(hall => {
            landRegistryDataArray.push('Flur: ' + hall['name']);

            if (hall['pieceList']) {
              hall['pieceList'].forEach(piece => {
                landRegistryDataArray.push('Flurstück: ' + piece['name']);
              });
            }
          });
        }

        details.push({
          'label': 'Objektdaten' + (subOrderDetails['landRegistrySheetList'].length > 0 ? ' ' + counter : ''),
          'value': landRegistryDataArray.join('<br>'),
        });
      });
    }
  }

  showNotification(message: string, type?: string) {
    let icon = '';
    switch (type) {
      case 'info':
        icon = 'info';
        break;
      case 'success':
        icon = 'check_circle';
        break;
      case 'danger':
        icon = 'error';
        break;
      case 'warning':
        icon = 'warning';
        break;
      default:
        icon = 'notifications'
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: type || 'info',
      timer: 3000,
      placement: {
        from: 'top',
        align: 'center'
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  /**
   * Return an environment configuration
   *
   * @param {string} name
   * @returns
   * @memberof GlobalService
   */
  getEnvironmentConfig(name: string) {
    return environment[name] ? environment[name] : {};
  }
}
