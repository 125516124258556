import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DiscountsService } from './discounts.service';
import { Discount } from '@app/interfaces/discount.interface';

@Injectable({
  providedIn: 'root'
})
export class DiscountsResolverService implements Resolve<Discount[]> {

  constructor(
    private ds: DiscountsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.routeConfig.path === 'discounts') {
      return this.ds.getAll();
    } else {
      return this.ds.getMyCompanyDiscounts();
    }
  }
}
