import { Injectable } from '@angular/core';
import { ACCOUNTING_POSITIONS } from '@app/models/accounting-positions';
import * as _ from 'underscore';
import { AccountingPosition } from '@app/interfaces/accounting-position.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountingPositionsService {
  public allPositions: AccountingPosition[];
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.ACCOUNTINGPOSITIONS;
  isListDataLoaded = false;


  constructor(
    private http: HttpClient) {
  }

  getAllPositions() {
    return this.http.get<AccountingPosition[]>(this.endpoint).pipe(
      tap(data => {
        this.setListData(data);
      })
    );
  }

  private setListData(data: AccountingPosition[]) {
    this.isListDataLoaded = true;
    this.allPositions = [...data];
  }

  updateAccountingPositions(accPositions?: AccountingPosition[]) {
    if (!accPositions) {
      accPositions = ACCOUNTING_POSITIONS;
    }
    if (this.allPositions) {
      accPositions.forEach(pos => {
        const foundIndex = this.allPositions.findIndex(item => item.id === pos.id);
        this.allPositions[foundIndex] = pos;
      });
    }

    this.http.put(this.endpoint, accPositions).subscribe(
      val => {
        console.log("PUT call successful value returned in body",
          val);
      },
      response => {
        console.log("PUT call in error", response);
      },
      () => {
        console.log("The PUT observable is now completed.");
      }
    );
  }

  getPositionData(id: string): AccountingPosition {
    return _.find(this.allPositions, function (position: any) { return position.id === id; }, this);
  }
}