import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import * as business from 'moment-business';

@Component({
  selector: 'app-status-dates',
  templateUrl: './status-dates.component.html',
  styleUrls: ['./status-dates.component.css']
})
export class StatusDatesComponent implements OnInit {
  @Input('suborder') suborder: any;
  @Input('role') role: any;
  @Input('iteration') iteration: number;

  delayed = false;
  delayDays = 0;
  statusDates: any;

  constructor() { }

  ngOnInit() {
    if (this.suborder.statusDates['feedbacks']) {
      if (this.suborder.statusDates['feedbacks'][this.iteration] && this.suborder.statusDates['feedbacks'][this.iteration]['estimatedDelivery']) {
        let currentDate: moment.Moment = moment().startOf('day');
        this.statusDates = this.suborder.statusDates['feedbacks'][this.iteration];
        Object.keys(this.statusDates).forEach(entry => {
          if(this.statusDates[entry] != "" && this.statusDates[entry].seconds && this.statusDates[entry].nanoseconds) {
            if(!this.statusDates[entry]){
              this.statusDates[entry] = function() {
                return moment(this).format("DD.MM.YYYY");
              }
            }
          }
        });
        let estimatedMoment: moment.Moment = moment(this.statusDates['estimatedDelivery'].seconds * 1000).startOf('day');
        if (currentDate.diff(estimatedMoment, 'days') > 0 && !this.statusDates['delivery']) {
          this.delayed = true;
        }
        if (this.statusDates['delivery']) {
          let deliveredMoment: moment.Moment = moment(this.statusDates['delivery'].seconds * 1000);
          this.delayDays = business.weekDays(estimatedMoment, deliveredMoment);
        }
      } else {
        this.statusDates = {};
      }
    }
  }
}
