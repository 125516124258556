import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '@app/_services/loader.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthService,
        private loader: LoaderService
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      /* This method checks the firebase AuthState and navigates to login, if the user is not logged in.
         The method is used by the router on each Route that has this specification: "canActivate: [AuthGuard]" */
      return await this.auth.checkAuthState().then(result => {
        this.loader.loaded = true;
        if (result === true) {
          // Check if route is restricted by role
          if (route.data && route.data.roles && route.data.roles.indexOf(this.auth.myUserObservable.role) === -1) {
            console.log('[GUARD]: Insufficient Permissions to access the request route.');
            if (this.auth.myUserObservable.role !== 'Dienstleister') {
              this.router.navigate(['/']);
            } else {
              this.router.navigate(['/orderoverview']);
            }
            return false;
          }
          console.log('[GUARD]: Logged in, so staying on current page!');
          return true;
        } else {
          console.log('[GUARD]: Not logged in, now navigating to login page!');
          this.auth.redirectUrl = state.url;
          this.router.navigate(['/login']);
          return false;
        }
      });
    }
    }
