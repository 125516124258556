import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { RealestateService } from '@app/_services/realestate.service';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';
import { of, Subscription, combineLatest, Observable } from 'rxjs';
import { UsersService } from './users.service';
import { SERVICES } from '@app/models/package';
import { GlobalService } from './global.service';
import { environment } from '@environments/environment';
import { EsoftApiService } from './esoft-api.service';
import { AuthService } from '@app/auth/auth.service';
import { MoneyService } from '@app/_services/money.service';
import * as firebase from 'firebase/app';
import { InvoiceService } from './invoice.service';
import { DocEstateService } from './docestate.service';
import { FunctionsService } from '@app/_services/functions.service';
import { HttpClient } from '@angular/common/http';
import { UploadService } from '@app/_services/upload.service';
const firestore = firebase.firestore;
@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  public currentRealestateTitle: any;
  public currentRealestateDescription: any;
  public currentRealestateAdress: any;
  public allOrders: any;
  public myCreatedOrders: any;
  public myCompanyCreatedOrders: any;
  public allSuborders: any;
  public allSubordersId: any;
  public myRequestedSuborders: any;
  public myCompanyRequestedSuborders: any;
  public currentSuborder: any;
  public $suborderSub: Subscription;
  public onSubOrderDetailsFetch: EventEmitter<boolean> = new EventEmitter();
  public completionEmailSub: Subscription;
  public allCompleteOrdersSub: Subscription;
  public allSubOrdersSub: Subscription;
  public allAcceptedOrdersSub: Subscription;
  public allServicesSub: Subscription;
  public imoServices: any[];
  public selectedRealEstate: any;
  public cartItems: any[] = [];
  public selectedServices: any[] = [];
  public imoPackages: any;
  public selectedPackageIds: any[] = [];
  public selectedPackageNames: any[] = [];
  public showInVisLink: boolean = false;
  public showOutVisLink: boolean = false;
  public showOrderConfirm: boolean = false;
  protected esoftSuborderTypes = ['vstaging']; // those suborders which should be done by esoft
  public savedOrderId = '';
  public isOrderSavedForLater = false;
  savedOrders: any = [];
  deletedSubOrderIds: string[] = [];
  selectedPackages: any = [];
  editingSavedOrder = false;
  previousCartItems = [];
  previousSelectedServices = [];
  onProductModification = new EventEmitter<any>();
  onSaveServiceForLater = new EventEmitter<any>();
  onSaveServiceFinish = new EventEmitter<any>();
  onCustomModification = new EventEmitter<any>();
  createdOrderDetails = {};
  apiBaseUrl = environment.apiUrl;

  // not required?
  // public allUnassignedOrders: any;
  // public myAssignedOrders: any;
  // public myRequestedOrders: any;
  // public myCompletedOrders: any;
  // public myUploadedOrders: any;
  // public allUnassignedSuborders: any;
  // public myAssignedSuborders: any;
  // public myUploadedSuborders: any;
  // public myCompletedSuborders: any;


  public showSubOrderDetails: EventEmitter<any> = new EventEmitter();

  public allSubordersTemp: any;

  constructor(
    protected afs: AngularFirestore,
    private realestateservice: RealestateService,
    private us: UsersService,
    private gs: GlobalService,
    protected esClient: EsoftApiService,
    protected auth: AuthService,
    public ms: MoneyService,
    public ivs: InvoiceService,
    private des: DocEstateService,
    private fs: FunctionsService,
    private uploadService: UploadService,
    protected http: HttpClient
  ) { }

  // not required?

  // getAllUnassignedOrders() {
  //   // Returned alle unzugeordneten Orders, die aber schon "checked" sind, als Observable für HTML Async Usage
  //   return this.afs.collection('orders', ref => {
  //     return ref
  //       .where('assignedTo', '==', '')
  //       .where('status', '==', 'checked');
  //   }).valueChanges();
  // }

  // getAllUnassignedSuborders() {
  //   return this.afs.collection('suborders', ref => {
  //     return ref
  //       .where('assignedTo', '==', '')
  //       .where('status', '==', 'checked');
  //   }).snapshotChanges().pipe(map(response => {
  //     return response.map(a => {
  //       const data = a.payload.doc.data();
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     });
  //   }));
  // }

  // getMyUploadedSuborders(userId: string) {
  //   return combineLatest(
  //     this.afs.collection('suborders', ref => {
  //       return ref
  //         .where('assignedTo', '==', userId)
  //         .where('status', '==', 'completed');
  //     }).snapshotChanges(),
  //     this.afs.collection('suborders', ref => {
  //       return ref
  //         .where('assignedTo', '==', userId)
  //         .where('status', '==', 'uploaded');
  //     }).snapshotChanges()
  //   ).pipe(map(response => {
  //     const combinedResponse = response[0].concat(response[1]);
  //     return combinedResponse.map(a => {
  //       const data = a.payload.doc.data();
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     });
  //   }));
  // }

  // getMyCompletedSuborders(userId: string) {
  //   return this.afs.collection('suborders', ref => {
  //     return ref
  //       .where('assignedTo', '==', userId)
  //       .where('status', '==', 'accepted');
  //   }).snapshotChanges().pipe(map(response => {
  //     return response.map(a => {
  //       const data = a.payload.doc.data();
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     });
  //   }));
  // }


  getAllOrders() {
    return this.afs.collection('orders', ref => {
      return ref
        .where('savedForLater', '==', false)
        .orderBy('createdOn', 'desc')
    }).valueChanges();
  }

  // todo: temporary for getAllSuborders (suborder-overview)
  getAllSubordersTemp(userId?: any, role?: any) {
    if (role === 'Kunde') {
      return this.afs.collection('suborders', ref => {
        return ref
          .where('createdBy', '==', userId)
          .where('savedForLater', '==', false);
      }).snapshotChanges().pipe(map(response => {
        return response.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }));
    } else {
      return this.afs.collection('suborders', ref => {
        return ref.where('savedForLater', '==', false)
      }).snapshotChanges().pipe(map(response => {
        return response.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }));
    }
  }

  // gets triggered when suborder status changes. Checks status of all suborders related to this orderId and changes status of order
  updateOrderStatus(suborderId: string) {
    let processing = true;
    let completed = true;
    let accepted = true;
    let serviceProviderActionRequired = false;
    let adminActionRequired = false;
    let adminUpdate = false;
    let customerActionRequired = false;

    const subOrder = _.find(this.allSubordersTemp, function (suborder: any) { return suborder.id === suborderId; }, this);
    const relatedSubOrders = _.filter(this.allSubordersTemp, function (suborder: any) { return suborder.orderId === subOrder.orderId; }, this);

    relatedSubOrders.forEach(element => {
      if (element.status === 'opened') {
        processing = false;
      }
      if (element.status !== 'completed' && element.status !== 'accepted') {
        completed = false;
      }
      if (element.status !== 'accepted') {
        accepted = false;
      }
      if (element.serviceProviderActionRequired) {
        serviceProviderActionRequired = true;
      }
      if (element.adminActionRequired || element.status === 'opened' || element.status === 'requested' || element.status === 'uploaded' || element.status === 'canceled') {
        adminActionRequired = true;
      }
      if (element.adminUpdate) {
        adminUpdate = true;
      }
      if (element.customerActionRequired) {
        customerActionRequired = true;
      }
    });
    let status = 'opened';
    if (processing) {
      status = 'processing';
    }
    if (completed) {
      status = 'completed';
    }
    if (accepted) {
      status = 'accepted';
    }
    this.afs.collection('orders').doc(subOrder.orderId).update({
      status: status,
      adminActionRequired: adminActionRequired,
      adminUpdate: adminUpdate,
      customerActionRequired: customerActionRequired,
      serviceProviderActionRequired: serviceProviderActionRequired
    });
  }

  async assignSuborder(suborder: any, userId: string) {
    await this.afs.collection('suborders').doc(suborder.id).update({
      assignedTo: userId,
      status: 'requested',
      adminActionRequired: true
    });
    this.updateOrderStatus(suborder.id);
  }

  changeAssignment(service: string, id: string) {
    console.log('changeAssignment');
    const batch = this.afs.firestore.batch();
    _.each(this.allSubordersTemp, function (suborder: any) {
      if (suborder.assignedTo !== id && suborder.orderType === service && suborder.status !== 'uploaded' && suborder.status !== 'completed') {
        console.log(suborder.id, ', ', suborder.assignedTo);
        batch.update(
          this.afs.firestore.doc('suborders/' + suborder.id), {
          assignedTo: id
        }
        );
      }
    }, this);
    batch.commit();
  }

  async acceptRequest(suborder: any, response: boolean) {
    await this.afs.collection('suborders').doc(suborder.id).set({
      status: response ? 'processing' : 'checked',
      serviceProviderActionRequired: response ? true : false,
      assignedTo: response ? suborder.assignedTo : '',
      statusDates: response ? {
        'processing': new Date()
      } : {
          'checked': new Date(),
        },
      adminActionRequired: false
    }, { merge: true });
    this.updateOrderStatus(suborder.id);
  }

  // not needed?
  // addToInvoice(subId, description, quantity, total, accountingPosition) {
  //   this.afs.collection('suborders').doc(subId).set({
  //     'description': description,
  //     'quantity': quantity,
  //     'total': total,
  //     'accounting-position': accountingPosition
  //   }, { merge: true });
  // }

  unAssignOrder(orderId: string) {
    this.afs.collection('suborders').doc(orderId).update({
      assignedTo: '',
      status: 'checked'
    }).then(() => {
      // parent order's status is affected when suborder status chanages
      this.updateOrderStatus(orderId);
    });
  }

  getCreatorDetails(uidParam: string) {
    return this.afs.collection('users').doc(uidParam).valueChanges();
  }

  async addToDocumentation(suborderId, updatedDocumentation) {
    console.log(suborderId, updatedDocumentation);
    await this.afs.collection('suborders').doc(suborderId).update({
      documentation: updatedDocumentation
    });
  }

  updateInvoice(suborder, prepayment) {
    let sum = 0.0;
    let sumBefore = this.ms.stringToFloat(suborder['total_price']);
    let billed_sum = 0;
    // check for old version of invoice_details
    if (!suborder['invoice_details'][0]['accounting-position']) {
      suborder['invoice_details'].forEach(item => {
        item.discount ? sum += this.ms.stringToFloat(item.total) * (1 - item.discount) : sum += this.ms.stringToFloat(item.total);
      });
    } else {
      suborder['invoice_details'].forEach(item => {
        item.discount ? sum += item.quantity * item.total * (1 - item.discount) : sum += item.quantity * item.total;
        if (item['accounting-position'] === 'billed') {
          billed_sum += item.total;
        }
      });
    }
    this.afs.collection('suborders').doc(suborder.id).update({
      total_price: this.ms.floatToString(sum),
      billed_sum: this.ms.floatToString(billed_sum),
      invoice_details: suborder['invoice_details']
    });
    if (prepayment) {
      this.updateOrderPrice(suborder['orderId'], this.ms.stringToFloat(sum) - this.ms.stringToFloat(sumBefore) - billed_sum);
    } else {
      this.updateOrderPrice(suborder['orderId'], this.ms.stringToFloat(sum) - this.ms.stringToFloat(sumBefore));
    }
  }

  updateOrderPrice(orderId, diff) {
    const tempOrder = _.find(this.allOrders, function (order: any) { return order.orderId === orderId; }, this);
    this.afs.collection('orders').doc(orderId).update({
      price: this.ms.floatToString(this.ms.stringToFloat(tempOrder.price) + this.ms.stringToFloat(diff))
    });
  }

  getAssignedOrders(userId: string) {
    return this.afs.collection('orders', ref => {
      return ref
        .where('assignedTo', '==', userId);
    }).valueChanges();
  }

  getRequestedOrders(userId: string) {
    return this.afs.collection('orders', ref => {
      return ref
        .where('assignedTo', '==', userId)
        .where('status', '==', 'requested');
    }).valueChanges();
  }

  getCreatedOrders(userId: string) {
    return this.afs.collection('orders', ref => {
      return ref
        .where('createdBy', '==', userId)
        .where('savedForLater', '==', false)
        .orderBy('createdOn', 'desc')
    }).valueChanges();
  }

  getCompanyCreatedOrders(companyDocRef: DocumentReference) {
    const companyUsers$ = this.afs.collection('users', ref => ref.where('company', '==', companyDocRef)).valueChanges();

    return companyUsers$.pipe(
      mergeMap(companyUsersData => {
        const requests = [];

        companyUsersData.forEach((item: any) => {
          requests.push(
            this.getCreatedOrders(item.uid)
          );
        });

        return combineLatest(
          requests
        ).pipe(map((response: any) => {
          let combinedResponse = [];
          for (let request = 0; request < response.length; request++) {
            combinedResponse = combinedResponse.concat(response[request]);
          }
          return combinedResponse;
        }));
      })
    );
  }

  getCompanyRequestedSubOrders(companyDocRef: DocumentReference) {
    const companyUsers$ = this.afs.collection('users', ref => ref.where('company', '==', companyDocRef)).valueChanges();

    return companyUsers$.pipe(
      mergeMap(companyUsersData => {
        const requests = [];

        companyUsersData.forEach((item: any) => {
          requests.push(
            this.getMyRequestedSuborders(item.uid)
          );
        });

        return combineLatest(
          requests
        ).pipe(map((response: any) => {
          let combinedResponse = [];
          for (let request = 0; request < response.length; request++) {
            combinedResponse = combinedResponse.concat(response[request]);
          }
          return combinedResponse;
        }));
      })
    );
  }

  getOrder(orderIdParam: string) {
    return this.afs.doc(`orders/${orderIdParam}`);
  }

  getSuborder(suborderIdParam: string) {
    return this.afs.doc(`suborders/${suborderIdParam}`);
  }

  getOrderDetails(orderIdParam: string) {
    return this.getOrder(orderIdParam).valueChanges();
  }

  getSubOrder(suborderIdParam: string) {
    return this.afs.doc(`suborders/${suborderIdParam}`).snapshotChanges();
  }

  getSubOrderDetails(suborderIdParam: string) {
    return this.getSuborder(suborderIdParam).valueChanges();
  }

  getSuborderDetails(suborderIdParam: string) {
    if (!_.isEmpty(this.$suborderSub)) {
      this.$suborderSub.unsubscribe();
    }
    this.$suborderSub = this.getSubOrder(suborderIdParam).subscribe(
      {
        next: response => {
          const data = response.payload.data();
          const id = response.payload.id;
          this.currentSuborder = { id, ...data };
          this.onSubOrderDetailsFetch.emit(true);
        }
      }
    );
    return this.$suborderSub;
  }

  async setStatusChecked(orderIdParam: string, order) {
    // update the status of child suborders
    const batch = this.afs.firestore.batch();
    let firstSuborderId = '';
    let billedSum = 0.0;
    await this.allSuborders.forEach(async (suborder: any) => {
      if (firstSuborderId === '') {
        firstSuborderId = suborder.id;
      }
      if (this.gs.unformat(order.price) >= 1000) {
        billedSum += this.gs.unformat(suborder.total_price) / -2;
        suborder['invoice_details'].push({
          'quantity': 1,
          'description': 'Vorauszahlung',
          'total': this.gs.unformat(suborder.total_price) / -2,
          'total_unformatted': this.gs.unformat(suborder.total_price) / -2,
          'accounting-position': 'billed'
        });
        this.updateInvoice(suborder, true);
      }
      if (suborder.status === 'opened') {
        let status = 'checked';
        // for retouching, virtual staging, directly set status to processing
        if (suborder.orderType === 'floorplan') {
          status = 'processing';
        }
        if (suborder.orderType === 'vstaging' || suborder.orderType === 'retouching') {
          // create order on esoft first
          this.auth.showLoader.emit(true);
          await this.esClient.createOrder(suborder).then(async () => {
            console.log('order creation on esoft handled');
            this.afs.collection('suborders').doc(suborder.id).update({
              status: 'processing',
              adminActionRequired: false
            }).then(() => {
              this.auth.showLoader.emit(false);
              this.updateOrderStatus(firstSuborderId);
            });
          }).catch(() => {
            this.auth.showLoader.emit(false);
          });
        } else if (suborder.orderType === 'documents_procurement') {
          this.auth.showLoader.emit(true);
          await this.des.placeDocEstateOrder(suborder).then((docEstateOrderId) => {
            console.log('Order created on DocEstate platform.');
            this.auth.showLoader.emit(false);
            this.afs.collection('suborders').doc(suborder.id).update({
              status: 'processing',
              adminActionRequired: false
            }).then(() => {
              this.auth.showLoader.emit(false);
              this.updateOrderStatus(firstSuborderId);
            });
          }).catch((error) => {
            this.gs.showNotification(error, 'danger');
            this.auth.showLoader.emit(false);
          });
        } else {
          batch.update(
            this.afs.firestore.doc('suborders/' + suborder.id), {
            adminActionRequired: false,
            status: status
          }
          );
        }
      }
      if (suborder.status === "pending") {
        batch.update(
          this.afs.firestore.doc('suborders/' + suborder.id), {
          adminActionRequired: false
        }
        );
      }
      if (suborder.assignedTo && suborder.status !== "pending") {
        let statusDates = suborder.statusDates;
        statusDates.processing = new Date();
        this.updateStatusDates(suborder, statusDates, false, false, false, false);
      }
    });
    for (let i = 0; i < this.allSuborders.length; i++) {
      if (this.allSuborders[i].orderType === 'floorplan') {
        this.sendConfirmationEmail(this.allSuborders[i].orderId, 'serviceProvider');
        break;
      }
    }
    batch.commit().then(() => this.updateOrderStatus(firstSuborderId));
    if (this.gs.unformat(order.price) >= 1000) {
      console.log(order);
      console.log(order.orderId);
      let invoiceData = {
        'uid': order.createdBy,
        'billingAddress': order.billingAddress,
        'realestateId': order.realestateId,
        'realestateName': order.Desc,
        'total': this.gs.unformat(order.price) / 2,
        'status': 'created',
        'createdOn': new Date(),
        'orderId': order.orderId,
        'invoice_details': [{
          'createdOn': new Date(),
          'quantity': 1,
          'description': 'Vorauszahlung',
          'total': this.gs.unformat(order.price) / 2,
          'total_unformatted': this.gs.unformat(order.price) / 2,
          'accounting-position': 'billed'
        }]
      };
      // ToDo
      // this.ivs.createInvoice(invoiceData);
    }
  }

  setStatusUploaded(oid: string) {
    return this.afs.collection('orders').doc(oid).update({
      status: 'uploaded'
    });
  }

  updateDocument(docType: string, docId: string, data: {}) {
    if (_.isEmpty(data)) {
      return;
    }
    return this.afs.collection(docType).doc(docId).update(data);
  }

  // Reusable method to add or update a field and its value both provided as parameters
  changeOrder(oid: string, fieldToChange: string, value) {
    console.log(oid, fieldToChange, value);
    const data = {};
    data[fieldToChange] = value;
    return this.afs.collection('orders').doc(oid).update(data);
  }

  // Resusable method to add or update a field and its value both provided as parameters
  changeSuborder(subid: string, fieldToChange: string, value: any, additionalData = {}) {
    console.log(subid, fieldToChange, value);
    let data = {}
    data[fieldToChange] = value;
    if (!_.isEmpty(additionalData)) {
      data = _.extend(data, additionalData);
    }
    return this.afs.collection('suborders').doc(subid).update(data);
  }


  setStatusCompleted(oid: string, rid: string) {
    return this.afs.collection('orders').doc(oid).update({
      status: 'completed',
      completedOn: new Date()
    });
  }

  createStatusDates(suborder, newStatusDates) {
    return this.afs.collection('suborders').doc(suborder.id).update({
      statusDates: newStatusDates
    });
  }
  /**
   *
   *
   * @param {*} suborder
   * @param {*} statusDates
   * @param {*} adminActionRequired
   * @param {*} adminUpdate
   * @param {*} [additionalData={}] additional data to be saved in the database if required. Otherwise leave empty
   * @memberof OrdersService
   */
  async updateStatusDates(suborder, statusDates, adminActionRequired, adminUpdate, customerActionRequired, serviceProviderActionRequired, additionalData = {}) {
    let data = {
      statusDates: statusDates,
      adminActionRequired: adminActionRequired,
      adminUpdate: adminUpdate,
      customerActionRequired: customerActionRequired,
      serviceProviderActionRequired: serviceProviderActionRequired
    };
    if (!_.isEmpty(additionalData)) {
      data = _.extend(data, additionalData);
    }
    await this.afs.collection('suborders').doc(suborder.id).set(data, { merge: true }).then(() => {
      this.updateOrderStatus(suborder.id);
    });
  }

  async acceptSuborderResults(suborder) {
    //    await this.
  }

  async changeSuborderStatus(suborder: any, status: string, videoURL?: any, noEmailFlag?) {
    let statusDates = {};
    statusDates[status] = new Date();
    const data = {
      status: status,
      statusDates: statusDates,
      completedOn: (status === 'completed') ? new Date() : ''
    };
    if (!videoURL && suborder.outputVideoUrl) {
      data['outputVideoUrl'] = suborder.outputVideoUrl;
    } else if (videoURL) {
      data['outputVideoUrl'] = videoURL;
    }
    await this.afs.collection('suborders').doc(suborder.id).set(data, { merge: true }).then(() => {
      // parent order's status is affected when suborder status chanages
      this.updateOrderStatus(suborder.id);
    });
  }

  setPhotoLink(orderIdParam: string, formValue: any) {
    return this.afs.collection('orders').doc(orderIdParam).update({
      editedPictures: formValue.photoLink
    });
  }

  // customer can cancel order if admin did not accept order before
  cancelOrder(oidParam: string) {
    // set status for order and all related suborders to 'canceled'
    const relatedSubOrders = _.filter(this.allSubordersTemp, function (suborder: any) { return suborder.orderId === oidParam; }, this);
    relatedSubOrders.forEach(element => {
      this.afs.collection('suborders').doc(element.id).update({
        status: 'canceled'
      });
    });
    this.afs.collection('orders').doc(oidParam).update({
      status: 'canceled'
    });
    // admin can delete order
  }

  deleteOrder(oidParam: string, ridParam: string) {
    this.afs.collection('orders').doc(oidParam).delete();
    // also delete the suborders when the order is deleted
    const batch = this.afs.firestore.batch();
    _.each(this.allSuborders, function (suborder: any) {
      batch.delete(
        this.afs.firestore.doc('suborders/' + suborder.id)
      );
    }, this);
    batch.commit().then(() => {
      console.log('Suborders deleted');
    });
  }

  // Suborders


  getSubordersID(orderID: string) {
    // fetch data as an observable and attach document id to it
    return this.afs.collection('suborders', ref => {
      return ref
        .where('orderId', '==', orderID);
    }).snapshotChanges().pipe(map(response => {
      return response.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  // get Photo Suborders for Feedback-Tool
  getSubordersPhotoID(orderID: string) {
    return this.afs.collection('suborders', ref => {
      return ref
        .where('orderId', '==', orderID).where('addUnit', '==', 'Foto');
    }).snapshotChanges().pipe(map(response => {
      return response.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data }
      })
    }))
  }

  getMyRequestedSuborders(userId: string) {
    return this.afs.collection('suborders', ref => {
      return ref
        .where('assignedTo', '==', userId);
    }).snapshotChanges().pipe(map(response => {
      return response.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  getAssignedSuborders(userId: string) {
    return this.afs.collection('suborders', ref => {
      return ref
        .where('assignedTo', '==', userId)
        .where('status', '==', 'processing');
    }).snapshotChanges().pipe(map(response => {
      return response.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  getSubordersForSkills(skills, orderTypes) {
    const requests = [];
    for (let skill = 0; skill < skills.length; skill++) {
      if (skills[skill]) {
        requests.push(this.afs.collection('suborders', ref => {
          return ref
            .where('orderType', '==', orderTypes[skill])
            .where('status', '==', 'checked')
            .where('assignedTo', '==', '');
        }).snapshotChanges());
      }
    }

    return combineLatest(
      requests
    ).pipe(map((response: any) => {
      let combinedResponse = [];
      for (let request = 0; request < response.length; request++) {
        combinedResponse = combinedResponse.concat(response[request]);
      }
      return combinedResponse.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  getPendingSuborders(suborderId: string) {
    // fetch data as an observable and attach document id to it
    return this.afs.collection('suborders', ref => {
      return ref
        .where('pendingOnSuborderId', '==', suborderId);
    }).snapshotChanges().pipe(map(response => {
      return response.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  addOrderTypes() {
    this.allOrders.forEach(order => {
      let serviceList = [];
      let matchingSuborders = _.filter(this.allSubordersTemp, function (suborder: any) { return suborder.orderId === order.orderId; }, this)
      matchingSuborders.forEach(suborder => {
        serviceList.push(suborder['orderType']);
      });
      this.changeOrder(order.orderId, 'packageTypes', serviceList);
    });
  }

  updatePendingSuborder(suborderId: string) {
    let $subOrders, subOrders;
    let statusDates = {};
    $subOrders = this.getPendingSuborders(suborderId).subscribe(data => {
      $subOrders.unsubscribe();
      _.each(data, function (subOrder: any) {
        // create order on esoft at this point if esoft api is enabled
        if (subOrder.status === 'pending') {
          this.auth.showLoader.emit(true);
          this.esClient.createOrder(subOrder).then(() => {
            console.log('order creation on esoft handled');
            statusDates = subOrder.statusDates;
            statusDates['processing'] = firestore.Timestamp.fromDate(new Date());
            this.afs.collection('suborders').doc(subOrder.id).set({
              status: 'processing',
              statusDates: statusDates
            }, { merge: true });
            this.auth.showLoader.emit(false);
          }).catch(() => this.auth.showLoader.emit(false));
        } else {
          this.afs.collection('suborders').doc(subOrder.id).set({
            status: 'processing',
            statusDates: statusDates
          }, { merge: true });
        }
        /*let statusDates = subOrder.statusDates;
        statusDates.processing = firestore.Timestamp.fromDate(new Date());
        this.updateStatusDates(subOrder, statusDates);*/
      }, this);
    });
  }

  sendOrderCompletionEmail(subOrderId: string, outputFolder?: string, comments?: string, withPlayer?: boolean): void {
    const folderName = outputFolder || 'outputPhotos';
    this.completionEmailSub && this.completionEmailSub.unsubscribe();
    this.completionEmailSub = this.getSubOrder(subOrderId).subscribe(response => {
      const subOrder: any = response.payload.data();
      this.completionEmailSub && this.completionEmailSub.unsubscribe();
      this.us.getUserDetails(subOrder.createdBy).subscribe((customerData: any) => {
        subOrder.id = subOrderId;
        const requestData = {
          subOrderDetails: [],
          attachments: [],
          orderId: subOrder['orderId'],
          customerEmail: customerData.email,
          comments: comments || '',
          isWithPlayer: withPlayer || false
        };
        const orderTypesWithStyle = ['floorplan', 'vstaging'];
        subOrder.attachmentsLink = environment.platformUrl + 'downloadzip;sid=' + subOrderId + ';dir=' + folderName + ';token=' + customerData.uid;
        requestData.subOrderDetails.push({
          fields: this.gs.getEmailSubOrderKeyValuePairs(subOrder),
          produkt: subOrder['packageName'] || this.gs.translateAppListStrings('order_type_list', subOrder.orderType),
          orderType: subOrder.orderType,
          referenceFile: orderTypesWithStyle.indexOf(subOrder.orderType) < 0 ? false : {
            'cid': "styleimage.com",
            'filename': subOrder.orderType + '_style' + subOrder.referenceImage.substr(subOrder.referenceImage.lastIndexOf(".")),
            'path': subOrder.referenceImage
          },
          attachmentsLink: (subOrder.selectedPackage === 'Video' || subOrder.selectedPackage === 'Drohnenaufnahmen - Video' || subOrder.orderType === 'virtual_tour') ? '' : subOrder.attachmentsLink || '',
          isVideo: subOrder.outputVideoUrl ? true : false,
          videoLink: subOrder.outputVideoUrl || '',
          videoLinkMsg: subOrder.orderType === 'vstaging' ? 'Link zur Ihrer fertiggestellten virtuellen Tour' : 'Link zur Ihrem fertiggestellten Video',
          subOrder: subOrder
        });
        this.us.getUserDetails(subOrder.createdBy).subscribe(customerData => {
          requestData.customerEmail = customerData['email'];
          this.fs.callFunction('sendOrderCompletionEmail', requestData).subscribe();
        });
      });
    });
  }

  getAllSuborders(orderId: string) {
    return new Promise(resolve => {
      this.allSubOrdersSub = this.afs.collection('suborders', ref => {
        return ref
          .where('orderId', '==', orderId);
      }).snapshotChanges().pipe(map(response => {
        const subOrders = [];
        response.forEach(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.uploadService.getSubcollection('suborders', id, 'originalPhotos').subscribe(attachments => {
            data['attachments'] = attachments.map(file => {
              return {
                'fileName': file.file_name,
                'url': file.download_url
              };
            });
            data['logoImage'] && data['attachments'].push(data['logoImage']);
            subOrders.push({ id, ...data });
            if (subOrders.length === response.length) {
              resolve(subOrders);
            }
          });
        });
      })).subscribe();
    });
  }

  /**
   * This is a sample method to add new services to the firebase.
   */
  addServices() {
    this.http.put(this.apiBaseUrl + 'services', JSON.parse(JSON.stringify(SERVICES))).subscribe(result => {
      for (const id in result) {
        console.log('updated: ' + result[id]['id']);
      }
    });
  }

  getSubordersByRealEstate(realEstateId: string) {
    return this.afs.collection('suborders', ref => {
      return ref
        .where('realestateId', '==', realEstateId);
    }).snapshotChanges().pipe(map(response => {
      return response.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }

  setOrderFeedback(subOrderId: string, feedback: string) {
    return this.afs.collection('suborders').doc(subOrderId).update({
      feedback: feedback
    });
  }

  removeServiceProvider(subOrderId: string) {
    return this.afs.collection('suborders').doc(subOrderId).update({
      assignedTo: ''
    });
  }

  assignToAdmin(subOrderId: string, adminId: string) {
    return this.afs.collection('suborders').doc(subOrderId).update({
      assignedTo: adminId
    });
  }

  setExpectedCompletionDate(subOrderId: string, date: any) {
    return this.afs.collection('suborders').doc(subOrderId).update({
      expectedCompletionDate: date
    });
  }

  getAllServices() {
    return this.http.get<object[]>(this.apiBaseUrl + 'services');
  }

  getImoServices() {
    return new Promise((resolve, reject) => {
      this.getAllServices().subscribe(servs => {
        let services = [];
        this.imoPackages = {};
        servs.forEach(service => {
          services = services.concat(service['packages']);
          this.imoPackages[service['id']] = service['packages'];
        });
        this.imoServices = services;
        resolve(services);
      });
    });
  }

  sendConfirmationEmail(orderId: string, toWho): void {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const requestData = {
      subOrderDetails: [],
      attachments: [],
      orderId: orderId,
      details: {}
    };
    const orderTypesWithStyle = ['floorplan', 'vstaging'];
    const orderTypesWithFiles = ['floorplan', 'vstaging', 'retouching', 'visualisation'];
    this.getAllSuborders(orderId).then((subOrders: [any]) => {
      // console.log(subOrders);
      const allSubOrders = new Promise((res, rej) => {
        const subOrderCount = subOrders.length;
        let iteration = 0;
        subOrders.forEach(async (subOrder, index) => {
          this.uploadService.getSubcollection('suborders', subOrder.id, 'originalPhotos').subscribe(
            (files) => {
              if (files && files.length > 0) {
                subOrder.attachmentsLink = environment.platformUrl + 'downloadzip;sid=' + subOrder.id + ';dir=originalPhotos;token=' + user.uid;
              }
              if (orderTypesWithFiles.indexOf(subOrder.orderType) >= 0 && !subOrder.pendingOnSuborderId) {
                subOrder.attachmentsLink = environment.platformUrl + 'downloadzip;sid=' + subOrder.id + ';dir=originalPhotos;token=' + user.uid;
              }
              if (subOrder.orderType === 'visualisation' && ((subOrder.customField && subOrder.customField.length > 0) || (subOrder.configurationObjects && subOrder.configurationObjects.length > 0))) {
                subOrder.customFieldLink = environment.apiBaseUrl + 'downloadvisform?oid=' + subOrder.id;
              }
              requestData.subOrderDetails.push({
                fields: this.gs.getEmailSubOrderKeyValuePairs(subOrder),
                produkt: subOrder['packageName'] || this.gs.translateAppListStrings('order_type_list', subOrder.orderType),
                orderType: subOrder.orderType,
                referenceFile: orderTypesWithStyle.indexOf(subOrder.orderType) < 0 ? false : {
                  'cid': index + '_styleimage.com',
                  'filename': subOrder.orderType + '_' + index + '_style' + subOrder.referenceImage.substr(subOrder.referenceImage.lastIndexOf('.')),
                  'path': subOrder.referenceImage
                },
                attachmentsLink: subOrder.attachmentsLink || '',
                customFieldLink: subOrder.customFieldLink || '',
                details: subOrder
              });
              iteration += 1;
              if (iteration === subOrderCount) {
                res();
              }
            }
          );
        });
      });
      allSubOrders.then(() => {
        let emailType = '';
        if (toWho === 'customer') {
          emailType = 'sendOrderConfirmationEmail';
        } else if (toWho === 'serviceProvider') {
          emailType = 'sendFpOrderToServiceProvider';
        }
        this.fs.callFunction(emailType, requestData).subscribe();
      });
    });
  }

  changeSubOrders(): void {
    const subReq$ = this.afs.collection('suborders').get().subscribe(subOrderRef => {
      subReq$.unsubscribe();
      const batch = this.afs.firestore.batch();
      subOrderRef.forEach(subOrderDoc => {
        const flag = subOrderDoc.data().savedForLater || false;
        batch.update(this.afs.firestore.doc('suborders/' + subOrderDoc.id), {
          savedForLater: flag
        });
      });
      batch.commit().then(() => {
        this.changeOrders();
      });
    });
  }
  changeOrders(): void {
    const req$ = this.afs.collection('orders').get().subscribe(subOrderRef => {
      req$.unsubscribe();
      const batch = this.afs.firestore.batch();
      subOrderRef.forEach(subOrderDoc => {
        const flag = subOrderDoc.data().savedForLater || false;
        batch.update(this.afs.firestore.doc('orders/' + subOrderDoc.id), {
          savedForLater: flag
        });
      });
      batch.commit();
    });
  }

  getSavedOrders(): Observable<any> {
    const role = this.auth.myUserObservable.role;
    const userId = this.auth.myUserObservable.uid;
    if (role === 'Administrator') {
      return this.afs.collection('orders', ref => {
        return ref
          .where('savedForLater', '==', true)
          .orderBy('createdOn', 'desc')
      }).valueChanges();
    } else if (role === 'Kunde') {
      return this.afs.collection('orders', ref => {
        return ref
          .where('createdBy', '==', userId)
          .where('savedForLater', '==', true)
          .orderBy('createdOn', 'desc')
      }).valueChanges();
    } else {
      return null;
    }
  }

  moveOrderFromSavedtoPlaced(orderId: string) {
    const sub$ = this.getSubordersID(orderId).subscribe(async (subOrders: any[]) => {
      sub$.unsubscribe();
      const batch = this.afs.firestore.batch();
      await subOrders.forEach(subOrderDoc => {
        batch.update(this.afs.firestore.doc('suborders/' + subOrderDoc.id), {
          'createdOn': new Date(),
          'savedForLater': false
        });
      });
      batch.commit().then(() => {
        this.afs.collection('orders').doc(orderId).update({
          'createdOn': new Date(),
          'savedForLater': false
        }).then(() => {
          this.sendConfirmationEmail(orderId, 'customer');
        });
      });
    }, (error) => {
      sub$.unsubscribe();
    });
  }
}
