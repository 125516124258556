import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../_services/users.service';
import { AuthService } from '@app/auth/auth.service';
import { CompaniesService } from '../_services/companies.service';
import { LoaderService } from '@app/_services/loader.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrdersService } from '@app/_services/orders.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '@app/_services/configuration.service';
import { CONSTANTS } from '@app/util/constants';
import { GlobalService } from '@app/_services/global.service';
import { UploadService } from '@app/_services/upload.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})

export class UsersComponent implements OnInit, OnDestroy {
  columnsToDisplayCustomer = ['createdOn', 'Desc', 'packages', 'status', 'priceNum', 'info'];
  columnsToDisplay = ['name', 'email', 'role', 'uid', 'registeredOn', 'company', 'status'];
  serviceForm: FormGroup;
  private currentUserData: any;
  private curUserId: string;
  public state = 'default';
  public success = false;
  configurationDocumentId = 'rsidBwFzO3Kp356emm9N';
  showSuccessMessage = false;
  successMessage = '';
  userDataSrc = new MatTableDataSource(this.us.allUsers);
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.userDataSrc.sort = value;
  }
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.userDataSrc.paginator = value;
  }
  filterValues = {};
  rolesList = [];
  curCompanyId: string;
  curCompany: any;
  selectedCompany: any;
  usersUpdatesub$: Subscription;
  serviceProviderData;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    public loader: LoaderService,
    private us: UsersService,
    public cs: CompaniesService,
    private os: OrdersService,
    private router: Router,
    private globalService: GlobalService,
    private configService: ConfigurationService,
    private uploadService: UploadService
    // private tableStatus: TableStatusComponent
  ) {
    // Set the validations for the serviceProvider form
    this.serviceForm = this.fb.group({
      floorplanService: [''],
      retouchingService: [''],
      vstagingService: ['']
    });
  }

  ngOnInit() {
    this.preloadData();
    this.setTableFilter();
    this.usersUpdatesub$ = this.us.onUsersRefresh.subscribe(() => {
      this.userDataSrc = new MatTableDataSource(this.us.allUsers);
      this.setTableFilter();
    });
    this.rolesList = [{
      value: 'Administrator',
      label: 'Administrator'
    }, {
      value: 'Dienstleister',
      label: 'Dienstleister'
    }, {
      value: 'Kunde',
      label: 'Kunde'
    }];
  }

  setTableFilter(): void {
    this.userDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValues).forEach(key => {
        if (!object[key] || this.filterValues[key] && object[key].toLowerCase().indexOf(this.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  ngOnDestroy() {
    this.usersUpdatesub$ && this.usersUpdatesub$.unsubscribe();
  }

  /**
   * Opens user's detail view
   */
  openDetailPage(row) {
    this.currentUserData = row;
    this.curUserId = row.uid;
    this.state = 'detail-view';
    if (row['company'] && row['company']['id']) {
      this.curCompanyId = row['company']['id'];
      this.selectedCompany = this.cs.companiesList.find(c => c.cid === this.curCompanyId);
    } else {
      this.selectedCompany = undefined;
    }
  }

  /**
   * Sets the pilot as verified (called by admin) after he(the pilot/service provider) uploads the required documents.
   */
  async setVerified(uidParam: string) {
    await this.us.updateUser(uidParam, {
      pilotVerified: true,
      verificationNeeded: false,
      verificationFailed: false
    })
      .then((userData) => {
        console.log('pilot verified successfully');
        this.success = true;
        this.currentUserData = userData;
      })
      .catch((error) => {
        this.globalService.showNotification('Unable to verify pilot', 'danger');
        console.log('unable to verify pilot: ', error);
      });
    setTimeout(() => {
      this.success = false;
    }, 2000);
  }

  /**
   * Sets the emailVerfied as true in user document (called by admin)
   */
  async setEmailVerified(uid: string) {
    await this.us.updateUser(uid, { emailVerified: true })
      .then((userData) => {
        console.log('email verfied successfully');
        this.successMessage = 'E-Mail erfolgreich verifiziert!';
        this.showSuccessMessage = true;
        this.currentUserData = userData;
      })
      .catch(function (error) {
        this.globalService.showNotification('Unable to verify email', 'danger');
        console.log('unable to verify email: ', error);
      });
    setTimeout(() => {
      this.showSuccessMessage = false;
      this.successMessage = '';
    }, 2000);
  }
  /**
   *  Admin can delete the uploaded documents by users (service providers)
   */
  deleteDoc(doc: string, uid: string) {
    const confirmed = confirm('Das Dokument wird aus dem Profil gelöscht und der Nutzer auf unverifiziert gesetzt. Er kann die Plattform dann nicht mehr nutzen, falls er vorher bereits verifiziert war. Bitte nur nutzen, wenn bei der anfänglichen Verifikation falsche oder ungültige Dokumente hochgeladen wurden! Nach dem Löschvorgang wird der Nutzer darüber informiert, dass die Verifikation gescheitert ist und er betreffende Dokumente neu hochladen muss.');
    if (confirmed) {
      let docName = '';
      let updatedDetails: any = {
        pilotVerified: false,
        verificationNeeded: false,
        verificationFailed: true
      };
      switch (doc) {
        case 'doc0':
          docName = this.currentUserData.verificationDocument0_file_name || 'vertragliche-vereinbarung';
          updatedDetails.verificationDocument0 = '';
          updatedDetails.verificationDocument0_file_name = '';
          updatedDetails.verificationDocument0_file_size = '';
          break;
        case 'doc1':
          docName = this.currentUserData.verificationDocument1_file_name || 'haftpflicht';
          updatedDetails.verificationDocument1 = '';
          updatedDetails.verificationDocument1_file_name = '';
          updatedDetails.verificationDocument1_file_size = '';
          break;
        case 'doc2':
          docName = this.currentUserData.verificationDocument2_file_name || 'kenntnisnachweis';
          updatedDetails.verificationDocument2 = '';
          updatedDetails.verificationDocument2_file_name = '';
          updatedDetails.verificationDocument2_file_size = '';
          break;
        case 'doc3':
          docName = this.currentUserData.verificationDocument3_file_name || 'aufstiegsgenehmigung';
          updatedDetails.verificationDocument3 = '';
          updatedDetails.verificationDocument3_file_name = '';
          updatedDetails.verificationDocument3_file_size = '';
          break;
      }
      this.uploadService.deleteFile(`users/${uid}/${docName}`).subscribe(
        () => {
          this.us.updateUser(uid, updatedDetails)
            .then((userData) => {
              console.log('User document details updated successfully');
              this.globalService.showNotification('Successfully deleted the document', 'success');
              this.currentUserData = userData;
            })
            .catch((error) => {
              this.globalService.showNotification('Document deleted but the user details were not updated', 'danger');
              console.log('User document details were not updated: ', error);
            });
        },
        (error) => {
          console.log('Could not delete the document');
          console.log(error);
          this.globalService.showNotification('Could not delete the document', 'danger');
        }
      );
    }
  }

  get floorplanService() {
    return this.serviceForm.get('floorplanService');
  }
  get retouchingService() {
    return this.serviceForm.get('retouchingService');
  }
  get vstagingService() {
    return this.serviceForm.get('vstagingService');
  }

  /**
   * Submit handler for service provider form. Updates the user document in firebase
   */
  async submitHandler() {
    const formValue = this.serviceForm.value;
    this.serviceForm.markAsPristine();
    if (formValue.floorplanService !== this.serviceProviderData.floorplanService) {
      let updateServiceProviderError = false;
      try {
        await this.us.updateUser(formValue.floorplanService, {
          floorplan: true
        });
      } catch (err) {
        console.error(err);
        updateServiceProviderError = true;
      }
      if (!updateServiceProviderError) {
        try {
          await this.us.updateUser(this.serviceProviderData.floorplanService, {
            floorplan: false
          });
          this.configService.updateConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_PROVIDERS, {
            floorplanService: formValue.floorplanService
          }).subscribe(
            (serviceProviderData) => {
              this.serviceProviderData = serviceProviderData;
              console.log('Service provider configuration updated');
              this.os.changeAssignment('floorplan', formValue.floorplanService);
            },
            (error) => {
              console.log('Service provider configuration could not be updated');
              console.log(error);
              this.globalService.showNotification('Service provider configuration could not be updated', 'danger');
            }
          );
        } catch (err) {
          console.error(err);
        }
      } else {
        this.globalService.showNotification('User does not exist', 'danger');
      }
    }
    if (formValue.retouchingService !== this.serviceProviderData.retouchingService) {
      let updateServiceProviderError = false;
      try {
        await this.us.updateUser(formValue.retouchingService, {
          retouching: true
        });
      } catch (err) {
        console.error(err);
        updateServiceProviderError = true;
      }
      if (!updateServiceProviderError) {
        try {
          await this.us.updateUser(this.serviceProviderData.retouchingService, {
            retouching: false
          });
          this.configService.updateConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_PROVIDERS, {
            retouchingService: formValue.retouchingService
          }).subscribe(
            (serviceProviderData) => {
              this.serviceProviderData = serviceProviderData;
              console.log('Service provider configuration updated');
              this.os.changeAssignment('retouching', formValue.retouchingService);
            },
            (error) => {
              console.log('Service provider configuration could not be updated');
              console.log(error);
              this.globalService.showNotification('Service provider configuration could not be updated', 'danger');
            }
          );
        } catch (err) {
          console.error(err);
        }
      } else {
        this.globalService.showNotification('User does not exist', 'danger');
      }
    }
    if (formValue.vstagingService !== this.serviceProviderData.vstagingService) {
      let updateServiceProviderError = false;
      try {
        await this.us.updateUser(formValue.vstagingService, {
          vstaging: true
        });
      } catch (err) {
        console.error(err);
        updateServiceProviderError = true;
      }
      if (!updateServiceProviderError) {
        try {
          await this.us.updateUser(this.serviceProviderData.vstagingService, {
            vstaging: false
          });
          this.configService.updateConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_PROVIDERS, {
            vstagingService: formValue.vstagingService
          }).subscribe(
            (serviceProviderData) => {
              this.serviceProviderData = serviceProviderData;
              console.log('Service provider configuration updated');
              this.os.changeAssignment('vstaging', formValue.vstagingService);
            },
            (error) => {
              console.log('Service provider configuration could not be updated');
              console.log(error);
              this.globalService.showNotification('Service provider configuration could not be updated', 'danger');
            }
          );
        } catch (err) {
          console.error(err);
        }
      } else {
        this.globalService.showNotification('User does not exist', 'danger');
      }
    }
  }
  /**
   * Preload data for user configuration
   */
  async preloadData() {
    await this.configService.getServiceProviders()
      .then((serviceProviderData) => {
        this.serviceForm.patchValue(serviceProviderData);
        this.serviceProviderData = serviceProviderData;
      })
      .catch((error) => {
        console.log('service provider configuration was not fetched');
        console.log(error);
      });
  }

  createNewUser(): void {
    this.auth.adminFlag = true;
    this.router.navigate(['/register']);
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.userDataSrc.filter = 'test';
  }

  noSort(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onRoleFilter(event): void {
    this.filterValues['role'] = (event['value'] || '').toLowerCase();
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.userDataSrc.filter = 'test';
  }

  changeCompany(event: any) {
    this.us.updateUser(this.curUserId, {
      company: {
        id: this.selectedCompany.cid
      }
    })
      .then((userData) => {
        console.log('Company assigned successfully');
        this.globalService.showNotification('Successfully assigned the user to selected company', 'success');
        this.currentUserData = userData;
      })
      .catch((error) => {
        this.globalService.showNotification('Could not assign the user to selected company', 'danger');
        console.log('Company could not be assigned: ', error);
      });
  }

  goBack() {
    this.state = 'default';
    this.userDataSrc.filter = 'test';
  }
}
