import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from 'rxjs/operators';
import { ThreeSixtyView } from "@app/threesixty-view-manager/model/threesixtyview.model";

@Injectable({
    providedIn: 'root'
})
export class ThreesixtyService {

    selectedView: ThreeSixtyView;

    constructor(
        private afs: AngularFirestore
    ) { }

    /**
     * Function to list all the existing views in the database.
     */
    listAllThreeSixtyViews() {
        return this.afs.collection('views').snapshotChanges().pipe(map(response => {
            return response.map(a => {
                const data: any = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            });
        }));
    }

    /**
     * 
     * @param view - A three sixty degree view object to be added to existing list of views.
     * 
     * Function to add a new three sixty view to the databse.
     */
    addNewThreeSixtyView(view: ThreeSixtyView) {
        return new Promise((res, rej) => {
            this.afs.collection('views').add(view).then((doc: any) => {
                res(doc.id);
            }).catch((err) => {
                rej(err);
            });
        });
    }

    /**
     * 
     * @param viewId - ID of the view to be updated.
     * @param viewObj - New key value pairs to be updated.
     */
    updateThreeSixtyView(viewId: string, viewObj: any) {
        return new Promise((res, rej) => {
            this.afs.collection('views').doc(viewId).update(viewObj).then((doc: any) => {
                res(doc.id);
            }).catch((err) => {
                rej(err);
            });
        });
    }
} 