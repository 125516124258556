import { Injectable } from '@angular/core';
import { CompaniesService } from './companies.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserRoleEnum } from '@app/models/user-role-list';

@Injectable({
  providedIn: 'root'
})
export class CompaniesResolverService {

  constructor(
    private cs: CompaniesService,
    private auth: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.cs.isListDataLoaded) {
      if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
        return this.cs.getCompanies();
      } else {
        return this.cs.getCompany(this.auth.myUserObservable.company.id, false, true);
      }
    } else {
      return this.cs.companiesList;
    }
  }
}
