import { CompanyRole } from '@app/interfaces/company-role.interface';

export enum CompanyRoleEnum {
  CompanyManager = 'CompanyManager',
  CompanyAccountant = 'CompanyAccountant',
  CompanyEmployee = 'CompanyEmployee',
}

export const companyRoleList: CompanyRole[] = [
  {
    id: CompanyRoleEnum.CompanyManager,
    name: 'Manager'
  },
  {
    id: CompanyRoleEnum.CompanyAccountant,
    name: 'Buchhalter'
  },
  {
    id: CompanyRoleEnum.CompanyEmployee,
    name: 'Mitarbeiter'
  }
];
