import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixedCartComponent } from './fixed-cart.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FixedCartComponent],
  exports: [FixedCartComponent]
})
export class FixedCartModule { }
