import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/_services/loader.service';
import { AuthService } from '@app/auth/auth.service';
import { GlobalService } from '@app/_services/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public loader: LoaderService,
    private gs: GlobalService
  ) { }

  ngOnInit() {
    console.log('Login-Component initialised');
  }

  /**
   * Called when login button is pressed. Uses the auth service to login.
   */
  login(email, password) {
    this.authService.showLoader.emit(true);
    this.authService.login(email.trim(), password.trim())
      .catch((err) => {
        err = err.error || err;
        if (err.message) {
          this.gs.showNotification(err.message, 'danger');
        }
      })
      .finally(() => {
        this.authService.showLoader.emit(false);
      });
  }

}
