import { Builder } from "protractor";

export const LANGUAGE = {
  'app_list_strings': {
    'order_type_list': {
      'dronemedia': 'Drohnenaufnahmen',
      'retouching': 'Bildbearbeitung',
      'floorplan': 'Grundrisse',
      'vstaging': 'Virtual Staging',
      'visualisation': '3D-Visualisierung',
      'hdphotos': 'HD Fotos',
      'expose': 'Exposé',
      'floor_overview': 'Flat Finder',
      'virtual_tour': 'Virtueller Rundgang',
      'documents_procurement': 'Dokumentenbeschaffung'
    },
    'status_list': {
      'opened': 'Prüfung erforderlich',
      'pending': 'Wartet auf Aufnahmen',
      'checked': 'In Vermittlung',
      'processing': 'In Bearbeitung',
      'finalizing': 'In Bearbeitung (Finalisierung)',
      'completed': 'Abgeschlossen',
      'accepted': 'Akzeptiert',
      'uploaded': 'In Nachbearbeitung',
      'requested': 'Angefragt',
      'canceled': 'Storniert'
    },
    'invoice_status_list': {
      'created': 'Rechnung erstellt',
      'paid': 'Zahlung eingegangen',
      'canceled': 'Rechnung storniert'
    },
    'add_unit_map': {
      'Foto': 'Bilder',
      'Sekunden': 'Videolänge'
    },
    'status_class_list': {
      'opened': 'text-danger',
      'checked': 'text-warning',
      'processing': 'text-success',
      'completed': 'text-success',
      'accepted': 'text-success',
      'uploaded': 'text-danger',
      'requested': 'text-warning',
      'canceled': 'text-danger',
      'pending': 'text-danger'
    },
    'service_provider_status': {
      'opened': 'bald verfügbar',
      'pending': 'bald verfügbar',
      'requested': 'Angefragt',
      'processing': 'In Bearbeitung',
      'uploaded': 'In Prüfung',
      'completed': 'Abgeschlossen',
      'accepted': 'Akzeptiert'
    }
  },
  'app_strings': {
    // add standalone translations in this object e.g 'foo' : 'bar'
    'LBL_ADD_Foto_DESC': 'Sie können das gewählte Paket um zusätzliche Bilder ergänzen',
    'LBL_ADD_Sekunden_DESC': 'Sie können beliebig Videolänge hinzufügen',
    'LBL_ADD': 'hinzufügen',
    'LBL_INDV_VT': 'Virtuelle Tour (Einzelspots)',
    'LBL_INDV_VT_DESC': 'Jedes 360°-Bild wird unter einem separaten Link aufgeführt. Beispiel:',
    'LBL_FREE': 'gratis'
  }
};

export const SUBORDER_DETAILS_MAP = {
  'dronemedia': {
    'numberOfPhotos': {
      'label': 'Anzahl der Fotos',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Foto'
      },
      'type': 'string'
    },
    'duration': {
      'label': 'Dauer',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Video'
      },
      'type': 'string'
    },
    'optionalWishPilot': {
      'label': 'Kommentar für den Piloten',
      'type': 'string',
      'isComment': true
    }
  },
  'expose': {
    'description': {
      'label': 'Kommentare',
      'type': 'string',
      'isComment': true
    }
  },
  'floorplan': {
    'isometric': {
      'label': 'Isometrische Ansicht',
      'type': 'boolean',
      'dependentField': {
        'field': 'dimension',
        'value': '3d'
      }
    },
    'preRender': {
      'label': 'PreRender',
      'type': 'boolean',
      'dependentField': {
        'field': 'withPreRender',
        'value': true
      }
    },
    'mobliert': {
      'label': 'Möbliert',
      'type': 'boolean'
    },
    'bemasung': {
      'label': 'Bemaßung',
      'type': 'boolean',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Klassisch'
      }
    },
    'nordpfeil': {
      'label': 'Nordpfeil',
      'type': 'boolean'
    },
    'sqrmeter': {
      'label': 'Quadratmeterangaben',
      'type': 'boolean'
    },
    'raumbezeichnung': {
      'label': 'Raumbezeichnung',
      'type': 'boolean'
    },
    'additionalFloor': {
      'label': 'Anzahl der Wohnungen',
      'type': 'string'
    },
    'packageName': {
      'label': 'Produkt',
      'type': 'string'
    },
    'styleName': {
      'label': 'Style',
      'type': 'string'
    },
    'comments': {
      'label': 'Kommentare/ Anmerkungen',
      'type': 'string',
      'isComment': true
    },
    'logoImage': {
      'label': 'Logo',
      'type': 'image',
      'dependentField': {
        'field': 'logo',
        'value': 'true'
      }
    }
  },
  'hdphotos': {
    'packageName': {
      'label': 'Produkt',
      'type': 'string'
    },
    'numberOfPhotos': {
      'label': 'Anzahl der Fotos',
      'type': 'string'
    },
    'photographerComments': {
      'label': 'Kommentare/ Anmerkungen für Fotografen',
      'type': 'string',
      'isComment': true
    }
  },
  'retouching': {
    'packageName': {
      'label': 'Produkt',
      'type': 'string'
    },
    'numberOfPhotos': {
      'label': 'Anzahl',
      'type': 'string'
    },
    'photoFormat': {
      'label': 'Ausgabeformat',
      'type': 'string'
    },
    'blueSky': {
      'label': 'Blauer Himmel',
      'type': 'boolean'
    },
    'blurred': {
      'label': 'Blurren',
      'type': 'boolean'
    },
    'retouchingComment': {
      'label': 'Kommentar für die Nachbearbeitung',
      'type': 'string',
      'isComment': true
    },
    'logoImage': {
      'label': 'Logo',
      'type': 'image',
      'dependentField': {
        'field': 'logo',
        'value': 'true'
      }
    }
  },
  'vstaging': {
    'packageName': {
      'label': 'Produkt',
      'type': 'string'
    },
    'catalog': {
      'label': 'Katalog',
      'type': 'string'
    },
    'styleName': {
      'label': 'Style',
      'type': 'string'
    },
    'accessoires': {
      'label': 'Accessoires',
      'type': 'string'
    },
    'numberOfPhotos': {
      'label': 'Anzahl',
      'type': 'string'
    },
    'removeFurniture': {
      'label': 'Möbel entfernen',
      'type': 'boolean'
    },
    'renovateRooms': {
      'label': 'Renovierung',
      'type': 'boolean'
    },
    'numberOfWalls': {
      'label': 'Wände versetzen',
      'type': 'booleanString'
    },
    'virtualTour': {
      'label': 'Virtuelle Tour',
      'type': 'boolean',
      'dependentField': {
        'field': 'selectedPackage',
        'value': '360° Bilder'
      }
    },
    'indvVirtualTour': {
      'label': 'Virtuelle Tour (Einzelspots)',
      'type': 'boolean',
      'dependentField': {
        'field': 'selectedPackage',
        'value': '360° Bilder'
      }
    },
    'description': {
      'label': 'Kommentare',
      'type': 'string',
      'isComment': true
    },
    'logoImage': {
      'label': 'Logo',
      'type': 'image',
      'dependentField': {
        'field': 'logo',
        'value': 'true'
      }
    }
  },
  'visualisation': {
    'outputFormat': {
      'label': 'Ausgabeformat',
      'type': 'string'
    },
    'quality': {
      'label': 'Qualitätsstufe',
      'type': 'string'
    },
    'length': {
      'label': 'Videolänge',
      'type': 'string',
      'dependentField': {
        'field': 'outputFormat',
        'value': 'Rundflug'
      }
    },
    'numPhotos': {
      'label': 'Anzahl der Bilder/ Räume',
      'type': 'string'
    },
    'numAdditionalPhotos': {
      'label': 'Anzahl zusätzlicher Standbilder',
      'type': 'string'
    },
    'numAdditional360Tour': {
      'label': 'Anzahl zusätzlicher 360 Grad Touren',
      'type': 'string'
    },
    'apartments': {
      'label': 'Anzahl der Wohneinheiten',
      'type': 'string',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Außenvisualisierung'
      }
    },
    'buildings': {
      'label': 'Anzahl der Gebäude',
      'type': 'string',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Außenvisualisierung'
      }
    },
    'description': {
      'label': 'Kommentare',
      'type': 'string',
      'isComment': true
    }
  },
  'floor_overview': {
    'packageName': {
      'label': 'Produkt',
      'type': 'string'
    },
    'quality': {
      'label': 'Quality',
      'type': 'string'
    },
    'buildingQuantity': {
      'label': 'Anzahl der Gebäudekörper',
      'type': 'string'
    },
    'flatQuantity': {
      'label': 'Anzahl der Wohnungen',
      'type': 'string',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Wohnungsfinder'
      }
    },
    'rundumBlick': {
      'label': '360°-Rundum Blick',
      'type': 'boolean'
    },
    'flatFinderTool': {
      'label': 'Einbinden in Flatfinder',
      'type': 'boolean',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Wohnungsfinder'
      }
    },
    'flatColor': {
      'label': 'Farbe des markierten Apartments',
      'type': 'string',
      'dependentField': {
        'field': 'selectedPackage',
        'value': 'Wohnungsfinder'
      }
    },
    'buildingColor': {
      'label': 'Farbe des Gebäudekörperss',
      'type': 'string'
    },
    'backgroundColor': {
      'label': 'Farbe des Untergrundes',
      'type': 'string'
    },
    'apartmentNumber': {
      'label': 'Wohnungsnummer',
      'type': 'boolean'
    },
    'streetReference': {
      'label': 'Straßenreferenz',
      'type': 'boolean'
    },
    'northArrow': {
      'label': 'Nordpfeil',
      'type': 'boolean'
    },
    'comments': {
      'label': 'Kommentare/ Anmerkungen',
      'type': 'string',
      'isComment': true
    },
    'logoImage': {
      'label': 'Logo',
      'type': 'image',
      'dependentField': {
        'field': 'logo',
        'value': 'true'
      }
    }
  },
  'virtual_tour': {
    'packageName': {
      'label': 'Produkt',
      'type': 'string'
    },
    'additionalUnits': {
      'label': 'Zusätzliche 20 qm',
      'type': 'string'
    },
    'comments': {
      'label': 'Kommentare/ Anmerkungen',
      'type': 'string',
      'isComment': true
    }
  },
  'documents_procurement': {
    'productNameList': {
      'label': 'Dokumente',
      'type': 'list'
    },
    'comments': {
      'label': 'Kommentare',
      'type': 'string',
      'isComment': true
    },
  }
}
