import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as _ from 'underscore';
import { GlobalService } from '@app/_services/global.service';
import * as firebase from 'firebase/app';
import { CompaniesService } from '@app/_services/companies.service';
import { HttpClient } from '@angular/common/http';
import { UserAuthModel } from '@app/models/user-auth.model';
import { environment } from '@environments/environment';
import { combineLatest } from 'rxjs';
import { FunctionsService } from '@app/_services/functions.service';
import { UserRoleEnum } from '@app/models/user-role-list';
import { CONSTANTS } from '@app/util/constants';
import {WebsocketsService} from '@app/_services/websockets.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public sessionEnded = false;
  userData: any; // Save logged in user data
  role: any;
  emailSub: any;
  userSub: any;
  userAuthState: any;
  checkStateSub: any;
  emailVerificationFailed = false;
  floorplan: any;
  dronepilot: any;
  retouching: any;
  hdphotos: any;
  visualisation: any;
  companySub: any;
  public emailVerified = false;
  public profileCompleted = true;
  public myUserObservable: any;
  public authorised = false;
  public checkingEmail = false;
  public registering = false;
  uid = null;
  uSub: any;
  public adminFlag: boolean;
  private createUserCallable: any;
  public showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  redirectUrl: string = '';
  apiBaseUrl = environment.apiUrl;
  tokenRefreshTimer;
  tutorialSkipped = false;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public ngxSmartModalService: NgxSmartModalService,
    private gs: GlobalService,
    private cs: CompaniesService,
    private http: HttpClient,
    private socketService: WebsocketsService
  ) {
  }

  checkAuthState(): Promise<boolean> {
    /* Since the router calls  the CanActivate() method on almost every route which in turn calls this,
    we would have many superflous database calls on every route-change, thus we set authorised = true;
    after the first AuthCheck, so we can catch every call after the successful-login with a simple if-statement.
    When the user logs out, this.authorised has to be set to false, because the page is not reloaded.
    */
    if (this.authorised === true) {
      // Not needed to ask Firebase
      return new Promise<boolean>(resolve => {
        resolve(true);
      });
    } else {
      let user: UserAuthModel = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        sessionStorage.removeItem('user');
        return new Promise<boolean>(resolve => {
          resolve(false);
        });
      } else if (user.idToken && user.refreshToken) {
        return this.refreshIdToken();
      }
    }
  }

  /* Method used by the Loader-Service inside the getDataAfterAuthenticated(),
  returns a promise, to ensure that we don't try to load data before the user is initialisied and
  we can access his role and won't work with an undefined value for it. */
  initializeUser(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.userAuthState = this.afAuth.authState.subscribe(async user => {
        if (user) {
          console.log('[3]: Initializing User-Values!');
          this.userData = user;
          sessionStorage.setItem('user', JSON.stringify(this.userData));
          this.userSub = this.getCustomUserDoc().valueChanges().subscribe(async data => {
            this.myUserObservable = data;
            this.authorised = true;
            if (this.myUserObservable['company'] && typeof this.myUserObservable['company'] !== 'string') {
              const company = await this.cs.getCompany(this.myUserObservable.company.id, false, true).toPromise();
              this.myUserObservable['companyName'] = company && company.name;
            }
          });
          this.getCustomUserData().then(result => {
            if (result === true) {
              resolve(true);
            } else {
              //
              window.alert('Fehler 18-5: Bitte kontaktieren Sie den Support!');
            }
          });
        } else {
          console.log('[3 & 4]: AuthState: Not logged-in!');
          sessionStorage.setItem('user', null);
          this.authorised = false;
          resolve(false);
        }
        this.userAuthState.unsubscribe();
      });
    });
  }

  /**
   * Use the angularfire auth service to check if the user email is verified. If it is verified, we updated the emailVerified bit to true
   * in user document in the user collection.
   */
  verifyEmail() {
    return new Promise((resolve, reject) => {
      this.checkingEmail = true;
      this.getAuthUserDetails()
        .then(() => {
          if (this.myUserObservable && this.myUserObservable.emailVerified) {
            console.log('email verified in auth and user document');
            this.ngxSmartModalService.close('emailVerificationModal');
            this.checkTutorial();
            resolve(true);
          } else if (this.emailVerified === true) {
            console.log('email verified in auth, updating user document');
            // TODO: Call function from users.service once it is ready
            this.http.put(this.apiBaseUrl + 'users/' + this.myUserObservable.uid, {
              emailVerified: true
            }).subscribe(
              () => {
                console.log('user doc updated for email verification');
                this.ngxSmartModalService.close('emailVerificationModal');
                this.checkTutorial();
                resolve(true);
              },
              (error) => {
                console.log('user doc update failed for email verification');
                resolve(false);
              }
            );
          } else {
            console.warn('email not verified!');
            this.emailVerificationFailed = true;
            resolve(false);
            setTimeout(() => {
              this.emailVerificationFailed = false;
            }, 5000);
          }
        })
        .catch(() => {
          resolve(false);
        })
        .finally(() => {
          this.checkingEmail = false;
        })
    });
  }

  // Getting the the user role, so it can be used by the loader's process to only load specific data
  getCustomUserData() {
    return new Promise<boolean>(resolve => {
      this.getCustomUserDoc().get().toPromise().then(result => {
        console.log('[4]: All values assigned');
        this.role = result.data().role;
        this.emailVerified = result.data().emailVerified;
        this.profileCompleted = result.data().profileCompleted;
        this.floorplan = result.data().floorplan;
        this.dronepilot = result.data().dronepilot;
        this.retouching = result.data().retouching;
        resolve(true);
      });
    });
  }

  // Login with email & password
  login(email, password, isFirstLogin?) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiBaseUrl + 'auth/login', {
        email: email,
        password: password
      }).subscribe(
        (loginResponse: any) => {
          const user: UserAuthModel = {
            userid: loginResponse.localId,
            email: loginResponse.email,
            refreshToken: loginResponse.refreshToken,
            idToken: loginResponse.idToken
          };
          localStorage.setItem('user', JSON.stringify(user));
          this.socketService.connect({idToken: user.idToken});
          this.autoTokenRefreshHandler();
          this.getAuthUserDetails()
            .then(() => {
              this.ngZone.run(() => {
                const redirectUrl = this.redirectUrl || '/createorder';
                this.redirectUrl = '';
                this.router.navigate([redirectUrl]);
              });
              if (this.emailVerified === false || (this.myUserObservable && !this.myUserObservable.emailVerified)) {
                this.ngxSmartModalService.getModal('emailVerificationModal').open();
              } else if (isFirstLogin) {
                this.checkTutorial();
              }
              resolve();
            })
            .catch(() => {
              console.log('Failed to fetch the logged in user details!');
              console.log('Logging out');
              this.logout();
              reject({
                error: 'Failed to fetch the logged in user details!'
              });
            });
        },
        (loginError) => {
          console.log('Login error!');
          console.log(loginError);
          reject(loginError);
        }
      );
    });
  }

  // Register with email & password
  register(rFV) {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (!this.adminFlag) {
          this.http.post(this.apiBaseUrl + 'auth/register', rFV).subscribe(
            (responseData) => {
              this.registering = false;
              this.login(rFV.email, rFV.password, true).then();
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          this.showLoader.emit(true);
          this.http.post(this.apiBaseUrl + 'auth/onboarduser', rFV).subscribe(
            () => {
              this.showLoader.emit(false);
              this.gs.showNotification('User created successfully', 'success');
              resolve();
            },
            (error) => {
              this.gs.showNotification(error.message, 'danger');
              reject(error);
            }
          );
        }
      } catch (error) {
        this.gs.showNotification(error.message, 'danger');
        reject(error);
      }
    });
  }

  // Send Verfication-Email
  SendVerificationMail() {
    return this.http.get(this.apiBaseUrl + 'auth/sendverificationlink').toPromise();
  }

  // Reset Forggot password
  forgot(passwordResetEmail) {
    this.http.get(this.apiBaseUrl + 'auth/resetpassword?email=' + passwordResetEmail).subscribe(
      () => {
        this.gs.showNotification('Password reset link sent successfully', 'success');
      },
      (error) => {
        error = error.error || error;
        if (error.message) {
          this.gs.showNotification(error.message, 'danger');
        } else {
          this.gs.showNotification('Something went wrong! Please contact Imogent team', 'danger');
        }
      }
    );
  }

  // Just used by the Sidebar and for css classes, not needed to run any database checks.
  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user, rFV) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      role: rFV.role,
      anrede: rFV.anrede,
      firstName: rFV.firstName,
      lastName: rFV.lastName,
      registeredOn: new Date(),
      pilotVerified: false,
      verificationNeeded: false,
      profileCompleted: false
    };
    return userRef.set(userData, {
      merge: true
    });
  }

  // Logging the user out
  logout() {
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    this.userSub && this.userSub.unsubscribe();
    this.socketService.disconnect();
    this.authorised = false;
    this.adminFlag = false;
    this.router.navigate(['/logout']);
  }

  // Getting the Document
  getCustomUserDoc() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const uid = user.uid;
    return this.afs.collection('users').doc(`${uid}`);
  }

  changePassword(password) {
    return this.http.put(this.apiBaseUrl + 'auth/changepassword', {
      "password": password
    }, {
      responseType: 'text'
    });
  }

  refreshIdToken() {
    let user: UserAuthModel = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.idToken || !user.userid) {
      return new Promise<boolean>((resolve, reject) => {
        resolve(false);
      });
    }
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(this.apiBaseUrl + 'auth/refreshtoken', {
        'refresh_token': user.refreshToken
      }).subscribe(
        (authResponse: any) => {
          console.log('Id token refreshed');
          this.autoTokenRefreshHandler();
          user.idToken = authResponse.id_token;
          user.refreshToken = authResponse.refresh_token;
          localStorage.setItem('user', JSON.stringify(user));
          this.socketService.disconnect();
          this.socketService.connect({idToken: authResponse.id_token});
          this.getAuthUserDetails()
            .then(() => resolve(true))
            .catch(() => {
              localStorage.removeItem('user');
              sessionStorage.removeItem('user');
              resolve(false);
            });
        },
        (error: any) => {
          console.log('Refresh token error!');
          console.log(error);
          localStorage.removeItem('user');
          sessionStorage.removeItem('user');
          resolve(false);
        }
      );
    });
  }

  getAuthUserDetails() {
    let user: UserAuthModel = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.idToken || !user.userid) {
      return new Promise((resolve, reject) => {
        reject('Invalid user');
      });
    }
    return new Promise((resolve, reject) => {
      combineLatest([
        this.http.get(this.apiBaseUrl + 'auth/getuserauthstate/' + user.userid),
        // TODO: Call function from users.service once it is ready
        this.http.get(this.apiBaseUrl + 'users/uid/' + user.userid)
      ]).subscribe(
        async (combinedResponse) => {
          const authState: any = combinedResponse[0] || {};
          const userData: any = combinedResponse[1] ? combinedResponse[1][0] || {} : {};
          sessionStorage.setItem('user', JSON.stringify(authState));
          if (userData) {
            this.myUserObservable = userData;
            this.role = userData.role;
            this.authorised = true;
            if (userData['company']) {
              if (typeof userData['company'] !== 'string') { // New company entries
                const company = await this.cs.getCompany(this.myUserObservable.company.id, false, true).toPromise();
                if (company) {
                  userData['companyName'] = company.name;
                  userData.companyObj = company;
                  this.myUserObservable = userData;
                }
              } else { // Fallback for old entries where company is just a string.
                userData['companyName'] = userData['company'];
              }
              this.myUserObservable = userData;
            }
            this.socketService.userDetails = userData;
          }
          this.emailVerified = authState.emailVerified;
          if (this.emailVerified === false || (this.myUserObservable && !this.myUserObservable.emailVerified)) {
            this.ngxSmartModalService.getModal('emailVerificationModal').open();
          }
          resolve();
        },
        (combinedError) => {
          console.log('Auth state and user details error');
          console.log(combinedError);
          localStorage.removeItem('user');
          sessionStorage.removeItem('user');
          reject();
        }
      );
    });
  }

  autoTokenRefreshHandler() {
    this.tokenRefreshTimer && clearTimeout(this.tokenRefreshTimer);
    this.tokenRefreshTimer = setTimeout(() => {
      this.refreshIdToken().then((response) => {
        if (!response) {
          console.log('Id token was not refreshed');
          console.log('Logging out');
        }
      });
    }, CONSTANTS.TOKEN_REFRESH_TIMER);
  }

  checkTutorial() {
    if (!this.tutorialSkipped) {
      if (this.role === UserRoleEnum.Customer) {
        this.ngxSmartModalService.getModal('firstImmoModal').open();
      } else if (this.role === UserRoleEnum.ServiceProvider && this.profileCompleted === false) {
        this.ngxSmartModalService.getModal('newDienstleisterModal').open();
      }
    } else {
      console.log('Tutorial skipped!');
    }
  }
}
