import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { CompaniesService } from '@app/_services/companies.service';
import { LoaderService } from '@app/_services/loader.service';
import { UsersService } from '@app/_services/users.service';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { companyRoleList, CompanyRoleEnum } from '@app/models/company-role-list';
import { UserRoleEnum } from '@app/models/user-role-list';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { existingEmailValidator } from '@app/shared-module/form.validators';
import { Company } from '@app/interfaces/company.interface';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit, OnDestroy {
  emailForm: any;
  companyForm: FormGroup;
  companies: any;
  curCompany: any;
  curUser: any;
  curEmailUserRef: any;
  companyDataSrc = new MatTableDataSource([]);
  userDataSrc = new MatTableDataSource([]);
  columnsToDisplay: any;
  columnsToDisplayUserTable: any;
  expanded = false;
  mode = 'list';
  EMAILREGEX = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,9})*$');
  companyDetailForm: FormGroup;
  filterValuesCompanies = {};
  filterValuesUsers = {};
  companyRoleList = companyRoleList;
  companyRoleEnum = CompanyRoleEnum;
  userRoleEnum = UserRoleEnum;

  // TODO: do NOT forget to unsubscribe to observables or else we'll have memory leaks
  private subscriptions: Subscription[] = [];

  @ViewChild(MatSort) set sort(value: MatSort) {
    // only platform admins and company admins have access to the users list
    if (this.auth.myUserObservable.role === this.userRoleEnum.Administrator || this.auth.myUserObservable.companyRole === this.companyRoleEnum.CompanyManager) {
      this.userDataSrc.sort = value;
    }
    this.companyDataSrc.sort = value;
  }

  @ViewChild('userTable') userDataTableSort: MatSort;

  constructor(
    public auth: AuthService,
    public loader: LoaderService,
    private us: UsersService,
    public cs: CompaniesService,
    private fb: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService
  ) {
    this.companyForm = this.fb.group({
      name: ['', [Validators.required]],
      street: [''],
      streetNumber: [''],
      postalcode: [''],
      city: [''],
      country: [''],
      ustid: [''],
      totalVolume: [0]
    });

    this.companyDetailForm = this.fb.group({
      name: ['', [Validators.required]],
      street: [''],
      streetNumber: [''],
      postalcode: [''],
      city: [''],
      country: [''],
      ustid: [''],
      totalVolume: [0]
    });

    this.emailForm = this.fb.group({
      emailInput: [
        '',
        Validators.compose([Validators.required, Validators.pattern(this.EMAILREGEX)]),
        []
      ]
    });
  }

  ngOnInit() {
    const user = JSON.parse(sessionStorage.getItem('user'));

    this.setCompanyDataSource(this.cs.companiesList);
    this.setCompanyTableFilter();

    this.subscriptions.push(
      this.cs.companiesListChanged.subscribe(() => {
        this.setCompanyDataSource(this.cs.companiesList);
        this.setCompanyTableFilter();
      })
    );

    this.subscriptions.push(this.us.getUserDetails(user.uid).subscribe(data => {
      this.curUser = data;

      if (this.curUser.role !== this.userRoleEnum.Administrator && this.curUser.companyRole !== this.companyRoleEnum.CompanyManager) {
        this.companyDetailForm.disable();
      } else {
        this.companyDetailForm.enable();
      }

      if (this.curUser.role !== this.userRoleEnum.Administrator && this.curUser.company && this.mode !== 'details') {
        this.showCompanyDetails(this.curUser.company.id);
      }
    }));

    this.columnsToDisplay = ['name', 'street', 'streetNumber', 'postalcode', 'city', 'country'];
    this.columnsToDisplayUserTable = ['lastName', 'firstName', 'email', 'remove', 'admin'];

    // remove column only available to Admins
    if (this.auth.myUserObservable.role !== this.userRoleEnum.Administrator) {
      const index = this.columnsToDisplayUserTable.indexOf('remove');
      this.columnsToDisplayUserTable.splice(index, 1);
    }
  }

  private setCompanyDataSource(companiesList: Company[]) {
    this.companyDataSrc.data = companiesList;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  get emailInput() {
    return <FormControl>this.emailForm.get('emailInput');
  }

  async submitHandler() {
    const formValues = this.companyForm.value;

    try {
      await this.cs.addCompany(formValues).toPromise();
      this.companyForm.reset();
    } catch (err) {
      console.error(err);
    }
  }

  removeUserFromCompany(user) {
    user['company'] = null;
    user['companyName'] = '';
    this.us.updateUser(user.uid, user);
    this.userDataSrc.data = this.userDataSrc.data.filter(item => item.uid !== user.uid);
  }

  async showCompanyDetails(companyId: string) {
    this.mode = 'details';
    this.emailInput.setAsyncValidators(existingEmailValidator(companyId, this.us));
    this.emailInput.setValidators([Validators.required, Validators.pattern(this.EMAILREGEX)]);
    this.emailInput.updateValueAndValidity();
    const companyDocRef = this.cs.returnCompanyRef(companyId);

    this.subscriptions.push(this.us.getAllUsersOfCompany(companyId).subscribe((data: any[]) => {
      this.userDataSrc.data = data;
      this.setUserTableFilter();
    }));

    const company = await this.cs.getCompany(companyDocRef.ref.id).toPromise();
    this.curCompany = company;
    this.companyDetailForm.patchValue({
      'name': company.name,
      'ustid': company.ustid || '',
      'street': company.street,
      'streetNumber': company.streetNumber,
      'postalcode': company.postalcode,
      'city': company.city,
      'country': company.country,
      'totalVolume': company.totalVolume
    });
  }

  showList() {
    this.mode = 'list';
  }

  async saveChanges() {
    const data = {
      ...this.companyDetailForm.value,
      cid: this.curCompany.cid
    };
    await this.cs.updateCompany(data, this.auth.myUserObservable.role).toPromise();
    this.companyDetailForm.markAsPristine();
  }

  addToCompany() {
    this.us.updateUser(this.us.tempUser.uid, {
      company: {
        id: this.curCompany.cid
      }
    });
    this.emailInput.setValue('');
    this.userDataSrc.data.push(this.us.tempUser);
  }

  onCompanyRoleChange(row: any, companyRoleId: string) {
    row.companyRole = companyRoleId;
    this.us.updateUser(row.uid, row);
  }

  setCompanyTableFilter(): void {
    this.companyDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValuesCompanies).forEach(key => {
        if (this.filterValuesCompanies[key] && object[key].toLowerCase().indexOf(this.filterValuesCompanies[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  setUserTableFilter(): void {
    this.userDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValuesUsers).forEach(key => {
        if (this.filterValuesUsers[key] && object[key].toLowerCase().indexOf(this.filterValuesUsers[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  filterUserTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValuesUsers[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.userDataSrc.filter = 'test';
  }

  filterCompanyTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValuesCompanies[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.companyDataSrc.filter = 'test';
  }

  private noSort(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async removeCompany(cid: string) {
    await this.cs.removeCompany(cid).toPromise();
    this.ngxSmartModalService.close('confirmCompanyRemovalModal');
  }
}
