import { OrderStatus } from "@app/interfaces/order-status.interface";

export enum OrderStatusEnum {
  Opened = 'opened',
  Accepted = 'accepted',
  Completed = 'completed',
  Requested = 'requested',
  Uploaded = 'uploaded',
  Canceled = 'canceled',
  Processing = 'processing',
  Checked = 'checked',
  Pending = 'pending',
  Finalizing = 'finalizing'
}

export const orderStatusList: OrderStatus[] = [
  {
    id: OrderStatusEnum.Opened,
    name: 'Opened'
  },
  {
    id: OrderStatusEnum.Accepted,
    name: 'Accepted'
  },
  {
    id: OrderStatusEnum.Completed,
    name: 'Completed'
  },
  {
    id: OrderStatusEnum.Requested,
    name: 'Requested'
  },
  {
    id: OrderStatusEnum.Uploaded,
    name: 'Uploaded'
  },
  {
    id: OrderStatusEnum.Canceled,
    name: 'Canceled'
  },
  {
    id: OrderStatusEnum.Processing,
    name: 'Processing'
  },
  {
    id: OrderStatusEnum.Checked,
    name: 'Checked'
  },
  {
    id: OrderStatusEnum.Pending,
    name: 'Pending'
  },
  {
    id: OrderStatusEnum.Finalizing,
    name: 'Finalizing'
  }
];
