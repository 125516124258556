import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { forbiddenFileValidator, fileRequiredValidator } from '../form.validators';
import * as _ from 'underscore';
import { UploadService } from '@app/_services/upload.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-imo-file-upload',
  templateUrl: './imo-file-upload.component.html',
  styleUrls: ['./imo-file-upload.component.css']
})
export class ImoFileUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('parentType') parentType: string = '';
  @Input('id') parentId: string;
  @Input('folderName') folderName: string;
  @Input('allowedExtn') allowedExtn: string[] = [];
  @Output() onUploadFinish: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef<any>;
  existingFiles = [];

  public uploader: FileUploader = new FileUploader({});
  filesForm: FormGroup;
  filesElem: ElementRef;
  files = [];
  startUpload: boolean = false;
  private readonly logComponent = 'ImoFileUploadComponent :: ';
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private uploadService: UploadService
  ) {
    this.filesForm = this.formBuilder.group({
      uploadedFiles: ['', [
        Validators.required,
        fileRequiredValidator(this.uploader.queue)
      ]]
    });
    this.startUpload = false;
  }

  get uploadedFiles() {
    return this.filesForm.get('uploadedFiles');
  }

  ngOnInit() {
    this.uploadService.getSubcollection(this.parentType, this.parentId, this.folderName).subscribe(files => {
      this.existingFiles = files || [];
    });
  }

  ngAfterViewInit() {
    var self = this;
    this.filesElem = this.fileInput.nativeElement;
    if (_.isEmpty(this.allowedExtn)) {
      this.uploadedFiles.setValidators([Validators.required]);
    } else {
      this.uploadedFiles.setValidators([Validators.required, fileRequiredValidator(this.uploader.queue), forbiddenFileValidator(this.allowedExtn, this.filesElem)]);
    }
    const subscription = this.filesForm.valueChanges.subscribe(() => {
      self.files = [];
      self.uploader.queue.forEach(entry => self.files.push(entry.file.rawFile));
    });

    this.subscriptions.push(subscription);
  }

  onChange(): void {
    let fileLst = [];
    let filesToRemove = [];
    this.uploader.queue.forEach(fl => {
      if (fileLst.findIndex(entry => fl.file.name === entry.name && fl.file.size === entry.size) >= 0) {
        filesToRemove.push(fl);
      } else if (this.existingFiles.findIndex(exstFl => exstFl.file_name === fl.file.name) >= 0) {
        filesToRemove.push(fl);
      } else {
        fileLst.push(fl.file);
      }
    });
    filesToRemove.forEach(fl => fl.remove());


    /* const fileListLength = this.uploader.queue.length;
    let lastFile = this.uploader.queue[fileListLength - 1];
    for (let i = 0; i < fileListLength - 1; i++) {
      if (lastFile.file.name === this.uploader.queue[i].file.name && lastFile.file.size === this.uploader.queue[i].file.size) {
        lastFile.remove();
      }
    }
    if (this.existingFiles.findIndex(fl => fl.file_name === lastFile.file.name) >= 0) {
      lastFile.remove();
    } */
    // fix for Internet Explorer. Manually set the file value
    if (this.uploader.queue.length > 0 && _.isEmpty(this.uploadedFiles.value)) {
      this.uploadedFiles.setValue(this.uploader.queue[0].file.name);
    }
    this.uploadedFiles.updateValueAndValidity();
  }

  uploadFiles() {
    this.uploadedFiles.markAsDirty();
    if (this.filesForm.valid) {
      this.startUpload = true;
    }
  }

  /**
 * Remove the file from upload queue
 */
  onRemoveFile(file): void {
    if (_.isFunction(file.remove)) {
      file.remove();
    } else {
      this.uploader.queue.forEach(entry => {
        if (entry.file.name === file.name) {
          entry.remove();
        }
        if (_.isEmpty(this.uploader.queue)) {
          this.startUpload = false;
        }
      }
      );
      // when uploader queue is empty reset the form touch state to remove
      // validation errors as the form is submitted
      if (this.uploader.queue.length === 0) {
        Object.keys(this.filesForm.controls).forEach(control => {
          this.filesForm.controls[control].markAsUntouched();
          this.filesForm.controls[control].markAsPristine();
        });
        this.onUploadFinish.emit(true);
      }
    }
    this.uploadService.getSubcollection(this.parentType, this.parentId, this.folderName)
      .pipe(take(1))
      .subscribe(files => {
        this.existingFiles = files || [];
      });
    this.filesForm.controls.uploadedFiles.updateValueAndValidity();
  }

  ngOnDestroy() {
    console.log(this.logComponent + 'Destroying component.');
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
