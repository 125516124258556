import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { MoneyService } from './money.service';
import { DiscountTypeEnum } from '@app/models/discount-type-list';
import { Discount } from '@app/interfaces/discount.interface';
import { AuthService } from '@app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '@app/util/constants';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {
  discountsList: Discount[] = [];
  onDataRefresh = new Subject();
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.DISCOUNTS;

  constructor(
    private auth: AuthService,
    private ms: MoneyService,
    private http: HttpClient
  ) {}

  getAll() {
    return this.http.get<Discount[]>(this.endpoint).pipe(
      tap(data => {
        this.setListData(data);
      })
    );
  }

  getMyCompanyDiscounts() {
    return this.http.get<Discount[]>(this.endpoint + '/my-company').pipe(
      tap(data => {
        this.setListData(data);
      })
    );
  }

  private setListData(dataList: Discount[]) {
    this.discountsList = dataList.map(discount => {
      this.addDiscountFormattedValue(discount);

      return discount;
    });
  }

  private addDiscountFormattedValue(discount: Discount) {
    let formattedValue = this.ms.floatToString(discount.value);

    if (discount.type === DiscountTypeEnum.Percentage) {
      formattedValue += ' %';
    } else {
      formattedValue += ' €';
    }

    discount.formattedValue = formattedValue;
  }

  createOrReplace(data: any) {
    return this.http.put<Discount>(this.endpoint, data).pipe(
      tap(discount => {
        this.addDiscountFormattedValue(discount);
        this.addOrUpdateDiscountInList(discount);

        // inform event subscribers that the list changed
        this.onDataRefresh.next();
      })
    );
  }

  private addOrUpdateDiscountInList(discount: Discount) {
    const foundIndex = this.discountsList.findIndex(item => item.id === discount.id);

    if (foundIndex > -1) {
      // update found discount in list
      this.discountsList[foundIndex] = discount;
    } else {
      // add new discount to list
      this.discountsList.push(discount);
    }
  }

  delete(discountId: string) {
    return this.http.delete(this.endpoint + '/' + discountId).pipe(
      tap(() => {
        this.discountsList = this.discountsList.filter((item) => item.id !== discountId);
        // inform event subscribers that the list changed
        this.onDataRefresh.next();
      })
    );
  }

  calculateDiscountedPrice(price: number, discount: Discount) {
    let priceDiscounted: number;
    let discountValue: number;

    const companySubsidisedVolume = (this.auth.myUserObservable.companyObj && this.auth.myUserObservable.companyObj.subsidisedVolume) || 0;
    const companyTotalVolume = (this.auth.myUserObservable.companyObj && this.auth.myUserObservable.companyObj.totalVolume) || 0;

    if (!discount || !this.auth.myUserObservable.companyObj || companySubsidisedVolume >= companyTotalVolume) {
      return price;
    }

    if (discount.type === DiscountTypeEnum.Percentage) {
      discountValue = price * discount.value / 100;
    } else {
      discountValue = discount.value;
    }

    priceDiscounted = price - discountValue;
    priceDiscounted = priceDiscounted > 0 ? priceDiscounted : 0;

    return priceDiscounted;
  }

}
