﻿import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { SidebarService } from '@app/sidebar/sidebar.service';
import { LoaderService } from '@app/_services/loader.service';
import { AuthService } from './auth/auth.service';
import { RealestateService } from './_services/realestate.service';
import { UsersService } from './_services/users.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import PerfectScrollbar from 'perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '@app/_services/global.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: [
    'app.component.css',
    '../styles/table.css'
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  isDownloadZip = false;
  constructor(private sidebarservice: SidebarService,
    public loader: LoaderService,
    public auth: AuthService,
    public res: RealestateService,
    public us: UsersService,
    public router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    public activeRoute: ActivatedRoute,
    public translate: TranslateService,
    public gs: GlobalService
  ) {
  }

  public emailResent = false;
  showLoader: boolean = false;

  ngOnInit() {
    const self = this;
    this.isDownloadZip = false;
    console.log('App-Component initialised');
    if (document.URL.indexOf('downloadzip') < 0 && document.URL.indexOf('imo360') < 0) {
      // this.loader.getDataAfterAuthenticated();
      this.loader.loaded = true;
    } else {
      this.isDownloadZip = true;
    }
    this.auth.showLoader.subscribe(showLoader => {
      this.showLoader = showLoader;
    });
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof RouteConfigLoadStart) {
        self.showLoader = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        self.showLoader = false;
      }
    });
  }

  ngAfterViewInit() {
    this.loader.initializeScroller.subscribe(() => {
      setTimeout(() => {
        if (!this.loader.scrollerInitialized) {
          const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
          const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
          const html = document.getElementsByTagName('html')[0];
          if (!this.isMac() && window.matchMedia(`(min-width: 960px)`).matches) {
            let ps;
            if (elemMainPanel) {
              ps = new PerfectScrollbar(elemMainPanel);
            }
            if (elemSidebar) {
              ps = new PerfectScrollbar(elemSidebar);
            }
            html.classList.add('perfect-scrollbar-on');
          } else {
            html.classList.add('perfect-scrollbar-off');
          }
          this.loader.scrollerInitialized = true;
        }
      }, 100);
    });
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  get isLoggedIn(): boolean {
    return this.auth.authorised;
  }

  navigateToRealestates() {
    this.res.add = true;
    this.router.navigate(['/realestate']);
    this.ngxSmartModalService.close('firstImmoModal');
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
    this.ngxSmartModalService.close('newDienstleisterModal');
  }

  reloadPage() {
    location.reload();
  }

  resendVerificationEmail() {
    try {
      this.auth.SendVerificationMail().then(result => {
        this.emailResent = true;
      }).catch(error => {
        window.alert(error);
        this.emailResent = false;
      });
      setTimeout(() => {
        this.emailResent = false;
      }, 3500);
    } catch (error) {
      window.alert(error);
    }
  }

  skipTutorial() {
    this.ngxSmartModalService.close('firstImmoModal');
    this.auth.tutorialSkipped = true;
  }

  ngOnDestroy() {
  }

}
