// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// `ng build --configuration=staging` replaces `environment.ts` with `environment.staging.ts`
// `ng build --configuration=dev` replaces `environment.ts` with `environment.dev.ts`
// The list of file replacements can be found in `angular.json`.

/* Following is the dev key */
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCwL6AECH6fvbclRAyoC2rKs1YMRocZZXo', // never share this dev key!!!!!!!!
    authDomain: 'imogent-platform-staging.firebaseapp.com',
    databaseURL: 'https://imogent-platform-staging.firebaseio.com',
    projectId: 'imogent-platform-staging',
    storageBucket: 'imogent-platform-staging.appspot.com',
    messagingSenderId: '750422950001',
    appId: '1:750422950001:web:515bcdeb0989e87a419bd0',
    measurementId: 'G-D276RFTDQ6'
  },
  apiBaseUrl: 'https://functions-dev.imogent.com/',
  esoft: {
    // configuration related to esoft integration
    apiURL: 'https://dev-m2.esoftsystems.com/ewarp/',
    apiUser: 'test',
    apiPassword: 'test',
    clientId: '50100166',
    receivingCompany: 'ESVN',
    callbackUrl: 'https://imogent-platform-staging.firebaseapp.com/api/v1/entryPoint/esoft',
    orderContacts: [
      {
        name: 'Sarmad Parvez',
        phoneMobile: '+4915221793123',
        email: 'sarmad.parvez@imogent.com',
        contactType: 'OWNER'
      }
    ]
  },
  platformUrl: 'https://imogent-platform-staging.web.app/',
  translateApi: 'https://translation.googleapis.com/language/translate/v2?key=AIzaSyDyoZ47oSiC_AJtlVKOOlZO-JRBi5H_Ju8',
  env: 'dev',
  externalApiGatewayUrl: 'https://api-dev.imogent.com/api/v1/apis/out/',
  apiUrl: 'https://api-dev.imogent.com/api/v1/',
  websocketUrl: 'https://api-dev.imogent.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
