import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';  // Angular Http module
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { FaqComponent } from './faq';
import { HomeComponent } from './home';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile';
import { RealestateComponent } from './realestate';
import { InboxComponent } from './inbox';
import { SettingsComponent } from './settings';
import { LogoutComponent } from './logout/logout.component';
import { ForgotComponent } from './forgot/forgot.component';
import { PaymentsComponent } from './payments/payments.component';
import { TermsComponent } from './terms/terms.component';
import { UsersComponent } from './users/users.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ThreesixtyViewManagerComponent } from './threesixty-view-manager/threesixty-view-manager.component';
import { SharedModule } from './shared-module/shared.module';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { DownloadZipModule } from './download-zip-module/download-zip.module';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './_guards/auth.guard';
import { RealestateService } from './_services/realestate.service';
import { OrdersService } from './_services/orders.service';
import { UsersService } from './_services/users.service';
import { LoaderService } from './_services/loader.service';
import { SidebarService } from './sidebar/sidebar.service';
import { UploadService } from './_services/upload.service';
import { GlobalService } from './_services/global.service';
import { MatDatepickerModule } from '@angular/material';
import { FloortourModule } from './floortour/floortour.module';
import { ThreesixtyViewListComponent } from './threesixty-view-list/threesixty-view-list.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CompaniesComponent } from './companies/companies.component';
import { AccountingPositionsComponent } from './accounting-positions/accounting-positions.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { DocEstateService } from './_services/docestate.service';
import { DiscountsComponent } from './discounts/discounts.component';
import { BrowserSupportComponent } from './browser-support/browser-support.component';
import { RequestInterceptor } from './_guards/request.interceptor';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {OrdersNewService} from '@app/_services/orders-new.service';
const config: SocketIoConfig = { url: environment.websocketUrl, options: {autoConnect : false, transports: ['polling']}}; // Use websockets, if connection is not possible fall back to polling.

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFireStorageModule, // imports firebase/storage only needed for storage features,
        SidebarModule,
        HttpClientModule,
        SharedModule,
        MaterialModule,
        AppRoutingModule,
        DownloadZipModule,
        FloortourModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SocketIoModule.forRoot(config)
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ProfileComponent,
        RealestateComponent,
        InboxComponent,
        FaqComponent,
        SettingsComponent,
        LogoutComponent,
        ForgotComponent,
        PaymentsComponent,
        TermsComponent,
        UsersComponent,
        ChangePasswordComponent,
        ThreesixtyViewManagerComponent,
        ThreesixtyViewListComponent,
        AccountingPositionsComponent,
        CompaniesComponent,
        DiscountsComponent,
        InvoicesComponent,
        BrowserSupportComponent
    ],
    providers: [
        OrdersNewService,
        AuthService,
        AuthGuard,
        RealestateService,
        OrdersService,
        UsersService,
        LoaderService,
        SidebarService,
        UploadService,
        GlobalService,
        {
            provide: FirestoreSettingsToken,
            useValue: {}
        },
        MatDatepickerModule,
        DocEstateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        }
    ],
    bootstrap: [BrowserSupportComponent] // Check for browser support before loading the main component.
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
