import { Component, OnInit, Input } from '@angular/core';

declare const $: any;
const md: any = {
    misc: {
        navbar_menu_visible: 0,
        active_collapse: true,
        disabled_collapse_init: 0,
    }
};

@Component({
    selector: 'app-fixed-cart',
    templateUrl: './fixed-cart.component.html',
    styleUrls: ['./fixed-cart.component.css']
})

export class FixedCartComponent implements OnInit {

    @Input() cartItems: any[];
    @Input() parent;

    constructor() { }

    ngOnInit() {
        // fixed plugin
        const window_width = $(window).width();

        const fixed_plugin_open = $('.sidebar .sidebar-wrapper .nav li.active a p').html();

        if (window_width > 767 && fixed_plugin_open === 'Dashboard') {
            if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
                $('.fixed-plugin .dropdown').addClass('open');
            }

        }

        $('.fixed-plugin a').click(function (event) {
            // Alex: if we click on switch, stop propagation of the event,
            // so the dropdown will not be hide, otherwise we set the  section active
            if ($(this).hasClass('switch-trigger')) {
                if (event.stopPropagation) {
                    event.stopPropagation();
                } else if (window.event) {
                    window.event.cancelBubble = true;
                }
            }
        });
    }

}
