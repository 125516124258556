import { FormGroup } from '@angular/forms';
import { AccountingPosition } from '@app/interfaces/accounting-position.interface';

// Accounting Positions model
export class AccountingPositions { }

export const ACCOUNTING_POSITIONS: AccountingPosition[] = [
  // dronemedia

  // dronemedia photo
  {
    'id': 'dronemedia_photo_price',
    'name': 'Professionelle Luftaufnahmen - Fotos (7 Fotos)',
    'price': 290.0,
    'ordertype': 'dronemedia'
  },
  {
    'id': 'dronemedia_photo_addUnits',
    'name': 'Zusätzliche Fotos - Luftaufnahmen - Fotos',
    'price': 10.9,
    'ordertype': 'dronemedia'
  },
  // dronemedia video
  {
    'id': 'dronemedia_video_price',
    'name': 'Luftaufnahmen - Video (40 Sekunden)',
    'price': 390.0,
    'ordertype': 'dronemedia'
  },
  {
    'id': 'dronemedia_video_addUnits',
    'name': 'Zusätzliche Sekunden - Luftaufnahmen - Video (10 Sekunden)',
    'price': 15.0,
    'ordertype': 'dronemedia'
  },
  {
    'id': 'dronemedia_video_extra_feedback_price_retouching',
    'name': 'Zusätzliches Feedback - Luftaufnahmen - Video',
    'price': 25.0,
    'ordertype': 'dronemedia'
  },
  // hdphotos
  {
    'id': 'hdphoto_price',
    'name': 'Professionelle Außen- und Innenfotos - Fotos (7 Fotos)',
    'price': 290.0,
    'ordertype': 'hdphotos'
  },
  {
    'id': 'hdphoto_addUnits',
    'name': 'Zusätzliches Foto - Professionelle Außen- und Innenfotos - Fotos (1 Foto)',
    'price': 25.0,
    'ordertype': 'hdphotos'
  },

  // retouching

  // retouching standard
  {
    'id': 'retouching_price',
    'name': 'Bildbearbeitung - Fotonachbearbeitung (Basic)',
    'price': 3.9,
    'ordertype': 'retouching'
  },
  {
    'id': 'retouching_extra_feedback',
    'name': 'zusätzliches Feedback Fotonachbearbeitung',
    'price': 2.0,
    'ordertype': 'retouching'
  },
  // retouching basic
  {
    'id': 'retouching_price_basic',
    'name': 'Bildbearbeitung - Fotomontage (Basic)',
    'price': 12.9,
    'ordertype': 'retouching'
  },
  {
    'id': 'retouching_basic_extra_feedback',
    'name': 'zusätzliches Feedback - Bildbearbeitung - Fotomontage (Basic)',
    'price': 5.0,
    'ordertype': 'retouching'
  },
  // retouching erweitert
  {
    'id': 'retouching_price_erweitert',
    'name': 'Bildbearbeitung - Fotomontage (Erweitert)',
    'price': 39.9,
    'ordertype': 'retouching'
  },
  {
    'id': 'retouching_erweitert_extra_feedback',
    'name': 'zusätzliches Feedback - Bildbearbeitung - Fotomontage (Erweitert)',
    'price': 5.0,
    'ordertype': 'retouching'
  },

  // floorplans

  // floorplans klassisch
  {
    'id': 'floorplan_price_klassisch',
    'name': '2D Grundrisse - Klassisch - bis 140qm Grundfläche',
    'price': 16.0,
    'ordertype': 'floorplan'
  },
  {
    'id': 'floorplan_klassisch_extra_feedback',
    'name': 'zusätzliches Feedback - 2D Grundrisse - Klassisch - bis 140qm Grundfläche',
    'price': 7.0,
    'ordertype': 'floorplan'
  },
  // floorplans premium
  {
    'id': 'floorplan_price_premium',
    'name': '3D Grundrisse - Premium - bis 140qm Grundfläche',
    'price': 29.0,
    'ordertype': 'floorplan'
  },
  {
    'id': 'floorplan_premium_extra_feedback',
    'name': 'zusätzliches Feedback - 3D Grundrisse - Premium - bis 140qm Grundfläche',
    'price': 10.0,
    'ordertype': 'floorplan'
  },
  // floorplans extravagant
  {
    'id': 'floorplan_price_extravagant',
    'name': '3D Grundrisse - Extravagant - bis 140qm Grundfläche',
    'price': 29.0,
    'ordertype': 'floorplan'
  },
  {
    'id': 'floorplan_extravagant_extra_feedback',
    'name': 'zusätzliches Feedback - 3D Grundrisse - Extravagant - bis 140qm Grundfläche',
    'price': 10.0,
    'ordertype': 'floorplan'
  },
  {
    'id': 'floorplan_extravagant_additional_view',
    'name': 'zusätzliche Ansicht - 3D Grundrisse - Extravagant - bis 140qm Grundfläche',
    'price': 5.9,
    'ordertype': 'floorplan'
  },
  // floorplans Sparkasse
  {
    'id': 'floorplan_price_sparkasse',
    'name': '2D Grundrisse - Sparkassen Design - bis 140qm Grundfläche',
    'price': 10.0,
    'ordertype': 'floorplan'
  },
  {
    'id': 'floorplan_sparkasse_extra_feedback',
    'name': 'Zusätzliches Feedback - 2D Grundrisse - Sparkassen Design - bis 140qm Grundfläche',
    'price': 7.0,
    'ordertype': 'floorplan'
  },

  // floor_overview

  // floor_overview Wohnungsfinder
  {
    'id': 'floor_overview_wohnungsfinder_price',
    'name': 'Wohnungsfinder ab',
    'price': 109.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_wohnungsfinder_extra_feedback',
    'name': 'zusätzliches Feedback - Etagenübersichten - 360° Rundumsicht',
    'price': 50.0,
    'ordertype': 'floor_overview'
  },
  // style Standard
  {
    'id': 'floor_overview_wohnungsfinder_standard_flat_price',
    'name': 'Etagenübersicht - Wohnungsfinder - (1) Wohnung hervorheben (Standard)',
    'price': 10.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_wohnungsfinder_standard_building_price',
    'name': 'Etagenübersicht - Wohnungsfinder - (1) Gebäudekörper (Standard)',
    'price': 99.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_wohnungsfinder_standard_rundumsicht_price',
    'name': 'Etagenübersicht - Wohnungsfinder - 360° Rundumsicht (Standard)',
    'price': 249.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_wohnungsfinder_standard_einbindung_price',
    'name': 'Etagenübersicht - Wohnungsfinder - technische Einbindung (Standard)',
    'price': 249.0,
    'ordertype': 'floor_overview'
  },
  // style Premium
  {
    'id': 'floor_overview_wohnungsfinder_premium_flat_price',
    'name': 'Etagenübersicht - Wohnungsfinder - (1) Wohnung hervorheben (Premium)',
    'price': 15.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_wohnungsfinder_premium_building_price',
    'name': 'Etagenübersicht - Wohnungsfinder - (1) Gebäudekörper (Premium)',
    'price': 250.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_wohnungsfinder_premium_rundumsicht_price',
    'name': 'Etagenübersicht - Wohnungsfinder - 360° Rundumsicht (Premium)',
    'price': 299.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_wohnungsfinder_premium_einbindung_price',
    'name': 'Etagenübersicht - Wohnungsfinder - technische Einbindung (Premium)',
    'price': 249.0,
    'ordertype': 'floor_overview'
  },
  // floor_overview 360°-Rundumsicht
  {
    'id': 'floor_overview_360_price',
    'name': '360°-Rundumsicht ab',
    'price': 199.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_360_extra_feedback',
    'name': 'Zusätzliches Feedback - Etagenübersichten - 360° Rundumsicht',
    'price': 75.0,
    'ordertype': 'floor_overview'
  },
  // style Standard
  {
    'id': 'floor_overview_360_standard_flat_price',
    'name': '360°-Rundumsicht Wohnungen (Standard)',
    'price': 0.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_360_standard_building_price',
    'name': '360°-Rundumsicht Gebäudekörper (Standard)',
    'price': 199.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_360_standard_rundumsicht_price',
    'name': 'Etagenübersichten - 360° Rundumsicht - technische Einbindung (Standard)',
    'price': 149.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_360_standard_einbindung_price',
    'name': '360°-Rundumsicht Einbindung (Standard)',
    'price': 0.0,
    'ordertype': 'floor_overview'
  },
  // style Premium
  {
    'id': 'floor_overview_360_premium_flat_price',
    'name': '360°-Rundumsicht Wohnungen (Premium)',
    'price': 0.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_360_premium_building_price',
    'name': 'Etagenübersichten - 360° Rundumsicht - (1) Gebäudekörper (Premium)',
    'price': 349.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_360_premium_rundumsicht_price',
    'name': 'Etagenübersichten - 360° Rundumsicht - technische Einbindung (Premium)',
    'price': 149.0,
    'ordertype': 'floor_overview'
  },
  {
    'id': 'floor_overview_360_premium_einbindung_price',
    'name': 'Wohnungsfinder Einbindung (Premium)',
    'price': 0.0,
    'ordertype': 'floor_overview'
  },

  // vstaging

  // vstaging Standbild
  {
    'id': 'vstaging_standbild_price',
    'name': 'Digital Staging Standbild ab',
    'price': 79.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_standbild_extra_feedback',
    'name': 'zusätzliches Feedback - Digitales Staging - Standbilder',
    'price': 15.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_standbild_furniture',
    'name': 'zusätzliches Möbel entfernen - Digitales Staging - Standilder',
    'price': 12.9,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_standbild_renovation',
    'name': 'zusätzliches Renovieren - Digitales Staging - Standbilder',
    'price': 30.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_standbild_wall_move',
    'name': 'zusätzlich Wände versetzten - Digitales Staging - Standbilder',
    'price': 20.0,
    'ordertype': 'vstaging'
  },
  // vstaging Standbild Standard
  {
    'id': 'vstaging_standbild_standard_price',
    'name': 'Digitales Staging - Standbilder - Standard-Katalog - Standard',
    'price': 79.0,
    'ordertype': 'vstaging'
  },
  // vstaging Standbild Basic
  {
    'id': 'vstaging_standbild_basic_price',
    'name': 'Digitales Staging - Standbilder - Premium-Katalog - Standard',
    'price': 89.0,
    'ordertype': 'vstaging'
  },
  // vstaging Standbild Erweitert
  {
    'id': 'vstaging_standbild_erweitert_price',
    'name': 'Digitales Staging - Standbilder - Premium Plus-Katalog - Standard',
    'price': 99.0,
    'ordertype': 'vstaging'
  },
  // vstaging 360
  {
    'id': 'vstaging_360_price',
    'name': 'Digital Staging 360°-Bilder ab',
    'price': 129.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_360_extra_feedback',
    'name': 'zusätzliches Feedback - Digitales Staging - 360° Bilder',
    'price': 20.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_360_furniture',
    'name': 'zusätzliches Möbel entfernen - Digitales Staging - 360° Bilder - Basic-Katalog',
    'price': 12.9,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_360_renovation',
    'name': 'zusätzliches Renovieren - Digitales Staging - 360° Bilder',
    'price': 30.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_360_wall_move',
    'name': 'zusätzlich Wände versetzten - Digitales Staging - 360° Bilder',
    'price': 20.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_360_fp',
    'name': 'zusätzlich Grundriss für Virtuelle Tour - 3D Grundrisse - Premium - bis 140qm Grundfläche',
    'price': 29.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_360_virtual_tour',
    'name': 'zusätzliche virtuelle Tour - Digitales Staging - 360° Bilder',
    'price': 9.0,
    'ordertype': 'vstaging'
  },
  {
    'id': 'vstaging_360_individual_tour',
    'name': 'zusätzliche virtuelle Tour (Einzelspots) - Digitales Staging - 360° Bilder',
    'price': 9.0,
    'ordertype': 'vstaging'
  },
  // vstaging 360°-Bilder Standard
  {
    'id': 'vstaging_360_standard_price',
    'name': 'Digitales Staging - 360° Bilder - Standard-Katalog - Standard',
    'price': 129.0,
    'ordertype': 'vstaging'
  },
  // vstaging 360°-Bilder Basic
  {
    'id': 'vstaging_360_basic_price',
    'name': 'Digitales Staging - 360° Bilder - Basic-Katalog - Standard',
    'price': 149.0,
    'ordertype': 'vstaging'
  },
  // vstaging 360°-Bilder Erweitert
  {
    'id': 'vstaging_360_erweitert_price',
    'name': 'Digitales Staging - 360° Bilder - Erweiterter-Katalog - Standard',
    'price': 159.0,
    'ordertype': 'vstaging'
  },

  // visualisation

  // visualisation indoor
  {
    'id': 'visualisation_indoor_price',
    'name': 'Innenvisualisierung ab',
    'price': 390.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor additional rooms
  {
    'id': 'visualisation_indoor_additional_standard',
    'name': 'Innenvisualisierung zusätzliche kostenpflichtige Räume Standard',
    'price': 250.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_premium',
    'name': 'Innenvisualisierung zusätzliche kostenpflichtige Räume Premium',
    'price': 300.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_premium_plus',
    'name': 'Innenvisualisierung zusätzliche kostenpflichtige Räume Premium Plus',
    'price': 350.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_insane',
    'name': 'nicht verfügbar! Innenvisualisierung zusätzliche kostenpflichtige Räume Insane',
    'price': 0.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor additional rooms
  {
    'id': 'visualisation_outdoor_additional_standard',
    'name': 'Außenvisualisierung zusätzliche kostenpflichtige Räume Standard',
    'price': 250.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_premium',
    'name': 'Außenvisualisierung zusätzliche kostenpflichtige Räume Premium',
    'price': 300.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_premium_plus',
    'name': 'Außenvisualisierung zusätzliche kostenpflichtige Räume Premium Plus',
    'price': 350.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_insane',
    'name': 'nicht verfügbar! Außenvisualisierung zusätzliche kostenpflichtige Räume Insane',
    'price': 0.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor additional 360
  {
    'id': 'visualisation_indoor_additional_360_standard',
    'name': 'zusätzliches 360° Bild aus - 3D Visualisierungen - Innenvisualisierung Video - Standard (in Sekunden)',
    'price': 200.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_360_premium',
    'name': 'zusätzliches 360° Bild aus - 3D Visualisierungen - Innenvisualisierung Video - Premium (in Sekunden)',
    'price': 400.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_360_premium_plus',
    'name': 'zusätzliches 360° Bild aus - 3D Visualisierungen - Innenvisualisierung Video - Premium Plus (in Sekunden)',
    'price': 500.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_360_insane',
    'name': 'nicht verfügbar! Innenvisualisierung zusätzliche 360°-Tour Insane',
    'price': 0.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor additional photos
  {
    'id': 'visualisation_indoor_additional_photos_standard',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Standard',
    'price': 100.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_photos_premium',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Premium',
    'price': 150.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_photos_premium_plus',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Premium Plus',
    'price': 200.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_additional_photos_insane',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Insane',
    'price': 800.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor additional 360
  {
    'id': 'visualisation_outdoor_additional_360_standard',
    'name': 'zusätzliches 360° Bild aus - 3D Visualisierungen - Außenvisualisierung Video - Standard (in Sekunden)',
    'price': 300.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_360_premium',
    'name': 'zusätzliches 360° Bild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium (in Sekunden)',
    'price': 400.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_360_premium_plus',
    'name': 'zusätzliches 360° Bild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium Plus (in Sekunden)',
    'price': 500.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_360_insane',
    'name': 'nicht verfügbar! Außenvisualisierung zusätzliche 360°-Tour Insane',
    'price': 0.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor additional photos
  {
    'id': 'visualisation_outdoor_additional_photos_standard',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Standard',
    'price': 250.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_photos_premium',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium',
    'price': 300.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_photos_premium_plus',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium Plus',
    'price': 350.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_photos_insane',
    'name': 'zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Insane',
    'price': 1100.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_indoor_extra_feedback',
    'name': 'Innenvisualisierung zusätzliches Feedback',
    'price': 70.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Standard
  {
    'id': 'visualisation_indoor_extra_feedback_standard',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Basic',
    'price': 70.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Premium
  {
    'id': 'visualisation_indoor_extra_feedback_premium',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Premium',
    'price': 100.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Premium Plus
  {
    'id': 'visualisation_indoor_extra_feedback_premium_plus',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Premium Plus',
    'price': 150.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Insane
  {
    'id': 'visualisation_indoor_extra_feedback_insane',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Insane',
    'price': 450.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor photo standard
  {
    'id': 'visualisation_indoor_photo_standard',
    'name': '3D Visualisierungen - Innenvisualisierung Standbild - Standard',
    'price': 390.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor photo premium
  {
    'id': 'visualisation_indoor_photo_premium',
    'name': '3D Visualisierungen - Innenvisualisierung Standbild - Premium',
    'price': 490.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor photo premium plus
  {
    'id': 'visualisation_indoor_photo_premium_plus',
    'name': '3D Visualisierungen - Innenvisualisierung Standbild - Premium Plus',
    'price': 790.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor photo insane
  {
    'id': 'visualisation_indoor_photo_insane',
    'name': '3D Visualisierungen - Innenvisualisierung Standbild - Insane',
    'price': 1500.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor 360 standard
  {
    'id': 'visualisation_indoor_360_standard',
    'name': '3D Visualisierungen - Innenvisualisierung 360° - Standard',
    'price': 400.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor 360 premium
  {
    'id': 'visualisation_indoor_360_premium',
    'name': '3D Visualisierungen - Innenvisualisierung 360° - Premium',
    'price': 550.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor 360 premium plus
  {
    'id': 'visualisation_indoor_360_premium_plus',
    'name': '3D Visualisierungen - Innenvisualisierung 360° - Premium Plus',
    'price': 850.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor 360 insane
  {
    'id': 'visualisation_indoor_360_insane',
    'name': '3D Visualisierungen - Innenvisualisierung 360° - Insane',
    'price': 1900.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor video standard
  {
    'id': 'visualisation_indoor_video_standard',
    'name': '3D Visualisierungen - Innenvisualisierung Video - Standard (in Sekunden)',
    'price': 60.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor video premium
  {
    'id': 'visualisation_indoor_video_premium',
    'name': '3D Visualisierungen - Innenvisualisierung Video - Premium (in Sekunden)',
    'price': 65.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor video premium plus
  {
    'id': 'visualisation_indoor_video_premium_plus',
    'name': '3D Visualisierungen - Innenvisualisierung Video - Premium Plus (in Sekunden)',
    'price': 70.0,
    'ordertype': 'visualisation'
  },
  // visualisation indoor video insane
  {
    'id': 'visualisation_indoor_video_insane',
    'name': '3D Visualisierungen - Innenvisualisierung Video - Insane (in Sekunden)',
    'price': 0.0,
    'ordertype': 'visualisation'
  },

  // visualisation outdoor
  {
    'id': 'visualisation_outdoor_price',
    'name': 'Außenvisualisierung ab',
    'price': 450.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional',
    'name': 'Außenvisualisierung zusätzliche kostenpflichtige Räume',
    'price': 100.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_360',
    'name': 'Außenvisualisierung zusätzliche 360°-Tour',
    'price': 150.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_additional_photos',
    'name': 'Außenvisualisierung zusätzliche Standbilder',
    'price': 100.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'visualisation_outdoor_extra_feedback',
    'name': 'Außenvisualisierung zusätzliches Feedback',
    'price': 70.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Standard
  {
    'id': 'visualisation_outdoor_extra_feedback_standard',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Standard',
    'price': 70.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Premium
  {
    'id': 'visualisation_outdoor_extra_feedback_premium',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Premium',
    'price': 100.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Premium Plus
  {
    'id': 'visualisation_outdoor_extra_feedback_premium_plus',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Premium Plus',
    'price': 150.0,
    'ordertype': 'visualisation'
  },
  // extra feedback Insane
  {
    'id': 'visualisation_outdoor_extra_feedback_insane',
    'name': 'zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Insane',
    'price': 450.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor photo standard
  {
    'id': 'visualisation_outdoor_photo_standard',
    'name': '3D Visualisierungen - Außenvisualisierung Standbild - Standard',
    'price': 450.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor photo premium
  {
    'id': 'visualisation_outdoor_photo_premium',
    'name': '3D Visualisierungen - Außenvisualisierung Standbild - Premium',
    'price': 690.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor photo premium plus
  {
    'id': 'visualisation_outdoor_photo_premium_plus',
    'name': '3D Visualisierungen - Außenvisualisierung Standbild - Premium Plus',
    'price': 1050.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor photo insane
  {
    'id': 'visualisation_outdoor_photo_insane',
    'name': '3D Visualisierungen - Außenvisualisierung Standbild - Insane',
    'price': 1800.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 360 standard
  {
    'id': 'visualisation_outdoor_360_standard',
    'name': '3D Visualisierungen - Außenvisualisierun 360° - Standard',
    'price': 490.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 360 premium
  {
    'id': 'visualisation_outdoor_360_premium',
    'name': '3D Visualisierungen - Außenvisualisierun 360° - Premium',
    'price': 750.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 360 premium plus
  {
    'id': 'visualisation_outdoor_360_premium_plus',
    'name': '3D Visualisierungen - Außenvisualisierun 360° - Premium Plus',
    'price': 1100.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 360 insane
  {
    'id': 'visualisation_outdoor_360_insane',
    'name': '3D Visualisierungen - Außenvisualisierun 360° - Insane',
    'price': 1900.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor video standard
  {
    'id': 'visualisation_outdoor_video_standard',
    'name': '3D Visualisierungen - Außenvisualisierung Video - Standard (in Sekunden)',
    'price': 60.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor video premium
  {
    'id': 'visualisation_outdoor_video_premium',
    'name': '3D Visualisierungen - Außenvisualisierung Video - Premium (in Sekunden)',
    'price': 65.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor video premium plus
  {
    'id': 'visualisation_outdoor_video_premium_plus',
    'name': '3D Visualisierungen - Außenvisualisierung Video - Premium Plus (in Sekunden)',
    'price': 70.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor video insane
  {
    'id': 'visualisation_outdoor_video_insane',
    'name': '3D Visualisierungen - Außenvisualisierung Video - Insane (in Sekunden)',
    'price': 0.0,
    'ordertype': 'visualisation'
  },

  // visualisation outdoor 2 buildings standard
  {
    'id': 'visualisation_outdoor_2_buildings_standard',
    'name': 'zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Standard',
    'price': 200.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 3 buildings standard
  {
    'id': 'visualisation_outdoor_3_buildings_standard',
    'name': 'zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Standard',
    'price': 350.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 4 buildings standard
  {
    'id': 'visualisation_outdoor_4_buildings_standard',
    'name': 'zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Standard',
    'price': 450.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 2 buildings premium
  {
    'id': 'visualisation_outdoor_2_buildings_premium',
    'name': 'zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Premium',
    'price': 250.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 3 buildings premium
  {
    'id': 'visualisation_outdoor_3_buildings_premium',
    'name': 'zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Premium',
    'price': 400.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 4 buildings premium
  {
    'id': 'visualisation_outdoor_4_buildings_premium',
    'name': 'zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Premium',
    'price': 550.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 2 buildings premium_plus
  {
    'id': 'visualisation_outdoor_2_buildings_premium_plus',
    'name': 'zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus',
    'price': 250.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 3 buildings premium_plus
  {
    'id': 'visualisation_outdoor_3_buildings_premium_plus',
    'name': 'zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus',
    'price': 400.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 4 buildings premium_plus
  {
    'id': 'visualisation_outdoor_4_buildings_premium_plus',
    'name': 'zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus',
    'price': 550.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 2 buildings insane
  {
    'id': 'visualisation_outdoor_2_buildings_insane',
    'name': 'zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Insane',
    'price': 450.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 3 buildings insane
  {
    'id': 'visualisation_outdoor_3_buildings_insane',
    'name': 'zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Insane',
    'price': 800.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor 4 buildings insane
  {
    'id': 'visualisation_outdoor_4_buildings_insane',
    'name': 'zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Insane',
    'price': 1150.0,
    'ordertype': 'visualisation'
  },

  // visualisation outdoor apartments standard
  {
    'id': 'visualisation_outdoor_apartments_standard',
    'name': 'zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Standard',
    'price': 150.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor apartments premium
  {
    'id': 'visualisation_outdoor_apartments_premium',
    'name': 'zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Premium',
    'price': 250.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor apartments premium_plus
  {
    'id': 'visualisation_outdoor_apartments_premium_plus',
    'name': 'zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus',
    'price': 300.0,
    'ordertype': 'visualisation'
  },
  // visualisation outdoor apartments insane
  {
    'id': 'visualisation_outdoor_apartments_insane',
    'name': 'zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Insane',
    'price': 450.0,
    'ordertype': 'visualisation'
  },
  {
    'id': 'matterport_tour_price',
    'name': 'Matterport Tour für eine Wohnfläche bis 80 m2',
    'price': 550.0,
    'ordertype': 'virtual_tour'
  },
  {
    'id': 'matterport_addunits',
    'name': 'Zusätzliche 20 qm',
    'price': 80.0,
    'ordertype': 'virtual_tour'
  },
  // Documents Procurement packages prices
  {
    'id': 'documents_procurement_starter_price',
    'name': 'Starter',
    'price': 194.00,
    'ordertype': 'documents_procurement'
  },
  {
    'id': 'documents_procurement_default_price',
    'name': 'Default',
    'price': 274.00,
    'ordertype': 'documents_procurement'
  },
  {
    'id': 'documents_procurement_plus_price',
    'name': 'Plus',
    'price': 339.00,
    'ordertype': 'documents_procurement'
  },
  {
    'id': 'documents_procurement_addUnits',
    'name': 'weiteren Flur',
    'price': 20.00,
    'ordertype': 'documents_procurement'
  }
];
