import { Component, OnInit, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { UploadService } from '@app/_services/upload.service';
import { SubcollectionFileModel } from '@app/models/subcollectionFileModel';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File; // file to be uploaded
  @Input() path: string; // path to upload file on cloud storage
  @Input() parentId: string; // id of the parent record to which subcollection for files is linked
  @Input() parentType: string; // parent type e.g suborders
  @Input() subCollectionName: string; // name for the subcollection
  @Output() removeFileFromQueue = new EventEmitter();
  percentage: BehaviorSubject<number>;
  snapshot: Observable<any>;

  constructor(
    private uploadService: UploadService
  ) { }

  ngOnInit() {
    this.percentage = new BehaviorSubject<number>(0);
    this.startUpload();
  }

  startUpload() {
    this.uploadService.uploadFile(this.file, this.path, false, '').subscribe((event) => {
      // Progress monitoring
      if (event.type === HttpEventType.UploadProgress) {
        this.percentage.next(Math.round(100 * event['loaded'] / event['total']));
      }
      if (event.type === HttpEventType.Response) {
        const uploadResponse = <SubcollectionFileModel> event.body;
        this.uploadService.addSubCollectionDocument(this.parentType, this.parentId, this.subCollectionName, uploadResponse).then(() => {
          this.removeFileFromQueue.emit(this.file);
        });
      }
    });
  }
}
