import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { discountTypeList, DiscountTypeEnum } from '@app/models/discount-type-list';
import { Subscription } from 'rxjs';
import { OrdersService } from '@app/_services/orders.service';
import { CompaniesService } from '@app/_services/companies.service';
import { Company } from '@app/interfaces/company.interface';
import { LoaderService } from '@app/_services/loader.service';
import { UsersService } from '@app/_services/users.service';
import { DiscountsService } from '@app/_services/discounts.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { GlobalService } from '@app/_services/global.service';
import {ServicesService} from '@app/_services/services.service';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css']
})
export class DiscountsComponent implements OnInit, OnDestroy {
  discountForm: FormGroup;
  isFormExpanded: boolean;
  isEditing = false;
  discountTypeList = discountTypeList;
  productList: any[];
  companyList: Company[];
  userList: any[];
  tableDataSrc = new MatTableDataSource();
  columnsToDisplay = ['companyName', 'productId', 'formattedValue', 'chargingInvoiceUserName', 'action'];
  filterValues = {};

  private subscriptions: Subscription[] = [];

  @ViewChild(MatSort) set sort(value: MatSort) {
    this.tableDataSrc.sort = value;
  }

  constructor(
    private gs: GlobalService,
    private fb: FormBuilder,
    private cs: CompaniesService,
    private us: UsersService,
    private ds: DiscountsService,
    private loader: LoaderService,
    private services: ServicesService
  ) { }

  ngOnInit() {
    this.discountForm = this.fb.group({
      type: ['', [Validators.required]],
      value: [0, [Validators.required]],
      productId: ['', [Validators.required]],
      companyId: ['', [Validators.required]],
      chargingInvoiceUserId: ['']
    });
    this.isFormExpanded = false;
    this.userList = this.us.allUsers;
    this.companyList = this.cs.companiesList;

    this.subscriptions.push(
      this.services.getAllServices().subscribe((services: any[]) => {
        this.productList = services;
      })
    );
    this.subscriptions.push(
      this.us.onUsersRefresh.subscribe(() => {
        this.userList = this.us.allUsers;
      })
    );

    this.tableDataSrc = new MatTableDataSource(this.ds.discountsList);
    this.setTableFilter();

    this.subscriptions.push(
      this.ds.onDataRefresh.subscribe(() => {
        this.tableDataSrc = new MatTableDataSource(this.ds.discountsList);
        this.setTableFilter();
      })
    );
  }

  get type() {
    return this.discountForm.get('type').value;
  }

  get value() {
    return this.discountForm.get('value').value;
  }

  get productId() {
    return this.discountForm.get('productId').value;
  }

  get companyId() {
    return this.discountForm.get('companyId').value;
  }

  get chargingInvoiceUserId() {
    return this.discountForm.get('chargingInvoiceUserId').value;
  }

  set type(value: string) {
    this.discountForm.get('type').setValue(value);
  }

  set value(value: number) {
    this.discountForm.get('value').setValue(value);
  }

  set productId(value: string) {
    this.discountForm.get('productId').setValue(value);
  }

  set companyId(value: string) {
    this.discountForm.get('companyId').setValue(value);
  }

  set chargingInvoiceUserId(value: string) {
    this.discountForm.get('chargingInvoiceUserId').setValue(value);
  }

  toggleFormExpansion() {
    this.isFormExpanded = !this.isFormExpanded;
  }

  async onSubmit() {
    const data = {
      ...this.discountForm.value,
      productId: this.productId,
      companyId: this.companyId
    };

    try {
      await this.ds.createOrReplace(data).toPromise();

      if (this.isEditing) {
        this.cancelEditing();
      } else {
        this.discountForm.reset();
      }

      this.gs.showNotification('Rabatt erfolgreich hinzugefügt.', 'success');
    } catch (err) {
      console.error(err);
      this.gs.showNotification('Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.', 'danger');
    }
  }

  private setTableFilter(): void {
    this.tableDataSrc.filterPredicate = (object) => {
      let flag = true;
      Object.keys(this.filterValues).forEach(key => {
        if (this.filterValues[key] && object[key].toLowerCase().indexOf(this.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.tableDataSrc.filter = 'test';
  }

  noSort(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  editItem(companyId: string, productId: string) {
    this.isEditing = true;
    this.isFormExpanded = true;

    const editingDiscount = this.ds.discountsList.find((item) => item.companyRef.id === companyId && item.productId === productId);

    this.type = editingDiscount.type;
    this.value = editingDiscount.value;
    this.productId = editingDiscount.productId;
    this.companyId = editingDiscount.companyRef.id;
    this.chargingInvoiceUserId = editingDiscount.chargingInvoiceUserRef && editingDiscount.chargingInvoiceUserRef.id;

    this.discountForm.get('productId').disable();
    this.discountForm.get('companyId').disable();
  }

  cancelEditing() {
    this.isEditing = false;
    this.isFormExpanded = false;
    this.discountForm.reset();
    this.discountForm.enable();
  }

  removeItem(companyId: string, productId: string) {
    const docId = companyId + '|' + productId;
    const confirmed = confirm('Möchten Sie den Rabatt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!');
    if (confirmed) {
      try {
        this.ds.delete(docId).toPromise();
        this.gs.showNotification('Rabatt erfolgreich entfernt.', 'success');
      } catch (err) {
        console.error(err);
        this.gs.showNotification('Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.', 'danger');
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
