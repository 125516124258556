import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { CONSTANTS } from '@app/util/constants';
import { GlobalService } from './global.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserRoleEnum } from '@app/models/user-role-list';
import { Resolve } from '@angular/router';
import * as accounting from 'accounting';
import { UserAuthModel } from '@app/models/user-auth.model';
import { SubOrder } from '@app/interfaces/suborder.interface';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class WebsocketsService implements Resolve<any> {
  allOrders: BehaviorSubject<[]>; // All orders, including saved orders and 'real' orders
  orders: BehaviorSubject<[]>; // Only 'real' orders = AllOrders without orders savedForLater
  savedOrders: BehaviorSubject<[]>; // Orders with savedForLater = true
  allSuborders: BehaviorSubject<SubOrder[]> = new BehaviorSubject<SubOrder[]>([]); // List of all suborders in an order
  currentSuborder: BehaviorSubject<any> = new BehaviorSubject<{}>({}); // Active suborder
  myAssignedSuborders: BehaviorSubject<[]> = new BehaviorSubject<[]>([]); // List of all assigned orders for a service provider
  openSuborders: BehaviorSubject<[]> = new BehaviorSubject<[]>([]); // List of open suborders for a service provider
  currentSuborderSubscription: Subscription;
  userDetails: any;
  subOrdersOfRealEstate: BehaviorSubject<SubOrder[]> = new BehaviorSubject<SubOrder[]>([]);

  constructor(
    private socket: Socket,
    private globalService: GlobalService
  ) {
    this.allOrders = new BehaviorSubject<[]>([]);
    this.orders = new BehaviorSubject<[]>([]);
    this.savedOrders = new BehaviorSubject<[]>([]);
    this.allOrders.pipe(map((orders: object[]) => orders.filter(order => order['savedForLater'] !== true))).subscribe(this.orders);
    this.allOrders.pipe(map((orders: object[]) => orders.filter(order => order['savedForLater'] === true))).subscribe(this.savedOrders);
  }

  /**
   * Connects to the websocket endpoint.
   * @param options should be a json object containing a valid idToken
   */
  connect(options: {}) {
    this.socket.ioSocket.io.opts.query = { token: 'Bearer ' + options['idToken'] };
    this.socket.connect();
  }

  /**
   * Disconnects from the websocket and closes the underlying connection.
   */
  disconnect() {
    this.socket.disconnect(true);
  }

  resolve() {
    if (!this.userDetails || !this.userDetails.role) {
      return;
    } else if (this.userDetails.role === UserRoleEnum.ServiceProvider) {
      return this.getMySuborders();
    } else {
      return this.subscribeOrders();
    }
  }

  checkConnectionAndRemoveDuplicateSubs(subscription?: string) {
    // Ensure that if the socket connection is broken the connection gets restored
    if (!this.socket || !this.socket.ioSocket || !this.socket.ioSocket.connected) {
      const user: UserAuthModel = JSON.parse(localStorage.getItem('user'));
      this.connect({ idToken: user.idToken });
    }
    // Prevent duplicated subscriptions
    if (subscription && this.socket.ioSocket._callbacks['$' + subscription]) {
      this.socket.removeListener(subscription);
    }
  }

  /**
   * Subscribes to orders in the maximum scope of the current user, authenticated by the idToken
   */
  subscribeOrders() {
    this.checkConnectionAndRemoveDuplicateSubs(CONSTANTS.WEBSOCKET_EVENTS.ORDERS.CHANGED);
    this.socket.on(CONSTANTS.WEBSOCKET_EVENTS.ORDERS.CHANGED, (data) => {
      data.forEach((order) => {
        order['priceNum'] = accounting.unformat(order['price'], ',');
      });
      this.allOrders.next(data);
    }
    );

    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.ORDERS.SUBSCRIBE);
  }

  /**
   * Unsubscribes from orders
   */
  unsubscribeOrders() {
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.ORDERS.UNSUBSCRIBE);
    this.socket.removeListener(CONSTANTS.WEBSOCKET_EVENTS.ORDERS.CHANGED);
  }

  /**
   *
   * @param orderId - id of the order for which the list of suborders need to be fetched
   * Subscribes to real time changes of list of suborders belonging to the given order id
   */
  getSuborders(orderId: string) {
    this.checkConnectionAndRemoveDuplicateSubs(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBORDER_CHANGES);
    this.socket.on(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBORDER_CHANGES, (data) => {
      this.allSuborders.next(data);
    });
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBSCRIBE_SUBORDERS_OF_ORDER, orderId);
  }

  /**
   * Unsubscribe from real time updated of suborders of an order
   */
  unsubscribeFromSuborders() {
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.UNSUBSCRIBE_SUBORDERS_OF_ORDER);
  }

  /**
   * @param suborderId - id of the suborder to fetch details
   * Subscribes to real time updates of a suborder
   */
  getSuborder(suborderId: string) {
    this.checkConnectionAndRemoveDuplicateSubs(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.CURRENT_SUBORDER_CHANGES);
    this.socket.on(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.CURRENT_SUBORDER_CHANGES, (data) => {
      this.currentSuborder.next(data);
    });
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBSCRIBE_SUBORDER, suborderId);
  }

  /**
   * This method should be used whenever we just want to fetch suborder info from all our realtime suborders.
   * There is already a method called 'getSuborder' which is fetching a suborder but also setting it as the
   * currentSuborder, therefore this method was name differently since we just want to fetch a suborder without setting the current one.
   * @param suborderId The suborder ID
   */
  fetchSuborder(suborderId: string) {
    return this.allSuborders.value.find(item => item.id === suborderId);
  }

  /**
   * Unsubscribes from real time update of a suborder
   */
  unsubscribeFromCurrentSuborder() {
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.UNSUBSCRIBE_SUBORDER);
  }

  /**
   * Subscribe to real time update of all suborders available to a service provider
   */
  getMySuborders() {
    const userDetails = this.userDetails;
    const orderTypes = ['dronemedia', 'visualisation', 'hdphotos', 'floor_overview', 'virtual_tour'];
    if (!userDetails || userDetails.role !== UserRoleEnum.ServiceProvider) {
      console.log("Not a service provider");
      this.allSuborders.next([]);
      this.myAssignedSuborders.next([]);
    } else {
      this.checkConnectionAndRemoveDuplicateSubs(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBORDER_LIST_CHANGES);
      this.socket.on(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBORDER_LIST_CHANGES, (data) => {
        data.forEach(row => {
          if (row.packageName) {
            row['suborderType'] = row.packageName;
          } else {
            row['suborderType'] = this.globalService.translateAppListStrings('order_type_list', row.orderType)
            row['suborderType'] += row.selectedPackage ? ' - ' + row.selectedPackage : '';
          }
        });
        this.allSuborders.next(data.filter(x => x.status === 'checked'));
        this.myAssignedSuborders.next(data.filter(x => x.status !== 'checked'));
      });
      this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBSCRIBE_SUBORDER_LIST, orderTypes.filter(x => userDetails[x]));
    }
  }

  /**
   * Unsubscribe from real time updates of all my suborders
   */
  unsubscribeFromMySuborders() {
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.UNSUBSCRIBE_SUBORDER_LIST);
  }

  /**
   *
   * @param realEstateId - id of the real estate for which the list of suborders need to be fetched
   * Subscribes to real time changes of list of suborders belonging to the given real estate id
   */
  getSubordersOfRealEstate(realEstateId: string) {
    this.checkConnectionAndRemoveDuplicateSubs(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.REALESTATE_SUBORDER_CHANGES);
    this.socket.on(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.REALESTATE_SUBORDER_CHANGES, (data) => {
      this.subOrdersOfRealEstate.next(data);
    });
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.SUBSCRIBE_REALESTATE_SUBORDERS, realEstateId);
  }

  /**
   * Unsubscribe from real time updates of all suborders of a real estate
   */
  unsubscribeFromRealEstateSuborders() {
    this.socket.emit(CONSTANTS.WEBSOCKET_EVENTS.SUBORDERS.UNSUBSCRIBE_REALESTATE_SUBORDERS);
  }

}
