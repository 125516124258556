import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(private http: HttpClient) { }

  callFunction(functionName: string, bodyContent: any) {
    return this.http.post(environment.apiBaseUrl + functionName, {data: bodyContent});
  }
}
