import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountingPositionsService } from '@app/_services/accounting-positions.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { LoaderService } from '@app/_services/loader.service';
import { AuthService } from '@app/auth/auth.service';
import { Subscription } from 'rxjs';
import { AccountingPosition } from '@app/interfaces/accounting-position.interface';

@Component({
  selector: 'app-accounting-positions',
  templateUrl: './accounting-positions.component.html',
  styleUrls: ['./accounting-positions.component.css']
})
export class AccountingPositionsComponent implements OnInit {
  private readonly logComponent = "AccountingPositionsComponent :: ";
  columnsToDisplay = ['name', 'price', 'ordertype', 'action'];
  accountingData = new MatTableDataSource(this.aps.allPositions);
  item = 1;
  editIndex = -1;
  tempAccountingPosition: AccountingPosition = {
    'id': '',
    'name': '',
    'ordertype': '',
    'price': 0
  };
  productList = [
    {
      'label': 'Drohnenaufnahmen',
      'value': 'dronemedia'
    },
    {
      'label': 'Etagenübersicht',
      'value': 'floor_overview'
    },
    {
      'label': 'Grundrisse',
      'value': 'floorplan'
    },
    {
      'label': 'HD Fotos',
      'value': 'hdphotos'
    },
    {
      'label': 'Bildbearbeitung',
      'value': 'retouching'
    },
    {
      'label': '3D-Visualisierung',
      'value': 'visualisation'
    },
    {
      'label': 'Digitales Staging',
      'value': 'vstaging'
    },
    {
      'label': 'Dokumentenbeschaffung',
      'value': 'documents_procurement'
    },
  ];
  filterValues = {};
  constructor(
    public auth: AuthService,
    public aps: AccountingPositionsService,
    public loader: LoaderService
  ) { }

  ngOnInit() { }

  onProductFilter(event): void {
    this.filterValues['ordertype'] = event['value'];
    console.log(this.filterValues);
    this.accountingData.filter = this.filterValues['ordertype'];
  }

  onEditClick(event, index) {
    this.tempAccountingPosition = {
      'id': event.id,
      'name': event.name,
      'ordertype': event.ordertype,
      'price': event.price
    };
    this.editIndex = index;
  }

  noSort(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onCancelClick() {
    this.editIndex = -1;
    this.tempAccountingPosition = {
      'id': '',
      'name': '',
      'ordertype': '',
      'price': 0
    };
  }

  onSaveClick() {
    this.editIndex = -1;
    this.aps.updateAccountingPositions([this.tempAccountingPosition]);
    this.tempAccountingPosition = {
      'id': '',
      'name': '',
      'ordertype': '',
      'price': 0
    };
    this.accountingData = new MatTableDataSource(this.aps.allPositions);
  }

  ngOnDestroy() {
    console.log(this.logComponent + 'Destroying component.');
  }
}
