import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/_services/loader.service';
import { AuthService } from '@app/auth/auth.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { RealestateService } from '@app/_services/realestate.service';
import { UsersService } from '@app/_services/users.service';
import { Router } from '@angular/router';
import { OrdersService } from '@app/_services/orders.service';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public emailResent = false;

  constructor(public auth: AuthService,
    private loader: LoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    public res: RealestateService,
    public us: UsersService,
    public router: Router,
    public os: OrdersService) {
  }

  ngOnInit() {
  }

}
