import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import proxyPolyfillFunc from 'proxy-polyfill/proxy.min.js';

/**
 * Angular fire analytics stops loading the app / browser support page. 
 * This does not make analyitcs work, but just ignores error and loads browser support page with some console errors on IE.
 */
if (!window['Proxy']) {
  window['Proxy'] = proxyPolyfillFunc();
}

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function() {}; // keine console.logs im Production Mode
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
