import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersService implements Resolve<any[]> {
  allUsers = [];
  tempUser = null; // property used to store a user info temporarily, so that we can set it in one place and read in another
  cartItems: any[] = [];
  selectedServices: any[] = [];
  onUsersRefresh: EventEmitter<boolean> = new EventEmitter();
  private readonly apiBaseUrl = environment.apiUrl + 'users/';

  constructor(
    private http: HttpClient
  ) { }

  resolve() {
    return this.getAllUsers();
  }

  getAllUsers(): Observable<any> {
    return this.http.get(this.apiBaseUrl).pipe(
      map((usersList: any[]) => {
        usersList.sort((a, b) => a.role.localeCompare(b.role))
        this.allUsers = usersList;
        this.onUsersRefresh.emit();
        return usersList;
      })
    );
  }

  getUserDetails(uid: string) {
    return this.http.get(this.apiBaseUrl + 'uid/' + uid).pipe(
      map(usersList => {
        return usersList[0] || {};
      })
    );
  }

  getUserRefByEMail(mail: string) {
    return this.http.get(this.apiBaseUrl + 'email/' + mail).pipe(
      map(usersList => {
        this.tempUser = usersList[0];

        return this.tempUser;
      })
    );
  }

  updateUser(uid: string, data: object) {
    return this.http.put(this.apiBaseUrl + '/' + uid, data).pipe(
      map((userData: any) => {
        const userIndex = this.allUsers.findIndex(user => user.uid === userData.uid);
        if(userIndex >= 0){
          this.allUsers[userIndex] = userData;
          this.onUsersRefresh.emit();
        }
        return userData;
      })
    ).toPromise();
  }

  getAllUsersOfCompany(companyId) {
    return this.http.get(this.apiBaseUrl + 'company/' + companyId);
  }

}
