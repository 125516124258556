import { Injectable, EventEmitter } from '@angular/core';
import { RealestateService } from '@app/_services/realestate.service';
import { OrdersService } from '@app/_services/orders.service';
import { ConfigurationService } from '@app/_services/configuration.service';
import { Router } from '@angular/router';
import { UsersService } from '@app/_services/users.service';
import { AuthService } from '@app/auth/auth.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as _ from 'underscore';
import * as accounting from 'accounting';
import { EsoftApiService } from './esoft-api.service';
import { GlobalService } from './global.service';

import { DiscountsService } from './discounts.service';
import { CompaniesService } from '@app/_services/companies.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  /* This service is used to do all necessary upfront-database-reads */

  private readonly logName = "-- LoaderService :: ";
  public uid;
  public emailVerified;
  public dataAfterAuthenticated: Promise<boolean>;
  public realestateSub: any;
  public loaded = false;
  $1: any;
  $2: any;
  $3: any;
  $4: any;
  $5: any;
  $6: any;
  $7: any;
  $8: any;
  $9: any;
  $10: any;
  $11: any;
  $12: any;
  public initializeScroller: EventEmitter<boolean> = new EventEmitter<boolean>();
  public scrollerInitialized: boolean = false;
  public onOrderRefresh: EventEmitter<string> = new EventEmitter();
  public onInvoicesRefresh: EventEmitter<boolean> = new EventEmitter();
  public onUsersRefresh: EventEmitter<boolean> = new EventEmitter();
  public onAccountingPositionsRefresh: EventEmitter<boolean> = new EventEmitter();
  public onSubOrderrefresh: EventEmitter<boolean> = new EventEmitter();
  public onAssignedOrderRefresh: EventEmitter<boolean> = new EventEmitter();
  onAllSubOrdersTempRefresh = new EventEmitter();
  savedOrderSub: Subscription;
  onSavedOrderRefresh: EventEmitter<string> = new EventEmitter();

  constructor(private res: RealestateService,
    private os: OrdersService,
    private router: Router,
    private us: UsersService,
    private auth: AuthService,
    private conf: ConfigurationService,
    private ngxSmartModalService: NgxSmartModalService,
    private esoftClient: EsoftApiService,
    private cs: CompaniesService,
    private discounts: DiscountsService,
    private gs: GlobalService
  ) { }
  /**
   * Gets called from App component and login component. Initializes the user data for according to its role if the user is authenticated.
   */
  async getDataAfterAuthenticated() {
    console.log('[1]: Initializing Loader-Service!');
    console.log('[2]: Loader started, awaiting User-Initialization!');
    await this.auth.initializeUser().then(result => {
      console.log('[5]: User initialized, continuing!');
      return new Promise<boolean>(async resolve => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user == null) {
          console.log('[6]: Nobody logged in');
          console.log('[7]: Skipping loading database-data.');
        } else {
          console.log('[6]: User logged in, starting to load data from the database!');
          this.uid = user.uid;
          this.gs.changeLanguage(this.auth.myUserObservable.language ? this.auth.myUserObservable.language : 'de', this.auth.myUserObservable.uid);
          // User-Tutorial
          if (this.auth.emailVerified === false) {
            this.ngxSmartModalService.getModal('emailVerificationModal').open();
          } else {
            this.checkTutorial();
          }

          /* The following construct is used to avoid unneccessary database reads and to load all data
          before component initialisation, so the data is already loaded on route change and we do not
          have a loading spinner on almost every page. This whole service replaces the approach of using
          a route resolver to load the route specific contents before showing the UI to not have to show
          loading spinners. They would not work with Firebase, because unlike with the HTTP Service,
          the Firebase observables dont end, thus it would either never resolve or if we use first it
          would resolve and load, but we would lose the realtime features.
          Ideal would be to just work with async pipes (which I replaced with subscriptions in this case),
          but this would only work if we would load all this data in the app.component.html with the async
          pipe and then all child components would have access to this data and we would never have to
          subscribe and then unsubscribe on logout, however the way we structured the application,
          the components are not child components, while we could change all that, it would
          be more work than just subscribing and unsubscribing in this service, which was used before anyways
          as a resolver for the custom user data and the
          Subscriptions added on 28th February */
          // ToDo: Suborders for Dienstleister & Kunde
          if (this.auth.role === 'Kunde') {
            console.log('[7]: Loading relevant database-data for role "Kunde"!');
            /* this.$1 = this.res.getMyRealestates().subscribe(data => {
              this.res.myRealestates = data;
            }); */
            this.$2 = this.os.getCreatedOrders(this.uid).subscribe(data => {
              this.convertOrderData(data);
              this.os.myCreatedOrders = data;
              this.onOrderRefresh.emit('Kunde');
            });
            this.$3 = this.os.getAllSubordersTemp(this.uid, this.auth.role).subscribe(data => {
              this.os.allSubordersTemp = data;

              console.log(this.logName + 'Notifying all subscriptions that we got new data.');
              this.onAllSubOrdersTempRefresh.emit();
            });
            this.$6 = this.conf.getDisabledServices().subscribe(data => {
              this.conf.disabledServices = data;
            });
            this.savedOrderSub = this.os.getSavedOrders().subscribe(data => {
              data.forEach((order) => {
                order['priceNum'] = accounting.unformat(order['price'], ',');
              });
              this.os.savedOrders = data;
              this.onSavedOrderRefresh.emit('Kunde');
            });
            if (this.auth.myUserObservable.company) {
              this.$8 = this.os.getCompanyCreatedOrders(this.auth.myUserObservable.company).subscribe(data => {
                this.convertOrderData(data);
                this.os.myCompanyCreatedOrders = data;
                this.onOrderRefresh.emit('Kunde');
              });
              /* this.$9 = this.res.getMyCompanyRealEstates(this.auth.myUserObservable.company).subscribe(data => {
                this.res.myCompanyRealestates = data;
                this.onRealEstatesRefresh.emit();
              }); */
              // this.$10 = this.discounts.getCompanyDiscounts(this.auth.myUserObservable.company).subscribe();
            }
          } else if (this.auth.role === 'Dienstleister') {
            console.log('[7]: Loading relevant database-data for role "Dienstleister"!');
            const skills = [this.auth.myUserObservable.dronepilot, this.auth.myUserObservable.visualisation, this.auth.myUserObservable.hdphotos, this.auth.myUserObservable.floor_overview, this.auth.myUserObservable.virtual_tour];
            const orderTypes = ['dronemedia', 'visualisation', 'hdphotos', 'floor_overview', 'virtual_tour'];
            this.$1 = this.os.getSubordersForSkills(skills, orderTypes).subscribe(data => {
              data.forEach(row => {
                if (row.packageName) {
                  row['suborderType'] = row.packageName;
                } else {
                  row['suborderType'] = this.gs.translateAppListStrings('order_type_list', row.orderType)
                  row['suborderType'] += row.selectedPackage ? ' - ' + row.selectedPackage : '';
                }
              });
              this.os.allSuborders = data;
              this.onSubOrderrefresh.emit(true);
            });
            this.$2 = this.os.getMyRequestedSuborders(this.uid).subscribe(data => {
              this.convertRequestedSubOrderData(data);
              this.os.myRequestedSuborders = data;
              this.onAssignedOrderRefresh.emit(true);
            });
            this.$3 = this.os.getAllSubordersTemp().subscribe(data => {
              this.os.allSubordersTemp = data;

              console.log(this.logName + 'Notifying all subscriptions that we got new data.');
              this.onAllSubOrdersTempRefresh.emit();
            });
            /* Company Role feature stays hidden because we're still not sure how they should be used for Service Providers
            if (this.auth.myUserObservable.company) {
              this.$4 = this.os.getCompanyRequestedSubOrders(this.auth.myUserObservable.company).subscribe(data => {
                this.convertRequestedSubOrderData(data);
                this.os.myCompanyRequestedSuborders = data;
                this.onAssignedOrderRefresh.emit(true);
              });
            } */
          } else if (this.auth.role === 'Administrator') {
            console.log('[7]: Loading relevant database-data for role "Administrator"!');
            /* this.$1 = this.res.getMyRealestates().subscribe(data => {
              this.res.myRealestates = data;
            }); */
            this.$2 = this.os.getAllOrders().subscribe(data => {
              this.os.allOrders = data;
              this.onOrderRefresh.emit('Administrator');
            });
            this.$3 = this.os.getCreatedOrders(this.uid).subscribe(data => {
              this.os.myCreatedOrders = data;
            });
            await this.cs.getCompanies().toPromise();
            /* this.$4 = this.us.getAllUsers().subscribe(data => {
              data.forEach(async user => {
                user.name = user.firstName + ' ' + user.lastName;
                if (user.company) {
                  if (user.company.id) {
                    const comp = await this.cs.getCompany(user.company.id).toPromise();
                    if (comp) {
                      user.companyName = comp['name'];
                    }
                  } else if (typeof user.company === 'string') { // as it is in old entries.
                    user.companyName = user.company;
                  }
                } else {
                  user.companyName = '';
                }

              });
              this.us.allUsers = data;
              // Sorting by role
              this.us.allUsers = this.us.allUsers.sort((a, b) => a.role.localeCompare(b.role));
              this.onUsersRefresh.emit(true);
            }); */ // only relevant for Admins
            // todo: getAllSuborders (suborder-overview)
            this.$5 = this.os.getAllSubordersTemp().subscribe(data => {
              this.os.allSubordersTemp = data;

              console.log(this.logName + 'Notifying all subscriptions that we got new data.');
              this.onAllSubOrdersTempRefresh.emit();
            });
            this.$8 = this.conf.getDisabledServices().subscribe(data => {
              this.conf.disabledServices = data;
            });
            this.$10 = this.discounts.getAll().subscribe();
            this.savedOrderSub = this.os.getSavedOrders().subscribe(data => {
              data.forEach((order) => {
                order['priceNum'] = accounting.unformat(order['price'], ',');
              });
              this.os.savedOrders = data;
              this.onSavedOrderRefresh.emit('Kunde');
            });

            /* Company Role feature stays hidden because we're still not sure how they should be used for Administrators
            if (this.auth.myUserObservable.company) {
              this.$10 = this.os.getCompanyCreatedOrders(this.auth.myUserObservable.company).subscribe(data => {
                this.os.myCompanyCreatedOrders = data;
                this.onOrderRefresh.emit('Administrator');
              });
              this.$11 = this.res.getMyCompanyRealEstates(this.auth.myUserObservable.company).subscribe(data => {
                this.res.myCompanyRealestates = data;
                this.onRealEstatesRefresh.emit();
              });
            } */
          } else {
            window.alert('Es gab einen Fehler beim Laden Ihrer Daten. Dieser Fehler betrifft spezifisch Ihren Account! Bitte kontaktieren Sie den IMOGENT-Support mit dem Error-Code: 18-5');
          }
        }
        console.log('[8]: Loading of database-data completed!');
        console.log('[9]: Loader done!');
        setTimeout(() => {
          this.loaded = true;
          this.initializeScroller.emit(true);
        }, 750);
        resolve(true);
        this.dataAfterAuthenticated = Promise.resolve(true);
      });
    });
  }
  /**
   * Gets called from the app.component.html when user clicks verify email button in the modal. User the auth service to verify email.
   * If the email is verified user is navigated to tutorial.
   */
  verifyEmail() {
    this.auth.verifyEmail().then(result => {
      if (result === true) {
        this.checkTutorial();
      } else {
        console.log('Verification Promise resolved, but false.');
        // do nothing for now
      }
    });
  }
  /**
   * Start the tutorial for the logged in user role. Skip it if user pressed the skip button.
   */
  checkTutorial() {
    if (this.auth.tutorialSkipped !== true && this.router.isActive('home', true) === true) {
      this.realestateSub = this.res.getRealestates().subscribe(async res => {
        if (this.auth.role === 'Kunde' && res.length === 0) {
          this.ngxSmartModalService.getModal('firstImmoModal').open();
          this.realestateSub.unsubscribe();
        } else if (this.auth.role === 'Dienstleister' && this.auth.profileCompleted === false) {
          this.ngxSmartModalService.getModal('newDienstleisterModal').open();
          this.realestateSub.unsubscribe();
        } else {
          console.log('Tutorial not necessary');
          this.realestateSub.unsubscribe();
        }
      });
    } else {
      console.log('Tutorial skipped!');
    }
  }

  logout() {
    console.log('Starting to unsubscribe!');
    this.router.navigate['/home'];
    this.auth.sessionEnded = true;
    this.$1 && this.$1.unsubscribe();
    this.$2 && this.$2.unsubscribe();
    this.$3 && this.$3.unsubscribe();
    this.$4 && this.$4.unsubscribe();
    this.$5 && this.$5.unsubscribe();
    this.$6 && this.$6.unsubscribe();
    this.$7 && this.$7.unsubscribe();
    this.$8 && this.$8.unsubscribe();
    this.$9 && this.$9.unsubscribe();
    this.$10 && this.$10.unsubscribe();
    this.$11 && this.$11.unsubscribe();
    this.$12 && this.$12.unsubscribe();
    this.savedOrderSub && this.savedOrderSub.unsubscribe();
    this.os.$suborderSub && this.os.$suborderSub.unsubscribe();
    this.esoftClient.subscriptions.forEach(sub => {
      if (_.isFunction(sub.unsubscribe)) {
        sub.unsubscribe();
      }
    });
    if (!_.isEmpty(this.esoftClient.$esoftDBConfigSub) && _.isFunction(this.esoftClient.$esoftDBConfigSub.unsubscribe)) {
      this.esoftClient.$esoftDBConfigSub.unsubscribe();
    }
    this.os.completionEmailSub && this.os.completionEmailSub.unsubscribe();
    this.os.allSubOrdersSub && this.os.allSubOrdersSub.unsubscribe();
    this.os.allServicesSub && this.os.allServicesSub.unsubscribe();
    this.$1 = null;
    this.$2 = null;
    this.$3 = null;
    this.$4 = null;
    this.$5 = null;
    this.$6 = null;
    this.$7 = null;
    this.$8 = null;
    this.savedOrderSub = null;
    this.$9 = null;
    this.$10 = null;
    this.$11 = null;
    this.$12 = null;
    this.os.cartItems = [];
    this.os.selectedPackageIds = [];
    this.os.selectedPackageNames = [];
    this.os.selectedRealEstate = null;
    this.os.selectedServices = [];
    this.auth.logout();
  }

  getSubordersID(orderID: string) {
    // Unsubscribe from all Subscriptions to prevent memory leaks and insufficient permission errors
    if (this.auth.role === 'Kunde') {
      // todo: this.os.allSubordersID (suborder-overview)
      this.os.allSuborders = null;
      // issue fix
      // if this.$4 is already subscribed we need to unsubscribe it first
      // otherwise if the user will open a different record it will subscribe again so we
      // will have multiple subscriptions and if any of the data from opened records is updated
      // this.os.allSuborders will get updated with this record and we will lose the information
      // for the current record that user is vieweing
      if (this.$5) {
        this.$5.unsubscribe();
      }
      this.$5 = this.os.getSubordersID(orderID).subscribe(data => {
        // todo: this.os.allSubordersId (suborder-overview)
        this.os.allSuborders = data;
      });
    } else if (this.auth.role === 'Administrator') {
      // issue fix: see above comment
      if (this.$6) {
        this.$6.unsubscribe();
      }
      this.$6 = this.os.getSubordersID(orderID).subscribe(data => {
        // todo: this.os.allSubordersId (suborder-overview)
        this.os.allSuborders = data;
      });
    }
  }

  private convertOrderData(data) {
    data.forEach((order) => {
      order['priceNum'] = accounting.unformat(order['price'], ',');
    });
  }

  private convertRequestedSubOrderData(data) {
    data = data.filter(suborder => suborder['status'] !== 'opened' && suborder['status'] !== 'pending');
    data.forEach((row: any) => {
      if (row.packageName) {
        row['suborderType'] = row.packageName;
      } else {
        row['suborderType'] = this.gs.translateAppListStrings('order_type_list', row.orderType);
        row['suborderType'] += row.selectedPackage ? ' - ' + row.selectedPackage : '';
      }
    });
  }
}
