import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public serviceProviders: any;
  public disabledServices: any;
  apiBaseUrl = environment.apiUrl + 'configuration/';

  constructor(
    private http: HttpClient
  ) { }

  getConfiguration(configId: string) {
    return this.http.get(this.apiBaseUrl + configId);
  }

  getDisabledServices() {
    return this.getConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_CLICKS).pipe(
      tap(serviceClicks => this.disabledServices = serviceClicks)
    );
  }

  getServiceProviders() {
    return this.getConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_PROVIDERS).pipe(
      tap(serviceProviders => this.serviceProviders = serviceProviders)
    ).toPromise();
  }

  updateConfiguration(configId: string, configData: any) {
    return this.http.put(this.apiBaseUrl + configId, configData || {});
  }
}
