import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MatButtonModule, MatTooltipModule } from '@angular/material';
import { MaterialModule } from '@app/material/material.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
    imports: [RouterModule, CommonModule, MatButtonModule, MatTooltipModule,
        MaterialModule, FormsModule, TranslateModule.forChild()],
    declarations: [NavbarComponent],
    exports: [NavbarComponent]
})

export class NavbarModule {
}
