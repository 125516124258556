import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { DocEstateConfig } from '@app/interfaces/docestate-config-data.interface';
import { take } from 'rxjs/operators';
import { DocEstateOrder } from '@app/interfaces/docestate-order-data.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileDetails } from '@app/interfaces/file-details-data.interface';
import * as fs from 'fs';
import { environment } from '@environments/environment';
import { ConfigurationService } from './configuration.service';
import { CONSTANTS } from '@app/util/constants';

@Injectable()
export class DocEstateService {
  private config: DocEstateConfig;
  private fakeCorsUrl = environment.externalApiGatewayUrl;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private configService: ConfigurationService
  ) {
    // this.getDocEstateConfig();
  }

  /**
   * this functions fetches the DocEstate configuration from firebase and stores them in service variable for future usage.
   * The configuration detail has the api details and token request details.
   */
  getDocEstateConfig(): void {
    this.configService.getConfiguration(CONSTANTS.CONFIGURATIONS.DOC_ESTATE).subscribe(
      (data: any) => {
        try {
          this.config = {
            'auth': data.auth,
            'grantType': data.grantType,
            'password': data.password,
            'baseUrl': data.url,
            'username': data.username,
            'userId': data.userId,
            'billingAddress': data.billingAddress
          };
        } catch (exception) {
          throw exception;
        }
      },
      (error) => {
        this.config = null;
        console.log(error);
      }
    );
  }

  /**
   *
   * @param path relative path of the url
   *
   * returns the complete url by adding docestate domain and fake cors url.
   */
  getFullUrl(path: string): string {
    if (this.config) {
      return this.fakeCorsUrl + this.config.baseUrl.replace('https://', '') + path;
    } else {
      throw new Error('Config not found');
    }
  }

  /**
   * Generates oAuth token for using DocEstate APIs.
   */
  getToken(): Observable<Object> {
    if (!this.config) {
      throw new Error('Config not found');
    }
    const formData = 'grant_type=' + this.config.grantType + '&username=' + this.config.username + '&password=' + this.config.password + '&jsonrpc=2.0';
    return this.http.post(this.getFullUrl('oauth/token'), formData, {
      headers: {
        'Authorization': this.config.auth,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  /**
   * Http call to get the list of products(documents) available on DocEstate
   */
  getProducts(): Promise<Object> {
    return this.httpGet('products');
  }

  /**
   *
   * @param orderId order id for which the details have to be fecthed(optional)
   *
   * returns promise with order details of the input order id if it is not empty or list of all orders if order id is empty
   */
  getOrders(orderId = ''): Promise<Object> {
    return this.httpGet('orders/' + orderId);
  }

  /**
   * returns a promise with list of notifications from DocEstate
   */
  getNotification(): Promise<Object> {
    return this.httpGet('notifications');
  }

  /**
   *
   * @param orderDetails suborder details from imogent platform
   *
   * creates an order on DocEstate for the given suborder details and returns the response as promise.
   */
  createOrderForm(orderDetails: any): Promise<Object> {
    return new Promise((resolve, reject) => {
      this.getToken().subscribe(
        (tokenResponse: Object) => {
          if (tokenResponse['access_token']) {
            const token = tokenResponse['access_token'];
            if (!orderDetails.productList) {
              reject('Document list does not exist!');
            }
            const formData: DocEstateOrder = {
              cached: false,
              billingAdress: this.config.billingAddress,
              properties: [{
                documents: orderDetails.productList.map(doc => {
                  return { 'docname': doc }
                }),
                landregisterfolios: orderDetails.landRegistrySheetList.map(land => {
                  land.hallList = land.hallList || [];
                  return {
                    flure: land.hallList.map(hall => {
                      hall.pieceList = hall.pieceList || [];
                      return {
                        number: hall.name,
                        flurstuecke: hall.pieceList.map(piece => {
                          return {
                            number: piece.name
                          };
                        })
                      }
                    }),
                    number: land.name,
                    gemarkung: land.district
                  }
                }),
                street: orderDetails.street,
                town: orderDetails.city,
                zip: environment.env !== 'prod' ? '00000' : orderDetails.postalcode,
                house_number: orderDetails.streetNumber
              }]
            };
            formData['jsonrpc'] = '2.0';
            this.http.post(this.getFullUrl('orders'), formData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).subscribe(
              (apiResponse: any) => {
                this.afs.collection('suborders').doc(orderDetails.id).update({
                  'docEstateId': apiResponse.orderid
                });
                resolve(apiResponse);
              },
              (apiError) => {
                if (apiError.error && apiError.error.errors && apiError.error.errors) {
                  if (apiError.error.errors.length) {
                    reject(apiError.error.errors.join(", "));
                  } else {
                    reject(apiError.error.errors);
                  }
                }
                reject('Order could not be placed to DocEstate');
              }
            );
          } else {
            throw new Error('Token does not exist');
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   *
   * @param orderId order id for which the files have to be uploaded
   * @param subOrderId respective suborder id from imogent platform which has list of files
   *
   * fetches the files using originalPhotos sub collection from firebase and uploads them to respective order on DocEstate.
   */
  uploadFilesToOrder(orderId: string, subOrderId: string): Promise<Object> {
    return new Promise((resolve, reject) => {
      this.afs.collection('suborders').doc(subOrderId).collection('originalPhotos').get().subscribe(
        async (collection: DocumentData) => {
          const fileList: FileDetails[] = collection.docs.map(document => document.data());
          const formData = new FormData();
          const files = [];
          for (let i = 0; i < fileList.length; i++) {
            await fetch(fileList[i].download_url).then(async (fileResponse) => {
              await fileResponse.blob().then((fileBob) => {
                files.push(new File([fileBob], fileList[i].file_name, {
                  type: fileBob.type
                }));
                formData.append('files', new File([fileBob], fileList[i].file_name, {
                  type: fileBob.type
                }));
              }).catch((fileBobError) => {
                reject(fileBobError);
              });
            }).catch((fileResponseError) => {
              reject(fileResponseError);
            });
          }
          if (formData.has('files')) {
            this.httpPost('vollmacht/' + orderId, formData).then((uploadResponse) => {
              this.afs.collection('suborders').doc(subOrderId).update({
                'docEstateFileUploaded': true
              });
              resolve(uploadResponse);
            }).catch((uploadError) => {
              this.afs.collection('suborders').doc(subOrderId).update({
                'docEstateFileUploaded': false
              });
              reject(uploadError);
            });
          } else {
            reject("No files exist for documents order!");
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  placeDocEstateOrder(orderDetails): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!orderDetails.docEstateId) {
        this.createOrderForm(orderDetails).then((docEstateOrder: any) => {
          this.uploadFilesToOrder(docEstateOrder.orderid, orderDetails.id).then((response) => {
            resolve(docEstateOrder.orderid);
          }).catch((error) => {
            reject(error);
          });
          console.log(docEstateOrder);
          resolve("Success");
        })
          .catch((error) => {
            reject(error);
          });
      } else if (!orderDetails.docEstateFileUploaded) {
        this.uploadFilesToOrder(orderDetails.docEstateId, orderDetails.id).then((response) => {
          resolve(orderDetails.docEstateId);
        }).catch((error) => {
          reject(error);
        });
      } else {
        resolve('Success');
      }
    });
  }

  /**
   *
   * @param url relative api path
   * @param options request options
   *
   * This function is a wrapper to HttpClient get method.
   * It accepts url and options as its parameter, converts to relative path to full url and adds DocEstate authentication token before sending the request.
   */
  httpGet(url: string, options = {}): Promise<Object> {
    return new Promise((resolve, reject) => {
      this.getToken().subscribe(
        (tokenResponse: Object) => {
          if (tokenResponse['access_token']) {
            options['headers'] = options['headers'] || {};
            options['headers']['Authorization'] = 'bearer ' + tokenResponse['access_token'];
            if (url.indexOf('?') < 0) {
              url = url + '?jsonrpc=2.0';
            } else {
              url = url + '&jsonrpc=2.0';
            }
            this.http.get(this.getFullUrl(url), options).subscribe(
              (apiResponse: Object) => {
                resolve(apiResponse);
              },
              (apiError) => {
                throw apiError;
              }
            );
          } else {
            throw new Error('Token does not exist');
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   *
   * @param url relative api path
   * @param body request body of post request
   * @param options request options
   *
   * This function is a wrapper to HttpClient post method.
   * It accepts url and options as its parameter, converts to relative path to full url and adds DocEstate authentication token before sending the request.
   */
  httpPost(url: string, body = {}, options = {}): Promise<Object> {
    return new Promise((resolve, reject) => {
      this.getToken().subscribe(
        (tokenResponse: Object) => {
          if (tokenResponse['access_token']) {
            options['headers'] = options['headers'] || {};
            options['headers']['Authorization'] = 'bearer ' + tokenResponse['access_token'];
            if (body instanceof FormData) {
              body.append('jsonrpc', '2.0');
            } else {
              body['jsonrpc'] = '2.0';
            }
            this.http.post(this.getFullUrl(url), body, options).subscribe(
              (apiResponse: Object) => {
                resolve(apiResponse);
              },
              (apiError) => {
                throw apiError;
              }
            );
          } else {
            throw new Error('Token does not exist');
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

}
