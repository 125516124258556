import { ListView } from "@app/interfaces/list-view.interface";

export enum ListViewEnum {
  All = 'All',
  MyList = 'MyList',
  CompanyList = 'CompanyList'
}

export const listViewList: ListView[] = [
  {
    id: ListViewEnum.All,
    name: 'All'
  },
  {
    id: ListViewEnum.MyList,
    name: 'Mein Account'
  },
  {
    id: ListViewEnum.CompanyList,
    name: 'Unternehmen'
  }
];
