import { Injectable } from '@angular/core';
import {SERVICES} from '@app/models/package';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {CONSTANTS} from '@app/util/constants';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  public imoPackages: any;
  public imoServices: any[];
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.SERVICES;
  constructor(private http: HttpClient) { }

  /**
   * Updates and adds new services to firebase based on the SERVICES file.
   */
  updateServices() {
    this.http.put(this.endpoint, JSON.parse(JSON.stringify(SERVICES))).subscribe(result => {
      for (const id in result) {
        console.log('updated: ' + result[id]['id']);
      }
    });
  }

  /**
   * Returns all Services in an angular http observable
   */
  getAllServices() {
    return this.http.get<object[]>(this.endpoint);
  }

  /**
   * Returns all packages and stores them into this.imoServices
   */
  getImoServices() {
    return new Promise((resolve, reject) => {
      this.getAllServices().subscribe(servs => {
        let services = [];
        this.imoPackages = {};
        servs.forEach(service => {
          services = services.concat(service['packages']);
          this.imoPackages[service['id']] = service['packages'];
        });
        this.imoServices = services;
        resolve(services);
      });
    });
  }
}
