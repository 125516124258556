import { Component, OnInit, ViewChild } from '@angular/core';
import { InvoiceService } from '@app/_services/invoice.service';
import { LoaderService } from '@app/_services/loader.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { GlobalService } from '@app/_services/global.service';
import { SelectionModel } from '@angular/cdk/collections';
import { LANGUAGE } from '@app/language/language';
import { UploadService } from '@app/_services/upload.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthService } from '@app/auth/auth.service';
import { ListViewEnum, listViewList } from '@app/models/list-view-list';
import { UserRoleEnum } from '@app/models/user-role-list';
import { CompanyRoleEnum } from '@app/models/company-role-list';
import { ListView } from '@app/interfaces/list-view.interface';
import { Subscription } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { SubcollectionFileModel } from '@app/models/subcollectionFileModel';

@Component({
  selector: 'app-invoices',
  providers: [DatePipe],
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  role: string;
  columnsToDisplay = ['name', 'number', 'company', 'total', 'createdOn', 'status'];
  currentInvoiceData: any;
  curInvoiceId: any;
  invoiceNumber = '';
  success = false;
  uploading = false;
  uploaded = false;
  public state = 'default';
  invoice: string;
  invoiceDataSrc = new MatTableDataSource(this.ivs.allInvoices);
  selectedListViewId: ListViewEnum;
  listViewList: ListView[] = [];
  companyRoleEnum = CompanyRoleEnum;
  userRoleEnum = UserRoleEnum;

  @ViewChild(MatSort) set sort(value: MatSort) {
    this.invoiceDataSrc.sort = value;
  }
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.invoiceDataSrc.paginator = value;
  }
  filterValues = {};
  statusList = [
    {
      'label': LANGUAGE.app_list_strings.invoice_status_list['created'],
      'value': 'created'
    },
    {
      'label': LANGUAGE.app_list_strings.invoice_status_list['paid'],
      'value': 'paid'
    },
    {
      'label': LANGUAGE.app_list_strings.invoice_status_list['canceled'],
      'value': 'canceled'
    },
  ];
  private subscriptions: Subscription[] = [];

  constructor(
    public ivs: InvoiceService,
    public datePipe: DatePipe,
    public loader: LoaderService,
    private afs: AngularFirestore,
    public gs: GlobalService,
    private uploadService: UploadService,
    public auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    let self = this;
    this.role = this.auth.myUserObservable.role;
    if (this.auth.myUserObservable.role === 'Kunde') {
      this.setSelectedViewListId();
      this.setListViewList();
    }
    self.setTableFilter();
    self.invoiceDataSrc = new MatTableDataSource(self.ivs.allInvoices);
    self.setTableFilter();
    if (this.auth.myUserObservable.role !== 'Administrator' && this.auth.myUserObservable.companyRole === this.companyRoleEnum.CompanyManager || this.auth.myUserObservable.companyRole === this.companyRoleEnum.CompanyAccountant) {
      this.subscriptions.push(
        this.ivs.getCompanyInvoices().subscribe()
      );
    }
  }

  ngAfterViewInit() {
    // set the paginators
    this.invoiceDataSrc.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // createTestInvoice() {
  //   let testInvoice = {
  //     'invoice_details': [
  //       {
  //         'discount': 0,
  //         'total': 290,
  //         'suborderId': 'Pqa7giNP4Dd1g5QEcgqk',
  //         'completedOn': new Date(),
  //         'createdOn': new Date(),
  //         'description': '7 Fotos',
  //         'quantity': 1,
  //         'total_unformatted': 290,
  //         'discount_type': '%'
  //       }
  //     ],
  //     'createdOn': new Date(),
  //     'realestateName': 'Founders',
  //     'billingAddress': {
  //       'name': 'Jakob Kunde',
  //       'email': 'jnaurath@techfak.uni-bielefeld.de',
  //       'taxId': '123456789',
  //       'city': 'Bielefeld',
  //       'phone': '02765',
  //       'street': 'Beispielstraße',
  //       'reachability': '02765',
  //       'postalCode': '63542',
  //       'number': '8',
  //       'company': ''
  //     },
  //     'uid': 'GjqVh61PJBTgr88kqhoHIOjyUEO2',
  //     'realestateId': 'Rl3NLSCFyo72UITNE1Iy',
  //     'status': 'created',
  //     'total': 290
  //   };
  //   this.ivs.createInvoice(testInvoice);
  // }

  // updateTestInvoice() {
  //   let testInvoice = {
  //     'realestateName': 'Meine riesige Villa',
  //     'status': 'created',
  //     'total': 29970
  //   };
  //   this.ivs.updateInvoice('8PfSW4pw1c4QOk5dP4A3', testInvoice);
  // }

  private setSelectedViewListId() {
    this.selectedListViewId = ListViewEnum.MyList;
  }

  private setListViewList() {
    this.listViewList = listViewList.filter(item => {
      return item.id !== ListViewEnum.All;
    });
  }

  onListViewChange(selectedListViewId: ListViewEnum, userRole?: UserRoleEnum) {
    this.selectedListViewId = selectedListViewId;
    if (this.selectedListViewId === ListViewEnum.CompanyList) {
      this.invoiceDataSrc = new MatTableDataSource(this.ivs.companyInvoices);
    } else {
      this.invoiceDataSrc = new MatTableDataSource(this.ivs.allInvoices);
    }
    this.invoiceDataSrc.paginator = this.paginator;
  }


  /**
   * Opens invoices detail view
   */
  openDetailPage(row) {
    this.currentInvoiceData = row;
    this.state = 'detail-view';
    if (this.currentInvoiceData.number) {
      this.invoiceNumber = this.currentInvoiceData.number;
    }
  }

  /**
   * Opens list view
   */
  openListView() {
    this.state = 'default';
    this.invoiceDataSrc = new MatTableDataSource(this.ivs.allInvoices);
    this.setTableFilter();
  }

  addInvoiceNumber() {
    this.currentInvoiceData.number = this.invoiceNumber;
    this.ivs.updateInvoice(this.currentInvoiceData.id, { 'number': this.invoiceNumber });
    this.invoiceNumber = '';
  }

  invoicePaid() {
    this.currentInvoiceData.status = 'paid';
    this.ivs.updateInvoice(this.currentInvoiceData.id, { 'status': 'paid' });
  }

  invoiceCanceled() {
    this.currentInvoiceData.status = 'canceled';
    this.ivs.updateInvoice(this.currentInvoiceData.id, { 'status': 'canceled' });
  }

  /**
   * Gets triggered when user uploads the Pilot Contract document
   * Uploads the document to firebase and updates the verificationDocument0 url in user document on firebase
   */
  async uploadDocument(eventUpload: any, fileName: string, id: string) {
    let fieldAttachments: any;
    new Promise((resolve, reject) => {
      // `invoices/${filePath}/${fileName}`
      this.uploadService.uploadFile(eventUpload.target.files[0], `invoices/${id}/`, true, '').subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.Response) {
            const uploadResponse = <SubcollectionFileModel>event.body;
            resolve(uploadResponse);
          }
        });
    }).then((uploadResponse: SubcollectionFileModel) => {
      fieldAttachments = {
        'file': uploadResponse.download_url,
        'fileName': eventUpload.target.files[0].name
      };
      this.ivs.updateInvoice(id, fieldAttachments);
    }).catch(exception => {
      throw exception;
    });
  }

  deleteInvoice(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm('Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!');
    if (confirmed) {
      this.uploadService.deleteFile(`invoices/${this.currentInvoiceData.id}/${this.currentInvoiceData.fileName}`).subscribe((success) => {
        this.ivs.updateInvoice(this.currentInvoiceData.id,
          {
            'file': '',
            'fileName': ''
          }
        );
      });
    }

  }

  setTableFilter(): void {
    let self = this;
    self.invoiceDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(self.filterValues).forEach(key => {
        let objValue = object[key] || '';
        if (key === 'createdOn' && objValue.toDate()) {
          objValue = this.datePipe.transform(objValue.toDate(), 'dd.MM.yyyy');
        }
        if (key === 'total' && objValue.toString()) {
          objValue = objValue.toString();
        }
        if (key === 'company') {
          objValue = object['billingAddress'][key];
          console.log(key, object[key]);
        }
        if (key === 'name') {
          objValue = object['billingAddress'][key];
          console.log(key, object[key]);
        }
        if (self.filterValues[key] && objValue.toLowerCase().indexOf(self.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  onStatusFilter(event): void {
    this.filterValues['status'] = event['value'];
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.invoiceDataSrc.filter = 'test';
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.invoiceDataSrc.filter = 'test';
  }

  noSort(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  routeToPayments() {
    this.router.navigate(['payments']);
  }
}