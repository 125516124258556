import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RealEstate } from '@app/interfaces/real-estate.interface';
import { RealestateService } from './realestate.service';

@Injectable({
  providedIn: 'root'
})
export class RealEstatesResolverService implements Resolve<RealEstate[]> {

  constructor(
    private res: RealestateService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.res.isListDataLoaded) {
      return this.res.getRealestates();
    } else {
      return this.res.realEstateList;
    }
  }
}
