import { Injectable } from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import { Subject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '@app/interfaces/company.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';
import { UserRoleEnum } from '@app/models/user-role-list';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.COMPANIES;
  companiesList: Company[] = [];
  isListDataLoaded = false;
  companiesListChanged = new Subject();

  public allCompanies: any;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient
  ) {}

  getCompanies() {
    return this.http.get<Company[]>(this.endpoint).pipe(
      tap(data => {
        this.isListDataLoaded = true;
        this.setListData(data);
        this.sortListDataByName();
      })
    );
  }

  private setListData(data: Company[]) {
    this.companiesList = [...data];
  }

  private sortListDataByName() {
    this.companiesList.sort((x, y) => x['name'].localeCompare(y['name']));
  }

  addCompany(data) {

    return this.http.post<Company>(this.endpoint, data).pipe(
      tap((company: Company) => {
        this.companiesList.push(company);
        // inform event subscribers that the list changed
        this.companiesListChanged.next();
      })
    );
  }

  updateCompany(company: Company, userRole = UserRoleEnum.Administrator) {
    let endpoint = this.endpoint + '/' + company.cid;

    // When updating a company using a different role then Admins, we should use a different route since the default one is protected
    if (userRole !== UserRoleEnum.Administrator) {
      endpoint = this.endpoint + '/my';
    }

    return this.http.put<Company>(endpoint, company).pipe(
      tap((updatedCompany: Company) => {
        const foundIndex = this.companiesList.findIndex(item => item.cid === company.cid);

        // replace item with new data, but keep properties already changed in case the company update is only partial
        this.companiesList[foundIndex] = {
          ...this.companiesList[foundIndex],
          ...updatedCompany
        };

        // inform event subscribers that the list changed
        this.companiesListChanged.next();
      })
    );
  }

  getCompany(companyId: string, isFetchRemote = false, isMyCompany = false) {
    let localCompany: Company = null;

    if (!isFetchRemote) {
      // search the item locally 1st
      localCompany = this.companiesList.find(item => item.cid === companyId);
    }

    if (localCompany) {
      // returning founded item
      return of(localCompany);
    } else {
      // fetching from database because it wasn't found locally
      let endpoint = this.endpoint + '/' + companyId;

      if (isMyCompany) {
        endpoint = this.endpoint + '/my';
      }

      return this.http.get<Company>(endpoint).pipe(
        tap(company => {
          // adding to local list
          this.companiesList.push(company);
        })
      );
    }
  }

  /**
   * TODO: Remove this method only when the users api is integrated, since its being used to get a CompanyRef saved on the users collection
   */
  getCompanyRef(cid: string) {
    return this.afs.doc('companies/'+cid).snapshotChanges();
  }

  /**
   * TODO: Remove this method only when the users api is integrated, since its being used to fetch users from a company
   */
  returnCompanyRef(cid: string | string) {
    return this.afs.doc('companies/'+cid);
  }

  removeCompany(companyId: string) {
    return this.http.delete(this.endpoint + '/' + companyId).pipe(
      tap(() => {
        this.companiesList = this.companiesList.filter((item) => item.cid !== companyId);
        // inform event subscribers that the list changed
        this.companiesListChanged.next();
      })
    );
  }
}
