export enum OrderTypeEnum {
  Area_Calculation = 'area_calculattion',
  Drone_Media = 'dronemedia',
  Expose = 'expose',
  Floor_Overview = 'floor_overview',
  Floor_Plan = 'floorplan',
  Hd_Photos = 'hdphotos',
  Map = 'map',
  Retouching = 'retouching',
  Social_Media = 'social_media',
  Virtual_Reality = 'virtual_reality',
  Virtual_Tour = 'virtual_tour',
  Visualisation = 'visualisation',
  V_Staging = 'vstaging',
  Documents_Procurement = 'documents_procurement'
}
