import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap, take } from 'rxjs/operators';
import { UploadService } from '@app/_services/upload.service';
import { AuthService } from '@app/auth/auth.service';
import { LoaderService } from '@app/_services/loader.service';
import { GlobalService } from '@app/_services/global.service';
import { UsersService } from '@app/_services/users.service';
import { CompaniesService } from '@app/_services/companies.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { SubcollectionFileModel } from '@app/models/subcollectionFileModel';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  profileForm: FormGroup;
  // Form State
  success = false;
  uploading = false;
  uploaded = false;
  user$;
  user: any;
  profile;
  role: string;
  color = 'text-gray';
  originalData = '';
  formChanged = false;
  userId = '';

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public us: UsersService,
    private uploadService: UploadService,
    public loader: LoaderService,
    public gs: GlobalService,
    private globalService: GlobalService
  ) {

    this.profileForm = this.fb.group({
      firstName: ['', [
        Validators.required,
        Validators.pattern('\\S.*')
      ]],
      lastName: ['', [
        Validators.required,
        Validators.pattern('\\S.*')
      ]],
      companyName: [''],
      ustid: [''],
      street: ['', [
        Validators.required,
        Validators.pattern('^\\D+$')
      ]],
      streetNumber: ['', [
        Validators.required
      ]],
      postalcode: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{4,5}$')
      ]],
      city: ['', [
        Validators.required
      ]],
      phone: ['', [
        Validators.required,
        Validators.pattern('[0-9+/ \-]*')
      ]],
      drone: [''],
      dronepilot: [''],
      visualisation: [''],
      hdphotos: [''],
      // floorplan: [''],,
      // vstaging: [''],
      reachability: [''],
      floor_overview: [''],
      virtual_tour: ['']
    });
  }

  ngOnInit() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    this.role = this.authService.myUserObservable['role'];
    this.user = this.authService.myUserObservable;
    this.userId = this.user.uid;
    this.preloadData();
    this.profileForm.valueChanges.subscribe(() => {
      if (this.originalData === JSON.stringify(this.profileForm.value)) {
        this.formChanged = false;
      } else {
        this.formChanged = true;
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.profile && this.profile.unsubscribe(); // Missing or insufficient permissions Error, Memory Leak fix
  }

  get firstName() {
    return this.profileForm.get('firstName');
  }
  get lastName() {
    return this.profileForm.get('lastName');
  }
  get companyName() {
    return this.profileForm.get('companyName');
  }
  get ustid() {
    return this.profileForm.get('ustid');
  }
  get street() {
    return this.profileForm.get('street');
  }
  get streetNumber() {
    return this.profileForm.get('streetNumber');
  }
  get postalcode() {
    return this.profileForm.get('postalcode');
  }
  get city() {
    return this.profileForm.get('city');
  }
  get phone() {
    return this.profileForm.get('phone');
  }
  get dronepilot() {
    return this.profileForm.get('dronepilot');
  }
  get visualisation() {
    return this.profileForm.get('visualisation');
  }
  get hdphotos() {
    return this.profileForm.get('hdphotos');
  }
  // get floorplan() {
  //   return this.profileForm.get('floorplan');
  // }
  // get vstaging() {
  //   return this.profileForm.get('vstaging');
  // }

  get reachability() {
    return this.profileForm.get('reachability');
  }

  get floor_overview() {
    return this.profileForm.get('floor_overview');
  }

  /**
   * Submit handler for profile form. Updates the user document in firebase
   */
  async submitHandler() {
    this.profileForm.value.profileCompleted = true;
    const formValue = this.profileForm.value;
    this.profileForm.markAsPristine();
    this.us.updateUser(this.userId, formValue)
      .then((userDetails) => {
        this.globalService.showNotification('Profil erfolgreich aktualisiert', 'success');
        this.user = userDetails;
        this.profileForm.patchValue(userDetails);
        this.originalData = JSON.stringify(this.profileForm.value);
      })
      .catch((error) => {
        console.log('Profile details could not be updated');
        console.log(error);
        this.globalService.showNotification('Das Profil konnte nicht aktualisiert werden', 'danger');
      });
  }
  /**
   * Preload data for user profile
   */
  async preloadData() {
    this.us.getUserDetails(this.userId).subscribe(
      (userDetails) => {
        this.user = userDetails;
        this.profileForm.patchValue(userDetails);
        this.originalData = JSON.stringify(this.profileForm.value);
      },
      (error) => {
        console.log('Could not fetch user details');
        console.log(error);
        this.globalService.showNotification('Profile details could not be fetched', 'danger');
      }
    );
  }

  /**
   * Gets triggered when user uploads the Pilot Contract document
   * Uploads the document to firebase and updates the verificationDocument0 url in user document on firebase
   */
  async uploadDocument(event: any, fileName: string, uid: string) {
    this.uploading = true;
    this.uploadService.uploadFile(event.target.files[0], `users/${uid}/`, false, '').subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Response) {
          const fileDetails = <SubcollectionFileModel>event.body;
          console.log('Uploaded the document, updating the user details');
          const documentFields = {
            'vertragliche-vereinbarung': 'verificationDocument0',
            'haftpflicht': 'verificationDocument1',
            'kenntnisnachweis': 'verificationDocument2',
            'aufstiegsgenehmigung': 'verificationDocument3',
            'companyLogo': 'companyLogo'
          };
          const requestData = {};
          requestData[documentFields[fileName]] = fileDetails.download_url;
          requestData[documentFields[fileName] + '_file_name'] = fileDetails.file_name;
          requestData[documentFields[fileName] + '_file_size'] = fileDetails.file_size;
          if(fileName !== 'companyLogo'){
            requestData['verificationNeeded'] = true;
          }
          this.us.updateUser(uid, requestData)
            .then((userDetails) => {
              this.uploading = false;
              this.globalService.showNotification('Dokument erfolgreich hochgeladen', 'success');
              this.user = userDetails;
              this.profileForm.patchValue(userDetails);
              this.originalData = JSON.stringify(this.profileForm.value);
            })
            .catch((error) => {
              this.uploading = false;
              console.log('Could not update user details');
              console.log(error);
              this.globalService.showNotification('Das Dokument wurde hochgeladen, aber die Details konnten nicht aktualisiert werden', 'danger');
            });
        }
      },
      (error) => {
        this.uploading = false;
        console.log('Could not upload the document');
        console.log(error);
        this.globalService.showNotification('Das Dokument konnte nicht hochgeladen werden', 'danger');
      });
  }

  deleteCompanyLogo(event: Event) {
    console.log("clicked trash");
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm('Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!');
    let path = `users/${this.userId}/`;
    if (this.user && this.user.companyLogo_file_name) {
      path += this.user.companyLogo_file_name;
    } else if (this.user && this.user.companyLogo) {
      path += 'companyLogo'
    }
    if (confirmed) {
      this.uploadService.deleteFile(path).subscribe(
        () => {
          const requestData = {
            'companyLogo': '',
            'companyLogo_file_name': '',
            'companyLogo_file_size': ''
          };
          this.us.updateUser(this.user.uid, requestData)
            .then((userDetails) => {
              this.user = userDetails;
              this.profileForm.patchValue(userDetails);
              this.originalData = JSON.stringify(this.profileForm.value);
            })
            .catch((error) => {
              console.log('Could not update the document details to user');
              console.log(error);
              this.globalService.showNotification('Das Logo wurde gelöscht, aber die Details konnten nicht aktualisiert werden', 'danger');
            });
        }, (error) => {
          console.log('Could not upload the document');
          console.log(error);
          this.globalService.showNotification('Das Logo konnte nicht gelöscht werden', 'danger');
        });
    }
  }
}
