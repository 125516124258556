export class TileModel {
    id: string;
    imageUrl: string;
    title: string;
    price_pre_text?: string;
    price?: string;
    price_note?: string;
    isDisabled?: boolean;
    newProperty?: boolean;
    physicalProduct?: boolean;
    packages?: any[];
    package_key?: any;
    service_key?: string;
    package_images?: any[];
    delivery_time?: any;
    tooltip?: any;
    isFixed?: boolean;
}
