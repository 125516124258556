import { Injectable, OnDestroy } from '@angular/core';
import { JsonrpcService } from './jsonrpc.service';
import * as _ from 'underscore';
import { GlobalService } from './global.service';
import { HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfigurationService } from './configuration.service';
import { Subscription } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import {FunctionsService} from '@app/_services/functions.service';
import { CONSTANTS } from '@app/util/constants';
import {SubcollectionFileModel} from '../models/subcollectionFileModel';
import {UploadService} from '@app/_services/upload.service';

@Injectable({
  providedIn: 'root'
})
export class EsoftApiService implements OnDestroy {

  private defaultHeaders; // default headers for api call to esoft
  public esoftEnvConfig; // Esoft realted configuration from environment.ts
  public esoftDBConfig: any; // esoft related configuration saved in database
  public $esoftDBConfigSub: any; // subscription reference for esoftDBConfig
  public subscriptions: Subscription[] = []; // to hold the subscriptions so that that can be unsubscribed all together
  protected esoftIMGOrderMap = [
    { // picture editing without logo
      packageNum: [
        '2', // Bildbearbeitung - Drohnenaufnahmen - Foto
        '23', // Bildbearbeitung - Innenfotos (hdphotos)
        '12', // Bildbearbeitung -Außen- und Innenfotos (hdphotos)
        '6' // Bildbearbeitung - Fotonachbearbeitung
      ],
      orderType: ['retouching'],
      logo: false,
      productId: 50150001,
      variant1: 'IMG_PS',
      unit: 'Pcs'
    },
    { // picture editing with logo
      packageNum: [
        '2', // Bildbearbeitung - Drohnenaufnahmen - Foto
        '23', // Bildbearbeitung - Innenfotos
        '12', // Bildbearbeitung -Außen- und Innenfotos (hdphotos)
        '6' // Bildbearbeitung - Fotonachbearbeitung
      ],
      orderType: ['retouching'],
      logo: true,
      productId: 50150001,
      variant1: 'IMG_PSL',
      unit: 'Pcs'
    },
    { // virtual staging
      packageNum: [
        '8', // Digitales Staging - Standbilder
        '9', // Digitales Staging - 360° Bilder
      ],
      orderType: ['vstaging'],
      productId: 50550000,
      variant1: 'IMG_FSYM',
      unit: 'Pcs',
      virtualTour: false
    },
    {
      packageNum: [
        '9', // Digitales Staging - 360° Bilder with Virtual tour
      ],
      orderType: ['vstaging'],
      productId: 50550000,
      variant1: 'IMG_FSYMP',
      unit: 'Pcs',
      virtualTour: true,
      indvVirtualTour: false,
    },
    {
      packageNum: [
        '9', // Digitales Staging - 360° Bilder with individual Virtual tour
      ],
      orderType: ['vstaging'],
      productId: 50550000,
      variant1: 'IMG_FSYMPE',
      unit: 'Pcs',
      virtualTour: true,
      indvVirtualTour: true,
    },
    { // Retouching of pictures
      packageNum: [
        '7', // Bildbearbeitung - Fotomontage Standard,
        '25', // Bildbearbeitung - Fotomontage Premium
      ],
      orderType: ['retouching'],
      productId: 50200000,
      variant1: 'IMG_RSYM',
      unit: 'Pcs'
    },
    { // Retouching of video wihout blurring
      packageNum: [
        '1', // Bildbearbeitung - Drohnenaufnahmen - Video
      ],
      orderType: ['retouching'],
      productId: 50450001, // Video Standard / IMG - "Without" blurring
      variant1: 'IMG_VS',
      unit: 'Pcs',
      blurred: false
    },
    { // Retouching of video with blurring
      packageNum: [
        '1', // Bildbearbeitung - Drohnenaufnahmen - Video
      ],
      orderType: ['retouching'],
      productId: 50450002, // Video Standard / IMG - "With" blurring
      variant1: 'IMG_VI',
      unit: 'Pcs',
      blurred: true
    }
  ];
  // All the package numbers for the video packages goes here
  protected videoPackages = ['1'];
  // Comments to be added on virtual staging order
  protected vstagingComments: any = {
    productType: 'Type of Product: ',
    reference: 'Product Ref: ',
    numberOfPhotos: 'Quantity: ',
    renovateRooms: 'Renovation: ',
    virtualTour: 'Virtual Tour: ',
    floorplan: 'Floorplan in Tour: ',
    styleName: 'Style: ',
    removeFurniture: 'Remove existing Furniture (also cables from the ceiling): ',
    numberOfWalls: 'Move walls (changes noted in floor plan): ',
    accessories: 'Amount of accessories: ',
    accessoriesDesc: 'Please see the attached file named {{filename}} for details. The left column shows "Clean" which means less amount of accessories. The right column shows "Comfortable" which means more amount of accessories.'
  };
  protected logoComment = 'Logo: ';
  protected vstagingFloorplanText = 'Yes (Floor plan is refurbished by us and sent to you within the next two days via Email.';
  protected vstagingStyleMap = {
    'Skandinavisch': {
      styleName: 'Skandinavisch',
      fileName: 'style_skandinavisch.pdf'
    },
    'Modern': {
      styleName: 'Modern',
      fileName: 'style_modern.pdf'
    },
    'Luxuriös': {
      styleName: 'Luxurious',
      fileName: 'style_luxurious.pdf'
    }
  };
  protected vstagingAccessoriesCommentMap = {
    'Wenige Accessoires': 'Clean',
    'Viele Accessoires': 'Comfortable'
  };
  protected customerComment = 'Comment from the customer: ';
  protected imogentComment = 'Translation of the customer\'s comment: ';
  protected blurredComment = 'Please blur the environment.';
  protected retouchingComments: any = {
    // packageNum : Comment
    '7': ['We approve retouching Basic, Standard, and Intermediate for this order, whichever is applicable.'],
    '25': ['We approve retouching Advanced and Extreme for this order, whichever is applicable.']
  };
  private blueSkyComment = 'Please use appropriate blue sky based on the mood of the pictures.';

  constructor(
    protected afs: AngularFirestore,
    protected jsonRpc: JsonrpcService,
    protected gs: GlobalService,
    protected conf: ConfigurationService,
    private uploadService: UploadService,
    private storage: AngularFireStorage,
    private fs: FunctionsService
  ) {
    // initialize configuration variable
    this.esoftEnvConfig = this.gs.getEnvironmentConfig('esoft');
    // initialize default headers
    this.defaultHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `EWS ${this.esoftEnvConfig.apiUser}:${btoa(this.esoftEnvConfig.apiPassword)}`
    });
  }
  /**
   * Set a header attribute for api call
   *
   * @param {string} name
   * @param {string} value
   * @memberof EsoftApiService
   */
  // setHeader(name: string, value: string) {
  //   this.headers.set(name, value);
  // }

  /**
   * Set default options for the api call
   *
   * @param {{}} options
   * @memberof EsoftApiService
   */
  getDefaultOptions() {
    return { headers: this.defaultHeaders };
  }

  /**
   * Prepare request body for the api call
   *
   * @param {{}} subOrder
   * @memberof EsoftApiService
   */
  async prepareRequestBody(method: string, subOrder: any, esoftResponse?: any) {

    let requestBody: any = {
      id: subOrder.id,
      method: method,
      params: {
        clientId: this.esoftEnvConfig.clientId,
      }
    };
    let esoftProduct: any = {};
    let materials: any = [];
    let quantity = 0;
    esoftProduct = this.getEsoftProductDetails(subOrder);
    if (_.isEmpty(esoftProduct)) {
      console.log('No related esoft product found');
      return false;
    }

    switch (method) {
      case 'createOrder':
        let comments = [];
        // add comments by the customer
        if (_.has(subOrder, 'retouchingComment') && !_.isEmpty(subOrder.retouchingComment)) {
          comments.push(this.customerComment + subOrder.retouchingComment);
        }
        // add comment for blurring
        if (subOrder.blurred) {
          comments.push(this.blurredComment);
        }
        // add comment for required aspect ration
        if (!_.isEmpty(subOrder.photoFormat)) {
          comments.push(`Required aspect ratio: ${subOrder.photoFormat}`);
        }
        // add comment for blue sky for retouching
        if (subOrder.blueSky) {
          comments.push('Blue sky: ' + this.blueSkyComment);
        }
        // add pacakge specific addtional comments
        switch (subOrder.packageNum) {
          // Virtual staging orders
          case '8': comments.push(this.vstagingComments.productType + 'Still Image'); break;
          case '9': comments.push(this.vstagingComments.productType + '360°-Images'); break;
          // Retouching orders
          case '7': comments = comments.concat(this.retouchingComments['7']); break;
          case '25': comments = comments.concat(this.retouchingComments['25']); break;
        }
        if (_.has(subOrder, 'renovateRooms')) {
          comments.push(this.vstagingComments.renovateRooms + (subOrder.renovateRooms ? 'Yes' : 'No'));
        }
        if (_.has(subOrder, 'virtualTour')) {
          comments.push(this.vstagingComments.virtualTour + (subOrder.virtualTour ? 'Yes' : 'No'));
        }
        if (_.has(subOrder, 'withFloorplan')) {
          comments.push(this.vstagingComments.floorplan + (subOrder.withFloorplan ? this.vstagingFloorplanText : 'No'));
        }
        if (_.has(subOrder, 'logo')) {
          comments.push(this.logoComment + (subOrder.logo ? 'Yes' : 'No'));
        }
        if (_.has(subOrder, 'styleName') && _.has(this.vstagingStyleMap, subOrder.styleName)) {
          comments.push(this.vstagingComments.styleName + this.vstagingStyleMap[subOrder.styleName].styleName);
        }
        // Virtual staging accessories comment
        if (_.has(subOrder, 'accessoires') && this.vstagingAccessoriesCommentMap[subOrder.accessoires]) {
          let accessoriesComment = this.vstagingComments.accessories;
          accessoriesComment += this.vstagingAccessoriesCommentMap[subOrder.accessoires] + '. ';
          accessoriesComment += this.vstagingComments.accessoriesDesc;
          if (_.has(this.vstagingStyleMap, subOrder.styleName)) {
            accessoriesComment = accessoriesComment.replace('{{filename}}', this.vstagingStyleMap[subOrder.styleName].fileName);
          }
          comments.push(accessoriesComment);
        }
        if (_.has(subOrder, 'removeFurniture')) {
          comments.push(this.vstagingComments.removeFurniture + (subOrder.removeFurniture ? 'Yes' : 'No'));
        }
        if (_.has(subOrder, 'numberOfWalls')) {
          comments.push(this.vstagingComments.numberOfWalls + (subOrder.numberOfWalls > 0 ? 'Yes' : 'No'));
        }
        // in virtual staging order, comments are stored in description field
        if (_.has(subOrder, 'description') && !_.isEmpty(subOrder.description)) {
          comments.push(this.customerComment + subOrder.description);
        }

        // add comments by the adminstrator too
        if (_.has(subOrder, 'adminComments') && !_.isEmpty(subOrder.adminComments)) {
          comments.push(this.imogentComment + subOrder.adminComments);
        }

        if (subOrder.orderType === 'vstaging' && subOrder.configurationObjects && subOrder.configurationObjects.length > 0){
          subOrder.configurationObjects = subOrder.configurationObjects.filter(config => config.selectedStyle !== '');
          subOrder.configurationObjects.forEach(config => {
            comments.push(
              'Modification Type: '+ config.adminType + ';  Style: ' + config.adminStyle + ';  Comments: ' + config.adminComment
            );
          });
        }

        // Get number of videos from firebase for video suborders, because they don't store the number
        if (this.videoPackages.indexOf(subOrder.packageNum) > -1) {
          materials = await this.getMaterials(subOrder);
          quantity = materials.length;
        } else {
          quantity = subOrder.numberOfPhotos;
        }

        requestBody.params['reference'] = subOrder.id;
        requestBody.params['receivingCompany'] = this.esoftEnvConfig.receivingCompany;
        requestBody.params['orderContacts'] = this.esoftEnvConfig.orderContacts;
        requestBody.params['orderLines'] = [{
          productId: esoftProduct.productId,
          quantity: quantity,
          unit: esoftProduct.unit,
          variant1: esoftProduct.variant1,
          comments: comments
        }];
        break;
      case 'addMaterialsForBatch':
        requestBody.params['reference'] = subOrder.id;
        requestBody.params['callbackUrl'] = this.esoftEnvConfig.callbackUrl + `?batchId=${esoftResponse.result.orderLines[0].batchId}&reference=${subOrder.id}`;
        requestBody.params['batchId'] = esoftResponse.result.orderLines[0].batchId;
        requestBody.params['materialsHttp'] = [];

        // add attachments
        materials = await this.getMaterials(subOrder);
        requestBody.params['materialsHttp'] = materials;
        // also add logo if it is in the subOrder
        if (subOrder.logo && subOrder.logoImage && subOrder.logoImage.url) {
          requestBody.params.materialsHttp.push({
            url: subOrder.logoImage.url,
            name: subOrder.logoImage.fileName,
            size: subOrder.logoImage.fileSize,
          });
        }
        // if materials to be sent to esoft were not found, log the error in the suborder
        if (materials.length < 1) {
          const errorMsg = 'Unable to get materials from the firebase for subOrder';
          console.log(errorMsg, subOrder);
          this.logErrorToSuborder(subOrder.id, errorMsg);
        }
        if (subOrder.orderType === 'vstaging' && _.has(this.vstagingStyleMap, subOrder.styleName)) {
          // add additional material i.e the style specific file already available on firebase storage
          const ref = this.storage.ref('assets/VirtualStaging/' + this.vstagingStyleMap[subOrder.styleName].fileName);
          const styleFileUrl = await ref.getDownloadURL().toPromise();
          const styleFileMetadata = await ref.getMetadata().toPromise();
          materials.push({
            url: styleFileUrl,
            name: styleFileMetadata.name,
            size: styleFileMetadata.size
          });
        }
        break;
    }
    return requestBody;
  }

  /**
   * Get attachments/materials from the suborder to send them to esoft
   *
   * @param {*} subOrderId
   * @param {*} subCollectionName
   * @returns {Promise<boolean>}
   * @memberof EsoftApiService
   */
  async getMaterials(subOrder: any): Promise<any> {
    let materialsSuborderId = '';
    let materialsSubcollectionName = '';
    let materials: any = [];
    if (!_.isEmpty(subOrder.pendingOnSuborderId)) {
      // if this order is pending on another suborder then get photos from that suborder
      materialsSuborderId = subOrder.pendingOnSuborderId;
      if (this.videoPackages.indexOf(subOrder.packageNum) > -1) {
        materialsSubcollectionName = 'outputVideos';
      } else {
        materialsSubcollectionName = 'outputPhotos';
      }
    } else {
      materialsSuborderId = subOrder.id;
      if (this.videoPackages.indexOf(subOrder.packageNum) > -1) {
        materialsSubcollectionName = 'originalVideos';
      } else {
        materialsSubcollectionName = 'originalPhotos';
      }
    }

    materials = await this.getMaterialsFromFirebase(materialsSuborderId, materialsSubcollectionName);
    // if materials not found, look in the feedback collection
    if (materials.length < 1) {
      if (this.videoPackages.indexOf(subOrder.packageNum) > -1) {
        materialsSubcollectionName = 'feedback1_outputVideos';
      } else {
        materialsSubcollectionName = 'feedback1_outputPhotos';
      }
      return this.getMaterialsFromFirebase(materialsSuborderId, materialsSubcollectionName);
    }
    return materials;
  }

  /**
   * Get materials/attachments from firebase, prepare an array and return
   *
   * @param {string} subOrderId
   * @param {string} subcollectionName
   * @returns
   * @memberof EsoftApiService
   */
  getMaterialsFromFirebase(subOrderId: string, subcollectionName: string) {
    return new Promise(resolve => {
      const materials = [];
      this.uploadService.getSubcollection('suborders', subOrderId, subcollectionName).subscribe((photoCollection: SubcollectionFileModel[]) => {
        photoCollection.forEach(doc => {
          materials.push({
            url: doc.download_url,
            name: doc.file_name,
            size: doc.file_size
          });
        });
        resolve(materials);
      });
    });
  }

  /**
   * find a mapping product from esoft which should be ordered for this suborder
   *
   * @param {*} suborder
   * @memberof EsoftApiService
   */
  getEsoftProductDetails(subOrder: any) {
    return _.find(this.esoftIMGOrderMap, function (obj: any) {
      let found = false;
      // first try to do orderType and packageNum match along with logo
      found = obj.orderType.indexOf(subOrder.orderType) > -1 &&
        obj.packageNum.indexOf(subOrder.packageNum) > -1 &&
        _.has(obj, 'logo') &&
        subOrder.logo === obj.logo;
      /** if still not found, try to do orderType and packageNum match along with
          blurred (for video retouching)*/
      if (!found) {
        found = obj.orderType.indexOf(subOrder.orderType) > -1 &&
        obj.packageNum.indexOf(subOrder.packageNum) > -1 &&
        _.has(obj, 'blurred') &&
        subOrder.blurred === obj.blurred;
      }
      // because for some products the logo doesn't matter
      if (!found && !_.has(obj, 'logo') && !_.has(obj, 'blurred') && !subOrder.virtualTour) {
        found = obj.orderType.indexOf(subOrder.orderType) > -1 &&
          obj.packageNum.indexOf(subOrder.packageNum) > -1;
      }
      // if still not found
      // For Digitales Staging with virtual tour and individual virtual tours
      if (!found && (subOrder.virtualTour || subOrder.indvVirtualTour)) {
        found = obj.orderType.indexOf(subOrder.orderType) > -1 &&
          obj.packageNum.indexOf(subOrder.packageNum) > -1 &&
          obj.virtualTour === subOrder.virtualTour &&
          obj.indvVirtualTour === subOrder.indvVirtualTour;
      }
      return found;
    }, this);
  }

  initializeEsoftDBConfig() {
    // fetch esoft configuration from firebase
    return new Promise((resolve) => {
      this.$esoftDBConfigSub = this.conf.getConfiguration(CONSTANTS.CONFIGURATIONS.ESOFT).subscribe(data => {
        this.esoftDBConfig = data;
        resolve();
      });
    });
  }

  /**
   * Implementation of esoft method createOrder
   * @param subOrder Suborder to be created
   * @memberof EsoftApiService
   */
  async createOrder(subOrder: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // Only create order on esoft if esoft api is enabled
      if (_.isEmpty(this.esoftDBConfig)) {
        await this.initializeEsoftDBConfig();
      }
      if (!this.esoftDBConfig.apiEnabled) {
        resolve();
        return;
      }
      // check if esoft api is enabled for virtual staging
      if (subOrder.orderType === 'vstaging' && !this.esoftDBConfig.vstagingEnabled) {
        resolve();
        return;
      } else if (subOrder.orderType === 'retouching') {
        // check if esoft api is enabled for retouching according to the type of order (picture retouching or video retouching)
        if ((!this.esoftDBConfig.pictureRetouchingEnabled && !(this.videoPackages.indexOf(subOrder.packageNum) > -1)) ||
          (!this.esoftDBConfig.videoRetouchingEnabled && this.videoPackages.indexOf(subOrder.packageNum) > -1)
        ) {
          resolve();
          return;
        }
      }
      /** if this subOrder already has a esoftBatchId, it means the order is already created on esoft
       No need to send createOrder call again in that case */
      if (subOrder.esoft && subOrder.esoft.esoftBatchId) {
        const preparedResponse = {
          result: { orderLines: [{ batchId: subOrder.esoft.esoftBatchId }] }
        };
        this.addMaterialsForBatch(subOrder, preparedResponse).then(() => resolve()).catch(() => reject(false));
      } else {
        const method = 'createOrder';
        let body;
        await this.prepareRequestBody(method, subOrder).then(data => body = data).catch(() => reject());
        if (!body) {
          // No related esoft product found, the suborder is not integrated yet with esoft
          resolve();
          return;
        }
        const options = this.getDefaultOptions();
        this.jsonRpc.makeRequest(body, options).then(async response => {
          console.log('response of createOrder esoft: ', response);
          if (response.result) {
            this.handleSuccessResponse(method, response, subOrder).then(() => resolve()).catch(() => reject());
          } else {
            this.handleErrorResponse(method, response, subOrder);
            reject();
          }
        }).catch(error => {
          console.log('error: ', error);
          reject();
        });
      }
    });
  }

  /**
   * Implementation of esoft method addMaterialsForBatch
   * @param subOrder: Suborder to be created
   * @memberof EsoftApiService
   */
  addMaterialsForBatch(subOrder: any, createOrderResponse: any) {
    return new Promise(async (resolve, reject) => {
      // check if materials for batch are already added
      if (subOrder.esoft && subOrder.esoft.addMaterialsForBatchResponse === 'materialsAdded') {
        console.log('Materials already addded for batch');
        resolve();
      }
      const method = 'addMaterialsForBatch';
      let body;
      await this.prepareRequestBody(method, subOrder, createOrderResponse).then(data => body = data).catch(() => reject());
      if (!body) {
        const errorMsg = 'Unable to prepare request body for esoft';
        console.log(errorMsg);
        this.logErrorToSuborder(subOrder.id, errorMsg);
        resolve();
        return;
      }
      const options = this.getDefaultOptions();
      this.jsonRpc.makeRequest(body, options).then(response => {
        console.log('response of addMaterialsForBatch esoft: ', response);
        if (response.result) {
          this.handleSuccessResponse(method, response, subOrder).then(() => resolve()).catch(() => reject());
        } else {
          this.handleErrorResponse(method, response, subOrder);
          reject();
        }
      }).catch(error => {
        console.log('error', error);
        reject();
      });
    });
  }

  /**
   * Handle successfull response of esoft api call
   *
   * @param method  esoft method name for which the success response needs to be handled
   * @param {*} response
   * @oaram subOrder : optional
   * @memberof EsoftApiService
   */
  handleSuccessResponse(method: string, response: any, subOrder?: any) {
    return new Promise((resolve, reject) => {
      if (!response.result) {
        reject();
      }
      switch (method) {
        case 'createOrder':
          if (_.isArray(response.result.orderLines) && !_.isUndefined(response.result.orderLines[0].batchId)) {
            this.afs.collection('suborders').doc(subOrder.id).set({
              esoft: {
                esoftBatchId: response.result.orderLines[0].batchId,
                esoftProductId: response.result.orderLines[0].productId,
                esoftWorkflowStep: method,
                esoftProductVariant: response.result.orderLines[0].variant1
              }
            }, { merge: true });
            this.addMaterialsForBatch(subOrder, response).then(() => resolve()).catch(() => reject());
          }
          break;
        case 'addMaterialsForBatch':
          const updateData = {
            esoft: {
              addMaterialsForBatchResponse: response.result,
              esoftWorkflowStep: method
            }
          };
          this.afs.collection('suborders').doc(subOrder.id).set(updateData, { merge: true });
          resolve();
          break;
      }
    });
  }

  /**
   * Handle esoft error response. Save the respone in subOrder for tracking
   *
   * @param {*} method Esfot method name
   * @param {*} response
   * @param {*} [subOrder]
   * @returns
   * @memberof EsoftApiService
   */
  handleErrorResponse(method: string, response: any, subOrder?: any) {
    if (!response.error) {
      return;
    }
    this.gs.showNotification(`esoft error ${response.error.code} : ${response.error.message}`, 'danger');
    if (subOrder) {
      let args = {};
      args[method + 'Response'] = response;
      this.afs.collection('suborders').doc(subOrder.id).set({ esoft: args }, { merge: true });
      // if this suborder has parent suborder on which it was dependent, change its status back to uploaded
      if (subOrder.status === 'pending' && subOrder.pendingOnSuborderId) {
        this.afs.collection('suborders').doc(subOrder.pendingOnSuborderId).update({ status: 'uploaded' });
      }
    }
  }

  /**
   * Function to store esoft related error message to subOrder
   *
   * @param {string} subOrderId
   * @param {string} errorMsg
   * @memberof EsoftApiService
   */
  logErrorToSuborder(subOrderId: string, errorMsg: string) {
    // add error with timestamp as key
    const timeString = (new Date()).toTimeString();
    this.afs.collection('suborders').doc(subOrderId).set({
      esoft: {
        timeString: errorMsg
      }
    }, { merge: true });
  }

  /**
   *  Retruns boolean based on whether the floorplan email needs to be sent to esoft or not
   *
   * @param {string} vstagingID: id of the virtual staging order
   * @returns
   * @memberof EsoftApiService
   */
  floorplanEmailRequired(vstagingID: string) {
    return new Promise<boolean>(resolve => {
      this.afs.collection('suborders').doc(vstagingID).get().toPromise().then((doc: any) => {
        const vstaging = doc.data();
        if (vstaging.withFloorplan && vstaging.esoft && vstaging.esoft.esoftBatchId && !vstaging.esoft.floorplanEmailSent) {
          resolve(true);
          return;
        }
        resolve(false);
      });
    });
  }

  /**
   * Send email to esoft for the floorplan
   *
   * @param {string} floorplanId
   * @param {string} vstagingID
   * @memberof EsoftApiService
   */
  async sendFloorplanEmail(floorplanId: string, vstagingID: string) {

    if (_.isEmpty(this.esoftDBConfig)) {
      await this.initializeEsoftDBConfig();
    }
    // check if esoft api is enabled
    if (!this.esoftDBConfig.apiEnabled) {
      return;
    }
    // check if esoft api is enabled for virtual staging
    if (!this.esoftDBConfig.vstagingEnabled) {
      return;
    }
    // check if esoft api is enabled for floorplan emails for Virtual tour
    if (!this.esoftDBConfig.floorplanEmailEnabled) {
      return;
    }
    this.floorplanEmailRequired(vstagingID).then(required => {
      if (required) {
        console.log('sending floorplan email');
        this.fs.callFunction('esoftEmailHandler', {
          emailType: 'floorplan',
          suborderId: floorplanId
        }).subscribe((result) => {
          console.log('floorplan email sent:', result);
          if (result['data'] && result['data']['emailSent']) {
            // update vstaging suborder
            this.afs.collection('suborders').doc(vstagingID).set({
              esoft: { floorplanEmailSent: true },
            }, { merge: true });
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (!_.isEmpty(this.$esoftDBConfigSub) && _.isFunction(this.$esoftDBConfigSub.unsubscribe)) {
      this.$esoftDBConfigSub.unsubscribe();
    }
    // unsubscript esoft service related subscriptions
    this.subscriptions.forEach(sub => {
      if (_.isFunction(sub.unsubscribe)) {
        sub.unsubscribe();
      }
    });
  }
}
