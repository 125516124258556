import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { OrdersService } from '@app/_services/orders.service';
import { Observable } from 'rxjs';
import { UploadService } from '@app/_services/upload.service';
import * as _ from 'underscore';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@environments/environment';
import { forbiddenFileValidator, fileRequiredValidator } from '@app/shared-module/form.validators';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @ViewChild("uploadFileElem") uploadFileElem: ElementRef<any>;
  @Input('parentId') parentId: string; // if of the parent reocrd to which files are linked
  @Input('parentType') parentType: string; // Collection name of the parent reocrd to which files are linked
  @Input('subCollectionName') subCollectionName: string; // name for the files subcollection
  @Input('allowedFileExt') allowedFileExt: []; // allowed file extensions
  @Input('parent') parent: any; // context (this) of the parent component
  @Input('readOnly') readOnly: any = false; // if the component should only show the files and not allow any actions
  @Input('subFolderName') subFolderName: any = ''; // subfolder name in the suborder main folder
  @Input('showFileUpload') showFileUpload = false;

  @Output() setSubmitDisabled = new EventEmitter<boolean>();
  downloadZipUrl = '';

  startUpload: boolean = false;
  $attachments: any; // subuscription for attachments
  attachments: any; // data for attachments

  public uploader: FileUploader = new FileUploader({});
  filesForm: FormGroup;
  filesElem: ElementRef;
  files = [];
  public status: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private os: OrdersService,
    private us: UploadService,
    public auth: AuthService
  ) {
    this.initializeForm();
  }

  initializeForm() {
    this.filesForm = this.formBuilder.group({
      uploadedFiles: ['', [
        Validators.required,
        forbiddenFileValidator(this.allowedFileExt, this.filesElem),
        fileRequiredValidator(this.uploader.queue)
      ]]
    });
  }

  ngOnInit() {
    this.downloadZipUrl = '';
    // fetch already uploaded files
    this.$attachments = this.us.getSubcollection(this.parentType, this.parentId, this.subCollectionName)
      .subscribe(data => {
        this.attachments = data;
        if (data.length > 0) {
          this.downloadZipUrl = environment.apiBaseUrl + 'downloadzip?uid=' + this.auth.myUserObservable.uid + '&oid=' + this.parentId + '&dir=' + this.subCollectionName;
        }
        // handle enabling/disabled state of submit button in this function
        this.setSubmitDisabledHanlder();
      });
    if (!_.isEmpty(this.parent) && !_.isEmpty(this.parent.os)
      && !_.isEmpty(this.parent.os.currentSuborder)) {
      this.status = this.parent.os.currentSuborder.status;
    } else if (this.parent && this.parent.orderService && this.parent.orderService.currentSuborder) {
      this.status = this.parent.orderService.currentSuborder.status;
    }
  }
  get uploadedFiles() {
    return this.filesForm.get('uploadedFiles');
  }

  /**
   * add condition to disable submit button according to your suborder type
   */
  setSubmitDisabledHanlder() {
    let disabled = false;

    if (!_.isEmpty(this.parent) && !_.isEmpty(this.parent.os)
      && !_.isEmpty(this.parent.os.currentSuborder)) {
      // condition for dronemedia and retouching suborder package number 2
      if ((['2', '6', '7', '8', '9'].indexOf(String(this.parent.os.currentSuborder.packageNum), 0) != -1) &&
        this.attachments.length < this.parent.os.currentSuborder.requiredUploads) {
        // retouching and virtual staging suborders
        disabled = true;
      }
    }
    if (this.parent && this.parent.orderService && this.parent.orderService.currentSuborder && this.parent.orderService.currentSuborder.orderType === 'floorplan' && this.attachments.length <= 0) {
      disabled = true;
    }
    if (this.parent && this.parent.orderService && this.parent.orderService.currentSuborder && this.parent.orderService.currentSuborder.orderType === 'hdphotos' && this.attachments.length <= 0) {
      disabled = true;
    }
    if (this.parent && this.parent.orderService && this.parent.orderService.currentSuborder && this.parent.orderService.currentSuborder.orderType === 'visualisation' && this.attachments.length <= 0) {
      disabled = true;
    }
    this.setSubmitDisabled.emit(disabled);
  }


  ngAfterViewInit() {
    if (_.isEmpty(this.uploadFileElem)) {
      return;
    }
    let self = this;
    this.filesElem = this.uploadFileElem.nativeElement;
    this.filesForm = this.formBuilder.group({
      uploadedFiles: ['', [
        Validators.required,
        forbiddenFileValidator(this.allowedFileExt, this.filesElem),
        fileRequiredValidator(this.uploader.queue)
      ]]
    });
    this.filesForm.valueChanges.subscribe(() => {
      let formValues = {};
      Object.keys(self.filesForm.controls).forEach(control => {
        if (control === 'uploadedFiles') {
          self.files = [];
          self.uploader.queue.forEach(entry => self.files.push(entry.file.rawFile));
        }
      });
    });
  }


  onChange(): void {
    const fileListLength = this.uploader.queue.length;
    let lastFile = this.uploader.queue[fileListLength - 1];
    for (let i = 0; i < fileListLength - 1; i++) {
      if (lastFile.file.name === this.uploader.queue[i].file.name && lastFile.file.size === this.uploader.queue[i].file.size) {
        lastFile.remove();
      }
    }
    // fix for Internet Explorer. Manually set the file value
    if (this.uploader.queue.length > 0 && _.isEmpty(this.filesForm.get('uploadedFiles').value)) {
      this.filesForm.controls['uploadedFiles'].setValue(this.uploader.queue[0].file.name);
    }
    this.filesForm.controls.uploadedFiles.updateValueAndValidity();
  }

  initiateUpload() {
    if (!_.isEmpty(this.uploader.queue)) {
      this.startUpload = true;
    }
  }
  /**
   * Remove the file from upload queue
   */
  onRemoveFile(file, type?: string): void {
    if (_.isFunction(file.remove)) {
      file.remove();
    } else {
      this.uploader.queue.forEach(entry => {
        if (entry.file.name === file.name) {
          entry.remove();
        }
        if (_.isEmpty(this.uploader.queue)) {
          this.startUpload = false;
        }
      }
      );
      // when uploader queue is empty reset the form touch state to remove
      // validation errors as the form is submitted
      if (this.uploader.queue.length === 0) {
        Object.keys(this.filesForm.controls).forEach(control => {
          this.filesForm.controls[control].markAsUntouched();
          this.filesForm.controls[control].markAsPristine();
        });
      }
    }
    this.filesForm.controls.uploadedFiles.updateValueAndValidity();
  }

  /**
   * Delete the uploaded filed
   */
  deleteAttachement(subColDocId: string, fileName: string, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.readOnly || (!_.isEmpty(this.parent) && _.isFunction(this.parent.deleteAttachmentDisabled) &&
      this.parent.deleteAttachmentDisabled())) {
      return;
    }
    const confirmed = confirm('Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!');
    if (confirmed) {
      const path = `${this.parentType}/${this.parentId}/${this.subFolderName}${fileName}`;
      this.us.deleteAttachment(this.parentType, this.parentId, this.subCollectionName, subColDocId, path);
    } else {
      alert('Löschvorgang abgebrochen!');
    }
  }
  /**
   * Disable the delete attachment icon conditionally
   */
  getDelAttachmentStyle() {
    let style = {};
    if (this.readOnly) {
      style = { 'cursor': 'not-allowed' };
    }
    if (!_.isEmpty(this.parent) && _.isFunction(this.parent.deleteAttachmentDisabled) &&
      this.parent.deleteAttachmentDisabled()) {
      style = { 'cursor': 'not-allowed' };
    }
    return style;
  }
  /**
   * Disable the upload files button conditionally
   */
  isUploadDisabled() {
    if (this.readOnly) {
      return true;
    }
    if (!_.isEmpty(this.parent) && _.isFunction(this.parent.isUploadDisabled) &&
      this.parent.isUploadDisabled()) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    // unsubscripte the obeservables when the view is destroyed
    if (!_.isEmpty(this.$attachments)) {
      console.log('un subscribing from attachments')
      this.$attachments.unsubscribe();
      this.attachments = null;
    }
  }

}
