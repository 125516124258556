import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap } from 'rxjs/operators';
import { OrdersService } from '@app/_services/orders.service';
import { AccountingPositionsService } from '@app/_services/accounting-positions.service';
import { CompaniesService } from '@app/_services/companies.service';
import { ServicesService } from '@app/_services/services.service';
import { OrdersNewService } from '@app/_services/orders-new.service';
import { WebsocketsService } from '@app/_services/websockets.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  active = true; // Initial State der einklappbaren Card
  settingsForm: any;
  success: boolean;
  settings: any;

  constructor(
    public auth: AuthService,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    public os: OrdersService,
    public services: ServicesService,
    public aps: AccountingPositionsService,
    public cs: CompaniesService,
    private os2: OrdersNewService,
    private ws: WebsocketsService
  ) {
    this.settingsForm = this.fb.group({
      sendConfirmationEmail: ['']
    });
  }

  /**
   * Submit handler for settings form. Updates the config document in firebase
   */
  async submitHandler() {
    try {
      await this.afs.doc(`users/${this.auth.myUserObservable.uid}`).update(this.settingsForm.value);
      this.success = true;
      setTimeout(() => {
        this.success = false;
      },
        1500);
    } catch (err) {
      console.error(err);
    }
  }
  /**
   * Preload data for user profile
   */
  async preloadData() {
    this.settings = await this.afs.doc(`users/${this.auth.uid}`).valueChanges().pipe(
      tap(data => {
        this.settingsForm.patchValue(data);
      })
    )
      .subscribe();
  }

  ngOnInit() {
    this.preloadData();
  }

  ngOnDestroy() {
    this.settings.unsubscribe(); // Missing or insufficient permissions Error, Memory Leak fix
  }

  onChanges() {
    this.settingsForm.get('sendConfirmationEmail').valueChanges.subscribe(this.submitHandler());
  }

}
