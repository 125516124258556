import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { GlobalService } from './global.service';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsonrpcService {

  protected baseUrl: string;
  jsonRpcVersion = '2.0';
  protected fakeCorsUrl = environment.externalApiGatewayUrl; // because esoft API does not add Access-Control-Allow-Origin header

  constructor(private http: HttpClient, protected gs: GlobalService) {
    const esoftConfig = this.gs.getEnvironmentConfig('esoft');
    this.baseUrl = esoftConfig.apiURL;
  }
  /**
   *
   * Makes a JSON-RPC request
   * @param body: Object
   * @param options: Object
   * @returns Promise
   */
  makeRequest(body: any, options: any): Promise<any> {
    body.jsonrpc = this.jsonRpcVersion;
    console.log('esoft request body: ', body);
    return this.http.post(this.fakeCorsUrl + this.baseUrl.replace('https://', ''), body, options).toPromise();
  }

}
