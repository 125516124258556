import { Component, OnInit, ViewChild, OnDestroy, isDevMode } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { OrdersService } from '@app/_services/orders.service';
import { UsersService } from '@app/_services/users.service';
import { LoaderService } from '@app/_services/loader.service';
import * as _ from 'underscore';
import * as accounting from 'accounting';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { MoneyService } from '@app/_services/money.service';
import { Subscription } from 'rxjs';
import { InvoiceService } from '@app/_services/invoice.service';
import { GlobalService } from '@app/_services/global.service';
import { Router } from '@angular/router';
import { DiscountTypeEnum } from '@app/models/discount-type-list';
import { take } from 'rxjs/operators';
import { CompaniesService } from '@app/_services/companies.service';
import { Company } from '@app/interfaces/company.interface';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PaymentsComponent implements OnInit, OnDestroy {

  private readonly logName = "-- PaymentsComponent :: ";
  // {{ row.createdOn.toDate() | date: 'dd.MM.yyyy HH:mm'}}
  columnsToDisplay = ['name', 'company', 'sum_formatted'];
  // columnsToDisplay = ['name', 'company', 'sum_formatted', 'invoice_sum', 'paid_sum'];
  columnsToDisplayDetails = ['quantity', 'description', 'total', 'createdOn', 'completedOn'];

  expandedElement = null;
  invoiceDetail = null;
  userRef: any;
  invoiceData = new MatTableDataSource([]);
  startDate: any;
  endDate: any;
  filterValues = {};
  dateToPassStart;
  dateToPassEnd;
  subscriptions: Subscription[] = [];
  discount_invoice = 0.0;
  typeDiscount = 1;


  @ViewChild(MatSort) set sort(value: MatSort) {
    this.invoiceData.sort = value;
  }

  constructor(
    public auth: AuthService,
    public moneyService: MoneyService,
    private os: OrdersService,
    private us: UsersService,
    private cs: CompaniesService,
    public loaderService: LoaderService,
    public ivs: InvoiceService,
    public gs: GlobalService,
    private router: Router) { }

  ngOnInit() {
    if (this.auth.myUserObservable.role !== 'Dienstleister') {
      // default dates
      let today = new Date();
      let startMonth = new Date(today.setDate(1));
      this.endDate = new Date(startMonth.setDate(startMonth.getDate() - 1));
      this.startDate = new Date(new Date().setMonth(today.getMonth() - 1)).setDate(1);
      this.dateToPassStart = new FormControl(new Date(this.startDate));
      this.dateToPassEnd = new FormControl(new Date(this.endDate));
      this.setTableFilter();
      this.updateInvoiceData();

      const subscription = this.loaderService.onAllSubOrdersTempRefresh.subscribe(() => {
        this.updateInvoiceData();
      });
      this.subscriptions.push(subscription);
    }
  }

  getInvoiceDetails(row) {
    this.expandedElement = this.expandedElement === row ? null : row;
    this.invoiceDetail = null;
    if (this.expandedElement) {
      let allInvoiceData = _.find(this.invoiceData.data, function (invoice: any) { return invoice.uid === row.uid; }, this);
      this.invoiceDetail = allInvoiceData.invoice_details;

      this.invoiceDetail.forEach(realestate => {
        let orderList = [];
        let paymentsStatus = realestate['details'][0]['payments'];
        let sum_realestate = 0.0;
        realestate['billingAddress'] = [];
        realestate['details'].forEach(detail => {
          if (orderList.indexOf(detail.orderId) === -1) {
            orderList.push(detail.orderId);
          }
          sum_realestate += detail.total_unformatted;
          if (paymentsStatus !== detail.payments) {
            paymentsStatus = 'error';
          }
        });
        realestate['total'] = sum_realestate;
        realestate['paymentsStatus'] = paymentsStatus;
        orderList.forEach(element => {
          let orderTemp = _.find(this.os.allOrders, function (order: any) { return order.orderId === element; }, this);
          let isAdressInArray = false;
          realestate['billingAddress'].forEach(billingAddr => {
            if (_.isEqual(orderTemp['billingAddress'], billingAddr)) {
              isAdressInArray = true;
            }
          });
          if (!isAdressInArray) {
            realestate['billingAddress'].push(orderTemp['billingAddress']);
          }
        });
        realestate['chosenAddress'] = realestate['billingAddress'][0];
      });
    }
  }

  updateInvoiceData(): void {
    console.log(this.logName + 'Updating invoice data.');

    if (!this.os.allSubordersTemp || this.os.allSubordersTemp.length === 0) {
      console.log(this.logName + 'Can\'t update data because of empty array.');
      return;
    }

    this.invoiceData = new MatTableDataSource([]);
    this.os.allSubordersTemp.forEach((suborder) => {
      if (suborder.invoice_details && suborder.completedOn && suborder.payments !== 'invoice' && suborder.payments !== 'paid') {
        let completedOn = new Date(suborder.completedOn['seconds'] * 1000);
        if (this.startDate <= completedOn && this.endDate >= new Date(completedOn.setDate(completedOn.getDate() - 1))) {
          suborder.invoice_details.forEach((details) => {
            if (!details['discount']) {
              details['discount'] = 0.0;
            }
            if (!details['discount_type']) {
              details['discount_type'] = '%';
            }
            details['createdOn'] = new Date(suborder.createdOn['seconds'] * 1000);
            details['realestateId'] = suborder.realestateId;
            details['realestateName'] = suborder.Desc;
            details['suborderId'] = suborder.id;
            details['orderId'] = suborder.orderId;

            // when detail entry is a suborder discount then save the charging invoice user ID
            if (details['accounting-position'] === 'totalDiscount' + DiscountTypeEnum.Percentage || details['accounting-position'] === 'totalDiscount' + DiscountTypeEnum.Monetary) {
              details['discountChargingInvoiceUserRef'] = suborder.discount && suborder.discount.chargingInvoiceUserRef;
            }

            suborder.payments !== undefined ? details['payments'] = suborder.payments : details['payments'] = '';
            let total_sum = 0.0;
            if (details['accounting-position']) {
              total_sum = details.quantity * details.total * (1 - details.discount);
              details['price_formatted'] = accounting.formatMoney(details.total, '', 2, '.', ',');
              details['total_formatted'] = accounting.formatMoney(total_sum, '', 2, '.', ',');

            } else {
              total_sum = details.total * (1 - details.discount);
              details['price_formatted'] = '';
              details['total_formatted'] = accounting.formatMoney(this.moneyService.stringToFloat(total_sum), '', 2, '.', ',');
            }
            if (typeof total_sum === 'string') {
              total_sum = accounting.unformat(total_sum, ',');
            }
            details['total_unformatted'] = total_sum;
            if (suborder.completedOn) {
              details['completedOn'] = new Date(suborder.completedOn['seconds'] * 1000);
            } else {

            }
            let tableData = this.invoiceData.data;
            let index = _.findIndex(tableData, function (invoice: any) { return invoice.uid === suborder.createdBy; }, this);
            // let invoice_sum = 0.0;
            // let paid_sum = 0.0;
            // if (details['payments'] === 'invoice') {
            //   invoice_sum = total_sum;
            // }
            // if (details['payments'] === 'paid') {
            //   paid_sum = total_sum;
            // }
            if (index !== -1) {
              let indexRealestate = _.findIndex(tableData[index].invoice_details, function (data: any) { return data.realestateId === suborder.realestateId; }, this);
              if (indexRealestate !== -1) {
                tableData[index]['invoice_details'][indexRealestate]['details'].push(details);
              } else {
                tableData[index]['invoice_details'].push({
                  realestateId: details.realestateId,
                  realestateName: details.realestateName,
                  details: [details]
                });
              }
              tableData[index]['sum'] += total_sum;
              tableData[index]['sum_formatted'] = accounting.formatMoney(tableData[index]['sum'], '', 2, '.', ',');
              // tableData[index]['invoice_sum'] += invoice_sum;
              // tableData[index]['paid_sum'] += paid_sum;
            } else {
              tableData.push({
                'uid': suborder.createdBy,
                'invoice_details': [{
                  realestateId: details.realestateId,
                  realestateName: details.realestateName,
                  details: [details]
                }],
                'sum': total_sum,
                'sum_formatted': accounting.formatMoney(total_sum, '', 2, '.', ','),
                // 'invoice_sum': invoice_sum,
                // 'paid_sum': paid_sum
              });
              const user = _.find(this.us.allUsers, function (users: any) { return users.uid === suborder.createdBy; }, this);
              if (this.auth.myUserObservable.role === 'Kunde') {
                tableData[tableData.length - 1]['name'] = this.auth.myUserObservable.firstName + ' ' + this.auth.myUserObservable.lastName;
                tableData[tableData.length - 1]['company'] = this.auth.myUserObservable.companyName;
              } else {
                tableData[tableData.length - 1]['name'] = user.firstName + ' ' + user.lastName;
                tableData[tableData.length - 1]['company'] = user.companyName;
              }
            }
            tableData.forEach((customer) => {
              customer['invoice_details'] = _.sortBy(customer['invoice_details'], 'realestateId');
            });
            this.invoiceData = new MatTableDataSource(tableData);
            this.setTableFilter();
          });
        }
      }
      // temporary method to reset payments status of suborder
      // if (suborder.payments === 'invoice' || suborder.payments === 'paid') {
      //   console.log(suborder, suborder.payments);
      //   this.os.changeSuborder(suborder.id, 'payments', '');
      // }
    });
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    switch (type) {
      case 'start':
        this.startDate = event.value;
        break;
      case 'end':
        this.endDate = event.value;
        break;
    }
    if (this.startDate && this.endDate) {
      this.updateInvoiceData();
    }
  }

  private updateChargingUserInvoicePositions(chargingUserInvoicePositions: any, discountChargingInvoiceUserId: string, invoicePositionData: any) {
    // The charging invoice user should see the discounted price with a positive sign (eg: 100€), instead of negative (eg: -100€)
    const invoicePositionDataShallowClone = {
      ...invoicePositionData,
      total: Math.abs(invoicePositionData.total),
      total_unformatted: Math.abs(invoicePositionData.total_unformatted)
    };

    if (!chargingUserInvoicePositions[discountChargingInvoiceUserId]) {
      chargingUserInvoicePositions[discountChargingInvoiceUserId] = [];
    }

    chargingUserInvoicePositions[discountChargingInvoiceUserId].push(invoicePositionDataShallowClone);
  }

  createInvoice(element, realestateIndex) {
    let suborderList = [];
    element['invoice_details'][realestateIndex]['details'].forEach(position => {
      if (suborderList.indexOf(position.suborderId) === -1) {
        suborderList.push(position.suborderId);
      }
    });
    console.log(element);
    console.log(element['invoice_details'][realestateIndex]);
    let invoicePositions = [];
    const chargingUserInvoicePositions = {};  // Invoice entries from suborders discounts that should be charged to another user
    element['invoice_details'][realestateIndex]['details'].forEach(position => {
      if (typeof position.total === 'string') {
        position.total = this.gs.unformat(position.total);
      }

      const invoicePositionData = {
        'createdOn': position.createdOn,
        'completedOn': position.completedOn,
        'description': position.description,
        'quantity': position.quantity,
        'discount': position.discount,
        'discount_type': position.discount_type,
        'suborderId': position.suborderId,
        'total': position.total,
        'total_unformatted': position.total_unformatted,
      };

      invoicePositions.push(invoicePositionData);

      if (position.discountChargingInvoiceUserRef) {
        this.updateChargingUserInvoicePositions(chargingUserInvoicePositions, position.discountChargingInvoiceUserRef.id, invoicePositionData);
      }
    });
    if (this.discount_invoice) {
      let discount_value = 0;
      let discount_type = '';
      let discount_total = 0;
      if (this.typeDiscount === 1) {
        discount_type = '%';
        discount_value = this.discount_invoice / 100;
        discount_total = element['invoice_details'][realestateIndex].total * -discount_value;
      } else {
        discount_type = '€';
        discount_value = this.discount_invoice;
        discount_total = -discount_value;
      }
      invoicePositions.push({
        'createdOn': new Date(),
        'completedOn': new Date(),
        'description': 'Rabatt gesamte Bestellung',
        'quantity': 1,
        'discount': 0.0,
        'discount_type': '',
        'suborderId': '',
        'total': discount_total,
        'total_unformatted': discount_total,
      });
      this.discount_invoice = 0.0;
      this.typeDiscount = 1;
      element['invoice_details'][realestateIndex].total += discount_total;
    }
    if (typeof element['invoice_details'][realestateIndex].total === 'string') {
      element['invoice_details'][realestateIndex].total = this.gs.unformat(element['invoice_details'][realestateIndex].total);
    }
    let invoiceData = {
      'uid': element.uid,
      'billingAddress': element['invoice_details'][realestateIndex].chosenAddress,
      'realestateId': element['invoice_details'][realestateIndex].realestateId,
      'realestateName': element['invoice_details'][realestateIndex].realestateName,
      'invoice_details': invoicePositions,
      'total': element['invoice_details'][realestateIndex].total,
      'status': 'created',
      'createdOn': new Date()
    };
    this.ivs.createInvoice(invoiceData);
    suborderList.forEach(s_order => {
      this.os.changeSuborder(s_order, 'payments', 'invoice');
    });

    this.createDiscountChargingUserInvoice(chargingUserInvoicePositions, element, realestateIndex);
  }

  private createDiscountChargingUserInvoice(chargingUserInvoicePositions: any, element: any, realestateIndex: any) {
    const chargingUserIdList = Object.keys(chargingUserInvoicePositions);

    chargingUserIdList.forEach(async userId => {
      let total = 0;
      const invoicePositions = chargingUserInvoicePositions[userId];
      invoicePositions.forEach(item => {
        total += Math.abs(item.total_unformatted);
      });

      const chargingUser = this.us.allUsers.find(item => item.uid === userId);
      const chargingUserInvoiceData = {
        'uid': chargingUser.uid,
        'billingAddress': this.createUserBillingAddress(chargingUser),
        'realestateId': element['invoice_details'][realestateIndex].realestateId,
        'realestateName': element['invoice_details'][realestateIndex].realestateName,
        'invoice_details': invoicePositions,
        'total': total,
        'status': 'created',
        'createdOn': new Date()
      };

      // ToDo
      // this.ivs.createInvoice(chargingUserInvoiceData);
      /**
       * Update company subsidised volume.
       * We should fetch the updated company data from the database instead of relying on local data,
       * since other users might have changed its subsidised value.
       */
      const company = await this.cs.getCompany(chargingUser.company.id, true).toPromise();
      const subsidisedVolume = company.subsidisedVolume || 0;
      const updatedData = {
        ...company,
        subsidisedVolume: subsidisedVolume + total
      };

      await this.cs.updateCompany(updatedData).toPromise();
    });
  }

  private createUserBillingAddress(user: any) {
    return {
      'name': user.firstName + " " + user.lastName,
      'email': user.email || '',
      'company': user.companyName || '',
      'taxId': user.ustid || '',
      'street': user.street || '',
      'number': user.streetNumber || '',
      'postalCode': user.postalcode || '',
      'city': user.city || '',
      'phone': user.phone || '',
      'reachability': user.reachability || ''
    };
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.invoiceData.filter = 'test';
  }

  noSort(event: Event): void {
    event.preventDefault();
    // event.stopPropagation();
  }

  setTableFilter(): void {
    let self = this;
    self.invoiceData.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(self.filterValues).forEach(key => {
        if (self.filterValues[key] && object[key].toLowerCase().indexOf(self.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  ngOnDestroy() {
    console.log(this.logName + 'Destroying component.');
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * @param id: Suborder Id
   * Sets the realestate view to the overview-mode and routes to the selected suborder.
   */
  showSuborder(id: string) {
    this.router.navigate(['orderoverview/suborder/' + id], { queryParams: { route: 'payments' } });
  }
}
