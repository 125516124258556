import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {CONSTANTS} from '@app/util/constants';
import {Order} from '@app/interfaces/order.interface';
import {MoneyService} from '@app/_services/money.service';
import {SubOrdersService} from '@app/_services/sub-orders.service';
import * as _ from 'underscore';
import {WebsocketsService} from '@app/_services/websockets.service';
import {SubOrder} from '@app/interfaces/suborder.interface';
import {GlobalService} from '@app/_services/global.service';
import {AuthService} from '@app/auth/auth.service';
import {DocEstateService} from '@app/_services/docestate.service';
import {EsoftApiService} from '@app/_services/esoft-api.service';
import {UploadService} from '@app/_services/upload.service';
import {FunctionsService} from '@app/_services/functions.service';
import {Subscription} from 'rxjs';
import {OrderStatusEnum} from '@app/models/order-status-list';
import { skip, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrdersNewService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.ORDERS;
  public showSubOrderDetails: EventEmitter<any> = new EventEmitter();
  ordersList: Order[] = [];
  private allSubordersSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private ms: MoneyService,
    private sos: SubOrdersService,
    private ws: WebsocketsService,
    private gs: GlobalService,
    private auth: AuthService,
    private des: DocEstateService,
    protected esClient: EsoftApiService,
    private uploadService: UploadService,
    private fs: FunctionsService,
  ) { }

  createOrder(data: any) {
    return this.http.post<string>(this.endpoint, data).toPromise();
  }

  updateOrder(id: string, data: any) {
    return this.http.put<string>(this.endpoint + '/' + id, data).toPromise();
  }

  deleteOrder(id: string) {
    return this.http.delete(this.endpoint + '/' + id).toPromise();
  }

  getOrderDetails(orderId: string) {
    return this.ordersList.find(item => item.orderId === orderId);
  }

  updateInvoice(suborder: SubOrder, prepayment: boolean) {
    let sum = 0.0;
    const sumBefore = this.ms.stringToFloat(suborder['total_price']);
    let billed_sum = 0;
    // check for old version of invoice_details
    if (!suborder['invoice_details'][0]['accounting-position']) {
      suborder['invoice_details'].forEach(item => {
        item.discount ? sum += this.ms.stringToFloat(item.total) * (1 - item.discount) : sum += this.ms.stringToFloat(item.total);
      });
    } else {
      suborder['invoice_details'].forEach(item => {
        item.discount ? sum += item.quantity * item.total * (1 - item.discount) : sum += item.quantity * item.total;
        if (item['accounting-position'] === 'billed') {
          billed_sum += item.total;
        }
      });
    }
    this.sos.updateSubOrder(suborder.id, {
      total_price: this.ms.floatToString(sum),
      billed_sum: this.ms.floatToString(billed_sum),
      invoice_details: suborder['invoice_details']
    });

    if (prepayment) {
      this.updateOrderPrice(suborder['orderId'], this.ms.stringToFloat(sum) - this.ms.stringToFloat(sumBefore) - billed_sum);
    } else {
      this.updateOrderPrice(suborder['orderId'], this.ms.stringToFloat(sum) - this.ms.stringToFloat(sumBefore));
    }
  }

  updateOrderPrice(orderId: string, diff: number) {
    const tempOrder = this.ws.orders.value.find(order => order['orderId'] === orderId);
    this.updateOrder(orderId, {
      price: this.ms.floatToString(this.ms.stringToFloat(tempOrder['price']) + this.ms.stringToFloat(diff))
    });
  }

  async setStatusChecked(orderIdParam: string, order: Order) {
    // update the status of child suborders
    let firstSuborderId = '';
    let billedSum = 0.0;

    const noOfUpdatesRequired = [...this.ws.allSuborders.value].length + 1;
    let noOfUpdatesPerformed = 0;

    // Subscribe to allSuborders to keep track of the suborder updates reflected in the BehaviourSubject. When all suborderupdates are
    // available in allSuborders call updateOrderStatus
    this.ws.getSuborders(orderIdParam);
    this.allSubordersSubscription = this.ws.allSuborders.subscribe((data) => {
      noOfUpdatesPerformed++;
      if (noOfUpdatesPerformed >= noOfUpdatesRequired) { // Minimal number of updates were all suborders could be updated.
        let allSubordersProcessed = true;
        // Check if all suborders in data don't have status == 'opened'
        for (const suborder of data) {
          if (suborder.status === OrderStatusEnum.Opened) {
            allSubordersProcessed = false;
            break;
          }
        }
        if (allSubordersProcessed) {
          this.updateOrderStatus(firstSuborderId);
          this.allSubordersSubscription.unsubscribe();
        }
      }
    });

    await this.ws.allSuborders.value.forEach(async (suborder: SubOrder) => {
      if (firstSuborderId === '') {
        firstSuborderId = suborder['id'];
      }
      if (this.gs.unformat(order.price) >= 1000) {
        billedSum += this.gs.unformat(suborder.total_price) / -2;
        suborder['invoice_details'].push({
          'quantity': 1,
          'description': 'Vorauszahlung',
          'total': this.gs.unformat(suborder.total_price) / -2,
          'total_unformatted': this.gs.unformat(suborder.total_price) / -2,
          'accounting-position': 'billed'
        });
        this.updateInvoice(suborder, true);
      }
      if (suborder.status === OrderStatusEnum.Opened) {
        let status = OrderStatusEnum.Checked;
        // for retouching, virtual staging, directly set status to processing
        if (suborder.orderType === 'floorplan') {
          status = OrderStatusEnum.Processing;
        }
        if (suborder.orderType === 'vstaging' || suborder.orderType === 'retouching') {
          // create order on esoft first
          this.auth.showLoader.emit(true);
          await this.esClient.createOrder(suborder).then(async () => {
            console.log('order creation on esoft handled');
            this.sos.updateSubOrder(suborder['id'], {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false
            })
              .then(() => {
                this.auth.showLoader.emit(false);
                this.updateOrderStatus(firstSuborderId);
              });
          }).catch(() => {
            this.auth.showLoader.emit(false);
          });
        } else if (suborder.orderType === 'documents_procurement') {
          this.auth.showLoader.emit(true);
          await this.des.placeDocEstateOrder(suborder).then((docEstateOrderId) => {
            console.log('Order created on DocEstate platform.');
            this.auth.showLoader.emit(false);
            this.sos.updateSubOrder(suborder['id'], {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false
            })
              .then(() => {
                this.auth.showLoader.emit(false);
                this.updateOrderStatus(firstSuborderId);
              });
          }).catch((error) => {
            this.gs.showNotification(error, 'danger');
            this.auth.showLoader.emit(false);
          });
        } else {
          this.sos.updateSubOrder(suborder['id'], {
            adminActionRequired: false,
            status: status
          });
        }
      }
      if (suborder.status === OrderStatusEnum.Pending) {
        this.sos.updateSubOrder(suborder['id'], {
          adminActionRequired: false
        });
      }
      if (suborder.assignedTo && suborder.status !== OrderStatusEnum.Pending) {
        const statusDates = suborder.statusDates;
        statusDates.processing = new Date();
        this.updateStatusDates(suborder, statusDates, false, false, false, false);
      }
    });
    for (let i = 0; i < this.ws.allSuborders.value.length; i++) {
      if (this.ws.allSuborders.value[i]['orderType'] === 'floorplan') {
        this.sendConfirmationEmail(this.ws.allSuborders.value[i]['orderId'], 'serviceProvider');
        break;
      }
    }

    if (this.gs.unformat(order.price) >= 1000) {
      console.log(order);
      console.log(order.orderId);
      const invoiceData = {
        'uid': order.createdBy,
        'billingAddress': order.billingAddress,
        'realestateId': order.realestateId,
        'realestateName': order.Desc,
        'total': this.gs.unformat(order.price) / 2,
        'status': 'created',
        'createdOn': new Date(),
        'orderId': order.orderId,
        'invoice_details': [{
          'createdOn': new Date(),
          'quantity': 1,
          'description': 'Vorauszahlung',
          'total': this.gs.unformat(order.price) / 2,
          'total_unformatted': this.gs.unformat(order.price) / 2,
          'accounting-position': 'billed'
        }]
      };
      // ToDo
      // this.ivs.createInvoice(invoiceData);
    }
  }

  async updateOrderStatus(suborderId: string) {
    let processing = true;
    let completed = true;
    let accepted = true;
    let serviceProviderActionRequired = false;
    let adminActionRequired = false;
    let adminUpdate = false;
    let customerActionRequired = false;

    const subOrder = this.ws.allSuborders.value.find(suborder => suborder.id === suborderId);
    const relatedSubOrders = this.ws.allSuborders.value.filter(suborder => suborder.orderId === subOrder.orderId);

    relatedSubOrders.forEach(element => {
      if (element.status === OrderStatusEnum.Opened) {
        processing = false;
      }
      if (element.status !== OrderStatusEnum.Completed && element.status !== OrderStatusEnum.Accepted) {
        completed = false;
      }
      if (element.status !== OrderStatusEnum.Accepted) {
        accepted = false;
      }
      if (element.serviceProviderActionRequired) {
        serviceProviderActionRequired = true;
      }
      if (element.adminActionRequired || element.status === OrderStatusEnum.Opened || element.status === OrderStatusEnum.Requested || element.status === OrderStatusEnum.Uploaded || element.status === OrderStatusEnum.Canceled) {
        adminActionRequired = true;
      }
      if (element.adminUpdate) {
        adminUpdate = true;
      }
      if (element.customerActionRequired) {
        customerActionRequired = true;
      }
    });
    let status = OrderStatusEnum.Opened;
    if (processing) {
      status = OrderStatusEnum.Processing;
    }
    if (completed) {
      status = OrderStatusEnum.Completed;
    }
    if (accepted) {
      status = OrderStatusEnum.Accepted;
    }

    await this.updateOrder(subOrder.orderId, {
      status: status,
      adminActionRequired: adminActionRequired,
      adminUpdate: adminUpdate,
      customerActionRequired: customerActionRequired,
      serviceProviderActionRequired: serviceProviderActionRequired
    });
  }

  sendConfirmationEmail(orderId: string, toWho): void {
    this.ws.allSuborders.pipe(skip(1), take(1)).subscribe(value => {
      const user = JSON.parse(sessionStorage.getItem('user'));
      const requestData = {
        subOrderDetails: [],
        attachments: [],
        orderId: orderId,
        details: {}
      };
      const orderTypesWithStyle = ['floorplan', 'vstaging'];
      const orderTypesWithFiles = ['floorplan', 'vstaging', 'retouching', 'visualisation'];
      const subOrders = value;
      const allSubOrders = new Promise((res, rej) => {
        const subOrderCount = subOrders.length;
        let iteration = 0;
        subOrders.forEach(async (subOrder, index) => {
          this.uploadService.getSubcollection('suborders', subOrder.id, 'originalPhotos').subscribe(
            (files) => {
              if (files && files.length > 0) {
                subOrder['attachmentsLink'] = environment.apiUrl + 'files/downloadzip?oid=' + subOrder.id + '&dir=originalPhotos&uid=' + user.uid;
              }
              if (orderTypesWithFiles.indexOf(subOrder.orderType) >= 0 && !subOrder['pendingOnSuborderId']) {
                subOrder['attachmentsLink'] = environment.apiUrl + 'files/downloadzip?oid=' + subOrder.id + '&dir=originalPhotos&uid=' + user.uid;
              }
              if (subOrder.orderType === 'visualisation' && ((subOrder['customField'] && subOrder['customField'].length > 0) || (subOrder['configurationObjects'] && subOrder['configurationObjects'].length > 0))) {
                subOrder['customFieldLink'] = environment.apiBaseUrl + 'downloadvisform?oid=' + subOrder.id;
              }
              requestData.subOrderDetails.push({
                fields: this.gs.getEmailSubOrderKeyValuePairs(subOrder),
                produkt: subOrder['packageName'] || this.gs.translateAppListStrings('order_type_list', subOrder.orderType),
                orderType: subOrder.orderType,
                referenceFile: orderTypesWithStyle.indexOf(subOrder.orderType) < 0 ? false : {
                  'cid': index + '_styleimage.com',
                  'filename': subOrder.orderType + '_' + index + '_style' + subOrder['referenceImage'].substr(subOrder['referenceImage'].lastIndexOf('.')),
                  'path': subOrder['referenceImage']
                },
                attachmentsLink: subOrder['attachmentsLink'] || '',
                customFieldLink: subOrder['customFieldLink'] || '',
                details: subOrder
              });
              iteration += 1;
              if (iteration === subOrderCount) {
                res();
              }
            }
          );
        });
      });
      allSubOrders.then(() => {
        let emailType = '';
        if (toWho === 'customer') {
          emailType = 'sendOrderConfirmationEmail';
        } else if (toWho === 'serviceProvider') {
          emailType = 'sendFpOrderToServiceProvider';
        }
        requestData['customerEmail'] = this.auth.myUserObservable.email;
        this.fs.callFunction(emailType, requestData).subscribe();
      });
    }
    );
    this.ws.unsubscribeFromSuborders();
  }

  /**
   *
   * @param suborder Suborder Object
   * @param statusDates related Status Dates
   * @param adminActionRequired Boolean indicating whether admin action is required
   * @param adminUpdate Boolean indicating whether admin Update icon shall be shown
   * @param [additionalData={}] additional data to be saved in the database if required. Otherwise leave empty
   */
  async updateStatusDates(suborder: SubOrder, statusDates, adminActionRequired, adminUpdate, customerActionRequired: boolean, serviceProviderActionRequired, additionalData = {}) {
    let data = {
      statusDates: statusDates,
      adminActionRequired: adminActionRequired,
      adminUpdate: adminUpdate,
      customerActionRequired: customerActionRequired,
      serviceProviderActionRequired: serviceProviderActionRequired
    };
    if (additionalData.constructor === Object && Object.keys(additionalData).length !== 0) { data = { ...data, ...additionalData }; }
    await this.sos.updateSubOrder(suborder.id, data).then(() => {
      this.updateOrderStatus(suborder.id);
    });
  }
}
