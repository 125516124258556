import { FormGroup } from '@angular/forms';

// Package model
export class Package {
  name: string;
  title: string;
  service_key: string;
  disabled: boolean;
  styles?: [{
    name: String,
    baseUrl: String,
    featuresUrl: String,
    index: number
  }];
  form?: FormGroup;
  package_key: string;
  price: string;
  price_pre_text: string;
  price_note: string;
  package_contents: [];
  package_images: [];
  extra_details: string;
  dimension?: string;
  description_placeholder?: string;
  details: any;
  packageDescHeading?: string;
  packageDesc?: string;
  addUnit?: string; // unit for add-on e.g Foto, Sekunden
  addUnitValue?: number // value for add-on e.g '10' for 10 sekunden, '1' for 1 Foto
  priceAddUnits?: string; // price for additional
  defaultQuantity?: number; // default quantity e.g 7 for fotos 40 for videos
  price_messages?: any;
  delivery_time?: any;
  withPreRender?: boolean;
}

export const SERVICES = [
  {
    'id': 'area_calculattion',
    'packages': [
      {
        'disabled': true,
        'extra_details': '',
        'name': 'Flächenberechnung',
        'newProperty': true,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'Wohnflaechenberechnung.jpg'
        ],
        'package_key': '16',
        'physicalProduct': false,
        'price': '0,00',
        'price_pre_text': 'ab',
        'service_key': 'area_calculattion',
        'service_title': 'Flächenberechnung',
        'title': 'Wohnflächenberechnung'
      }
    ]
  },
  {
    'id': 'dronemedia',
    'packages': [
      {
        'addUnit': 'Foto',
        'addUnitValue': 1,
        'defaultQuantity': 7,
        'details': [
          {
            'label': 'Nachbearbeitung',
            'value': 'Bei der Bildbearbeitung - Standard werden die Helligkeit, die Farbgebung und die Winkel des Bildes optimal angepasst.'
          },
          {
            'label': 'Logo einfügen',
            'value': 'Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          },
          {
            'label': 'Weichzeichnen',
            'value': 'Bei Luftaufnahmen wird z.B. die Umgebung unkenntlich gemacht. Bei Fotos im Innen- und Außenbereich werden Vertrauliche Details wie Fotos und Kennzeichen unkenntlich gemacht.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          }
        ],
        'disabled': false,
        'name': 'Fotos',
        'newProperty': true,
        'numberOfDrafts': 0,
        'packageDesc': 'aus verschiedenen Perspektiven',
        'packageDescHeading': '7 Luftaufnahmen',
        'package_contents': [
          'Inhalte Basic',
          '7 statt 5 Luftbilder',
          'Prof. Nachbearbeitung'
        ],
        'package_images': [
          'Drohnenaufnahmen.jpg'
        ],
        'package_key': '2',
        'physicalProduct': false,
        'price': 'dronemedia_photo_price',
        'priceAddUnits': 'dronemedia_photo_addUnits',
        'price_note': '(Preis exkl. MwSt.)',
        'service_key': 'dronemedia',
        'service_title': 'Luftaufnahmen',
        'title': 'Luftaufnahmen'
      },
      {
        'addUnit': 'Sekunden',
        'addUnitValue': 10,
        'defaultQuantity': 40,
        'details': [
          {
            'label': 'Nachbearbeitung',
            'value': 'Bei der Bildbearbeitung - Standard werden die Helligkeit, die Farbgebung und die Winkel des Bildes optimal angepasst.'
          },
          {
            'label': 'Logo einfügen',
            'value': 'Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt und in dem Videoabspann gezeigt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          },
          {
            'label': 'Weichzeichnen',
            'value': 'Bei Luftaufnahmen wird zur Bewahrung der Persönlichkeitsrechte, die Umgebung unkenntlich gemacht.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          }
        ],
        'disabled': false,
        'name': 'Videos',
        'newProperty': true,
        'numberOfDrafts': 0,
        'packageDesc': 'Zusammenschnitt von verschiedenen Videosequenzen zu einem Video',
        'packageDescHeading': 'Video (40 Sekunden)',
        'package_contents': [
          'Flugvorbereitung',
          'Videoaufnahmen',
          'Zusammenschnitt Video'
        ],
        'package_images': [
          'Drohnenaufnahmen_Video.jpg'
        ],
        'package_key': '1',
        'extra_feedback_price_retouching': 'dronemedia_video_extra_feedback_price_retouching',
        'feedbacks_included_retouching': 1,
        'physicalProduct': false,
        'price': 'dronemedia_video_price',
        'priceAddUnits': 'dronemedia_video_addUnits',
        'price_note': '(Preis exkl. MwSt.)',
        'service_key': 'dronemedia',
        'service_title': 'Luftaufnahmen',
        'title': 'Luftaufnahmen'
      },
      {
        'disabled': true,
        'name': 'Pro',
        'newProperty': true,
        'package_contents': [
          'Inhalte Extended',
          'Videoaufnahmen',
          'Zusammenschnitt Video'
        ],
        'package_images': [
          'Drohnenaufnahmen_komplett.jpg'
        ],
        'package_key': '3',
        'physicalProduct': false,
        'price': '364,00',
        'price_note': '(Preis exkl. MwSt.)',
        'service_key': 'dronemedia',
        'service_title': 'Luftaufnahmen',
        'title': 'Luftaufnahmen'
      }
    ]
  },
  {
    'id': 'expose',
    'packages': [
      {
        'disabled': false,
        'extra_details': '',
        'name': 'Digitales Exposé',
        'newProperty': true,
        'package_contents': [
          'Professionelles Design',
          'Unterstützung verschiedener Medien',
          'Optimiert für mobile Ansicht'
        ],
        'package_images': [
          'Expose.jpg'
        ],
        'package_key': '21',
        'physicalProduct': false,
        'price': '0,00',
        'price_pre_text': '',
        'service_key': 'expose',
        'service_title': 'Exposé',
        'title': 'Exposé'
      }
    ]
  },
  {
    'id': 'floor_overview',
    'packages': [
      {
        'details': [{
          'label': 'Notwendige Unterlagen',
          'value': 'Bemaßte Grundrisse <br/> Bemaßte Ansichtszeichnungen '
        }, {
          'label': 'Ausgabeformat',
          'value': 'Format: PNG, JPEG <br/> Auflösung: HD, 100 dpi <br/><br/>Sollten Sie SVG oder andere Formate benötigen, kontaktieren Sie uns bitte im Vorhinein.'
        }, {
          'label': '360°-Rundum Blick',
          'value': 'Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt, in der sich das Objekt über einen Schieber um 360° drehen lässt. Sie erhalten den Zugang zu dem Link, sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.'
        }, {
          'label': 'Wohnungsfinder',
          'value': 'Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt. Zur Erstellung des Wohnungsfinders nehmen wir Kontakt zu Ihnen auf,  sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.'
        }, {
          'label': 'Farbauswahl',
          'value': 'Bei der Farbauswahl haben Sie die Möglichkeit zwischen drei Farben zu wählen. <br/><br/><ol><li>Farbe der markierten Wohnung: Hier sind Farben passend zu dem Designkonzept des Projektes zu empfehlen</li><li>Farbe des Untergrundes: Hier sind Grüntöne zu empfehlen</li><li>Farbe des Gebäudekörpers: Hier ist die Farbe weiß zu empfehlen.</li></ol>'
        }, {
          'label': 'Straßenreferenz',
          'value': 'Anhand der eingereichten Pläne können Straßenreferenzen und Straßennamen eingezeichnet werden. Die Straßenreferenz ist sehr zu empfehlen, da diese dem Kunden erheblich bei der Orientierung hilft.'
        }, {
          'label': 'Nordfpeil',
          'value': 'Anhand der eingereichten Pläne kann ein Nordpfeil übernommen werden. Der Nordpfeil ist sehr zu empfehlen, da dieser dem Kunden erheblich bei der Orientierung hilft.'
        }],
        'disabled': false,
        'example_flatfinder': 'https://www.imogent.de/produktseite-Wohnungsfinder/',
        'example_rundumblick': 'https://www.imogent.de/produktseite-Wohnungsfinder/',
        'extra_details': '',
        'extra_feedback_price': 'floor_overview_wohnungsfinder_extra_feedback',
        'feedbacks_included': 1,
        'name': 'Wohnungsfinder',
        'newProperty': true,
        'numberOfDrafts': 0,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'flat_finder/flat_finder/standard.jpg',
          'flat_finder/flat_finder/standard.jpg'
        ],
        'package_key': '15',
        'physicalProduct': false,
        'price': 'floor_overview_wohnungsfinder_price',
        'price_pre_text': 'ab',
        'service_image': 'flat_finder/flat_finder/premium.jpg',
        'service_key': 'floor_overview',
        'service_title': 'Etagenübersichten',
        'styles': [
          {
            'styleUrl': '../assets/images/flat_finder/flat_finder/standard.jpg',
            'rundumsBlickUrl': '../assets/images/flat_finder/standard_360.gif',
            'flatFinderUrl': '../assets/images/flat_finder/standard_flatfinder.gif',
            'index': 0,
            'name': 'Standard',
            'flat_price': 'floor_overview_wohnungsfinder_standard_flat_price',
            'building_price': 'floor_overview_wohnungsfinder_standard_building_price',
            'rundumsicht_price': 'floor_overview_wohnungsfinder_standard_rundumsicht_price',
            'einbindung_price': 'floor_overview_wohnungsfinder_standard_einbindung_price'
          },
          {
            'styleUrl': '../assets/images/flat_finder/flat_finder/premium.jpg',
            'rundumsBlickUrl': '../assets/images/flat_finder/premium_360.gif',
            'flatFinderUrl': '../assets/images/flat_finder/premium_flatfinder.gif',
            'index': 1,
            'name': 'Premium',
            'flat_price': 'floor_overview_wohnungsfinder_premium_flat_price',
            'building_price': 'floor_overview_wohnungsfinder_premium_building_price',
            'rundumsicht_price': 'floor_overview_wohnungsfinder_premium_rundumsicht_price',
            'einbindung_price': 'floor_overview_wohnungsfinder_premium_einbindung_price'
          }
        ],
        'title': 'Etagenübersichten'
      },
      {
        'details': [{
          'label': 'Notwendige Unterlagen',
          'value': 'Bemaßte Grundrisse <br/> Bemaßte Ansichtszeichnungen '
        }, {
          'label': 'Ausgabeformat',
          'value': 'Format: PNG, JPEG <br/> Auflösung: HD, 100 dpi <br/><br/>Sollten Sie SVG oder andere Formate benötigen, kontaktieren Sie uns bitte im Vorhinein. '
        }, {
          'label': '360°-Rundum Blick',
          'value': 'Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt, in der sich das Objekt über einen Schieber um 360° drehen lässt. Sie erhalten den Zugang zu dem Link, sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.'
        }, {
          'label': 'Farbauswahl',
          'value': 'Bei der Farbauswahl haben Sie die Möglichkeit zwischen drei Farben zu wählen. <br/><br/><ol><li>Farbe des Untergrundes: Hier sind Grüntöne zu empfehlen</li><li>Farbe des Gebäudekörpers: Hier ist die Farbe weiß zu empfehlen.</li></ol>'
        }, {
          'label': 'Straßenreferenz',
          'value': 'Anhand der eingereichten Pläne können Straßenreferenzen und Straßennamen eingezeichnet werden. Die Straßenreferenz ist sehr zu empfehlen, da diese dem Kunden erheblich bei der Orientierung hilft.'
        }, {
          'label': 'Nordfpeil',
          'value': 'Anhand der eingereichten Pläne kann ein Nordpfeil übernommen werden. Der Nordpfeil ist sehr zu empfehlen, da dieser dem Kunden erheblich bei der Orientierung hilft.'
        }],
        'disabled': false,
        'example_flatfinder': 'https://www.imogent.de/produktseite-Wohnungsfinder/',
        'example_rundumblick': 'https://www.imogent.de/produktseite-Wohnungsfinder/',
        'extra_details': '',
        'extra_feedback_price': 'floor_overview_360_extra_feedback',
        'feedbacks_included': 2,
        'name': '360°-Rundumsicht',
        'newProperty': true,
        'numberOfDrafts': 0,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'flat_finder/flat_finder_360/standard.gif',
          'flat_finder/flat_finder_360/premium.gif'
        ],
        'package_key': '27',
        'physicalProduct': false,
        'price': 'floor_overview_360_price',
        'price_pre_text': 'ab',
        'service_key': 'floor_overview',
        'service_title': 'Etagenübersichten',
        'styles': [
          {
            'styleUrl': '../assets/images/flat_finder/flat_finder_360/standard.jpg',
            'rundumsBlickUrl': '../assets/images/flat_finder/standard_360.gif',
            'flatFinderUrl': '../assets/images/flat_finder/standard_flatfinder.gif',
            'index': 0,
            'name': 'Standard',
            'flat_price': 'floor_overview_360_standard_flat_price',
            'building_price': 'floor_overview_360_standard_building_price',
            'rundumsicht_price': 'floor_overview_360_standard_rundumsicht_price',
            'einbindung_price': 'floor_overview_360_standard_einbindung_price'
          },
          {
            'styleUrl': '../assets/images/flat_finder/flat_finder_360/premium.jpg',
            'rundumsBlickUrl': '../assets/images/flat_finder/premium_360.gif',
            'flatFinderUrl': '../assets/images/flat_finder/premium_flatfinder.gif',
            'index': 1,
            'name': 'Premium',
            'flat_price': 'floor_overview_360_premium_flat_price',
            'building_price': 'floor_overview_360_premium_building_price',
            'rundumsicht_price': 'floor_overview_360_premium_rundumsicht_price',
            'einbindung_price': 'floor_overview_360_premium_einbindung_price'
          }
        ],
        'title': 'Etagenübersichten'
      }
    ]
  },
  {
    'id': 'floorplan',
    'packages': [
      {
        'delivery_time': '48 h',
        'details': [
          {
            'label': 'Grundrissdesign',
            'value': 'Die Grundriss Designs sind nicht änderbar. Böden, Wände und Einrichtungsdesign können nicht verändert werden.'
          },
          {
            'label': 'Möblierung',
            'value': 'Möbel werden aus dem bestehenden Grundriss übernommen. Falls keine Möbel vorhanden sind, wird auf Basis der Raumbezeichnung nach bestem Gewissen eingerichtet.'
          },
          {
            'label': 'Maßketten',
            'value': 'Nur bestehende und vollständige Maßketten können übernommen werden. Bei unvollständigen Angaben kann anhand eines Referenzmaßes die fehlenden Maße ermittelt werden. (Aufpreis 19€/Grundriss)'
          },
          {
            'label': 'Quadratmeterangaben',
            'value': 'Nur bestehende und vollständige Quadratmeterangaben können übernommen werden. Bei unvollständigen Angaben können anhand eines Referenzmaßes die fehlenden Maße ermittelt werden. (Aufpreis 19€/Grundriss)'
          },
          {
            'label': 'Nordpfeil',
            'value': 'Bitte auf jeder Etage vermerken. Auch ein handschriftlich eingezeichneter Nordpfeil kann berücksichtigt werden.'
          },
          {
            'label': 'Raumbezeichnung',
            'value': 'Bitte Raumbezeichnung in den Grundriss eintragen oder anhand von Zahlen in einer Liste.'
          },
          {
            'label': 'Anpassungen',
            'value': 'Änderungen im Grundriss können Sie händisch eintragen. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.'
          },
          {
            'label': 'Zusatzkosten',
            'value': 'Nachträgliche Änderungen im Grundriss werden pauschal mit einem Aufpreis von  10€/Grundriss berechnet. Sollte der Fehler auf unserer Seite liegen, ist die Änderung natürlich kostenfrei.'
          }
        ],
        'dimension': '2d',
        'disabled': false,
        'extra_details': 'Unterschiedliche Designs können im nächsten Schritt ausgewählt werden',
        'extra_feedback_price': 'floorplan_klassisch_extra_feedback',
        'feedbacks_included': 1,
        'name': 'Klassisch',
        'newProperty': true,
        'numberOfDrafts': 0,
        'package_contents': [
          'ein Grundriss pro Etage',
          'Output je Grundriss: PDF, JPEG',
          'Zwei Auflösungsstufen (Web optimiert, hochauflösend)'
        ],
        'package_images': [
          'basic_Moebel.jpg',
          'basic_ohneMoebel.jpg'
        ],
        'package_key': '4',
        'price': 'floorplan_price_klassisch',
        'price_messages': {
          'header': 'Der Preis pro Grundriss von 16,00€ gilt für Grundrisse bis 140 m<sup>2</sup>. Dabei sind Balkon & Terrassenflächen mit berücksichtigt.',
          'list': [
            '141 - 200 m<sup>2</sup>: 24€/Grundriss',
            '201 - 400 m<sup>2</sup>: 34€/Grundriss'
          ]
        },
        'price_pre_text': '',
        'service_image': 'floor_plans/2d/Modern_2D_Premium/Grundriss_moebliert.jpeg',
        'service_key': 'floorplan',
        'service_title': 'Grundrisse',
        'styles': [
          {
            'baseUrl': '../assets/images/floor_plans/2d/schwarz_weiss/',
            'featuresUrl': '../assets/images/floor_plans/features_black/',
            'index': 0,
            'name': 'Schwarz Weiß'
          },
          {
            'baseUrl': '../assets/images/floor_plans/2d/schwarz_weiss_hinterlegt/',
            'featuresUrl': '../assets/images/floor_plans/features_black/',
            'index': 1,
            'name': 'Schwarz Weiß Hinterlegt'
          }
        ],
        'title': '2D-Grundrisse',
        'withPreRender': false
      },
      {
        'delivery_time': '72 h',
        'details': [
          {
            'label': 'Grundrissdesign',
            'value': 'Die Grundriss Designs sind nicht änderbar. Böden, Wände und Einrichtungsdesign können nicht verändert werden.'
          },
          {
            'label': 'Möblierung',
            'value': 'Möbel werden aus dem bestehenden Grundriss übernommen. Falls keine Möbel vorhanden sind, wird auf Basis der Raumbezeichnung nach bestem Gewissen eingerichtet.'
          },
          {
            'label': 'Quadratmeterangaben',
            'value': 'Nur bestehende und vollständige Quadratmeterangaben können übernommen werden. Bei unvollständigen Angaben können anhand eines Referenzmaßes die fehlenden Maße ermittelt werden. (Aufpreis 19€/Grundriss)'
          },
          {
            'label': 'Nordpfeil',
            'value': 'Bitte auf jeder Etage vermerken. Auch ein handschriftlich eingezeichneter Nordpfeil kann berücksichtigt werden.'
          },
          {
            'label': 'Raumbezeichnung',
            'value': 'Bitte Raumbezeichnung in den Grundriss eintragen oder anhand von Zahlen in einer Liste.'
          },
          {
            'label': 'Anpassungen',
            'value': 'Änderungen im Grundriss können Sie händisch eintragen. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.'
          },
          {
            'label': 'Zusatzkosten',
            'value': 'Nachträgliche Änderungen im Grundriss werden pauschal mit einem Aufpreis von  10€/Grundriss berechnet. Sollte der Fehler auf unserer Seite liegen, ist die Änderung natürlich kostenfrei.'
          },
          {
            'label': 'Vorentwurf',
            'value': 'Sie erhalten eine Vorstufe des finalen Grundrisses, sodass Fehler frühzeitig beseitigt werden können. Dieses ist zu empfehlen, wenn die Grundrisse sehr kompliziert bzw. schlechte Qualität haben.'
          }
        ],
        'dimension': '2d',
        'disabled': false,
        'extra_details': 'Unterschiedliche Designs können im nächsten Schritt ausgewählt werden',
        'extra_feedback_price': 'floorplan_premium_extra_feedback',
        'feedbacks_included': 1,
        'name': 'Premium',
        'newProperty': true,
        'numberOfDrafts': {
          'withPreRender': 1,
          'withoutPreRender': 0
        },
        'package_contents': [
          'ein Grundriss pro Etage',
          'Output je Grundriss: PDF, JPEG',
          'Zwei Auflösungsstufen (Web optimiert, hochauflösend)'
        ],
        'package_images': [
          'premium_Moebel.jpeg',
          'premium_ohneMoebel.jpeg'
        ],
        'package_key': '24',
        'price': 'floorplan_price_premium',
        'price_messages': {
          'header': 'Der Preis pro Grundriss von 29,00€ gilt für Grundrisse bis 140 m<sup>2</sup>. Dabei sind Balkon & Terrassenflächen mit berücksichtigt.',
          'list': [
            '141 - 200 m<sup>2</sup>: 39€/Grundriss',
            '201 - 400 m<sup>2</sup>: 59€/Grundriss'
          ]
        },
        'price_pre_text': '',
        'service_key': 'floorplan',
        'service_title': 'Grundrisse',
        'styles': [
          {
            'baseUrl': '../assets/images/floor_plans/2d/Modern_2D_Premium/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 0,
            'name': 'Modern 2D-Premium'
          },
          {
            'baseUrl': '../assets/images/floor_plans/2d/Modern_schwarz/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 1,
            'name': 'Modern Schwarz'
          },
          {
            'baseUrl': '../assets/images/floor_plans/2d/Neutral_bestend/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 2,
            'name': 'Neutral Bestand'
          },
          {
            'baseUrl': '../assets/images/floor_plans/2d/Neubau_grau/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 3,
            'name': 'Neubau Grau'
          },
          {
            'baseUrl': '../assets/images/floor_plans/2d/Neubau_weiss/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 4,
            'name': 'Neubau Weiß'
          }
        ],
        'title': '2D-Grundrisse',
        'withPreRender': true
      },
      {
        'additional_view_price': 'floorplan_extravagant_additional_view',
        'delivery_time': '72 h',
        'details': [
          {
            'label': 'Grundrissdesign',
            'value': 'Die Grundriss Designs sind nicht änderbar. Böden, Wände und Einrichtungsdesign können nicht verändert werden.'
          },
          {
            'label': 'Möblierung',
            'value': 'Möbel werden aus dem bestehenden Grundriss übernommen. Falls keine Möbel vorhanden sind, wird auf Basis der Raumbezeichnung nach bestem Gewissen eingerichtet.'
          },
          {
            'label': 'Quadratmeterangaben',
            'value': 'Nur bestehende und vollständige Quadratmeterangaben können übernommen werden. Bei unvollständigen Angaben können anhand eines Referenzmaßes die fehlenden Maße ermittelt werden. (Aufpreis 19€/Grundriss)'
          },
          {
            'label': 'Nordpfeil',
            'value': 'Bitte auf jeder Etage vermerken. Auch ein handschriftlich eingezeichneter Nordpfeil kann berücksichtigt werden.'
          },
          {
            'label': 'Raumbezeichnung',
            'value': 'Bitte Raumbezeichnung in den Grundriss eintragen oder anhand von Zahlen in einer Liste.'
          },
          {
            'label': 'Anpassungen',
            'value': 'Änderungen im Grundriss können Sie händisch eintragen. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.'
          },
          {
            'label': 'Zusatzkosten',
            'value': 'Nachträgliche Änderungen im Grundriss werden pauschal mit einem Aufpreis von  10€/Grundriss berechnet. Sollte der Fehler auf unserer Seite liegen, ist die Änderung natürlich kostenfrei.'
          },
          {
            'label': 'Vorentwurf',
            'value': 'Sie erhalten eine Vorstufe des finalen Grundrisses, sodass Fehler frühzeitig beseitigt werden können. Dieses ist zu empfehlen, wenn die Grundrisse sehr kompliziert bzw. schlechte Qualität haben.'
          }
        ],
        'dimension': '3d',
        'disabled': false,
        'extra_details': 'Unterschiedliche Designs können im nächsten Schritt ausgewählt werden',
        'extra_feedback_price': 'floorplan_extravagant_extra_feedback',
        'feedbacks_included': 1,
        'name': 'Extravagant',
        'newProperty': true,
        'numberOfDrafts': {
          'withPreRender': 1,
          'withoutPreRender': 0
        },
        'package_contents': [
          'ein Grundriss pro Etage',
          'Output je Grundriss: PDF, JPEG',
          'Zwei Auflösungsstufen (Web optimiert, hochauflösend)'
        ],
        'package_images': [
          '3d_grundriss_draufsicht.jpg',
          '3d_grundriss.jpg'
        ],
        'package_key': '5',
        'price': 'floorplan_price_extravagant',
        'price_messages': {
          'header': 'Der Preis pro Grundriss von 29,00€ gilt für Grundrisse bis 140 m<sup>2</sup>. Dabei sind Balkon & Terrassenflächen mit berücksichtigt.',
          'list': [
            '141 - 200 m<sup>2</sup>: 39€/Grundriss',
            '201 - 400 m<sup>2</sup>: 59€/Grundriss'
          ]
        },
        'price_pre_text': 'ab',
        'service_key': 'floorplan',
        'service_title': 'Grundrisse',
        'styles': [
          {
            'baseUrl': '../assets/images/floor_plans/3d/Modern_weiss/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 0,
            'name': 'Modern Weiß'
          },
          {
            'baseUrl': '../assets/images/floor_plans/3d/Modern_grau/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 1,
            'name': 'Modern Grau'
          },
          {
            'baseUrl': '../assets/images/floor_plans/3d/Alt/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 2,
            'name': 'Bestand'
          },
          {
            'baseUrl': '../assets/images/floor_plans/3d/Neutral_bestend/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 3,
            'name': 'Neutral Bestand'
          },
          {
            'baseUrl': '../assets/images/floor_plans/3d/Neubau_grau/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 4,
            'name': 'Neubau Grau'
          },
          {
            'baseUrl': '../assets/images/floor_plans/3d/Neubau_weiss/',
            'featuresUrl': '../assets/images/floor_plans/features_white/',
            'index': 5,
            'name': 'Neubau Weiß'
          }
        ],
        'title': '3D-Grundrisse',
        'withPreRender': true
      },
      {
        "companies": [
          "Imogent GmbH",
          "Sparkasse Allgäu",
          "Sparkassen-Immobilien-Vermittlungs-GmbH",
          "Stadt- und Kreissparkasse Erlangen Höchstadt Herzogenaurach",
          "S Immobilien GmbH",
          "Stadtsparkasse Augsburg",
          "Sparkasse Ingolstadt Eichstätt",
          "Kreissparkasse Miesbach-Tegernsee",
          "Sparkasse Schwerte, Immobiliencenter",
          "Sparkasse Herford",
          "Sparkasse Bottrop"
        ],
        'delivery_time': '48 h',
        'details': [
          {
            'label': 'Grundrissdesign',
            'value': 'Die Grundriss Designs sind nicht änderbar. Böden, Wände und Einrichtungsdesign können nicht verändert werden.'
          },
          {
            'label': 'Möblierung',
            'value': 'Möbel werden aus dem bestehenden Grundriss übernommen. Falls keine Möbel vorhanden sind, wird auf Basis der Raumbezeichnung nach bestem Gewissen eingerichtet.'
          },
          {
            'label': 'Maßketten',
            'value': 'Nur bestehende und vollständige Maßketten können übernommen werden. Bei unvollständigen Angaben kann anhand eines Referenzmaßes die fehlenden Maße ermittelt werden. (Aufpreis 19€/Grundriss)'
          },
          {
            'label': 'Quadratmeterangaben',
            'value': 'Nur bestehende und vollständige Quadratmeterangaben können übernommen werden. Bei unvollständigen Angaben können anhand eines Referenzmaßes die fehlenden Maße ermittelt werden. (Aufpreis 19€/Grundriss)'
          },
          {
            'label': 'Nordpfeil',
            'value': 'Bitte auf jeder Etage vermerken. Auch ein handschriftlich eingezeichneter Nordpfeil kann berücksichtigt werden.'
          },
          {
            'label': 'Raumbezeichnung',
            'value': 'Bitte Raumbezeichnung in den Grundriss eintragen oder anhand von Zahlen in einer Liste.'
          },
          {
            'label': 'Anpassungen',
            'value': 'Änderungen im Grundriss können Sie händisch eintragen. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.'
          },
          {
            'label': 'Zusatzkosten',
            'value': 'Nachträgliche Änderungen im Grundriss werden pauschal mit einem Aufpreis von  7€/Grundriss berechnet. Sollte der Fehler auf unserer Seite liegen, ist die Änderung natürlich kostenfrei.'
          }
        ],
        'dimension': '2d',
        'disabled': false,
        'extra_details': 'Unterschiedliche Designs können im nächsten Schritt ausgewählt werden',
        'extra_feedback_price': 'floorplan_sparkasse_extra_feedback',
        'feedbacks_included': 1,
        'name': 'Sparkasse',
        'newProperty': true,
        'numberOfDrafts': 0,
        'package_contents': [
          'ein Grundriss pro Etage',
          'Output je Grundriss: PDF, JPEG',
          'Zwei Auflösungsstufen (Web optimiert, hochauflösend)'
        ],
        'package_images': [
          'S-Floorplan-2D.jpg'
        ],
        'package_key': '26',
        'price': 'floorplan_price_sparkasse',
        'price_messages': {
          'header': 'Der Preis pro Grundriss von 10,00€ gilt für Grundrisse bis 140 m<sup>2</sup>. Dabei sind Balkon & Terrassenflächen mit berücksichtigt.',
          'list': [
            '141 - 200 m<sup>2</sup>: 16€/Grundriss',
            '201 - 400 m<sup>2</sup>: 24€/Grundriss'
          ]
        },
        'price_pre_text': '',
        'service_key': 'floorplan',
        'service_title': 'Grundrisse',
        'styles': [
          {
            'baseUrl': '../assets/images/floor_plans/2d/Sparkasse/',
            'featuresUrl': '../assets/images/floor_plans/features_sparkasse/',
            'index': 0,
            'name': 'Sparkasse'
          }
        ],
        'title': '2D-Grundrisse',
        'withPreRender': false
      }
    ]
  },
  {
    'id': 'hdphotos',
    'packages': [
      {
        'details': [
          {
            'label': 'Perspektiven',
            'value': 'Bitte geben Sie in dem Kommentarfeld gewünschte Perspektiven oder Zimmer an, die abgelichtet werden sollen. Sollten Sie keine speziellen Wünsche bezüglich Perspektiven und Zimmern haben, entscheidet der Fotograf nach bestem Gewissen.'
          },
          {
            'label': 'Nachbearbeitung',
            'value': 'Bei der Bildbearbeitung - Standard werden die Helligkeit, die Farbgebung und die Winkel der Fotos optimal angepasst.'
          },
          {
            'label': 'Logo einfügen',
            'value': 'Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          },
          {
            'label': 'Weichzeichnen',
            'value': 'Bei Luftaufnahmen wird z.B. die Umgebung unkenntlich gemacht. Bei Fotos im Innen- und Außenbereich werden vertrauliche Details wie Fotos und Kennzeichen unkenntlich gemacht.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          }
        ],
        'disabled': false,
        'name': 'Innenfotos',
        'newProperty': false,
        'numberOfDrafts': 0,
        'package_contents': [
          'Anfahrt bis 150 Kilometer',
          'Auflösung min. 4 Megapixel',
          '2 Fotoformate 16:9 und 4:3',
          'Professionelle Nachbearbeitung'
        ],
        'package_images': [
          'hd_fotos/inside_1.jpg',
          'hd_fotos/inside_2.jpg'
        ],
        'package_key': '23',
        'photo_price': 'hdphoto_addUnits',
        'phsysical_products': false,
        'price': 'hdphoto_price',
        'price_note': '(Preis exkl. MwSt.)',
        'service_key': 'hdphotos',
        'service_title': 'HD Fotos',
        'title': 'HD Fotos'
      },
      {
        'details': [
          {
            'label': 'Perspektiven',
            'value': 'Bitte geben Sie in dem Kommentarfeld gewünschte Perspektiven oder Zimmer an, die abgelichtet werden sollen. Sollten Sie keine speziellen Wünsche bezüglich Perspektiven und Zimmern haben, entscheidet der Fotograf nach bestem Gewissen.'
          },
          {
            'label': 'Nachbearbeitung',
            'value': 'Bei der Bildbearbeitung - Standard werden die Helligkeit, die Farbgebung und die Winkel der Fotos optimal angepasst.'
          },
          {
            'label': 'Logo einfügen',
            'value': 'Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          },
          {
            'label': 'Weichzeichnen',
            'value': 'Bei Luftaufnahmen wird z.B. die Umgebung unkenntlich gemacht. Bei Fotos im Innen- und Außenbereich werden vertrauliche Details wie Fotos und Kennzeichen unkenntlich gemacht.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          }
        ],
        'disabled': false,
        'name': 'Außen- und Innenfotos',
        'newProperty': false,
        'numberOfDrafts': 0,
        'package_contents': [
          'Anfahrt bis 150 Kilometer',
          'Auflösung min. 4 Megapixel',
          '2 Fotoformate 16:9 und 4:3',
          'Professionelle Nachbearbeitung'
        ],
        'package_images': [
          'hd_fotos/outside_1.jpg',
          'hd_fotos/outside_2.jpg'
        ],
        'package_key': '12',
        'photo_price': 'hdphoto_addUnits',
        'phsysical_products': false,
        'price': 'hdphoto_price',
        'price_note': '(Preis exkl. MwSt.)',
        'service_key': 'hdphotos',
        'service_title': 'HD Fotos',
        'title': 'HD Fotos'
      }
    ]
  },
  {
    'id': 'map',
    'packages': [
      {
        'disabled': true,
        'extra_details': '',
        'name': 'Lageplan',
        'newProperty': true,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'Lageplan.jpg'
        ],
        'package_key': '14',
        'physicalProduct': false,
        'price': '0,00',
        'price_pre_text': 'ab',
        'service_key': 'map',
        'service_title': 'Lageplan',
        'title': 'Lageplan'
      }
    ]
  },
  {
    'id': 'retouching',
    'packages': [
      {
        'description_placeholder': 'zB.: Kontrast erhöhen (alle Bilder), Helligkeit anpassen (alle Bilder), Horizont gerade ausrichten (Bild 2)',
        'details': [
          {
            'label': 'Bildbearbeitung - Fotonachbearbeitung',
            'value': 'Bei der Bildbearbeitung - Standard werden lediglich die Helligkeit, die Farbgebung und die Winkel des Bildes optimal angepasst. Nicht inkludiert sind die Beseitigung von Gegenständen und das Einfärben von Gegenständen. Hierfür gibt es in der Produktauswahl die Kategorie Bildbearbeitung - Erweitert.'
          },
          {
            'label': 'Logo einfügen',
            'value': 'Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          },
          {
            'label': 'Weichzeichnen',
            'value': 'Bei Luftaufnahmen wird z.B. die Umgebung unkenntlich gemacht. Bei Fotos im Innen- und Außenbereich werden Vertrauliche Details wie Fotos und Kennzeichen unkenntlich gemacht.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          }
        ],
        'disabled': false,
        'extra_feedback_price': 'retouching_extra_feedback',
        'feedbacks_included': 1,
        'name': 'Fotonachbearbeitung',
        'newProperty': false,
        'numberOfDrafts': 0,
        'package_contents': [
          'Farbkorrekturen',
          'Licht- und Winkelanpassungen',
          'Web optimiert und hochauflösend',
          'gewünschtes Format'
        ],
        'package_images': [
          'retouching/Standard.jpg'
        ],
        'package_key': '6',
        'physicalProduct': false,
        'price': 'retouching_price',
        'price_note': 'pro Bild (Preis exkl. MwSt.)',
        'price_pre_text': '',
        'service_key': 'retouching',
        'service_title': 'Bildbearbeitung',
        'title': 'Bildbearbeitung'
      },
      {
        'description': 'Kleine bis mittelgroße Objekte können aus dem Bild retuschiert werden. Mittelgroße Objekte haben einen maximalen Durchmesser von ca. 60 Zentimetern (z.B. Büsche, Mülltonnen).',
        'description_placeholder': 'zB.: blauer Himmel (alle Bilder), grün-karierten Teppich entfernen (Bild 2)',
        'details': [
          {
            'label': 'Bildbearbeitung - Fotomontage Standard',
            'value': 'Bei der Fotomontage - Basic können kleine bis mittelgroße Objekte aus dem Bild retuschiert werden. Mittelgroße Objekte haben einen maximalen Durchmesser von ca. 60 Zentimetern (z.B. Büsche, Mülltonnen). Die Bildbearbeitung – Standard ist in diesem Paket inkludiert.'
          },
          {
            'label': 'Logo einfügen',
            'value': 'Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          },
          {
            'label': 'Weichzeichnen',
            'value': 'Bei Luftaufnahmen wird z.B. die Umgebung unkenntlich gemacht. Bei Fotos im Innen- und Außenbereich werden Vertrauliche Details wie Fotos und Kennzeichen unkenntlich gemacht.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          }
        ],
        'disabled': false,
        'extra_feedback_price': 'retouching_basic_extra_feedback',
        'feedbacks_included': 2,
        'name': 'Fotomontage Standard',
        'newProperty': false,
        'numberOfDrafts': 0,
        'package_contents': [
          'Entfernen von kleinen bis mittelgroßen Gegenständen ',
          'Farbkorrekturen',
          'Licht- und Winkelanpassungen',
          'Web optimiert und hochauflösend',
          'gewünschtes Format'
        ],
        'package_images': [
          'retouching/Extended.jpg'
        ],
        'package_key': '7',
        'physicalProduct': false,
        'price': 'retouching_price_basic',
        'price_note': 'pro Bild (Preis exkl. MwSt.)',
        'price_pre_text': '',
        'service_key': 'retouching',
        'service_title': 'Bildbearbeitung',
        'title': 'Bildbearbeitung',
        'tooltip': 'Kleine bis mittelgroße Objekte können aus dem Bild retuschiert werden. Mittelgroße Objekte haben einen maximalen Durchmesser von ca. 60 Zentimetern (z.B. Büsche, Mülltonnen).'
      },
      {
        'description': 'Mittelgroße bis große Objekte können aus dem Bild retuschiert werden. Große Objekte haben einen maximalen Durchmesser von ca. 200 Zentimetern (z.B. Autos, Baustellentoiletten).',
        'description_placeholder': 'zB.: blauer Himmel (alle Bilder), grün-karierten Teppich entfernen (Bild 2)',
        'details': [
          {
            'label': 'Bildbearbeitung - Fotomontage Premium',
            'value': 'Bei der Fotomontage - Erweitert können mittelgroße bis große Objekte aus dem Bild retuschiert werden. Große Objekte haben einen maximalen Durchmesser von ca. 200 Zentimetern (z.B. Autos, Baustellentoiletten). Die Bildbearbeitung – Standard ist in diesem Paket inkludiert.'
          },
          {
            'label': 'Logo einfügen',
            'value': 'Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          },
          {
            'label': 'Weichzeichnen',
            'value': 'Bei Luftaufnahmen wird z.B. die Umgebung unkenntlich gemacht. Bei Fotos im Innen- und Außenbereich werden Vertrauliche Details wie Fotos und Kennzeichen unkenntlich gemacht.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.'
          }
        ],
        'disabled': false,
        'extra_feedback_price': 'retouching_erweitert_extra_feedback',
        'feedbacks_included': 2,
        'name': 'Fotomontage Premium',
        'newProperty': false,
        'numberOfDrafts': 0,
        'package_contents': [
          'Entfernen von mittelgroße bis große Gegenständen',
          'Farbkorrekturen',
          'Licht- und Winkelanpassungen',
          'Web optimiert und hochauflösend',
          'gewünschtes Format'
        ],
        'package_images': [
          'retouching/Erweitert.jpg'
        ],
        'package_key': '25',
        'physicalProduct': false,
        'price': 'retouching_price_erweitert',
        'price_note': 'pro Bild (Preis exkl. MwSt.)',
        'price_pre_text': '',
        'service_key': 'retouching',
        'service_title': 'Bildbearbeitung',
        'title': 'Bildbearbeitung',
        'tooltip': 'Mittelgroße bis große Objekte können aus dem Bild retuschiert werden. Große Objekte haben einen maximalen Durchmesser von ca. 200 Zentimetern (z.B. Autos, Baustellentoiletten).'
      }
    ]
  },
  {
    'id': 'social_media',
    'packages': [
      {
        'disabled': true,
        'extra_details': '',
        'name': 'Social-Media',
        'newProperty': true,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'social-media.jpg'
        ],
        'package_key': '22',
        'physicalProduct': false,
        'price': '0,00',
        'price_pre_text': 'ab',
        'service_key': 'social_media',
        'service_title': 'Social-Media',
        'title': 'Social-Media Beratung'
      }
    ]
  },
  {
    'id': 'virtual_reality',
    'packages': [
      {
        'disabled': true,
        'extra_details': '',
        'name': 'VR Brillen',
        'newProperty': true,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'vr.jpg'
        ],
        'package_key': '17',
        'physicalProduct': true,
        'price': '0,00',
        'price_pre_text': 'ab',
        'service_key': 'virtual_reality',
        'service_title': 'Virtuelle Realität',
        'title': 'Virtuelle Realität'
      },
      {
        'disabled': true,
        'extra_details': '',
        'name': 'Mobile Tour',
        'newProperty': true,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'vr.jpg'
        ],
        'package_key': '18',
        'physicalProduct': false,
        'price': '0,00',
        'price_pre_text': 'ab',
        'service_key': 'virtual_reality',
        'service_title': 'Virtuelle Realität',
        'title': 'Virtuelle Realität'
      },
      {
        'disabled': true,
        'extra_details': '',
        'name': 'Stationäre Tour',
        'newProperty': true,
        'package_contents': [
          'Feature 1',
          'Feature 2',
          'Feature 3'
        ],
        'package_images': [
          'vr.jpg'
        ],
        'package_key': '19',
        'physicalProduct': false,
        'price': '0,00',
        'price_pre_text': 'ab',
        'service_key': 'virtual_reality',
        'service_title': 'Virtuelle Realität',
        'title': 'Virtuelle Realität'
      }
    ]
  },
  {
    'id': 'virtual_tour',
    'packages': [
      {
        'additional_unit_price': 'matterport_addunits',
        'details': [
          {
            'label': 'Hosting',
            'value': 'Das Hosting der virtuellen Tour ist auf 1 Jahr beschränkt. Falls das Hosting über einen längeren Zeitraum gewünscht ist, kontaktieren Sie bitte unseren Support: mailto:kontakt@imogent.com'
          },
          {
            'label': 'Mattertags',
            'value': 'Das setzen von Mattertags ist nicht im Preis inbegriffen.'
          },
          {
            'label': 'Ausgabeformat',
            'value': 'Als Ausgabeformat erhalten Sie einen Link zu der virtuellen Matterport Tour.'
          },
          {
            'label': 'Außenbereiche',
            'value': 'Außenbereiche wie Balkone/Terrassen werden bei passenden Bedingungen mit aufgenommen. Sollte dieses bspw. aufgrund starker Sonneneinstrahlung nicht möglich sein, werden alternativ 360°-Fotos erstellt und in die Tour eingebunden. Die Entscheidung liegt im Ermessen des Fotografen. Mit Abschluss der Bestellung akzeptieren Sie diese Bedingung.'
          },
          {
            'label': 'Vertragsbedingung',
            'value': 'Das Setzen von Mattertags ist in dem Preis nicht inbegriffen. Mattertags müssen individuell mit dem Fotografen abgesprochen werden.'
          }
        ],
        'disabled': false,
        'extra_details': '',
        'name': 'Matterport Tour',
        'numberOfDrafts': 0,
        'newProperty': true,
        'package_contents': [],
        'package_images': [
          'rundgang.jpg'
        ],
        'package_key': '13',
        'physicalProduct': false,
        'price': 'matterport_tour_price',
        'price_note': '(Preis exkl. MwSt.)',
        'price_pre_text': 'ab',
        'service_key': 'virtual_tour',
        'service_title': 'Virtueller Rundgang',
        'title': 'Virtueller Rundgang'
      }
    ]
  },
  {
    'id': 'visualisation',
    'packages': [
      {
        'additionalPrice': ['visualisation_indoor_additional_standard', 'visualisation_indoor_additional_premium', 'visualisation_indoor_additional_premium_plus', 'visualisation_indoor_additional_insane'],
        'lengthMatrix': [30, 40, 50],
        'priceMatrix': {
          'photo': ['visualisation_indoor_photo_standard', 'visualisation_indoor_photo_premium', 'visualisation_indoor_photo_premium_plus', 'visualisation_indoor_photo_insane'],
          'degree-360': ['visualisation_indoor_360_standard', 'visualisation_indoor_360_premium', 'visualisation_indoor_360_premium_plus', 'visualisation_indoor_360_insane'],
          'video': ['visualisation_indoor_video_standard', 'visualisation_indoor_video_premium', 'visualisation_indoor_video_premium_plus', 'visualisation_indoor_video_insane']
        },
        'additional360Tour': ['visualisation_indoor_additional_360_standard', 'visualisation_indoor_additional_360_premium', 'visualisation_indoor_additional_360_premium_plus', 'visualisation_indoor_additional_360_insane'],
        'additionalPhotos': ['visualisation_indoor_additional_photos_standard', 'visualisation_indoor_additional_photos_premium', 'visualisation_indoor_additional_photos_premium_plus', 'visualisation_indoor_additional_photos_insane'],
        'configuration': [
          {
            'possibilities': [
              {
                'img_url': 'Modern.jpg',
                'title': 'Individuelles Design'
              },
              {
                'img_url': 'Skandinavisch.jpg',
                'title': 'Skandinavisch'
              },
              {
                'img_url': 'Special.jpg',
                'title': ' Modern'
              }
            ],
            'type': 'Einrichtungsstil',
            'folder': 'interior-style'
          },
          {
            'possibilities': [
              {
                'img_url': 'Bodendielen alt.jpg',
                'title': 'Bodendielen alt'
              },
              {
                'img_url': 'Bodendielen neu.jpg',
                'title': 'Bodendielen neu'
              },
              {
                'img_url': 'Parkett Fischgraet.jpg',
                'title': 'Parkett Fischgrät'
              },
              {
                'img_url': 'Parkett Matt geraeuchert.jpg',
                'title': 'Parkett Matt geräuchert'
              },
              {
                'img_url': 'Parkett Mosaik Wuerfel.jpg',
                'title': 'Parkett Mosaik Würfel'
              },
              {
                'img_url': 'Parkett Natur_1.jpg',
                'title': 'Parkett Natur 1'
              },
              {
                'img_url': 'Parkett Natur_2.jpg',
                'title': 'Parkett Natur 2'
              },
              {
                'img_url': 'Parkett Schiffsboden.jpg',
                'title': 'Parkett Schiffsboden'
              },
              {
                'img_url': 'Parkett_Chevron.jpg',
                'title': 'Parkett Chevron'
              }
            ],
            'type': 'Holzböden',
            'folder': 'wooden_floors'
          },
          {
            'possibilities': [
              {
                'img_url': 'Bodenfliese antrazit.jpg',
                'title': 'Bodenfliese antrazit'
              },
              {
                'img_url': 'Bodenfliese Beton.jpg',
                'title': 'Bodenfliese Beton'
              },
              {
                'img_url': 'Bodenfliese grau.jpg',
                'title': 'Bodenfliese grau'
              },
              {
                'img_url': 'Bodenfliese Marmor.jpg',
                'title': 'Bodenfliese Marmor'
              },
              {
                'img_url': 'Bodenfliese Travertino.jpg',
                'title': 'Bodenfliese Travertino'
              },
              {
                'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                'title': 'Fliese Betonoptik 1 großformatik'
              },
              {
                'img_url': 'Fliese Betonoptik_2.jpg',
                'title': 'Fliese Betonoptik 2'
              },
              {
                'img_url': 'Fliese schwarz.jpg',
                'title': 'Fliese schwarz'
              },
              {
                'img_url': 'Fliese weiss.jpg',
                'title': 'Fliese weiß'
              }
            ],
            'type': 'Fliesenböden',
            'folder': 'tiled_floors'
          },
          {
            'possibilities': [
              {
                'img_url': 'weiss.jpg',
                'title': 'Weiß - ohne Fräsungen'
              },
              {
                'img_url': 'weiss-modern.jpg',
                'title': 'Weiß modern - horizontale Fräsung'
              },
              {
                'img_url': 'weiss-klassisch1.jpg',
                'title': 'Klassisch weiß - Variante 1'
              },
              {
                'img_url': 'weiss-klassisch-2.jpg',
                'title': 'Klassisch weiß - Variante 2'
              },
              {
                'img_url': 'weiss-mit-glas.jpg',
                'title': 'Klassisch weiß - mit Glas'
              },
              {
                'img_url': 'holz.jpg',
                'title': 'Holztür - schlicht'
              },
              {
                'img_url': 'holz-landhaus.jpg',
                'title': 'Holztür - Landhaus'
              },
              {
                'img_url': 'Glastur.jpg',
                'title': 'Glastür'
              },
              {
                'img_url': 'schiebetur.jpg',
                'title': 'Schiebetür'
              }
            ],
            'type': 'Zimmertüren',
            'folder': 'doors'
          },
          {
            'possibilities': [
              {
                'img_url': 'Basic Kuche.jpg',
                'title': 'Schlicht'
              },
              {
                'img_url': 'Designer.jpg',
                'title': 'Designer'
              },
              {
                'img_url': 'Landhaus.jpg',
                'title': 'Landhaus'
              },
              {
                'img_url': 'Skandinavisch.jpg',
                'title': 'Skandinavisch'
              }
            ],
            'type': 'Küchen',
            'folder': 'kitchen'
          },
          {
            'possibilities': [
              {
                'img_url': 'bestehnde Foto einarbeiten.jpg',
                'title': 'Bestehendes Foto einarbeiten'
              },
              {
                'img_url': 'Google Maps.jpg',
                'title': 'Auf Basis von google Maps'
              },
              {
                'img_url': 'Grune Wiese.jpg',
                'title': 'Grüne Wiese'
              },
              {
                'img_url': 'Klassicher Garten.jpg',
                'title': 'Klassischer Garten'
              }
            ],
            'type': 'Ausblick',
            'folder': 'view'
          }
        ],
        'description_placeholder': 'Kommentar',
        'disabled': false,
        'extra_feedback_price': 'visualisation_indoor_extra_feedback',
        'feedbacks_included': 1,
        'helperTexts': [
          {
            'text': 'Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.',
            'title': 'Welche Unterlagen werden benötigt?'
          },
          {
            'text': 'Mit Modifizieren meinen wir, dass Sie Angaben machen können, die nicht aus den Architektenplänen ersichtlich werden. ',
            'title': 'Was meinen wir mit modifizieren?'
          },
          {
            'keyPoints': [
              'Fußleisten',
              'Deckenleisten',
              'Wandfarben',
              'Zimmerpflanzen',
              'Dekoration'
            ],
            'text': 'Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ',
            'title': 'Was meinen wir mit Besonderheiten?'
          }
        ],
        'name': 'Innenvisualisierung',
        'newProperty': true,
        'numberOfDrafts': {
          0: [/**Value of format starts from 1 so keep this empty */],
          1: ['', 3, 4, 4, 6],
          2: ['', 3, 4, 4, 6],
          3: ['', 3, 4, 4, 6]
        },
        'opFormats': [
          {
            'quality_levels': [
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_standard',
                'feedbacks_included': 2,
                'name': 'Standard',
                'value': 1
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_premium',
                'feedbacks_included': 3,
                'name': 'Premium',
                'value': 2
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_premium_plus',
                'feedbacks_included': 3,
                'name': 'Premium Plus',
                'value': 3
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_insane',
                'feedbacks_included': 5,
                'name': 'Insane',
                'value': 4
              }
            ],
            'title1': 'Standbild',
            'value': 1
          },
          {
            'quality_levels': [
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_standard',
                'feedbacks_included': 2,
                'name': 'Standard',
                'value': 1
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_premium',
                'feedbacks_included': 3,
                'name': 'Premium',
                'value': 2
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_premium_plus',
                'feedbacks_included': 3,
                'name': 'Premium Plus',
                'value': 3
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_insane',
                'feedbacks_included': 5,
                'name': 'Insane',
                'value': 4
              }
            ],
            'title1': '360°-Tour',
            'value': 2
          },
          {
            'quality_levels': [
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_standard',
                'feedbacks_included': 2,
                'name': 'Standard',
                'value': 1
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_premium',
                'feedbacks_included': 3,
                'name': 'Premium',
                'value': 2
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_premium_plus',
                'feedbacks_included': 3,
                'name': 'Premium Plus',
                'value': 3
              },
              {
                'extra_feedback_price': 'visualisation_indoor_extra_feedback_insane',
                'feedbacks_included': 5,
                'name': 'Insane',
                'value': 4
              }
            ],
            'title1': 'Rundflug',
            'value': 3
          }
        ],
        'package_contents': [
          'Innenvisualisierungen auf Basis von Grundrissen',
          'Anpassungen auf gewünschtes Format',
          'Web optimiert und hochauflösend'
        ],
        'package_images': [
          'visualisation/indoor.jpg'
        ],
        'package_key': '10',
        'physicalProduct': false,
        'price': 'visualisation_indoor_price',
        'price_note': 'pro Bild (Preis exkl. MwSt.)',
        'price_pre_text': 'ab',
        'service_key': 'visualisation',
        'service_title': '3D-Visualisierungen',
        'title': '3D-Visualisierungen'
      },
      {
        'additionalPrice': ['visualisation_outdoor_additional_standard', 'visualisation_outdoor_additional_premium', 'visualisation_outdoor_additional_premium_plus', 'visualisation_outdoor_additional_insane'],
        'lengthMatrix': [30, 40, 50],
        'apartmentsMatrix': ['visualisation_outdoor_apartments_standard', 'visualisation_outdoor_apartments_premium', 'visualisation_outdoor_apartments_premium_plus', 'visualisation_outdoor_apartments_insane'],
        'priceMatrix': {
          'photo': ['visualisation_outdoor_photo_standard', 'visualisation_outdoor_photo_premium', 'visualisation_outdoor_photo_premium_plus', 'visualisation_outdoor_photo_insane'],
          'degree-360': ['visualisation_outdoor_360_standard', 'visualisation_outdoor_360_premium', 'visualisation_outdoor_360_premium_plus', 'visualisation_outdoor_360_insane'],
          'video': ['visualisation_outdoor_video_standard', 'visualisation_outdoor_video_premium', 'visualisation_outdoor_video_premium_plus', 'visualisation_outdoor_video_insane']
        },
        'buildingsMatrix': {
          'standard': ['visualisation_outdoor_2_buildings_standard', 'visualisation_outdoor_3_buildings_standard', 'visualisation_outdoor_4_buildings_standard'],
          'premium': ['visualisation_outdoor_2_buildings_premium', 'visualisation_outdoor_3_buildings_premium', 'visualisation_outdoor_4_buildings_premium'],
          'premium_plus': ['visualisation_outdoor_2_buildings_premium_plus', 'visualisation_outdoor_3_buildings_premium_plus', 'visualisation_outdoor_4_buildings_premium_plus'],
          'insane': ['visualisation_outdoor_2_buildings_insane', 'visualisation_outdoor_3_buildings_insane', 'visualisation_outdoor_4_buildings_insane']
        },
        'additional360Tour': ['visualisation_outdoor_additional_360_standard', 'visualisation_outdoor_additional_360_premium', 'visualisation_outdoor_additional_360_premium_plus', 'visualisation_outdoor_additional_360_insane'],
        'additionalPhotos': ['visualisation_outdoor_additional_photos_standard', 'visualisation_outdoor_additional_photos_premium', 'visualisation_outdoor_additional_photos_premium_plus', 'visualisation_outdoor_additional_photos_insane'],
        'configuration': [
          {
            'possibilities': [
              {
                'img_url': 'Burokomplex.jpg',
                'title': 'Bürokomplex'
              },
              {
                'img_url': 'Klinkerhaus.jpg',
                'title': 'Klinkerhaus'
              },
              {
                'img_url': 'Mehrfamilienhaus mit .jpg',
                'title': 'Mehrfamilienhaus mit Staffelgeschoss'
              },
              {
                'img_url': 'Mehrfamilienhaus mit Flachdach.jpg',
                'title': 'Mehrfamilienhaus mit Flachdach'
              },
              {
                'img_url': 'Mehrfamilienhaus mit Spitzdach.jpg',
                'title': 'Mehrfamilienhaus mit Spitzdach'
              },
              {
                'img_url': 'Mehrfamilienhaus 2.jpg',
                'title': 'Merhfamilienhaus - Modern'
              },
              {
                'img_url': 'Modern 1.jpg',
                'title': 'Einfamilienhaus - Modern mit Satteldach'
              },
              {
                'img_url': 'Modern 2.jpg',
                'title': 'Einfamilienhaus - Modern mit Flachdach'
              },
              {
                'img_url': 'Modern 3.jpg',
                'title': 'Einfamilienhaus - Architektenhaus'
              }
            ],
            'type': 'Haustyp',
            'folder': 'housetype'
          },
          {
            'possibilities': [
              {
                'img_url': 'Dach rot_2.jpg',
                'title': 'Dachziegel - Tonziegel Rot'
              },
              {
                'img_url': 'Dach hellgrau_2.jpg',
                'title': 'Dachziegel - Tonziegel Hellgrau'
              },
              {
                'img_url': 'Dach schwarz_2.jpg',
                'title': 'Dachziegel - Tonziegel Grau'
              },
              {
                'img_url': 'Dach rot_1.jpg',
                'title': 'Dachziegel - Steinziegel Rot'
              },
              {
                'img_url': 'Dach hellgrau_1.jpg',
                'title': 'Dachziegel - Steinziegel Hellgrau'
              },
              {
                'img_url': 'Dach schwarz_1.jpg',
                'title': 'Dachziegel - Steinziegel Schwarz'
              },
              {
                'img_url': 'Dach rot_3.jpg',
                'title': 'Dachziegel - Metallziegel Rot'
              },
              {
                'img_url': 'Dach grau_3.jpg',
                'title': 'Dachziegel - Metallziegel Hellgrau'
              },
              {
                'img_url': 'Dach schwarz_3.jpg',
                'title': 'Dachziegel - Metallziegel Schwarz'
              },
              {
                'img_url': 'Dachbegrunung 1.jpg',
                'title': 'Dachbegrünung - Ziegraser'
              },
              {
                'img_url': 'Dachbegrunung 2.jpg',
                'title': 'Dachbegrünung - Sukkulenten'
              },
              {
                'img_url': 'Dachbegrunung 3.jpg',
                'title': 'Flachdach - Kies'
              }
            ],
            'type': 'Dachbelag',
            'folder': 'roof'
          },
          {
            'possibilities': [
              {
                'img_url': 'holz.jpg',
                'title': 'Fensterrahmen - Holz'
              },
              {
                'img_url': 'nuss.jpg',
                'title': 'Fensterrahmen - Nuss'
              },
              {
                'img_url': 'schwarz.jpg',
                'title': 'Fensterrahmen - Schwarz'
              },
              {
                'img_url': 'weiss.jpg',
                'title': 'Fensterrahmen - Weiß'
              }
            ],
            'type': 'Fenster',
            'folder': 'windows'
          },
          {
            'possibilities': [
              {
                'img_url': 'Absturzsicherung Edelstahl rund.jpg',
                'title': 'Absturzsicherung - Edelstahl'
              },
              {
                'img_url': 'Absturzsicherung Flachstahlgeländer Verzinkt.jpg',
                'title': 'Absturzsicherung Flachstahlgeländer Verzinkt'
              },
              {
                'img_url': 'Absturzsicherung Ganzglas.jpg',
                'title': 'Absturzsicherung - Absturzsicherung Ganzglas'
              },
              {
                'img_url': 'Edelstahl.jpg',
                'title': 'Edelstahl'
              },
              {
                'img_url': 'Edelstahl_Glas1.jpg',
                'title': 'Edelstahl mit Glas'
              },
              {
                'img_url': 'Edelstahl_Glas2.jpg',
                'title': 'Edelstahl / Chrom mit Glas'
              },
              {
                'img_url': 'Glas.jpg',
                'title': 'Glas'
              },
              {
                'img_url': 'Stahl.jpg',
                'title': 'Stahl'
              },
              {
                'img_url': 'Balkon Brüstung Flachstahlgeländer.jpg',
                'title': 'Balkon Brüstung Flachstahlgeländer'
              }
            ],
            'type': 'Balkon',
            'folder': 'balcony-parapet'
          },
          {
            'possibilities': [
              {
                'img_url': 'Google Maps.jpg',
                'title': 'Google Maps'
              },
              {
                'img_url': 'Grune Wiese.jpg',
                'title': 'Grüne Wiese'
              },
              {
                'img_url': 'In bestehendes Foto einarbeiten.jpg',
                'title': 'Bestehendes Foto einarbeiten'
              },
              {
                'img_url': 'Klassischer Garten.jpg',
                'title': 'Klassischer Garten'
              }
            ],
            'type': 'Umgebung',
            'folder': 'environment'
          },
          {
            'possibilities': [
              {
                'img_url': 'Familiar.jpg',
                'title': 'Familiär'
              },
              {
                'img_url': 'Young professionals.jpg',
                'title': 'Young Professionals'
              },
              {
                'img_url': 'Keine Menschen.jpg',
                'title': 'Keine Menschen'
              }
            ],
            'type': 'Menschen',
            'folder': 'humans'
          },
          {
            'possibilities': [
              {
                'img_url': 'Abendrot.jpg',
                'title': 'Abendrot'
              },
              {
                'img_url': 'Abendsonne.jpg',
                'title': 'Abendsonne'
              },
              {
                'img_url': 'Bewoelkt.jpg',
                'title': 'Bewölkt'
              },
              {
                'img_url': 'Herbstlich.jpg',
                'title': 'Herbstlich'
              }, {
                'img_url': 'Mittagssonne.jpg',
                'title': 'Mittagssonne'
              }, {
                'img_url': 'Morgensonne.jpg',
                'title': 'Morgensonne'
              }, {
                'img_url': 'Nebelig.jpg',
                'title': 'Nebelig'
              }, {
                'img_url': 'Regnerisch.jpg',
                'title': 'Regnerisch'
              }, {
                'img_url': 'Winterabend.jpg',
                'title': 'Winterabend'
              },
            ],
            'type': 'Stimmung',
            'folder': 'moods'
          }
        ],
        'description_placeholder': 'Kommentar',
        'disabled': false,
        'extra_feedback_price': 'visualisation_outdoor_extra_feedback',
        'feedbacks_included': 1,
        'helperTexts': [
          {
            'text': 'Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.',
            'title': 'Welche Unterlagen werden benötigt?'
          },
          {
            'text': 'Mit Modifizieren meinen wir, dass Sie Angaben machen können, die nicht aus den Architektenplänen ersichtlich werden. ',
            'title': 'Was meinen wir mit modifizieren?'
          },
          {
            'keyPoints': [
              'Balkon- bzw. Terrassenbelag',
              'Gartenutensilien',
              'Außenbeleuchtung',
              'Fassadenart u. -Farbe',
              'Absturzsicherung Fenster',
              'Bepflanzung'
            ],
            'text': 'Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ',
            'title': 'Was meinen wir mit Besonderheiten?'
          }
        ],
        'name': 'Außenvisualisierung',
        'newProperty': true,
        'numberOfDrafts': {
          0: [/**Value of format starts from 1 so keep this empty */],
          1: ['', 2, 2, 3, 5],
          2: ['', 2, 2, 3, 5],
          3: ['', 2, 2, 3, 5]
        },
        'opFormats': [
          {
            'quality_levels': [
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_standard',
                'feedbacks_included': 2,
                'name': 'Standard',
                'value': 1
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_premium',
                'feedbacks_included': 3,
                'name': 'Premium',
                'value': 2
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_premium_plus',
                'feedbacks_included': 3,
                'name': 'Premium Plus',
                'value': 3
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_insane',
                'feedbacks_included': 5,
                'name': 'Insane',
                'value': 4
              }
            ],
            'title1': 'Standbild',
            'value': 1
          },
          {
            'quality_levels': [
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_standard',
                'feedbacks_included': 2,
                'name': 'Standard',
                'value': 1
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_premium',
                'feedbacks_included': 3,
                'name': 'Premium',
                'value': 2
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_premium_plus',
                'feedbacks_included': 3,
                'name': 'Premium Plus',
                'value': 3
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_insane',
                'feedbacks_included': 5,
                'name': 'Insane',
                'value': 4
              }
            ],
            'title1': '360°-Tour',
            'value': 2
          },
          {
            'quality_levels': [
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_standard',
                'feedbacks_included': 2,
                'name': 'Standard',
                'value': 1
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_premium',
                'feedbacks_included': 3,
                'name': 'Premium',
                'value': 2
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_premium_plus',
                'feedbacks_included': 3,
                'name': 'Premium Plus',
                'value': 3
              },
              {
                'extra_feedback_price': 'visualisation_outdoor_extra_feedback_insane',
                'feedbacks_included': 5,
                'name': 'Insane',
                'value': 4
              }
            ],
            'title1': 'Rundflug',
            'value': 3
          }
        ],
        'package_contents': [
          'Außenvisualisierungen auf Basis von Ihren Bauplänen',
          'Anpassungen auf gewünschtes Format',
          'Web optimiert und hochauflösend'
        ],
        'package_images': [
          'visualisation/outdoor.jpg'
        ],
        'package_key': '11',
        'physicalProduct': false,
        'price': 'visualisation_outdoor_price',
        'price_note': 'pro Bild (Preis exkl. MwSt.)',
        'price_pre_text': 'ab',
        'service_key': 'visualisation',
        'service_title': '3D-Visualisierungen',
        'title': '3D-Visualisierungen'
      }
    ]
  },
  {
    'id': 'vstaging',
    'packages': [
      {
        'configuration': {
          'basic':
          {
            'luxus': [
              {
                'possibilities': [
                  {
                    'img_url': '2011.jpg',
                    'title': '2011'
                  },
                  {
                    'img_url': '2012.jpg',
                    'title': '2012'
                  },
                  {
                    'img_url': '2018.jpg',
                    'title': '2018'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3001.jpg',
                    'title': '3001'
                  },
                  {
                    'img_url': '3004.jpg',
                    'title': '3004'
                  },
                  {
                    'img_url': '3011.jpg',
                    'title': '3011'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4035.jpg',
                    'title': '4035'
                  },
                  {
                    'img_url': '4041.jpg',
                    'title': '4041'
                  },
                  {
                    'img_url': '4045.jpg',
                    'title': '4045'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7022-3.png',
                    'title': '7022-3'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7000.jpg',
                    'title': '7000'
                  },
                  {
                    'img_url': '7003.jpg',
                    'title': '7003'
                  },
                  {
                    'img_url': '7008.jpg',
                    'title': '7008'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'modern': [
              {
                'possibilities': [
                  {
                    'img_url': '2081.jpg',
                    'title': '2081'
                  },
                  {
                    'img_url': '2090.jpg',
                    'title': '2090'
                  },
                  {
                    'img_url': '2132.jpg',
                    'title': '2132'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3055.jpg',
                    'title': '3055'
                  },
                  {
                    'img_url': '3162.jpg',
                    'title': '3162'
                  },
                  {
                    'img_url': '3301.jpg',
                    'title': '3301'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4103.jpg',
                    'title': '4103'
                  },
                  {
                    'img_url': '4106.jpg',
                    'title': '4106'
                  },
                  {
                    'img_url': '4107.jpg',
                    'title': '4107'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3601.jpg',
                    'title': '3601'
                  },
                  {
                    'img_url': '4601.jpg',
                    'title': '4601'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7023-5.png',
                    'title': '7023-5'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7040.jpg',
                    'title': '7040'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'skandinavisch': [
              {
                'possibilities': [
                  {
                    'img_url': '2094.jpg',
                    'title': '2094'
                  },
                  {
                    'img_url': '2121.jpg',
                    'title': '2121'
                  },
                  {
                    'img_url': '2130.jpg',
                    'title': '2130'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3153.jpg',
                    'title': '3153'
                  },
                  {
                    'img_url': '3158.jpg',
                    'title': '3158'
                  },
                  {
                    'img_url': '3401.jpg',
                    'title': '3401'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4028.jpg',
                    'title': '4028'
                  },
                  {
                    'img_url': '4030.jpg',
                    'title': '4030'
                  },
                  {
                    'img_url': '4301.jpg',
                    'title': '4301'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6401.jpg',
                    'title': '6401'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-1.png',
                    'title': '7059-1'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  },
                  {
                    'img_url': '7059-4.png',
                    'title': '7059-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ]
          },
          'erweitert':
          {
            'luxus': [
              {
                'possibilities': [
                  {
                    'img_url': '2011.jpg',
                    'title': '2011'
                  },
                  {
                    'img_url': '2012.jpg',
                    'title': '2012'
                  },
                  {
                    'img_url': '2018.jpg',
                    'title': '2018'
                  },
                  {
                    'img_url': '2024.jpg',
                    'title': '2024'
                  },
                  {
                    'img_url': '2064.jpg',
                    'title': '2064'
                  },
                  {
                    'img_url': '2065.jpg',
                    'title': '2065'
                  },
                  {
                    'img_url': '2089.jpg',
                    'title': '2089'
                  },
                  {
                    'img_url': '2110.jpg',
                    'title': '2110'
                  },
                  {
                    'img_url': '2113.jpg',
                    'title': '2113'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3001.jpg',
                    'title': '3001'
                  },
                  {
                    'img_url': '3004.jpg',
                    'title': '3004'
                  },
                  {
                    'img_url': '3011.jpg',
                    'title': '3011'
                  },
                  {
                    'img_url': '3136.jpg',
                    'title': '3136'
                  },
                  {
                    'img_url': '3159.jpg',
                    'title': '3159'
                  },
                  {
                    'img_url': '3168.jpg',
                    'title': '3168'
                  },
                  {
                    'img_url': '3171.jpg',
                    'title': '3171'
                  },
                  {
                    'img_url': '3172.jpg',
                    'title': '3172'
                  },
                  {
                    'img_url': '3176.jpg',
                    'title': '3176'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4035.jpg',
                    'title': '4035'
                  },
                  {
                    'img_url': '4041.jpg',
                    'title': '4041'
                  },
                  {
                    'img_url': '4045.jpg',
                    'title': '4045'
                  },
                  {
                    'img_url': '4048.jpg',
                    'title': '4048'
                  },
                  {
                    'img_url': '4067.jpg',
                    'title': '4067'
                  },
                  {
                    'img_url': '4116.jpg',
                    'title': '4116'
                  },
                  {
                    'img_url': '4120.jpg',
                    'title': '4120'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  },
                  {
                    'img_url': '2086.jpg',
                    'title': '2086'
                  },
                  {
                    'img_url': '2087.jpg',
                    'title': '2087'
                  },
                  {
                    'img_url': '2103.jpg',
                    'title': '2103'
                  },
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6301.jpg',
                    'title': '6301'
                  },
                  {
                    'img_url': '6401.jpg',
                    'title': '6401'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7022-3.png',
                    'title': '7022-3'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7023-5.png',
                    'title': '7023-5'
                  },
                  {
                    'img_url': '7027-1.png',
                    'title': '7027-1'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7028-3.png',
                    'title': '7028-3'
                  },
                  {
                    'img_url': '7028-4.png',
                    'title': '7028-4'
                  },
                  {
                    'img_url': '7028-6.png',
                    'title': '7028-6'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-1.png',
                    'title': '7059-1'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  },
                  {
                    'img_url': '7059-4.png',
                    'title': '7059-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  },
                  {
                    'img_url': '7018.jpg',
                    'title': '7018'
                  },
                  {
                    'img_url': '7019.jpg',
                    'title': '7019'
                  },
                  {
                    'img_url': '7038.jpg',
                    'title': '7038'
                  },
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7000.jpg',
                    'title': '7000'
                  },
                  {
                    'img_url': '7003.jpg',
                    'title': '7003'
                  },
                  {
                    'img_url': '7008.jpg',
                    'title': '7008'
                  },
                  {
                    'img_url': '7020.jpg',
                    'title': '7020'
                  },
                  {
                    'img_url': '7026.jpg',
                    'title': '7026'
                  },
                  {
                    'img_url': '7034.jpg',
                    'title': '7034'
                  },
                  {
                    'img_url': '7050.jpg',
                    'title': '7050'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'modern': [
              {
                'possibilities': [
                  {
                    'img_url': '2049.jpg',
                    'title': '2049'
                  },
                  {
                    'img_url': '2060.jpg',
                    'title': '2060'
                  },
                  {
                    'img_url': '2062.jpg',
                    'title': '2062'
                  },
                  {
                    'img_url': '2081.jpg',
                    'title': '2081'
                  },
                  {
                    'img_url': '2090.jpg',
                    'title': '2090'
                  },
                  {
                    'img_url': '2115.jpg',
                    'title': '2115'
                  },
                  {
                    'img_url': '2120.jpg',
                    'title': '2120'
                  },
                  {
                    'img_url': '2132.jpg',
                    'title': '2132'
                  },
                  {
                    'img_url': '2142.jpg',
                    'title': '2142'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3017.jpg',
                    'title': '3017'
                  },
                  {
                    'img_url': '3053.jpg',
                    'title': '3053'
                  },
                  {
                    'img_url': '3055.jpg',
                    'title': '3055'
                  },
                  {
                    'img_url': '3141.jpg',
                    'title': '3141'
                  },
                  {
                    'img_url': '3156.jpg',
                    'title': '3156'
                  },
                  {
                    'img_url': '3158.jpg',
                    'title': '3158'
                  },
                  {
                    'img_url': '3162.jpg',
                    'title': '3162'
                  },
                  {
                    'img_url': '3177.jpg',
                    'title': '3177'
                  },
                  {
                    'img_url': '3301.jpg',
                    'title': '3301'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4046.jpg',
                    'title': '4046'
                  },
                  {
                    'img_url': '4058.jpg',
                    'title': '4058'
                  },
                  {
                    'img_url': '4066.jpg',
                    'title': '4066'
                  },
                  {
                    'img_url': '4100.jpg',
                    'title': '4100'
                  },
                  {
                    'img_url': '4103.jpg',
                    'title': '4103'
                  },
                  {
                    'img_url': '4106.jpg',
                    'title': '4106'
                  },
                  {
                    'img_url': '4107.jpg',
                    'title': '4107'
                  },
                  {
                    'img_url': '4112.jpg',
                    'title': '4112'
                  },
                  {
                    'img_url': '4523.jpg',
                    'title': '4523'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  },
                  {
                    'img_url': '2086.jpg',
                    'title': '2086'
                  },
                  {
                    'img_url': '2087.jpg',
                    'title': '2087'
                  },
                  {
                    'img_url': '2103.jpg',
                    'title': '2103'
                  },
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6301.jpg',
                    'title': '6301'
                  },
                  {
                    'img_url': '6401.jpg',
                    'title': '6401'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7023-5.png',
                    'title': '7023-5'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  },
                  {
                    'img_url': '7018.jpg',
                    'title': '7018'
                  },
                  {
                    'img_url': '7019.jpg',
                    'title': '7019'
                  },
                  {
                    'img_url': '7038.jpg',
                    'title': '7038'
                  },
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7000.jpg',
                    'title': '7000'
                  },
                  {
                    'img_url': '7002.jpg',
                    'title': '7002'
                  },
                  {
                    'img_url': '7007.jpg',
                    'title': '7007'
                  },
                  {
                    'img_url': '7008.jpg',
                    'title': '7008'
                  },
                  {
                    'img_url': '7040.jpg',
                    'title': '7040'
                  },
                  {
                    'img_url': '7050.jpg',
                    'title': '7050'
                  },
                  {
                    'img_url': '7053.jpg',
                    'title': '7053'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'skandinavisch': [
              {
                'possibilities': [
                  {
                    'img_url': '2004.jpg',
                    'title': '2004'
                  },
                  {
                    'img_url': '2005.jpg',
                    'title': '2005'
                  },
                  {
                    'img_url': '2008.jpg',
                    'title': '2008'
                  },
                  {
                    'img_url': '2063.jpg',
                    'title': '2063'
                  },
                  {
                    'img_url': '2090.jpg',
                    'title': '2090'
                  },
                  {
                    'img_url': '2094.jpg',
                    'title': '2094'
                  },
                  {
                    'img_url': '2121.jpg',
                    'title': '2121'
                  },
                  {
                    'img_url': '2130.jpg',
                    'title': '2130'
                  },
                  {
                    'img_url': '2138.jpg',
                    'title': '2138'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3003.jpg',
                    'title': '3003'
                  },
                  {
                    'img_url': '3041.jpg',
                    'title': '3041'
                  },
                  {
                    'img_url': '3048.jpg',
                    'title': '3048'
                  },
                  {
                    'img_url': '3150.jpg',
                    'title': '3150'
                  },
                  {
                    'img_url': '3153.jpg',
                    'title': '3153'
                  },
                  {
                    'img_url': '3158.jpg',
                    'title': '3158'
                  },
                  {
                    'img_url': '3167.jpg',
                    'title': '3167'
                  },
                  {
                    'img_url': '3174.jpg',
                    'title': '3174'
                  },
                  {
                    'img_url': '3401.jpg',
                    'title': '3401'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4028.jpg',
                    'title': '4028'
                  },
                  {
                    'img_url': '4030.jpg',
                    'title': '4030'
                  },
                  {
                    'img_url': '4058.jpg',
                    'title': '4058'
                  },
                  {
                    'img_url': '4107.jpg',
                    'title': '4107'
                  },
                  {
                    'img_url': '4109.jpg',
                    'title': '4109'
                  },
                  {
                    'img_url': '4118.jpg',
                    'title': '4118'
                  },
                  {
                    'img_url': '4122.jpg',
                    'title': '4122'
                  },
                  {
                    'img_url': '4301.jpg',
                    'title': '4301'
                  },
                  {
                    'img_url': '4401.jpg',
                    'title': '4401'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  },
                  {
                    'img_url': '2086.jpg',
                    'title': '2086'
                  },
                  {
                    'img_url': '2087.jpg',
                    'title': '2087'
                  },
                  {
                    'img_url': '2103.jpg',
                    'title': '2103'
                  },
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4601.jpg',
                    'title': '4601'
                  },
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6301.jpg',
                    'title': '6301'
                  },
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-1.png',
                    'title': '7059-1'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  },
                  {
                    'img_url': '7059-4.png',
                    'title': '7059-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  },
                  {
                    'img_url': '7018.jpg',
                    'title': '7018'
                  },
                  {
                    'img_url': '7019.jpg',
                    'title': '7019'
                  },
                  {
                    'img_url': '7038.jpg',
                    'title': '7038'
                  },
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7003.jpg',
                    'title': '7003'
                  },
                  {
                    'img_url': '7020.jpg',
                    'title': '7020'
                  },
                  {
                    'img_url': '7034.jpg',
                    'title': '7034'
                  },
                  {
                    'img_url': '7040.jpg',
                    'title': '7040'
                  },
                  {
                    'img_url': '7050.jpg',
                    'title': '7050'
                  },
                  {
                    'img_url': '7053.jpg',
                    'title': '7053'
                  },
                  {
                    'img_url': '7101.jpg',
                    'title': '7101'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ]
          }
        },
        'catalog_price': [
          'vstaging_standbild_standard_price',
          'vstaging_standbild_basic_price',
          'vstaging_standbild_erweitert_price'
        ],
        'description_placeholder': 'zB.: ...',
        'details': [
          {
            'label': 'Einrichtungsstil',
            'value': 'Sie können zwischen 3 vorgefertigten Stilen unterscheiden oder anhand unseres Stilkataloges individuell Möbel auswählen. Die Nummern der Einrichtungsgegenstände, können Sie als Formulierungshilfe nutzten. Katalog: @https://www.virtualfurniture.io/?c=WQVyYdmwbe'
          },
          {
            'label': 'Digitales Staging inkl. Möbel entfernen',
            'value': 'Das bloße digitale Stagen ist nur möglich, wenn die Räume von Möbeln befreit sind. Sollten sich Möbel in den Bildern befinden, wird eine Gebühr von 12,90€ je Bild berechnet um diese zu beseitigen. Dies gilt bis maximal 4 Möbelstücken. Bei einer höheren Anzahl an Möbeln muss der Raum renoviert werden, da sonst die Qualität des Stagings nicht gewährleistet werden kann. Beispiele: @https://www.imogent.de/produkt-digitales-Staging'
          },
          {
            'label': 'Digitales Staging inkl. Renovierung',
            'value': 'Bei der digitalen Renovierung werden Böden, Wände, Decken, Fenster usw. digital aufgewertet. Bestehende Möbel werden entfernt und durch neue ersetzt. Beispiele: @https://www.imogent.de/produkt-digitales-Staging'
          },
          {
            'label': 'Wände versetzen',
            'value': 'Bei der digitalen Renovierung können Durchbrüche oder Wände neu eingezogen werden. Die Änderungen müssen hierzu nachvollziehbar in die Grundrissen oder Fotos eingezeichnet werden. Geben Sie hier nur die Anzahl der Bilder an, die davon betroffen sind. Beispiele: @https://www.imogent.de/produkt-digitales-Staging'
          },
          {
            'label': 'Kommentar',
            'value': 'Beschreiben Sie in dem Kommentarfeld, welche Besonderheiten bei dem digitalen Staging beachtet werden sollen und beziehen Sie sich dabei auf den Namen der Datei.'
          },
          {
            'label': 'Fotos',
            'value': 'Benennen Sie die Fotos mit der Raumbezeichnung und beziehen Sie sich in dem Kommentarfeld wiederum auf die Fotos.'
          }
        ],
        'disabled': false,
        'extra_feedback_price': 'vstaging_standbild_extra_feedback',
        'feedbacks_included': 1,
        'furniture_price': 'vstaging_standbild_furniture',
        'helperTexts': [
          {
            'text': 'Hier haben Sie die Möglichkeit Unterlagen, die bei dem Virtuellen Staging helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.',
            'title': 'Welche Unterlagen werden benötigt?'
          },
          {
            'text': 'Bitte wählen Sie die Einrichtungsgegenstände für Ihre hochgeladenen Räume aus. Sie können zu jedem Einrichtungsgegenstand einen Kommentar verfassen und Ihre Bestellung so auf Ihre Bedürfnisse modifizieren. Sollten Sie Räume kombinieren wollen, können Sie die Nummernkennung der Einrichtungsgegenstände nutzen und in dem Kommentar darauf referenzieren.',
            'title': 'Was meinen wir mit modifizieren?'
          }
        ],
        'isVirtualTour': false,
        'name': 'Standbilder',
        'newProperty': false,
        'numberOfDrafts': 0,
        'package_contents': [
          'Virtuelles Einrichten',
          'moderne Accessoires',
          'Web optimiert und hochauflösend'
        ],
        'package_images': [
          'digital_staging/skandinavischer.jpg',
          'digital_staging/modern.jpg',
          'digital_staging/retro.jpg'
        ],
        'package_key': '8',
        // 'photo_price': '79,00',
        'physicalProduct': false,
        'price': 'vstaging_standbild_price',
        'price_note': 'pro Bild (Preis exkl. MwSt.)',
        'price_pre_text': 'ab',
        'renovation_price': 'vstaging_standbild_renovation',
        'service_key': 'vstaging',
        'service_title': 'Digitales Staging',
        'styles': [
          {
            'styleImage': '../../assets/images/digital_staging/skandinavischer.jpg',
            'folder': '../../assets/images/digital_staging/skandinavisch/',
            'styleImages': [
              'skandinavisch2.jpg',
              'skandinavisch3.jpg',
              'skandinavisch4.jpg',
              'skandinavisch5.jpg',
              'skandinavisch1.jpg',
            ],
            'styleLink': 'Skandinavischer Stilkatalog',
            'styleName': 'Skandinavisch',
            'selector': 'skandinavisch',
            'styleUrl': 'https://www.virtualfurniture.io/styles/14?c=gKRyevVjxW'
          },
          {
            'styleImage': '../../assets/images/digital_staging/modern.jpg',
            'folder': '../../assets/images/digital_staging/modern/',
            'styleImages': [
              'modern2.jpg',
              'modern3.jpg',
              'modern4.jpg',
              'modern5.jpg',
              'modern1.jpg',
            ],
            'styleLink': 'Moderner Stilkatalog',
            'styleName': 'Modern',
            'selector': 'modern',
            'styleUrl': 'https://www.virtualfurniture.io/styles/16?c=gKRyevVjxW'
          },
          {
            'styleImage': '../../assets/images/digital_staging/luxus.jpg',
            'folder': '../../assets/images/digital_staging/luxus/',
            'styleImages': [
              'luxus2.jpg',
              'luxus3.jpg',
              'luxus4.jpg',
              'luxus5.jpg',
              'luxus1.jpg',
            ],
            'styleLink': 'Luxuriöser Stilkatalog',
            'styleName': 'Luxuriös',
            'selector': 'luxus',
            'styleUrl': 'https://www.virtualfurniture.io/styles/15?c=gKRyevVjxW'
          },
        ],
        'title': 'Digitales Staging',
        'wall_move_price': 'vstaging_standbild_wall_move'
      },
      {
        'configuration': {
          'basic':
          {
            'luxus': [
              {
                'possibilities': [
                  {
                    'img_url': '2011.jpg',
                    'title': '2011'
                  },
                  {
                    'img_url': '2012.jpg',
                    'title': '2012'
                  },
                  {
                    'img_url': '2018.jpg',
                    'title': '2018'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3001.jpg',
                    'title': '3001'
                  },
                  {
                    'img_url': '3004.jpg',
                    'title': '3004'
                  },
                  {
                    'img_url': '3011.jpg',
                    'title': '3011'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4035.jpg',
                    'title': '4035'
                  },
                  {
                    'img_url': '4041.jpg',
                    'title': '4041'
                  },
                  {
                    'img_url': '4045.jpg',
                    'title': '4045'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7022-3.png',
                    'title': '7022-3'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7000.jpg',
                    'title': '7000'
                  },
                  {
                    'img_url': '7003.jpg',
                    'title': '7003'
                  },
                  {
                    'img_url': '7008.jpg',
                    'title': '7008'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'modern': [
              {
                'possibilities': [
                  {
                    'img_url': '2081.jpg',
                    'title': '2081'
                  },
                  {
                    'img_url': '2090.jpg',
                    'title': '2090'
                  },
                  {
                    'img_url': '2132.jpg',
                    'title': '2132'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3055.jpg',
                    'title': '3055'
                  },
                  {
                    'img_url': '3162.jpg',
                    'title': '3162'
                  },
                  {
                    'img_url': '3301.jpg',
                    'title': '3301'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4103.jpg',
                    'title': '4103'
                  },
                  {
                    'img_url': '4106.jpg',
                    'title': '4106'
                  },
                  {
                    'img_url': '4107.jpg',
                    'title': '4107'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3601.jpg',
                    'title': '3601'
                  },
                  {
                    'img_url': '4601.jpg',
                    'title': '4601'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7023-5.png',
                    'title': '7023-5'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7040.jpg',
                    'title': '7040'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'skandinavisch': [
              {
                'possibilities': [
                  {
                    'img_url': '2094.jpg',
                    'title': '2094'
                  },
                  {
                    'img_url': '2121.jpg',
                    'title': '2121'
                  },
                  {
                    'img_url': '2130.jpg',
                    'title': '2130'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3153.jpg',
                    'title': '3153'
                  },
                  {
                    'img_url': '3158.jpg',
                    'title': '3158'
                  },
                  {
                    'img_url': '3401.jpg',
                    'title': '3401'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4028.jpg',
                    'title': '4028'
                  },
                  {
                    'img_url': '4030.jpg',
                    'title': '4030'
                  },
                  {
                    'img_url': '4301.jpg',
                    'title': '4301'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6401.jpg',
                    'title': '6401'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-1.png',
                    'title': '7059-1'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  },
                  {
                    'img_url': '7059-4.png',
                    'title': '7059-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ]
          },
          'erweitert':
          {
            'luxus': [
              {
                'possibilities': [
                  {
                    'img_url': '2011.jpg',
                    'title': '2011'
                  },
                  {
                    'img_url': '2012.jpg',
                    'title': '2012'
                  },
                  {
                    'img_url': '2018.jpg',
                    'title': '2018'
                  },
                  {
                    'img_url': '2024.jpg',
                    'title': '2024'
                  },
                  {
                    'img_url': '2064.jpg',
                    'title': '2064'
                  },
                  {
                    'img_url': '2065.jpg',
                    'title': '2065'
                  },
                  {
                    'img_url': '2089.jpg',
                    'title': '2089'
                  },
                  {
                    'img_url': '2110.jpg',
                    'title': '2110'
                  },
                  {
                    'img_url': '2113.jpg',
                    'title': '2113'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3001.jpg',
                    'title': '3001'
                  },
                  {
                    'img_url': '3004.jpg',
                    'title': '3004'
                  },
                  {
                    'img_url': '3011.jpg',
                    'title': '3011'
                  },
                  {
                    'img_url': '3136.jpg',
                    'title': '3136'
                  },
                  {
                    'img_url': '3159.jpg',
                    'title': '3159'
                  },
                  {
                    'img_url': '3168.jpg',
                    'title': '3168'
                  },
                  {
                    'img_url': '3171.jpg',
                    'title': '3171'
                  },
                  {
                    'img_url': '3172.jpg',
                    'title': '3172'
                  },
                  {
                    'img_url': '3176.jpg',
                    'title': '3176'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4035.jpg',
                    'title': '4035'
                  },
                  {
                    'img_url': '4041.jpg',
                    'title': '4041'
                  },
                  {
                    'img_url': '4045.jpg',
                    'title': '4045'
                  },
                  {
                    'img_url': '4048.jpg',
                    'title': '4048'
                  },
                  {
                    'img_url': '4067.jpg',
                    'title': '4067'
                  },
                  {
                    'img_url': '4116.jpg',
                    'title': '4116'
                  },
                  {
                    'img_url': '4120.jpg',
                    'title': '4120'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  },
                  {
                    'img_url': '2086.jpg',
                    'title': '2086'
                  },
                  {
                    'img_url': '2087.jpg',
                    'title': '2087'
                  },
                  {
                    'img_url': '2103.jpg',
                    'title': '2103'
                  },
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6301.jpg',
                    'title': '6301'
                  },
                  {
                    'img_url': '6401.jpg',
                    'title': '6401'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7022-3.png',
                    'title': '7022-3'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  },
                  {
                    'img_url': '7018.jpg',
                    'title': '7018'
                  },
                  {
                    'img_url': '7019.jpg',
                    'title': '7019'
                  },
                  {
                    'img_url': '7038.jpg',
                    'title': '7038'
                  },
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7000.jpg',
                    'title': '7000'
                  },
                  {
                    'img_url': '7003.jpg',
                    'title': '7003'
                  },
                  {
                    'img_url': '7008.jpg',
                    'title': '7008'
                  },
                  {
                    'img_url': '7020.jpg',
                    'title': '7020'
                  },
                  {
                    'img_url': '7026.jpg',
                    'title': '7026'
                  },
                  {
                    'img_url': '7034.jpg',
                    'title': '7034'
                  },
                  {
                    'img_url': '7050.jpg',
                    'title': '7050'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'modern': [
              {
                'possibilities': [
                  {
                    'img_url': '2049.jpg',
                    'title': '2049'
                  },
                  {
                    'img_url': '2060.jpg',
                    'title': '2060'
                  },
                  {
                    'img_url': '2062.jpg',
                    'title': '2062'
                  },
                  {
                    'img_url': '2081.jpg',
                    'title': '2081'
                  },
                  {
                    'img_url': '2090.jpg',
                    'title': '2090'
                  },
                  {
                    'img_url': '2115.jpg',
                    'title': '2115'
                  },
                  {
                    'img_url': '2120.jpg',
                    'title': '2120'
                  },
                  {
                    'img_url': '2132.jpg',
                    'title': '2132'
                  },
                  {
                    'img_url': '2142.jpg',
                    'title': '2142'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3017.jpg',
                    'title': '3017'
                  },
                  {
                    'img_url': '3053.jpg',
                    'title': '3053'
                  },
                  {
                    'img_url': '3055.jpg',
                    'title': '3055'
                  },
                  {
                    'img_url': '3141.jpg',
                    'title': '3141'
                  },
                  {
                    'img_url': '3156.jpg',
                    'title': '3156'
                  },
                  {
                    'img_url': '3158.jpg',
                    'title': '3158'
                  },
                  {
                    'img_url': '3162.jpg',
                    'title': '3162'
                  },
                  {
                    'img_url': '3177.jpg',
                    'title': '3177'
                  },
                  {
                    'img_url': '3301.jpg',
                    'title': '3301'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4046.jpg',
                    'title': '4046'
                  },
                  {
                    'img_url': '4058.jpg',
                    'title': '4058'
                  },
                  {
                    'img_url': '4066.jpg',
                    'title': '4066'
                  },
                  {
                    'img_url': '4100.jpg',
                    'title': '4100'
                  },
                  {
                    'img_url': '4103.jpg',
                    'title': '4103'
                  },
                  {
                    'img_url': '4106.jpg',
                    'title': '4106'
                  },
                  {
                    'img_url': '4107.jpg',
                    'title': '4107'
                  },
                  {
                    'img_url': '4112.jpg',
                    'title': '4112'
                  },
                  {
                    'img_url': '4523.jpg',
                    'title': '4523'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  },
                  {
                    'img_url': '2086.jpg',
                    'title': '2086'
                  },
                  {
                    'img_url': '2087.jpg',
                    'title': '2087'
                  },
                  {
                    'img_url': '2103.jpg',
                    'title': '2103'
                  },
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6301.jpg',
                    'title': '6301'
                  },
                  {
                    'img_url': '6401.jpg',
                    'title': '6401'
                  }
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7022-2.png',
                    'title': '7022-2'
                  },
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7023-5.png',
                    'title': '7023-5'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  },
                  {
                    'img_url': '7018.jpg',
                    'title': '7018'
                  },
                  {
                    'img_url': '7019.jpg',
                    'title': '7019'
                  },
                  {
                    'img_url': '7038.jpg',
                    'title': '7038'
                  },
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7000.jpg',
                    'title': '7000'
                  },
                  {
                    'img_url': '7002.jpg',
                    'title': '7002'
                  },
                  {
                    'img_url': '7007.jpg',
                    'title': '7007'
                  },
                  {
                    'img_url': '7008.jpg',
                    'title': '7008'
                  },
                  {
                    'img_url': '7040.jpg',
                    'title': '7040'
                  },
                  {
                    'img_url': '7050.jpg',
                    'title': '7050'
                  },
                  {
                    'img_url': '7053.jpg',
                    'title': '7053'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ],
            'skandinavisch': [
              {
                'possibilities': [
                  {
                    'img_url': '2004.jpg',
                    'title': '2004'
                  },
                  {
                    'img_url': '2005.jpg',
                    'title': '2005'
                  },
                  {
                    'img_url': '2008.jpg',
                    'title': '2008'
                  },
                  {
                    'img_url': '2063.jpg',
                    'title': '2063'
                  },
                  {
                    'img_url': '2090.jpg',
                    'title': '2090'
                  },
                  {
                    'img_url': '2094.jpg',
                    'title': '2094'
                  },
                  {
                    'img_url': '2121.jpg',
                    'title': '2121'
                  },
                  {
                    'img_url': '2130.jpg',
                    'title': '2130'
                  },
                  {
                    'img_url': '2138.jpg',
                    'title': '2138'
                  }
                ],
                'type': 'Schlafzimmer',
                'folder': 'bedroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '3003.jpg',
                    'title': '3003'
                  },
                  {
                    'img_url': '3041.jpg',
                    'title': '3041'
                  },
                  {
                    'img_url': '3048.jpg',
                    'title': '3048'
                  },
                  {
                    'img_url': '3150.jpg',
                    'title': '3150'
                  },
                  {
                    'img_url': '3153.jpg',
                    'title': '3153'
                  },
                  {
                    'img_url': '3158.jpg',
                    'title': '3158'
                  },
                  {
                    'img_url': '3167.jpg',
                    'title': '3167'
                  },
                  {
                    'img_url': '3174.jpg',
                    'title': '3174'
                  },
                  {
                    'img_url': '3401.jpg',
                    'title': '3401'
                  }
                ],
                'type': 'Wohnzimmer',
                'folder': 'livingroom'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4028.jpg',
                    'title': '4028'
                  },
                  {
                    'img_url': '4030.jpg',
                    'title': '4030'
                  },
                  {
                    'img_url': '4058.jpg',
                    'title': '4058'
                  },
                  {
                    'img_url': '4107.jpg',
                    'title': '4107'
                  },
                  {
                    'img_url': '4109.jpg',
                    'title': '4109'
                  },
                  {
                    'img_url': '4118.jpg',
                    'title': '4118'
                  },
                  {
                    'img_url': '4122.jpg',
                    'title': '4122'
                  },
                  {
                    'img_url': '4301.jpg',
                    'title': '4301'
                  },
                  {
                    'img_url': '4401.jpg',
                    'title': '4401'
                  }
                ],
                'type': 'Esszimmer',
                'folder': 'kitchen'
              },
              {
                'possibilities': [
                  {
                    'img_url': '2027.jpg',
                    'title': '2027'
                  },
                  {
                    'img_url': '2054.jpg',
                    'title': '2054'
                  },
                  {
                    'img_url': '2074.jpg',
                    'title': '2074'
                  },
                  {
                    'img_url': '2086.jpg',
                    'title': '2086'
                  },
                  {
                    'img_url': '2087.jpg',
                    'title': '2087'
                  },
                  {
                    'img_url': '2103.jpg',
                    'title': '2103'
                  },
                  {
                    'img_url': '2124.jpg',
                    'title': '2124'
                  },
                  {
                    'img_url': '2126.jpg',
                    'title': '2126'
                  },
                  {
                    'img_url': '8305.jpg',
                    'title': '8305'
                  }
                ],
                'type': 'Kinderzimmer',
                'folder': 'nursery'
              },
              {
                'possibilities': [
                  {
                    'img_url': '4601.jpg',
                    'title': '4601'
                  },
                  {
                    'img_url': '6000.jpg',
                    'title': '6000'
                  },
                  {
                    'img_url': '6007.jpg',
                    'title': '6007'
                  },
                  {
                    'img_url': '6020.jpg',
                    'title': '6020'
                  },
                  {
                    'img_url': '6021.jpg',
                    'title': '6021'
                  },
                  {
                    'img_url': '6301.jpg',
                    'title': '6301'
                  },
                ],
                'type': 'Büro',
                'folder': 'office'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodendielen alt.jpg',
                    'title': 'Bodendielen alt'
                  },
                  {
                    'img_url': 'Bodendielen neu.jpg',
                    'title': 'Bodendielen neu'
                  },
                  {
                    'img_url': 'Parkett Fischgraet.jpg',
                    'title': 'Parkett Fischgrät'
                  },
                  {
                    'img_url': 'Parkett Matt geraeuchert.jpg',
                    'title': 'Parkett Matt geräuchert'
                  },
                  {
                    'img_url': 'Parkett Mosaik Wuerfel.jpg',
                    'title': 'Parkett Mosaik Würfel'
                  },
                  {
                    'img_url': 'Parkett Natur_1.jpg',
                    'title': 'Parkett Natur 1'
                  },
                  {
                    'img_url': 'Parkett Natur_2.jpg',
                    'title': 'Parkett Natur 2'
                  },
                  {
                    'img_url': 'Parkett Schiffsboden.jpg',
                    'title': 'Parkett Schiffsboden'
                  },
                  {
                    'img_url': 'Parkett_Chevron.jpg',
                    'title': 'Parkett Chevron'
                  }
                ],
                'type': 'Holzböden',
                'folder': 'wooden_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': 'Bodenfliese antrazit.jpg',
                    'title': 'Bodenfliese antrazit'
                  },
                  {
                    'img_url': 'Bodenfliese Beton.jpg',
                    'title': 'Bodenfliese Beton'
                  },
                  {
                    'img_url': 'Bodenfliese grau.jpg',
                    'title': 'Bodenfliese grau'
                  },
                  {
                    'img_url': 'Bodenfliese Marmor.jpg',
                    'title': 'Bodenfliese Marmor'
                  },
                  {
                    'img_url': 'Bodenfliese Travertino.jpg',
                    'title': 'Bodenfliese Travertino'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_1 grossformatik.jpg',
                    'title': 'Fliese Betonoptik 1 großformatik'
                  },
                  {
                    'img_url': 'Fliese Betonoptik_2.jpg',
                    'title': 'Fliese Betonoptik 2'
                  },
                  {
                    'img_url': 'Fliese schwarz.jpg',
                    'title': 'Fliese schwarz'
                  },
                  {
                    'img_url': 'Fliese weiss.jpg',
                    'title': 'Fliese weiß'
                  }
                ],
                'type': 'Fliesenböden',
                'folder': 'tiled_floors'
              },
              {
                'possibilities': [
                  {
                    'img_url': '9004a.jpg',
                    'title': '9004a'
                  },
                  {
                    'img_url': '9004b.jpg',
                    'title': '9004b'
                  },
                  {
                    'img_url': '9004c.jpg',
                    'title': '9004c'
                  },
                  {
                    'img_url': '9004d.jpg',
                    'title': '9004d'
                  }
                ],
                'type': 'Dekoration',
                'folder': 'decoration'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7023-4.png',
                    'title': '7023-4'
                  },
                  {
                    'img_url': '7027-6.png',
                    'title': '7027-6'
                  },
                  {
                    'img_url': '7028-2.png',
                    'title': '7028-2'
                  },
                  {
                    'img_url': '7043-b.png',
                    'title': '7043-b'
                  },
                  {
                    'img_url': '7043-f.png',
                    'title': '7043-f'
                  },
                  {
                    'img_url': '7059-1.png',
                    'title': '7059-1'
                  },
                  {
                    'img_url': '7059-2.png',
                    'title': '7059-2'
                  },
                  {
                    'img_url': '7059-3.png',
                    'title': '7059-3'
                  },
                  {
                    'img_url': '7059-4.png',
                    'title': '7059-4'
                  }
                ],
                'type': 'Blumen',
                'folder': 'flowers'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7005.jpg',
                    'title': '7005'
                  },
                  {
                    'img_url': '7006.jpg',
                    'title': '7006'
                  },
                  {
                    'img_url': '7012.jpg',
                    'title': '7012'
                  },
                  {
                    'img_url': '7018.jpg',
                    'title': '7018'
                  },
                  {
                    'img_url': '7019.jpg',
                    'title': '7019'
                  },
                  {
                    'img_url': '7038.jpg',
                    'title': '7038'
                  },
                  {
                    'img_url': '7052.jpg',
                    'title': '7052'
                  },
                  {
                    'img_url': '7055.jpg',
                    'title': '7055'
                  },
                  {
                    'img_url': '7106.jpg',
                    'title': '7106'
                  }
                ],
                'type': 'Essbereiche außen',
                'folder': 'dining_areas_outside'
              },
              {
                'possibilities': [
                  {
                    'img_url': '7003.jpg',
                    'title': '7003'
                  },
                  {
                    'img_url': '7020.jpg',
                    'title': '7020'
                  },
                  {
                    'img_url': '7034.jpg',
                    'title': '7034'
                  },
                  {
                    'img_url': '7040.jpg',
                    'title': '7040'
                  },
                  {
                    'img_url': '7050.jpg',
                    'title': '7050'
                  },
                  {
                    'img_url': '7053.jpg',
                    'title': '7053'
                  },
                  {
                    'img_url': '7101.jpg',
                    'title': '7101'
                  },
                  {
                    'img_url': '7102.jpg',
                    'title': '7102'
                  },
                  {
                    'img_url': '7104.jpg',
                    'title': '7104'
                  }
                ],
                'type': 'Sitzbereiche außen',
                'folder': 'sitting_corners_outside'
              }
            ]
          }
        },
        'catalog_price': [
          'vstaging_360_standard_price',
          'vstaging_360_basic_price',
          'vstaging_360_erweitert_price'
        ],
        'description_placeholder': 'zB.: Wohnung bitte im skandinavischen Stil einrichten',
        'details': [
          {
            'label': 'Einrichtungsstil',
            'value': 'Sie können zwischen 3 vorgefertigten Stilen unterscheiden oder anhand unseres Stilkataloges individuell Möbel auswählen. Die Nummern der Einrichtungsgegenstände, können Sie als Formulierungshilfe nutzten. Katalog: @https://www.virtualfurniture.io/?c=WQVyYdmwbe'
          },
          {
            'label': 'Digitales Staging inkl. Möbel entfernen',
            'value': 'Das bloße digitale Stagen ist nur möglich, wenn die Räume von Möbeln befreit sind. Sollten sich Möbel in den Bildern befinden, wird eine Gebühr von 12,90€ je Bild berechnet um diese zu beseitigen. Dies gilt bis maximal 4 Möbelstücken. Bei einer höheren Anzahl an Möbeln muss der Raum renoviert werden, da sonst die Qualität des Stagings nicht gewährleistet werden kann. Beispiele: @https://www.imogent.de/produkt-digitales-Staging'
          },
          {
            'label': 'Digitales Staging inkl. Renovierung',
            'value': 'Bei der digitalen Renovierung werden Böden, Wände, Decken, Fenster usw. digital aufgewertet. Bestehende Möbel werden entfernt und durch neue ersetzt. Beispiele: @https://www.imogent.de/produkt-digitales-Staging'
          },
          {
            'label': 'Wände versetzen',
            'value': 'Bei der digitalen Renovierung können Durchbrüche oder Wände neu eingezogen werden. Die Änderungen müssen hierzu nachvollziehbar in die Grundrissen oder Fotos eingezeichnet werden. Geben Sie hier nur die Anzahl der Bilder an, die davon betroffen sind. Beispiele: @https://www.imogent.de/produkt-digitales-Staging'
          },
          {
            'label': 'Kommentar',
            'value': 'Beschreiben Sie in dem Kommentarfeld, welche Besonderheiten bei dem digitalen Staging beachtet werden sollen und beziehen Sie sich dabei auf den Namen der Datei.'
          },
          {
            'label': 'Fotos',
            'value': 'Benennen Sie die Fotos mit der Raumbezeichnung und beziehen Sie sich in dem Kommentarfeld wiederum auf die Fotos.'
          },
          {
            'label': 'Virtuelle Tour',
            'value': 'Bei der virtuellen Tour werden die 360°-Fotos zu einer Tour zusammengesetzt. Die Tour wird von IMOGENT gehostet und als Link zur Verfügung gestellt. Die Einzelfotos erhalten Sie separat.'
          },
          {
            'label': 'Virtuelle Tour (Einzelspots)',
            'value': 'Bei der virtuellen Tour (Einzelspots) werden einzelne 360°-Fotos zu einer Tour zusammengesetzt. Die Tour wird von IMOGENT gehostet und als Link zur Verfügung gestellt. Die Einzelfotos erhalten Sie separat.'
          },
          {
            'label': 'Grundrisse',
            'value': 'Zur Orientierung in der virtuellen Tour können Grundrisse eingearbeitet werden. Die Standorte der 360° Spots werden durch Punkte im Grundriss kenntlich gemacht.<br/>Das Einbinden der Grundrisse erzeugt keine Mehrkosten,  lediglich die Aufarbeitung der Grundrisse wird mit 29€ je Grundriss berechnet. Bei mehreren Etagen bitte die Anzahl an Grundrissen angeben und entsprechend die Grundrisse hochladen.'
          }
        ],
        'disabled': false,
        'extra_feedback_price': 'vstaging_360_extra_feedback',
        'feedbacks_included': 2,
        'fp_price': 'vstaging_360_fp',
        'furniture_price': 'vstaging_360_furniture',
        'helperTexts': [
          {
            'text': 'Hier haben Sie die Möglichkeit Unterlagen, die bei dem Virtuellen Staging helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.',
            'title': 'Welche Unterlagen werden benötigt?'
          },
          {
            'text': 'Bitte wählen Sie die Einrichtungsgegenstände für Ihre hochgeladenen Räume aus. Sie können zu jedem Einrichtungsgegenstand einen Kommentar verfassen und Ihre Bestellung so auf Ihre Bedürfnisse modifizieren. Sollten Sie Räume kombinieren wollen, können Sie die Nummernkennung der Einrichtungsgegenstände nutzen und in dem Kommentar darauf referenzieren.',
            'title': 'Was meinen wir mit modifizieren?'
          }
        ],
        'isVirtualTour': true,
        'name': '360° Bilder',  // cannot change this string from '360° Bilder' to '360°-Bilder' because its being used as a reference in dependentField property under the language.ts file. If we need to change this in the future, we should make a refactor on that dependentField usage also (have in mind legacy orders with legacy name).
        'newProperty': false,
        'numberOfDrafts': 0,
        'package_contents': [
          'Virtuelles Einrichten',
          'moderne Accessoires',
          'Web optimiert und hochauflösend'
        ],
        'package_images': [
          'digital_staging/skandinavischer.jpg',
          'digital_staging/modern.jpg',
          'digital_staging/retro.jpg'
        ],
        'package_key': '9',
        // 'photo_price': '129,00',
        'physicalProduct': false,
        'price': 'vstaging_360_price',
        'price_individual_tour': 'vstaging_360_individual_tour',
        'price_note': 'pro Bild (Preis exkl. MwSt.)',
        'price_pre_text': 'ab',
        'price_virtual_tour': 'vstaging_360_virtual_tour',
        'renovation_price': 'vstaging_360_renovation',
        'service_key': 'vstaging',
        'service_title': 'Digitales Staging',
        'styles': [
          {
            'styleImage': '../../assets/images/digital_staging/skandinavischer.jpg',
            'folder': '../../assets/images/digital_staging/skandinavisch/',
            'styleImages': [
              'skandinavisch1.jpg',
              'skandinavisch2.jpg',
              'skandinavisch3.jpg',
              'skandinavisch4.jpg',
              'skandinavisch5.jpg'
            ],
            'styleLink': 'Skandinavischer Stilkatalog',
            'styleName': 'Skandinavisch',
            'selector': 'skandinavisch',
            'styleUrl': 'https://www.virtualfurniture.io/styles/14?c=gKRyevVjxW'
          },
          {
            'styleImage': '../../assets/images/digital_staging/modern.jpg',
            'folder': '../../assets/images/digital_staging/modern/',
            'styleImages': [
              'modern1.jpg',
              'modern2.jpg',
              'modern3.jpg',
              'modern4.jpg',
              'modern5.jpg'
            ],
            'styleLink': 'Moderner Stilkatalog',
            'styleName': 'Modern',
            'selector': 'modern',
            'styleUrl': 'https://www.virtualfurniture.io/styles/16?c=gKRyevVjxW'
          },
          {
            'styleImage': '../../assets/images/digital_staging/luxus.jpg',
            'folder': '../../assets/images/digital_staging/luxus/',
            'styleImages': [
              'luxus1.jpg',
              'luxus2.jpg',
              'luxus3.jpg',
              'luxus4.jpg',
              'luxus5.jpg',
            ],
            'styleLink': 'Luxuriöser Stilkatalog',
            'styleName': 'Luxuriös',
            'selector': 'luxus',
            'styleUrl': 'https://www.virtualfurniture.io/styles/15?c=gKRyevVjxW'
          },
        ],
        'title': 'Digitales Staging',
        'wall_move_price': 'vstaging_360_wall_move'
      }
    ]
  },
  {
    "id": "documents_procurement",
    "packages": [
      {
        "description_placeholder": "My starter package description",
        "details": [],
        "disabled": false,
        "extra_feedback_price": "",
        "feedbacks_included": -1,
        "name": "6 Auskünfte",
        "newProperty": false,
        "numberOfDrafts": 0,
        "package_contents": [],
        "package_images": [
          "documents_procurement/starter.jpg"
        ],
        "package_key": "28",
        "physicalProduct": false,
        "price": "documents_procurement_starter_price",
        "price_note": "Preise für die Servicegebühr. Hinzu kommen die Behördenauslagen",
        "price_pre_text": "ab",
        "priceAddUnits": "documents_procurement_addUnits",
        "service_key": "documents_procurement",
        "service_title": "Behördenauskünfte",
        "title": "Behördenauskünfte",
        "maxDocumentsLimit": 6,
        "docEstateProductList": [
          {
              "id": 101,
              "nameShort": "grunde",
              "name_long": "Grundbuchauszug",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Grundbuchauszug (einfacher Ausdruck)</b> <br>
                <br>
                Das Grundbuch ist ein öffentliches Register (erstellt vom Staat), in dem die Eigentumsverhältnisse von Grundstücken verbindlich geregelt sind. Das Grundbuch nennt den oder die jeweiligen Besitzer und enthält alle wesentlichen Inhalte zu Rechten und Lasten des Grundstücks. Das Grundbuch ist wie folgt aufgebaut:
                <br><br>
                <b>Bestandsverzeichnis:</b><br><br>
                Das Bestandsverzeichnis führt alle dem Grundbuchblatt zugehörigen Grundstücke und deren Lage (Gemarkung, Flur, Flurstück, Adresse, Größe des Grundstücks) auf. Darüber hinaus werden im Bestandsverzeichnis Rechte eingetragen, die ein im Grundbuch genanntes Flurstück berechtigen. Diese Eintragungen werden auch als „Herrschvermerk“ bezeichnet. Belastungen einzelner oder mehrere Flurstücke werden nicht im Bestandsverzeichnis gelistet.
                <br><br>
                <b>Abteilung I:</b><br><br>
                In Abteilung I sind sämtliche Eigentümer sowie die dinglich-rechtliche Grundlage des Erwerbes genannt.
                <br><br>
                <b>Abteilung II: </b><br><br>
                Abteilung II listet alle dinglichen Rechte, wie z.B. Nießbrauch, Reallasten, Dienstbarkeiten, etc. Hiervon ausgenommen sind Grundpfandrechte, welche sich in Abteilung III wiederfinden.
                <br><br>
                <b>Abteilung III:</b><br><br>
                In Abteilung III sind sämtliche Grundpfandrechte (Grundschuld, Hypothek, Rentenschuld) gelistet.
              `
          },
          {
              "id": 102,
              "nameShort": "altlas",
              "name_long": "Altlastenauskunft",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Auskunft aus dem Altlastenkataster (Altlastenauskunft)</b><br><br>
                Wurden an einem Grundstück Altlasten identifiziert werden diese in dem zuständigen Register, auch als Altlastenkataster bezeichnet, aufgeführt. Altlasten stellen dabei Verunreinigungen der Flächen durch Gefahrenstoffe dar, wodurch Boden und Grundwasser nicht mehr der gesetzlichen Qualität entsprechen. Gründe hierfür können die nicht sachgemäße Behandlung, Lagerung oder Ablagerung von Abfällen sowie der unsachgemäße Umgang mit umweltgefährdenden Stoffen sein.<br><br>
                Insbesondere die finanzielle Belastung, die im Zusammenhang mit der Aufbereitung kontaminierter Flächen entstehen, sorgen für die dringende Notwendigkeit einer Altlastenauskunft vor jedem Grundstücks-/ Immobilienerwerb, um notwendige Maßnahmen in der Wirtschaftlichkeitsanalyse berücksichtigen zu können. Sofern keine Altlasten für das Grundstück vorhanden sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 103,
              "nameShort": "baulas",
              "name_long": "Baulastenauskunft",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Auszug aus dem Baulastenverzeichnis (Baulastenauskunft)</b><br><br>
                Bei einer Baulast verpflichtet sich der Grundstückseigentümer gegenüber der Baubehörde gewisse Pflichten im Zusammenhang mit dem Grundstück einzuhalten, welche allgemein als „Baulasten“ bezeichnet werden. Diese öffentlich-rechtlichen Verpflichtungen bedeuten i.d.R. Einschränkungen für den Grundstückseigentümer, d.h. etwas zu tun, zu unterlassen oder zu dulden. Eine Baulast wird u.a. dann notwendig, wenn ein Bauvorhaben nicht baurechtskonform erstellt werden kann (z.B. Einhaltung der Grenzabstände) und angrenzende Grundstücke für den Genehmigungsprozess herangezogen werden müssen.
                <br><br>Das Eigentum an einem Grundstück kann daher sowohl durch Rechte privater Personen als auch durch die Baubehörde eingeschränkt sein. <br><br>
                Baulasten werden im Baulastverzeichnis des jeweiligen Amtes geführt und dienen als ergänzende Auskunft zu den Lasten im Grundbuch, welches die Baulasten i.d.R. nicht beinhaltet. Ausnahmen bilden die Länder Bayern und Brandenburg. Hier erfolgt die Sicherung baurechtskonformer Zustände ausschließlich im Grundbuch. Durch die Baulastenauskunft wissen Sie welche Baulasten in der Vergangenheit eingetragen wurden. Sofern keine Baulasten für das Grundstück eintragen sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 104,
              "nameShort": "liegen",
              "name_long": "Liegenschaftskarte",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Liegenschaftskarte (Flurkarte)</b>
                <br><br>
                Die Liegenschaftskarte hat mehrere Synonyme. Die Begriffe Flurkarte und Katasterkarte werden analog für sie verwendet. Sie stellt eine wichtige Grundlage dar, indem sie einen Überblick über das Grundstück verschafft sowie Lage und Grenzen der Grundstücke nachweisbar darstellt und somit die amtliche Grundlage für das Grundbuch bildet. <br>
                <br>

                Auf der Liegenschaftskarte werden die gesamten Liegenschaften (Flurstücke, Grundstücke und Gebäude) eines bestimmten Bereiches maßstabgetreu abgebildet. Die Standard-Maßstäbe der Liegenschaftskarte variieren zwischen 1:500, 1:1000 und 1:2000. <br>
                <br>

                Im Detail umfasst die Liegenschaftskarte folgende Inhalte: <br>
                <br>

                Sie ist ein wichtiges Dokument und wird häufig im Zusammenhang mit Planungen, Finanzierung oder Bewertung von Grundstücken bzw. Immobilien gefordert. <br>
                <br>

                <u>Achtung:</u> Die (amtliche) Liegenschaftskarte ist dabei nicht mit dem amtlichen Lageplan zu verwechseln
              `
          },
          {
              "id": 105,
              "nameShort": "flurstb",
              "name_long": "Bestandsnachweis",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Bestandsnachweis (Auszug aus dem Liegenschaftskataster)</b><br>
                <br>
                Der Bestandsnachweis ist ein Auszug aus dem Liegenschaftskataster (früher auch Liegenschaftsbuch). Dieser enthält umfangreiche Informationen zur Liegenschaft eines Eigentümers (z.B. Detaillierte Angaben aller Flurstücke eines Grundbuchblattes + Größe, Art und Nutzung des Grundstücks zzgl. Eigentümerangaben. Der Bestandsnachweis ist somit analog zum „Flurstücks- und Eigentümernachweis“ anzusehen mit dem Unterschied, dass der Bestandsnachweis alle vorhandenen Flurstücke auflistet und der Flurstücks- und Eigentümernachweis lediglich ein spezifisches Flurstück beschreibt.
              `
          },
          {
              "id": 106,
              "nameShort": "flursta",
              "name_long": "Flurstücks und Eigentümernachweis",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Flurstücks- und Eigentümernachweis (Auszug aus dem Liegenschaftskataster)</b>
                Der Flurstücks- und Eigentumsnachweis ist ein Auszug aus dem Liegenschaftskataster (früher auch Liegenschaftsbuch) und beinhaltet detaillierte Informationen zum abgefragten Flurstück. Diese Informationen umfassen u.a. Gebietszugehörigkeit, Lage, Fläche, Tatsächliche Nutzung, Buchungsart, Buchung sowie Eigentümerangaben eines Flurstücks.
              `
          },
          {
              "id": 107,
              "nameShort": "denkma",
              "name_long": "Gebäude- und Bodendenkmalschutz",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Denkmalschutzauskunft</b> <br> <br>
                Im allgemeinen Sprachgebrauch wird unter dem „Denkmalschutz“ ein „Baudenkmal“ verstanden. Baudenkmäler werden als solche Immobilien definiert, an denen zur Nutzung und Erhaltung ein öffentliches Interesse besteht. Besteht ein solches Interesse und wurde die Immobilie als Denkmal eingestuft wird es in die Denkmalliste der Gemeinde bzw. Stadt eingetragen. Mit einer positiven Eintragung in die Denkmalliste haben Eigentümer die Verpflichtung, das Denkmal instand zu setzen sowie instand zu halten, Gefährdungen abzuwenden und sachgemäß zu behandeln. Vor der Durchführung von baulichen Maßnahmen am Denkmal muss zunächst eine entsprechende Genehmigung von der Denkmalbehörde eingeholt werden. Ohne diese Genehmigung ist abschlagen, abtragen, zerlegen oder ein Abbruch nicht realisierbar. Aber auch Änderungen von bspw. Fenstern, Fassade oder die Durchführung eines Teilabbruchs etc. erfordern eine zusätzliche Genehmigung.
                Die Denkmalschutzauskunft informiert also über die Existenz eines entsprechenden Baudenkmals (teilweise enthält Sie gleichzeitig Informationen ob ein Bodendenkmal besteht) des jeweiligen Gebäudes und sollte in jedem Fall vor einer Transaktion eingeholt werden, um Einschränkungen und Auflagen, die in Verbindung mit dem Denkmalschutz bestehen in dem Vorhaben berücksichtigen zu können. Sofern kein Denkmalschutz besteht wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 108,
              "nameShort": "planun",
              "name_long": "Satzungs- und Gebietsauskünfte",
              "name_show": null,
              "price": "39.95",
              "description": `
                <b>Satzungs- und Gebietsauskunft (Planungsrechtauskunft):</b>&lt;<br>
                Eine Planungsrechtauskunft kann prinzipiell sehr individuelle Informationen beinhalten, in Abhängigkeit von dem jeweiligen Vorhaben des Antragstellers. Da insbesondere im Rahmen von Transaktionen, Bewertungen und Baumaßnahmen sämtliche Informationen relevant sein können, ist die Planungsrechtsabfrage von DocEstate breit aufgestellt und umfasst folgende Themen:
                <br><br>
                    <li>Umlegungsgebiet</li>
                    <li>Erhaltungssatzungen</li>
                    <li>Vereinfachte Umlegung</li>
                    <li>Gestaltungssatzungen</li>
                    <li>Veränderungssperre</li>
                    <li>Flurbereinigungsgebiet</li>
                    <li>Städtebauliche Sanierungsmaßnahmen</li>
                    <li>Städtebauliche Entwicklungsmaßnahme</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten städtebaulichen Sanierungsmaßnahme</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten Städtebauliche Entwicklungsmaßnahme</li>
                <br><br>Liegt das Grundstück in einem der aufgezählten Gebiete können für den Grundstückeigentümer zusätzliche Kosten, in relevanter Höhe entstehen.
              `
          },
          {
              "id": 109,
              "nameShort": "erschl",
              "name_long": "Erschließungsbeitragsbescheinigung",
              "name_show": "Erschließungsbeitrags- bescheinigung",
              "price": "29.95",
              "description": `
                <b>Erschließungskostenbescheinigung (Anliegerbescheinigung)</b><br><br>
                Die Erschließungskosten beziehen sich auf die Gebühren, die bei der Stadt im Zusammenhang mit der Erschließung anfallen und die Kosten sind bis zu 90% durch den Eigentümer/ Erbbauberechtigten zu tragen. Verantwortlich für die Begleichung der Erschließungskosten ist der aktuelle Eigentümer/ Erbbauberechtigte. Da Erschließungskosten auch noch nach langjähriger Teilerschließung anfallen können, da bspw. Straßenbeleuchtung, Parkbuchten, Grünanlagen etc. erst später fertiggestellt wurden und der Gemeinde ein mehrjähriges Zeitfenster bis zur Verjährung zur Verfügung steht, können Erschießungskosten auch noch nach der Grundstückserschließung auftreten.
                <br><br>
                In jedem Fall ist der zum Erhebungszeitpunkt rechtmäßige Eigentümer/ Erbbauberechtigte der Kostenträger, sodass zur Vermeidung von unvorhergesehenen Kosten sowie Rechtsstreitigkeiten eine Auskunft über den Zahlungsstand der Erschließungskosten dringend zu empfehlen ist. Die Erschließungsbeitragsbescheinigung (auch als Anliegerbescheinigung bezeichnet) umfasst standardmäßig die Beiträge für die Straßenerschließung. Abhängig nach Kommune und Grundstück werden teilweise auch Kanalanschluss- / herstellbeiträge und andere Erschließungsgebühren mit in die Auskunft aufgenommen. Sofern keine Erschließungsbeiträge mehr offen sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 110,
              "nameShort": "städte",
              "name_long": "Städtebaulicher Vertrag",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Auskunft über Städtebauliche Verträge / Erschließungsverträge:</b><br><br>
                Wenn es um städtebauliche Projekte (Bauvorhaben) geht, regeln Städtebauliche Verträge bzw. ein Erschließungsvertrag, welche Rechte und Pflichten „ zumeist ein privater Investor “ als eine Vertragspartei sowie die Gemeinde als zweite Vertragspartei hat. Der Inhalt eines solchen Vertrages wird im BauGB nicht exakt definiert. Ausschlaggebend ist, dass sich der Inhalt auf das Städtebaurecht bezieht. Außerdem bezieht sich die Rechtsprechung nur dann auf einen städtebaulichen Vertrag, wenn die Vertragspartei die Gemeinde ist. Mit dem städtebaulichen Vertrag kann also neben der Übertragung von organisatorischen Aufgaben auch die Kostenübernahme vereinbart werden. Beispielsweise kann die Privatperson, Kosten für bestimmte Städteprojekte übernehmen und im Gegenzug schafft die Kommune das notwendige Baurecht für die Aufstellung eines B-Plans, um das Vorhaben des Eigentümers umzusetzen.
                <br>Die Auskunft über bestehende städtebauliche Verträge informiert demnach über den Stand öffentlich-rechtlicher Verpflichtungen in Form von operativen Aufgaben oder der zusammenhängenden Kostenübernahmepflicht.  Sofern keine Städtebauliche Verträge oder Verpflichtung bestehen wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 111,
              "nameShort": "bebauu",
              "name_long": "Bebauungsplan",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Bebauungsplan (Kopie bzw. Negativattest):</b>
                <br><br>
                Der Bebauungsplan (kurz „B-Plan“) stellt eine rechtsverbindliche Satzung dar und wird durch die Gemeinde beschlossen. Der B-Plan baut auf dem übergeordneten Flächennutzungsplan auf. Sinn und Zweck ist die Festlegung der Art und Weise der möglichen Bebauung von Grundstücken. Der B-Plan stellt somit ein essenzielles Dokument für sämtliche Bau- und Umbaumaßnahmen oder auch für eine Prüfung einer potenziellen Projektentwicklung dar. Es gibt jedoch auch Fälle in denen kein B-Plan für ein Grundstück existiert. In diesem Fall ist eine Negativauskunft wichtig, da somit die Sonderreglungen des Baugesetzbuches in Kraft treten. Mit der Auskunft von DocEstate wird grundsätzlich die Kopie des B-Plans angefordert und sofern kein B-Plan vorhanden ist eine Auskunft darüber wie mit dem Grundstück verfahren wird.
              `
          },
          {
              "id": 112,
              "nameShort": "fläche",
              "name_long": "Flächennutzungsplan",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Flächennutzungsplan</b>
                <br><br>
                Der Flächennutzungsplan (kurz FNP) ist der „vorbereitendende Bauleitplan“ und ein Planungsinstrument (Planzeichnung mit Begründung) der öffentlichen Verwaltung im System der Raumordnung der Bundesrepublik Deutschland, mit dem die städtebauliche Entwicklung der Gemeinden gesteuert werden soll. Der Flächennutzungsplan ist jedoch nur als übergeordnete städtebauliche Orientierung anzusehen, da dieser im Gegensatz zum Bebauungsplan keinen rechtsverbindlichen Charakter ausübt. Er dient lediglich als Basis für den rechtsverbindlichen Bebauungsplan.
              `
          },
          {
              "id": 113,
              "nameShort": "besche",
              "name_long": "Städtisches Vorkaufsrecht",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Vorkaufsrecht (Verzichtserklärung / Negativerklärung)</b><br><br>
                Gemäß der §§ 24, 25 BauGB besitzen Gemeinden ein Vorkaufsrecht bei Grundstückstransaktionen. Demzufolge obliegt der Stadt die Möglichkeit in die Vereinbarungen zwischen dem Veräußerer und Kaufinteressenten einzutreten. Um Kosten die mit einer Grundstückstransaktion in Verbindung stehen zu vermeiden, sollte zunächst eine Verzichtserklärung bzw. eine Negativerklärung über das Vorkaufsrecht bei der jeweiligen Behörde beantragt werden. Denn wurden bereits Aufwendungen im Zusammenhang mit der Grundstückstransaktion aufgebracht und übt die Gemeinde das Vorkaufsrecht aus, werden die bereits getätigten Aufwendungen i.d.R. weder durch den Veräußerer noch durch die Gemeinde erstattet.
              `
          },
          {
              "id": 114,
              "nameShort": "kampfm",
              "name_long": "Kampfmittelauskunft",
              "name_show": null,
              "price": "34.95",
              "description": `
                Kampfmittelauskunft (Luftbildauswertung)

                Die Kampfmittelauskunft erfolgt anhand von Luftbildauswertungen und weiterer historischer Dokumente, die Aufschluss darüber geben können ob sich ein Grundstück in einem Verdachtsgebiet von Kampfmitteln befindet. Liegt kein Verdacht vor wird ein Negativattest ausgestellt. Die Beantragung einer Kampfmittelauskunft wird vor dem Grundstückskauf bzw. der Grundstücksprüfung empfohlen, um unter Umstände weitere Maßnahmen durchzuführen.
              `
          },
          {
              "id": 115,
              "nameShort": "bodenr",
              "name_long": "Bodenrichtwertkarte",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Bodenrichtwert(-karte) / Grundstückswert</b><br>
                Der Bodenrichtwert (BRW) ist der durchschnittliche Lagewert für Grund und Boden eines bestimmten Gebietes, die jeweils ähnliche bzw. gleiche Nutzungs- und Wertverhältnisse aufweisen. Basis für die Herleitung des jeweiligen BRW ist die des Gutachterausschusses geführte Kaufpreissammlung. Bei den Bodenrichtwertkarten handelt es sich um spezielle Karten, in denen jeweils die gültigen Bodenrichtwerte je Quadratmeter Grundstücksfläche „ unter Angabe der wertbeeinflussenden Lagemerkmale “ eingetragen werden.
              `
          },
          {
              "id": 116,
              "nameShort": "grunds",
              "name_long": "Grundsteuerbescheid",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Grundsteuerbescheid:</b>
                Der Grundsteuerbescheid weist die monetäre Höhe der Steuerforderung auf. Mit der Einsicht in den Grundsteuerbescheid kann die tatsächliche Höhe der Steuerlast eingesehen werden und somit in der Wirtschaftlichkeitsbetrachtung berücksichtigt werden.
              `
          },
          {
              "id": 117,
              "nameShort": "einhei",
              "name_long": "Einheitswertbescheid",
              "name_show": "",
              "price": "19.95",
              "description": `
                <b>Grundsteuermessbescheid (Einheitswertbescheid):</b><br><br>
                Der Einheitswertbescheid wird durch das Finanzamt ausgestellt und übermittelt den Einheitswert einer Immobilie. Der Bescheid enthält keine Angaben zur Höhe der Steuer und ist dementsprechend nicht mit einem steuerlichen Bescheid vergleichbar. Da der Einheitswertbescheid die Grundlage für die Höhe der Grundsteuer bildet, ist es ratsam im Rahmen einer Transaktion, den aktuellen Bescheid anzufordern. Somit kann sich der potenzielle Erwerber über die zu erwartende Steuerlast informieren.
              `
          },
          {
              "id": 118,
              "nameShort": "teilun",
              "name_long": "Teilungserklärung",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Teilungserklärung:</b> <br>
                Mit der Teilungserklärung wird die formelle Aufteilung eines Gebäudes geregelt. Aus ihr geht die Aufteilung des jeweiligen Sonder- und Gemeinschaftseigentum hervor. Die Aufteilung ist dabei neben Bewertungsaspekten auch für die Kostenverteilung interessant. Entsteht ein Schaden (z.B. am Dach, Ver-/ Entsorgungsleitungen etc.) und ist dieser dem Sondereigentum zuzuordnen, muss der Eigentümer selbst für den Schaden aufkommen. Ist der Schaden jedoch dem Gemeinschaftseigentum zuzuordnen, werden die Kosten auf die Eigentümergemeinschaft verteilt. Des Weiteren listet die Teilungserklärung Rechte und Pflichten der jeweiligen Wohnungseigentümer auf.
              `
          }
        ]
      },
      {
        "description_placeholder": "My default package description",
        "details": [],
        "disabled": false,
        "extra_feedback_price": "",
        "feedbacks_included": -1,
        "name": "9 Auskünfte",
        "newProperty": false,
        "numberOfDrafts": 0,
        "package_contents": [],
        "package_images": [
          "documents_procurement/default.jpg"
        ],
        "package_key": "29",
        "physicalProduct": false,
        "price": "documents_procurement_default_price",
        "price_note": "Preise für die Servicegebühr. Hinzu kommen die Behördenauslagen",
        "price_pre_text": "ab",
        "priceAddUnits": "documents_procurement_addUnits",
        "service_key": "documents_procurement",
        "service_title": "Behördenauskünfte",
        "title": "Behördenauskünfte",
        "maxDocumentsLimit": 9,
        "docEstateProductList": [
          {
              "id": 101,
              "nameShort": "grunde",
              "name_long": "Grundbuchauszug",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Grundbuchauszug (einfacher Ausdruck)</b> <br>
                <br>
                Das Grundbuch ist ein öffentliches Register (erstellt vom Staat), in dem die Eigentumsverhältnisse von Grundstücken verbindlich geregelt sind. Das Grundbuch nennt den oder die jeweiligen Besitzer und enthält alle wesentlichen Inhalte zu Rechten und Lasten des Grundstücks. Das Grundbuch ist wie folgt aufgebaut:
                <br><br>
                <b>Bestandsverzeichnis:</b><br><br>
                Das Bestandsverzeichnis führt alle dem Grundbuchblatt zugehörigen Grundstücke und deren Lage (Gemarkung, Flur, Flurstück, Adresse, Größe des Grundstücks) auf. Darüber hinaus werden im Bestandsverzeichnis Rechte eingetragen, die ein im Grundbuch genanntes Flurstück berechtigen. Diese Eintragungen werden auch als „Herrschvermerk“ bezeichnet. Belastungen einzelner oder mehrere Flurstücke werden nicht im Bestandsverzeichnis gelistet.
                <br><br>
                <b>Abteilung I:</b><br><br>
                In Abteilung I sind sämtliche Eigentümer sowie die dinglich-rechtliche Grundlage des Erwerbes genannt.
                <br><br>
                <b>Abteilung II: </b><br><br>
                Abteilung II listet alle dinglichen Rechte, wie z.B. Nießbrauch, Reallasten, Dienstbarkeiten, etc. Hiervon ausgenommen sind Grundpfandrechte, welche sich in Abteilung III wiederfinden.
                <br><br>
                <b>Abteilung III:</b><br><br>
                In Abteilung III sind sämtliche Grundpfandrechte (Grundschuld, Hypothek, Rentenschuld) gelistet.
              `
          },
          {
              "id": 102,
              "nameShort": "altlas",
              "name_long": "Altlastenauskunft",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Auskunft aus dem Altlastenkataster (Altlastenauskunft)</b><br><br>
                Wurden an einem Grundstück Altlasten identifiziert werden diese in dem zuständigen Register, auch als Altlastenkataster bezeichnet, aufgeführt. Altlasten stellen dabei Verunreinigungen der Flächen durch Gefahrenstoffe dar, wodurch Boden und Grundwasser nicht mehr der gesetzlichen Qualität entsprechen. Gründe hierfür können die nicht sachgemäße Behandlung, Lagerung oder Ablagerung von Abfällen sowie der unsachgemäße Umgang mit umweltgefährdenden Stoffen sein.<br><br>
                Insbesondere die finanzielle Belastung, die im Zusammenhang mit der Aufbereitung kontaminierter Flächen entstehen, sorgen für die dringende Notwendigkeit einer Altlastenauskunft vor jedem Grundstücks-/ Immobilienerwerb, um notwendige Maßnahmen in der Wirtschaftlichkeitsanalyse berücksichtigen zu können. Sofern keine Altlasten für das Grundstück vorhanden sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 103,
              "nameShort": "baulas",
              "name_long": "Baulastenauskunft",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Auszug aus dem Baulastenverzeichnis (Baulastenauskunft)</b><br><br>
                Bei einer Baulast verpflichtet sich der Grundstückseigentümer gegenüber der Baubehörde gewisse Pflichten im Zusammenhang mit dem Grundstück einzuhalten, welche allgemein als „Baulasten“ bezeichnet werden. Diese öffentlich-rechtlichen Verpflichtungen bedeuten i.d.R. Einschränkungen für den Grundstückseigentümer, d.h. etwas zu tun, zu unterlassen oder zu dulden. Eine Baulast wird u.a. dann notwendig, wenn ein Bauvorhaben nicht baurechtskonform erstellt werden kann (z.B. Einhaltung der Grenzabstände) und angrenzende Grundstücke für den Genehmigungsprozess herangezogen werden müssen.
                <br><br>Das Eigentum an einem Grundstück kann daher sowohl durch Rechte privater Personen als auch durch die Baubehörde eingeschränkt sein. <br><br>
                Baulasten werden im Baulastverzeichnis des jeweiligen Amtes geführt und dienen als ergänzende Auskunft zu den Lasten im Grundbuch, welches die Baulasten i.d.R. nicht beinhaltet. Ausnahmen bilden die Länder Bayern und Brandenburg. Hier erfolgt die Sicherung baurechtskonformer Zustände ausschließlich im Grundbuch. Durch die Baulastenauskunft wissen Sie welche Baulasten in der Vergangenheit eingetragen wurden. Sofern keine Baulasten für das Grundstück eintragen sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 104,
              "nameShort": "liegen",
              "name_long": "Liegenschaftskarte",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Liegenschaftskarte (Flurkarte)</b>
                <br><br>
                Die Liegenschaftskarte hat mehrere Synonyme. Die Begriffe Flurkarte und Katasterkarte werden analog für sie verwendet. Sie stellt eine wichtige Grundlage dar, indem sie einen Überblick über das Grundstück verschafft sowie Lage und Grenzen der Grundstücke nachweisbar darstellt und somit die amtliche Grundlage für das Grundbuch bildet. <br>
                <br>

                Auf der Liegenschaftskarte werden die gesamten Liegenschaften (Flurstücke, Grundstücke und Gebäude) eines bestimmten Bereiches maßstabgetreu abgebildet. Die Standard-Maßstäbe der Liegenschaftskarte variieren zwischen 1:500, 1:1000 und 1:2000. <br>
                <br>

                Im Detail umfasst die Liegenschaftskarte folgende Inhalte: <br>
                <br>

                Sie ist ein wichtiges Dokument und wird häufig im Zusammenhang mit Planungen, Finanzierung oder Bewertung von Grundstücken bzw. Immobilien gefordert. <br>
                <br>

                <u>Achtung:</u> Die (amtliche) Liegenschaftskarte ist dabei nicht mit dem amtlichen Lageplan zu verwechseln
              `
          },
          {
              "id": 105,
              "nameShort": "flurstb",
              "name_long": "Bestandsnachweis",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Bestandsnachweis (Auszug aus dem Liegenschaftskataster)</b><br>
                <br>
                Der Bestandsnachweis ist ein Auszug aus dem Liegenschaftskataster (früher auch Liegenschaftsbuch). Dieser enthält umfangreiche Informationen zur Liegenschaft eines Eigentümers (z.B. Detaillierte Angaben aller Flurstücke eines Grundbuchblattes + Größe, Art und Nutzung des Grundstücks zzgl. Eigentümerangaben. Der Bestandsnachweis ist somit analog zum „Flurstücks- und Eigentümernachweis“ anzusehen mit dem Unterschied, dass der Bestandsnachweis alle vorhandenen Flurstücke auflistet und der Flurstücks- und Eigentümernachweis lediglich ein spezifisches Flurstück beschreibt.
              `
          },
          {
              "id": 106,
              "nameShort": "flursta",
              "name_long": "Flurstücks und Eigentümernachweis",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Flurstücks- und Eigentümernachweis (Auszug aus dem Liegenschaftskataster)</b>
                Der Flurstücks- und Eigentumsnachweis ist ein Auszug aus dem Liegenschaftskataster (früher auch Liegenschaftsbuch) und beinhaltet detaillierte Informationen zum abgefragten Flurstück. Diese Informationen umfassen u.a. Gebietszugehörigkeit, Lage, Fläche, Tatsächliche Nutzung, Buchungsart, Buchung sowie Eigentümerangaben eines Flurstücks.
              `
          },
          {
              "id": 107,
              "nameShort": "denkma",
              "name_long": "Gebäude- und Bodendenkmalschutz",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Denkmalschutzauskunft</b> <br> <br>
                Im allgemeinen Sprachgebrauch wird unter dem „Denkmalschutz“ ein „Baudenkmal“ verstanden. Baudenkmäler werden als solche Immobilien definiert, an denen zur Nutzung und Erhaltung ein öffentliches Interesse besteht. Besteht ein solches Interesse und wurde die Immobilie als Denkmal eingestuft wird es in die Denkmalliste der Gemeinde bzw. Stadt eingetragen. Mit einer positiven Eintragung in die Denkmalliste haben Eigentümer die Verpflichtung, das Denkmal instand zu setzen sowie instand zu halten, Gefährdungen abzuwenden und sachgemäß zu behandeln. Vor der Durchführung von baulichen Maßnahmen am Denkmal muss zunächst eine entsprechende Genehmigung von der Denkmalbehörde eingeholt werden. Ohne diese Genehmigung ist abschlagen, abtragen, zerlegen oder ein Abbruch nicht realisierbar. Aber auch Änderungen von bspw. Fenstern, Fassade oder die Durchführung eines Teilabbruchs etc. erfordern eine zusätzliche Genehmigung.
                Die Denkmalschutzauskunft informiert also über die Existenz eines entsprechenden Baudenkmals (teilweise enthält Sie gleichzeitig Informationen ob ein Bodendenkmal besteht) des jeweiligen Gebäudes und sollte in jedem Fall vor einer Transaktion eingeholt werden, um Einschränkungen und Auflagen, die in Verbindung mit dem Denkmalschutz bestehen in dem Vorhaben berücksichtigen zu können. Sofern kein Denkmalschutz besteht wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 108,
              "nameShort": "planun",
              "name_long": "Satzungs- und Gebietsauskünfte",
              "name_show": null,
              "price": "39.95",
              "description": `
                <b>Satzungs- und Gebietsauskunft (Planungsrechtauskunft):</b>&lt;<br>
                Eine Planungsrechtauskunft kann prinzipiell sehr individuelle Informationen beinhalten, in Abhängigkeit von dem jeweiligen Vorhaben des Antragstellers. Da insbesondere im Rahmen von Transaktionen, Bewertungen und Baumaßnahmen sämtliche Informationen relevant sein können, ist die Planungsrechtsabfrage von DocEstate breit aufgestellt und umfasst folgende Themen:
                <br><br>
                    <li>Umlegungsgebiet</li>
                    <li>Erhaltungssatzungen</li>
                    <li>Vereinfachte Umlegung</li>
                    <li>Gestaltungssatzungen</li>
                    <li>Veränderungssperre</li>
                    <li>Flurbereinigungsgebiet</li>
                    <li>Städtebauliche Sanierungsmaßnahmen</li>
                    <li>Städtebauliche Entwicklungsmaßnahme</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten städtebaulichen Sanierungsmaßnahme</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten Städtebauliche Entwicklungsmaßnahme</li>
                <br><br>Liegt das Grundstück in einem der aufgezählten Gebiete können für den Grundstückeigentümer zusätzliche Kosten, in relevanter Höhe entstehen.
              `
          },
          {
              "id": 109,
              "nameShort": "erschl",
              "name_long": "Erschließungsbeitragsbescheinigung",
              "name_show": "Erschließungsbeitrags- bescheinigung",
              "price": "29.95",
              "description": `
                <b>Erschließungskostenbescheinigung (Anliegerbescheinigung)</b><br><br>
                Die Erschließungskosten beziehen sich auf die Gebühren, die bei der Stadt im Zusammenhang mit der Erschließung anfallen und die Kosten sind bis zu 90% durch den Eigentümer/ Erbbauberechtigten zu tragen. Verantwortlich für die Begleichung der Erschließungskosten ist der aktuelle Eigentümer/ Erbbauberechtigte. Da Erschließungskosten auch noch nach langjähriger Teilerschließung anfallen können, da bspw. Straßenbeleuchtung, Parkbuchten, Grünanlagen etc. erst später fertiggestellt wurden und der Gemeinde ein mehrjähriges Zeitfenster bis zur Verjährung zur Verfügung steht, können Erschießungskosten auch noch nach der Grundstückserschließung auftreten.
                <br><br>
                In jedem Fall ist der zum Erhebungszeitpunkt rechtmäßige Eigentümer/ Erbbauberechtigte der Kostenträger, sodass zur Vermeidung von unvorhergesehenen Kosten sowie Rechtsstreitigkeiten eine Auskunft über den Zahlungsstand der Erschließungskosten dringend zu empfehlen ist. Die Erschließungsbeitragsbescheinigung (auch als Anliegerbescheinigung bezeichnet) umfasst standardmäßig die Beiträge für die Straßenerschließung. Abhängig nach Kommune und Grundstück werden teilweise auch Kanalanschluss- / herstellbeiträge und andere Erschließungsgebühren mit in die Auskunft aufgenommen. Sofern keine Erschließungsbeiträge mehr offen sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 110,
              "nameShort": "städte",
              "name_long": "Städtebaulicher Vertrag",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Auskunft über Städtebauliche Verträge / Erschließungsverträge:</b><br><br>
                Wenn es um städtebauliche Projekte (Bauvorhaben) geht, regeln Städtebauliche Verträge bzw. ein Erschließungsvertrag, welche Rechte und Pflichten „ zumeist ein privater Investor “ als eine Vertragspartei sowie die Gemeinde als zweite Vertragspartei hat. Der Inhalt eines solchen Vertrages wird im BauGB nicht exakt definiert. Ausschlaggebend ist, dass sich der Inhalt auf das Städtebaurecht bezieht. Außerdem bezieht sich die Rechtsprechung nur dann auf einen städtebaulichen Vertrag, wenn die Vertragspartei die Gemeinde ist. Mit dem städtebaulichen Vertrag kann also neben der Übertragung von organisatorischen Aufgaben auch die Kostenübernahme vereinbart werden. Beispielsweise kann die Privatperson, Kosten für bestimmte Städteprojekte übernehmen und im Gegenzug schafft die Kommune das notwendige Baurecht für die Aufstellung eines B-Plans, um das Vorhaben des Eigentümers umzusetzen.
                <br>Die Auskunft über bestehende städtebauliche Verträge informiert demnach über den Stand öffentlich-rechtlicher Verpflichtungen in Form von operativen Aufgaben oder der zusammenhängenden Kostenübernahmepflicht.  Sofern keine Städtebauliche Verträge oder Verpflichtung bestehen wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 111,
              "nameShort": "bebauu",
              "name_long": "Bebauungsplan",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Bebauungsplan (Kopie bzw. Negativattest):</b>
                <br><br>
                Der Bebauungsplan (kurz „B-Plan“) stellt eine rechtsverbindliche Satzung dar und wird durch die Gemeinde beschlossen. Der B-Plan baut auf dem übergeordneten Flächennutzungsplan auf. Sinn und Zweck ist die Festlegung der Art und Weise der möglichen Bebauung von Grundstücken. Der B-Plan stellt somit ein essenzielles Dokument für sämtliche Bau- und Umbaumaßnahmen oder auch für eine Prüfung einer potenziellen Projektentwicklung dar. Es gibt jedoch auch Fälle in denen kein B-Plan für ein Grundstück existiert. In diesem Fall ist eine Negativauskunft wichtig, da somit die Sonderreglungen des Baugesetzbuches in Kraft treten. Mit der Auskunft von DocEstate wird grundsätzlich die Kopie des B-Plans angefordert und sofern kein B-Plan vorhanden ist eine Auskunft darüber wie mit dem Grundstück verfahren wird.
              `
          },
          {
              "id": 112,
              "nameShort": "fläche",
              "name_long": "Flächennutzungsplan",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Flächennutzungsplan</b>
                <br><br>
                Der Flächennutzungsplan (kurz FNP) ist der „vorbereitendende Bauleitplan“ und ein Planungsinstrument (Planzeichnung mit Begründung) der öffentlichen Verwaltung im System der Raumordnung der Bundesrepublik Deutschland, mit dem die städtebauliche Entwicklung der Gemeinden gesteuert werden soll. Der Flächennutzungsplan ist jedoch nur als übergeordnete städtebauliche Orientierung anzusehen, da dieser im Gegensatz zum Bebauungsplan keinen rechtsverbindlichen Charakter ausübt. Er dient lediglich als Basis für den rechtsverbindlichen Bebauungsplan.
              `
          },
          {
              "id": 113,
              "nameShort": "besche",
              "name_long": "Städtisches Vorkaufsrecht",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Vorkaufsrecht (Verzichtserklärung / Negativerklärung)</b><br><br>
                Gemäß der §§ 24, 25 BauGB besitzen Gemeinden ein Vorkaufsrecht bei Grundstückstransaktionen. Demzufolge obliegt der Stadt die Möglichkeit in die Vereinbarungen zwischen dem Veräußerer und Kaufinteressenten einzutreten. Um Kosten die mit einer Grundstückstransaktion in Verbindung stehen zu vermeiden, sollte zunächst eine Verzichtserklärung bzw. eine Negativerklärung über das Vorkaufsrecht bei der jeweiligen Behörde beantragt werden. Denn wurden bereits Aufwendungen im Zusammenhang mit der Grundstückstransaktion aufgebracht und übt die Gemeinde das Vorkaufsrecht aus, werden die bereits getätigten Aufwendungen i.d.R. weder durch den Veräußerer noch durch die Gemeinde erstattet.
              `
          },
          {
              "id": 114,
              "nameShort": "kampfm",
              "name_long": "Kampfmittelauskunft",
              "name_show": null,
              "price": "34.95",
              "description": `
                Kampfmittelauskunft (Luftbildauswertung)

                Die Kampfmittelauskunft erfolgt anhand von Luftbildauswertungen und weiterer historischer Dokumente, die Aufschluss darüber geben können ob sich ein Grundstück in einem Verdachtsgebiet von Kampfmitteln befindet. Liegt kein Verdacht vor wird ein Negativattest ausgestellt. Die Beantragung einer Kampfmittelauskunft wird vor dem Grundstückskauf bzw. der Grundstücksprüfung empfohlen, um unter Umstände weitere Maßnahmen durchzuführen.
              `
          },
          {
              "id": 115,
              "nameShort": "bodenr",
              "name_long": "Bodenrichtwertkarte",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Bodenrichtwert(-karte) / Grundstückswert</b><br>
                Der Bodenrichtwert (BRW) ist der durchschnittliche Lagewert für Grund und Boden eines bestimmten Gebietes, die jeweils ähnliche bzw. gleiche Nutzungs- und Wertverhältnisse aufweisen. Basis für die Herleitung des jeweiligen BRW ist die des Gutachterausschusses geführte Kaufpreissammlung. Bei den Bodenrichtwertkarten handelt es sich um spezielle Karten, in denen jeweils die gültigen Bodenrichtwerte je Quadratmeter Grundstücksfläche „ unter Angabe der wertbeeinflussenden Lagemerkmale “ eingetragen werden.
              `
          },
          {
              "id": 116,
              "nameShort": "grunds",
              "name_long": "Grundsteuerbescheid",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Grundsteuerbescheid:</b>
                Der Grundsteuerbescheid weist die monetäre Höhe der Steuerforderung auf. Mit der Einsicht in den Grundsteuerbescheid kann die tatsächliche Höhe der Steuerlast eingesehen werden und somit in der Wirtschaftlichkeitsbetrachtung berücksichtigt werden.
              `
          },
          {
              "id": 117,
              "nameShort": "einhei",
              "name_long": "Einheitswertbescheid",
              "name_show": "",
              "price": "19.95",
              "description": `
                <b>Grundsteuermessbescheid (Einheitswertbescheid):</b><br><br>
                Der Einheitswertbescheid wird durch das Finanzamt ausgestellt und übermittelt den Einheitswert einer Immobilie. Der Bescheid enthält keine Angaben zur Höhe der Steuer und ist dementsprechend nicht mit einem steuerlichen Bescheid vergleichbar. Da der Einheitswertbescheid die Grundlage für die Höhe der Grundsteuer bildet, ist es ratsam im Rahmen einer Transaktion, den aktuellen Bescheid anzufordern. Somit kann sich der potenzielle Erwerber über die zu erwartende Steuerlast informieren.
              `
          },
          {
              "id": 118,
              "nameShort": "teilun",
              "name_long": "Teilungserklärung",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Teilungserklärung:</b> <br>
                Mit der Teilungserklärung wird die formelle Aufteilung eines Gebäudes geregelt. Aus ihr geht die Aufteilung des jeweiligen Sonder- und Gemeinschaftseigentum hervor. Die Aufteilung ist dabei neben Bewertungsaspekten auch für die Kostenverteilung interessant. Entsteht ein Schaden (z.B. am Dach, Ver-/ Entsorgungsleitungen etc.) und ist dieser dem Sondereigentum zuzuordnen, muss der Eigentümer selbst für den Schaden aufkommen. Ist der Schaden jedoch dem Gemeinschaftseigentum zuzuordnen, werden die Kosten auf die Eigentümergemeinschaft verteilt. Des Weiteren listet die Teilungserklärung Rechte und Pflichten der jeweiligen Wohnungseigentümer auf.
              `
          }
        ]
      },
      {
        "description_placeholder": "My plus package description",
        "details": [],
        "disabled": false,
        "extra_feedback_price": "",
        "feedbacks_included": -1,
        "name": "12 Auskünfte",
        "newProperty": false,
        "numberOfDrafts": 0,
        "package_contents": [],
        "package_images": [
          "documents_procurement/plus.jpg"
        ],
        "package_key": "30",
        "physicalProduct": false,
        "price": "documents_procurement_plus_price",
        "price_note": "Preise für die Servicegebühr. Hinzu kommen die Behördenauslagen",
        "price_pre_text": "ab",
        "priceAddUnits": "documents_procurement_addUnits",
        "service_key": "documents_procurement",
        "service_title": "Behördenauskünfte",
        "title": "Behördenauskünfte",
        "maxDocumentsLimit": 12,
        "docEstateProductList": [
          {
              "id": 101,
              "nameShort": "grunde",
              "name_long": "Grundbuchauszug",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Grundbuchauszug (einfacher Ausdruck)</b> <br>
                <br>
                Das Grundbuch ist ein öffentliches Register (erstellt vom Staat), in dem die Eigentumsverhältnisse von Grundstücken verbindlich geregelt sind. Das Grundbuch nennt den oder die jeweiligen Besitzer und enthält alle wesentlichen Inhalte zu Rechten und Lasten des Grundstücks. Das Grundbuch ist wie folgt aufgebaut:
                <br><br>
                <b>Bestandsverzeichnis:</b><br><br>
                Das Bestandsverzeichnis führt alle dem Grundbuchblatt zugehörigen Grundstücke und deren Lage (Gemarkung, Flur, Flurstück, Adresse, Größe des Grundstücks) auf. Darüber hinaus werden im Bestandsverzeichnis Rechte eingetragen, die ein im Grundbuch genanntes Flurstück berechtigen. Diese Eintragungen werden auch als „Herrschvermerk“ bezeichnet. Belastungen einzelner oder mehrere Flurstücke werden nicht im Bestandsverzeichnis gelistet.
                <br><br>
                <b>Abteilung I:</b><br><br>
                In Abteilung I sind sämtliche Eigentümer sowie die dinglich-rechtliche Grundlage des Erwerbes genannt.
                <br><br>
                <b>Abteilung II: </b><br><br>
                Abteilung II listet alle dinglichen Rechte, wie z.B. Nießbrauch, Reallasten, Dienstbarkeiten, etc. Hiervon ausgenommen sind Grundpfandrechte, welche sich in Abteilung III wiederfinden.
                <br><br>
                <b>Abteilung III:</b><br><br>
                In Abteilung III sind sämtliche Grundpfandrechte (Grundschuld, Hypothek, Rentenschuld) gelistet.
              `
          },
          {
              "id": 102,
              "nameShort": "altlas",
              "name_long": "Altlastenauskunft",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Auskunft aus dem Altlastenkataster (Altlastenauskunft)</b><br><br>
                Wurden an einem Grundstück Altlasten identifiziert werden diese in dem zuständigen Register, auch als Altlastenkataster bezeichnet, aufgeführt. Altlasten stellen dabei Verunreinigungen der Flächen durch Gefahrenstoffe dar, wodurch Boden und Grundwasser nicht mehr der gesetzlichen Qualität entsprechen. Gründe hierfür können die nicht sachgemäße Behandlung, Lagerung oder Ablagerung von Abfällen sowie der unsachgemäße Umgang mit umweltgefährdenden Stoffen sein.<br><br>
                Insbesondere die finanzielle Belastung, die im Zusammenhang mit der Aufbereitung kontaminierter Flächen entstehen, sorgen für die dringende Notwendigkeit einer Altlastenauskunft vor jedem Grundstücks-/ Immobilienerwerb, um notwendige Maßnahmen in der Wirtschaftlichkeitsanalyse berücksichtigen zu können. Sofern keine Altlasten für das Grundstück vorhanden sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 103,
              "nameShort": "baulas",
              "name_long": "Baulastenauskunft",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Auszug aus dem Baulastenverzeichnis (Baulastenauskunft)</b><br><br>
                Bei einer Baulast verpflichtet sich der Grundstückseigentümer gegenüber der Baubehörde gewisse Pflichten im Zusammenhang mit dem Grundstück einzuhalten, welche allgemein als „Baulasten“ bezeichnet werden. Diese öffentlich-rechtlichen Verpflichtungen bedeuten i.d.R. Einschränkungen für den Grundstückseigentümer, d.h. etwas zu tun, zu unterlassen oder zu dulden. Eine Baulast wird u.a. dann notwendig, wenn ein Bauvorhaben nicht baurechtskonform erstellt werden kann (z.B. Einhaltung der Grenzabstände) und angrenzende Grundstücke für den Genehmigungsprozess herangezogen werden müssen.
                <br><br>Das Eigentum an einem Grundstück kann daher sowohl durch Rechte privater Personen als auch durch die Baubehörde eingeschränkt sein. <br><br>
                Baulasten werden im Baulastverzeichnis des jeweiligen Amtes geführt und dienen als ergänzende Auskunft zu den Lasten im Grundbuch, welches die Baulasten i.d.R. nicht beinhaltet. Ausnahmen bilden die Länder Bayern und Brandenburg. Hier erfolgt die Sicherung baurechtskonformer Zustände ausschließlich im Grundbuch. Durch die Baulastenauskunft wissen Sie welche Baulasten in der Vergangenheit eingetragen wurden. Sofern keine Baulasten für das Grundstück eintragen sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 104,
              "nameShort": "liegen",
              "name_long": "Liegenschaftskarte",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Liegenschaftskarte (Flurkarte)</b>
                <br><br>
                Die Liegenschaftskarte hat mehrere Synonyme. Die Begriffe Flurkarte und Katasterkarte werden analog für sie verwendet. Sie stellt eine wichtige Grundlage dar, indem sie einen Überblick über das Grundstück verschafft sowie Lage und Grenzen der Grundstücke nachweisbar darstellt und somit die amtliche Grundlage für das Grundbuch bildet. <br>
                <br>

                Auf der Liegenschaftskarte werden die gesamten Liegenschaften (Flurstücke, Grundstücke und Gebäude) eines bestimmten Bereiches maßstabgetreu abgebildet. Die Standard-Maßstäbe der Liegenschaftskarte variieren zwischen 1:500, 1:1000 und 1:2000. <br>
                <br>

                Im Detail umfasst die Liegenschaftskarte folgende Inhalte: <br>
                <br>

                Sie ist ein wichtiges Dokument und wird häufig im Zusammenhang mit Planungen, Finanzierung oder Bewertung von Grundstücken bzw. Immobilien gefordert. <br>
                <br>

                <u>Achtung:</u> Die (amtliche) Liegenschaftskarte ist dabei nicht mit dem amtlichen Lageplan zu verwechseln
              `
          },
          {
              "id": 105,
              "nameShort": "flurstb",
              "name_long": "Bestandsnachweis",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Bestandsnachweis (Auszug aus dem Liegenschaftskataster)</b><br>
                <br>
                Der Bestandsnachweis ist ein Auszug aus dem Liegenschaftskataster (früher auch Liegenschaftsbuch). Dieser enthält umfangreiche Informationen zur Liegenschaft eines Eigentümers (z.B. Detaillierte Angaben aller Flurstücke eines Grundbuchblattes + Größe, Art und Nutzung des Grundstücks zzgl. Eigentümerangaben. Der Bestandsnachweis ist somit analog zum „Flurstücks- und Eigentümernachweis“ anzusehen mit dem Unterschied, dass der Bestandsnachweis alle vorhandenen Flurstücke auflistet und der Flurstücks- und Eigentümernachweis lediglich ein spezifisches Flurstück beschreibt.
              `
          },
          {
              "id": 106,
              "nameShort": "flursta",
              "name_long": "Flurstücks und Eigentümernachweis",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Flurstücks- und Eigentümernachweis (Auszug aus dem Liegenschaftskataster)</b>
                Der Flurstücks- und Eigentumsnachweis ist ein Auszug aus dem Liegenschaftskataster (früher auch Liegenschaftsbuch) und beinhaltet detaillierte Informationen zum abgefragten Flurstück. Diese Informationen umfassen u.a. Gebietszugehörigkeit, Lage, Fläche, Tatsächliche Nutzung, Buchungsart, Buchung sowie Eigentümerangaben eines Flurstücks.
              `
          },
          {
              "id": 107,
              "nameShort": "denkma",
              "name_long": "Gebäude- und Bodendenkmalschutz",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Denkmalschutzauskunft</b> <br> <br>
                Im allgemeinen Sprachgebrauch wird unter dem „Denkmalschutz“ ein „Baudenkmal“ verstanden. Baudenkmäler werden als solche Immobilien definiert, an denen zur Nutzung und Erhaltung ein öffentliches Interesse besteht. Besteht ein solches Interesse und wurde die Immobilie als Denkmal eingestuft wird es in die Denkmalliste der Gemeinde bzw. Stadt eingetragen. Mit einer positiven Eintragung in die Denkmalliste haben Eigentümer die Verpflichtung, das Denkmal instand zu setzen sowie instand zu halten, Gefährdungen abzuwenden und sachgemäß zu behandeln. Vor der Durchführung von baulichen Maßnahmen am Denkmal muss zunächst eine entsprechende Genehmigung von der Denkmalbehörde eingeholt werden. Ohne diese Genehmigung ist abschlagen, abtragen, zerlegen oder ein Abbruch nicht realisierbar. Aber auch Änderungen von bspw. Fenstern, Fassade oder die Durchführung eines Teilabbruchs etc. erfordern eine zusätzliche Genehmigung.
                Die Denkmalschutzauskunft informiert also über die Existenz eines entsprechenden Baudenkmals (teilweise enthält Sie gleichzeitig Informationen ob ein Bodendenkmal besteht) des jeweiligen Gebäudes und sollte in jedem Fall vor einer Transaktion eingeholt werden, um Einschränkungen und Auflagen, die in Verbindung mit dem Denkmalschutz bestehen in dem Vorhaben berücksichtigen zu können. Sofern kein Denkmalschutz besteht wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 108,
              "nameShort": "planun",
              "name_long": "Satzungs- und Gebietsauskünfte",
              "name_show": null,
              "price": "39.95",
              "description": `
                <b>Satzungs- und Gebietsauskunft (Planungsrechtauskunft):</b>&lt;<br>
                Eine Planungsrechtauskunft kann prinzipiell sehr individuelle Informationen beinhalten, in Abhängigkeit von dem jeweiligen Vorhaben des Antragstellers. Da insbesondere im Rahmen von Transaktionen, Bewertungen und Baumaßnahmen sämtliche Informationen relevant sein können, ist die Planungsrechtsabfrage von DocEstate breit aufgestellt und umfasst folgende Themen:
                <br><br>
                    <li>Umlegungsgebiet</li>
                    <li>Erhaltungssatzungen</li>
                    <li>Vereinfachte Umlegung</li>
                    <li>Gestaltungssatzungen</li>
                    <li>Veränderungssperre</li>
                    <li>Flurbereinigungsgebiet</li>
                    <li>Städtebauliche Sanierungsmaßnahmen</li>
                    <li>Städtebauliche Entwicklungsmaßnahme</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten städtebaulichen Sanierungsmaßnahme</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten Städtebauliche Entwicklungsmaßnahme</li>
                <br><br>Liegt das Grundstück in einem der aufgezählten Gebiete können für den Grundstückeigentümer zusätzliche Kosten, in relevanter Höhe entstehen.
              `
          },
          {
              "id": 109,
              "nameShort": "erschl",
              "name_long": "Erschließungsbeitragsbescheinigung",
              "name_show": "Erschließungsbeitrags- bescheinigung",
              "price": "29.95",
              "description": `
                <b>Erschließungskostenbescheinigung (Anliegerbescheinigung)</b><br><br>
                Die Erschließungskosten beziehen sich auf die Gebühren, die bei der Stadt im Zusammenhang mit der Erschließung anfallen und die Kosten sind bis zu 90% durch den Eigentümer/ Erbbauberechtigten zu tragen. Verantwortlich für die Begleichung der Erschließungskosten ist der aktuelle Eigentümer/ Erbbauberechtigte. Da Erschließungskosten auch noch nach langjähriger Teilerschließung anfallen können, da bspw. Straßenbeleuchtung, Parkbuchten, Grünanlagen etc. erst später fertiggestellt wurden und der Gemeinde ein mehrjähriges Zeitfenster bis zur Verjährung zur Verfügung steht, können Erschießungskosten auch noch nach der Grundstückserschließung auftreten.
                <br><br>
                In jedem Fall ist der zum Erhebungszeitpunkt rechtmäßige Eigentümer/ Erbbauberechtigte der Kostenträger, sodass zur Vermeidung von unvorhergesehenen Kosten sowie Rechtsstreitigkeiten eine Auskunft über den Zahlungsstand der Erschließungskosten dringend zu empfehlen ist. Die Erschließungsbeitragsbescheinigung (auch als Anliegerbescheinigung bezeichnet) umfasst standardmäßig die Beiträge für die Straßenerschließung. Abhängig nach Kommune und Grundstück werden teilweise auch Kanalanschluss- / herstellbeiträge und andere Erschließungsgebühren mit in die Auskunft aufgenommen. Sofern keine Erschließungsbeiträge mehr offen sind wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 110,
              "nameShort": "städte",
              "name_long": "Städtebaulicher Vertrag",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Auskunft über Städtebauliche Verträge / Erschließungsverträge:</b><br><br>
                Wenn es um städtebauliche Projekte (Bauvorhaben) geht, regeln Städtebauliche Verträge bzw. ein Erschließungsvertrag, welche Rechte und Pflichten „ zumeist ein privater Investor “ als eine Vertragspartei sowie die Gemeinde als zweite Vertragspartei hat. Der Inhalt eines solchen Vertrages wird im BauGB nicht exakt definiert. Ausschlaggebend ist, dass sich der Inhalt auf das Städtebaurecht bezieht. Außerdem bezieht sich die Rechtsprechung nur dann auf einen städtebaulichen Vertrag, wenn die Vertragspartei die Gemeinde ist. Mit dem städtebaulichen Vertrag kann also neben der Übertragung von organisatorischen Aufgaben auch die Kostenübernahme vereinbart werden. Beispielsweise kann die Privatperson, Kosten für bestimmte Städteprojekte übernehmen und im Gegenzug schafft die Kommune das notwendige Baurecht für die Aufstellung eines B-Plans, um das Vorhaben des Eigentümers umzusetzen.
                <br>Die Auskunft über bestehende städtebauliche Verträge informiert demnach über den Stand öffentlich-rechtlicher Verpflichtungen in Form von operativen Aufgaben oder der zusammenhängenden Kostenübernahmepflicht.  Sofern keine Städtebauliche Verträge oder Verpflichtung bestehen wird ein entsprechendes Negativattest bereitgestellt.
              `
          },
          {
              "id": 111,
              "nameShort": "bebauu",
              "name_long": "Bebauungsplan",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Bebauungsplan (Kopie bzw. Negativattest):</b>
                <br><br>
                Der Bebauungsplan (kurz „B-Plan“) stellt eine rechtsverbindliche Satzung dar und wird durch die Gemeinde beschlossen. Der B-Plan baut auf dem übergeordneten Flächennutzungsplan auf. Sinn und Zweck ist die Festlegung der Art und Weise der möglichen Bebauung von Grundstücken. Der B-Plan stellt somit ein essenzielles Dokument für sämtliche Bau- und Umbaumaßnahmen oder auch für eine Prüfung einer potenziellen Projektentwicklung dar. Es gibt jedoch auch Fälle in denen kein B-Plan für ein Grundstück existiert. In diesem Fall ist eine Negativauskunft wichtig, da somit die Sonderreglungen des Baugesetzbuches in Kraft treten. Mit der Auskunft von DocEstate wird grundsätzlich die Kopie des B-Plans angefordert und sofern kein B-Plan vorhanden ist eine Auskunft darüber wie mit dem Grundstück verfahren wird.
              `
          },
          {
              "id": 112,
              "nameShort": "fläche",
              "name_long": "Flächennutzungsplan",
              "name_show": null,
              "price": "24.95",
              "description": `
                <b>Flächennutzungsplan</b>
                <br><br>
                Der Flächennutzungsplan (kurz FNP) ist der „vorbereitendende Bauleitplan“ und ein Planungsinstrument (Planzeichnung mit Begründung) der öffentlichen Verwaltung im System der Raumordnung der Bundesrepublik Deutschland, mit dem die städtebauliche Entwicklung der Gemeinden gesteuert werden soll. Der Flächennutzungsplan ist jedoch nur als übergeordnete städtebauliche Orientierung anzusehen, da dieser im Gegensatz zum Bebauungsplan keinen rechtsverbindlichen Charakter ausübt. Er dient lediglich als Basis für den rechtsverbindlichen Bebauungsplan.
              `
          },
          {
              "id": 113,
              "nameShort": "besche",
              "name_long": "Städtisches Vorkaufsrecht",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Vorkaufsrecht (Verzichtserklärung / Negativerklärung)</b><br><br>
                Gemäß der §§ 24, 25 BauGB besitzen Gemeinden ein Vorkaufsrecht bei Grundstückstransaktionen. Demzufolge obliegt der Stadt die Möglichkeit in die Vereinbarungen zwischen dem Veräußerer und Kaufinteressenten einzutreten. Um Kosten die mit einer Grundstückstransaktion in Verbindung stehen zu vermeiden, sollte zunächst eine Verzichtserklärung bzw. eine Negativerklärung über das Vorkaufsrecht bei der jeweiligen Behörde beantragt werden. Denn wurden bereits Aufwendungen im Zusammenhang mit der Grundstückstransaktion aufgebracht und übt die Gemeinde das Vorkaufsrecht aus, werden die bereits getätigten Aufwendungen i.d.R. weder durch den Veräußerer noch durch die Gemeinde erstattet.
              `
          },
          {
              "id": 114,
              "nameShort": "kampfm",
              "name_long": "Kampfmittelauskunft",
              "name_show": null,
              "price": "34.95",
              "description": `
                Kampfmittelauskunft (Luftbildauswertung)

                Die Kampfmittelauskunft erfolgt anhand von Luftbildauswertungen und weiterer historischer Dokumente, die Aufschluss darüber geben können ob sich ein Grundstück in einem Verdachtsgebiet von Kampfmitteln befindet. Liegt kein Verdacht vor wird ein Negativattest ausgestellt. Die Beantragung einer Kampfmittelauskunft wird vor dem Grundstückskauf bzw. der Grundstücksprüfung empfohlen, um unter Umstände weitere Maßnahmen durchzuführen.
              `
          },
          {
              "id": 115,
              "nameShort": "bodenr",
              "name_long": "Bodenrichtwertkarte",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Bodenrichtwert(-karte) / Grundstückswert</b><br>
                Der Bodenrichtwert (BRW) ist der durchschnittliche Lagewert für Grund und Boden eines bestimmten Gebietes, die jeweils ähnliche bzw. gleiche Nutzungs- und Wertverhältnisse aufweisen. Basis für die Herleitung des jeweiligen BRW ist die des Gutachterausschusses geführte Kaufpreissammlung. Bei den Bodenrichtwertkarten handelt es sich um spezielle Karten, in denen jeweils die gültigen Bodenrichtwerte je Quadratmeter Grundstücksfläche „ unter Angabe der wertbeeinflussenden Lagemerkmale “ eingetragen werden.
              `
          },
          {
              "id": 116,
              "nameShort": "grunds",
              "name_long": "Grundsteuerbescheid",
              "name_show": null,
              "price": "19.95",
              "description": `
                <b>Grundsteuerbescheid:</b>
                Der Grundsteuerbescheid weist die monetäre Höhe der Steuerforderung auf. Mit der Einsicht in den Grundsteuerbescheid kann die tatsächliche Höhe der Steuerlast eingesehen werden und somit in der Wirtschaftlichkeitsbetrachtung berücksichtigt werden.
              `
          },
          {
              "id": 117,
              "nameShort": "einhei",
              "name_long": "Einheitswertbescheid",
              "name_show": "",
              "price": "19.95",
              "description": `
                <b>Grundsteuermessbescheid (Einheitswertbescheid):</b><br><br>
                Der Einheitswertbescheid wird durch das Finanzamt ausgestellt und übermittelt den Einheitswert einer Immobilie. Der Bescheid enthält keine Angaben zur Höhe der Steuer und ist dementsprechend nicht mit einem steuerlichen Bescheid vergleichbar. Da der Einheitswertbescheid die Grundlage für die Höhe der Grundsteuer bildet, ist es ratsam im Rahmen einer Transaktion, den aktuellen Bescheid anzufordern. Somit kann sich der potenzielle Erwerber über die zu erwartende Steuerlast informieren.
              `
          },
          {
              "id": 118,
              "nameShort": "teilun",
              "name_long": "Teilungserklärung",
              "name_show": null,
              "price": "29.95",
              "description": `
                <b>Teilungserklärung:</b><br>
                Mit der Teilungserklärung wird die formelle Aufteilung eines Gebäudes geregelt. Aus ihr geht die Aufteilung des jeweiligen Sonder- und Gemeinschaftseigentum hervor. Die Aufteilung ist dabei neben Bewertungsaspekten auch für die Kostenverteilung interessant. Entsteht ein Schaden (z.B. am Dach, Ver-/ Entsorgungsleitungen etc.) und ist dieser dem Sondereigentum zuzuordnen, muss der Eigentümer selbst für den Schaden aufkommen. Ist der Schaden jedoch dem Gemeinschaftseigentum zuzuordnen, werden die Kosten auf die Eigentümergemeinschaft verteilt. Des Weiteren listet die Teilungserklärung Rechte und Pflichten der jeweiligen Wohnungseigentümer auf.
              `
          }
        ]
      }
    ]
  }
];
