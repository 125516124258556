import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountingPosition } from '@app/interfaces/accounting-position.interface';
import { AccountingPositionsService } from './accounting-positions.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingPositionResolverService implements Resolve<AccountingPosition[]> {

  constructor(
    private aps: AccountingPositionsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.aps.isListDataLoaded) {
      return this.aps.getAllPositions();
    } else {
      return this.aps.allPositions;
    }
  }
}
