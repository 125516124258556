export const CONSTANTS = {
  FLAT_FINDER: {
    COLORPICKER_MSG: 'Geben Sie auch eigene Farbcodes manuell ein (RGB, HEX, HSW oder sonstiges)'
  },
  TOKEN_REFRESH_TIMER: 3000000,
  CONFIGURATIONS: {
    SERVICE_PROVIDERS: 'rsidBwFzO3Kp356emm9N',
    SERVICE_CLICKS: 'Lt8fpXKYGVNy6qjpi0hU',
    DOC_ESTATE: 'docestate',
    ESOFT: 'esoft'
  },
  BACKEND_ENDPOINTS: {
    ACCOUNTINGPOSITIONS: 'accounting-positions',
    COMPANIES: 'companies',
    DISCOUNTS: 'discounts',
    FILES: 'files',
    INVOICES: 'invoices',
    ORDERS: 'orders',
    REAL_ESTATES: 'real-estates',
    SERVICES: 'services',
    SUBCOLLECTIONS: 'subcollections',
    SUBORDERS: 'suborders',
    USERS: 'users',
    VIEWS: 'views'
  },
  WEBSOCKET_EVENTS: {
    CONNECTION: 'connection',
    DISCONNECT: 'disconnect',
    ORDERS: {
      SUBSCRIBE: 'orders.subscribeOrders',
      UNSUBSCRIBE: 'orders.unsubscribeOrders',
      CHANGED: 'orders.changed'
    },
    SUBORDERS: {
      SUBSCRIBE_SUBORDERS_OF_ORDER: 'subscribeToSubordersOfOrder',
      SUBSCRIBE_SUBORDER_LIST: 'subscribeToSuborderList',
      SUBSCRIBE_SUBCOLLECTION: 'subscribeToSubCollection',
      UNSUBSCRIBE_SUBORDERS_OF_ORDER: 'unsubscribeToSubordersOfOrder',
      UNSUBSCRIBE_SUBORDER_LIST: 'unsubscribeToSuborderList',
      UNSUBSCRIBE_SUBCOLLECTION: 'unsubscribeToSubCollection',
      SUBORDER_CHANGES: 'suborderChanges',
      SUBORDER_LIST_CHANGES: 'mySuborderListChanges',
      SUBCOLLECTION_CHANGES: 'subCollectionChanges',
      UNSUBSCRIBE_ALL: 'unsubscribeAll',
      SUBSCRIBE_SUBORDER: 'subscribeToSuborder',
      CURRENT_SUBORDER_CHANGES: 'currentSuborderChanges',
      UNSUBSCRIBE_SUBORDER: 'unsubscribeToSuborder',
      SUBSCRIBE_REALESTATE_SUBORDERS: 'subscribeToRealEstateSuborders',
      REALESTATE_SUBORDER_CHANGES: 'realEstateSuborderChanges',
      UNSUBSCRIBE_REALESTATE_SUBORDERS: 'unsubscribeToRealEstateSuborders'
    }
  }
};
