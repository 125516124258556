import { UserRole } from "@app/interfaces/user-role.interface";

export enum UserRoleEnum {
  Customer = 'Kunde',
  ServiceProvider = 'Dienstleister',
  Administrator = 'Administrator'
}

export const userRoleList: UserRole[] = [
  {
    id: UserRoleEnum.Customer,
    name: 'Customer Role'
  },
  {
    id: UserRoleEnum.ServiceProvider,
    name: 'Service Provider Role'
  },
  {
    id: UserRoleEnum.Administrator,
    name: 'Administrator Role'
  }
];
