import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';
import { AuthService } from '@app/auth/auth.service';
import { Invoice } from '@app/interfaces/invoice.interface';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  public companyInvoices: Invoice[];
  public myInvoices: Invoice[];
  public allInvoices: Invoice[];
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.INVOICES;
  isListDataLoaded: boolean;


  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  getAllInvoices() {
    if (this.auth.myUserObservable.role === 'Kunde') {
      return this.http.get<Invoice[]>(this.endpoint + '/my').pipe(
        tap(data => {
          this.setListData(data);
        })
      );
    } else if (this.auth.myUserObservable.role === 'Administrator') {
      return this.http.get<Invoice[]>(this.endpoint).pipe(
        tap(data => {
          this.setListData(data);
        })
      );
    }
  }

  getCompanyInvoices() {
    return this.http.get<Invoice[]>(this.endpoint + '/my-company').pipe(
      tap(data => {
        this.setListData(data, true);
      })
    );
  }

  private setListData(data: Invoice[], company?: boolean) {
    this.isListDataLoaded = true;
    if (company) {
      this.companyInvoices = [...data];
    } else {
      this.allInvoices = [...data];
    }
  }

  createInvoice(invoiceData) {
    return this.http.post<Invoice>(this.endpoint, invoiceData).subscribe(
      val => {
        console.log("POST call successful value returned in body",
          val);
        this.allInvoices.push(invoiceData);
      },
      response => {
        console.log("POST call in error", response);
      },
      () => {
        console.log("The POST observable is now completed.");
      }
    );
  }

  updateInvoice(invoiceId, invoiceData) {
    const foundIndex = this.allInvoices.findIndex(item => item.id === invoiceId);
    Object.keys(invoiceData).forEach(k => {
      this.allInvoices[foundIndex][k] = invoiceData[k];
    });
    return this.http.put<Invoice>(this.endpoint + '/' + invoiceId, invoiceData).subscribe(
      val => {
        console.log("PUT call successful value returned in body",
          val);
      },
      response => {
        console.log("PUT call in error", response);
      },
      () => {
        console.log("The PUT observable is now completed.");
      }
    );
  }
}
