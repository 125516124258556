import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { RealestateService } from '@app/_services/realestate.service';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';
import { AuthService } from '@app/auth/auth.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UsersService } from '@app/_services/users.service';
import { Router } from '@angular/router';
import { ListViewEnum, listViewList } from '@app/models/list-view-list';
import { CompanyRoleEnum } from '@app/models/company-role-list';
import { UserRoleEnum } from '@app/models/user-role-list';
import { LoaderService } from '@app/_services/loader.service';
import { ListView } from '@app/interfaces/list-view.interface';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '@app/_services/orders.service';
import { MatTableDataSource } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { RealEstate } from '@app/interfaces/real-estate.interface';
import { WebsocketsService } from '@app/_services/websockets.service';

@Component({
  selector: 'app-realestate',
  templateUrl: './realestate.component.html',
  styleUrls: ['./realestate.component.css']
})
export class RealestateComponent implements OnInit, OnDestroy {
  realestateForm: FormGroup;
  // Form States:
  loading = false;
  success = false;
  filterValues = {};
  formSub: any;
  mySelectedRealestate: any;
  mySelectedRealestateName: string;
  userRef: any;
  //
  dataloaded: any;
  realestateSub: any;
  paramRealestateId: string;
  paramRealestateName: string;

  data: any;

  private subscriptions: Subscription[] = [];
  listViewList: ListView[] = [];
  companyRoleEnum = CompanyRoleEnum;
  userRoleEnum = UserRoleEnum;

  columnsToDisplay: any;
  private realEstateDataSrc: MatTableDataSource<any>;
  private suborders: any;
  realEstateList: RealEstate[] = [];
  selectedListViewId = ListViewEnum.MyList;

  @ViewChild(MatSort) set sort(value: MatSort) {
    if (this.realEstateDataSrc) {
      this.realEstateDataSrc.sort = value;
    }
  }
  /**
   * Set the real estate form validation in the constructor.
   */
  constructor(public realestateservice: RealestateService,
    private fb: FormBuilder,
    private afs: AngularFirestore,
    public auth: AuthService,
    public ngxSmartModalService: NgxSmartModalService,
    private us: UsersService,
    private router: Router,
    private loader: LoaderService,
    private route: ActivatedRoute,
    public ws: WebsocketsService
  ) {
    this.realestateForm = this.fb.group({
      title: ['', [Validators.required]],
      objectNumber: [''],
      street: ['', [
        Validators.pattern('^([A-ZäöüÄÖÜß.-][a-zA-ZäöüÄÖÜß. -]*[a-zA-ZäöüÄÖÜß.]$)')
      ]],
      streetnumber: ['', [
        Validators.pattern('[1-9][0-9a-zA-Z -]{0,10}$')
      ]],
      postalcode: ['', [
        Validators.pattern('^[0-9]{4,5}$')
      ]],
      city: ['', [
        Validators.pattern('^([A-ZäöüÄÖÜß -][a-zA-ZäöüÄÖÜß -]*[a-zA-ZäöüÄÖÜß]$)')
      ]],
    });
  }
  /**
   * Gets called by default when the class is initialised
   * store the logged in user id in the class attribute to be used later on
   */
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.paramRealestateId = params['realestateId'];
      this.paramRealestateName = params['realestateName'];
    });
    const user = JSON.parse(sessionStorage.getItem('user'));
    this.userRef = user.uid; // UID

    this.setListViewList();
    this.setRealEstateList(this.realestateservice.realEstateList);
    this.subscriptions.push(
      this.realestateservice.realEstateListChanged.subscribe(() => {
        this.setRealEstateList(this.realestateservice.realEstateList);
      })
    );

    this.columnsToDisplay = ["createdOn", "id", "packageName", "status", "total_price"];
    this.realestateservice.add = false;
    this.realestateservice.edit = false;
    this.realestateservice.showSuborderList = false;
    if (this.paramRealestateId) {
      this.showSuborders(this.paramRealestateId, this.paramRealestateName);
    }
  }

  private setRealEstateList(realEstateList: RealEstate[]) {
    if (this.selectedListViewId === ListViewEnum.MyList) {
      this.realEstateList = realEstateList.filter(item => item.ownerID === this.auth.myUserObservable.uid);
    } else {
      this.realEstateList = realEstateList;
    }
  }

  private setListViewList() {
    this.listViewList = listViewList.filter(item => {
      return item.id !== ListViewEnum.All;
    });
  }

  /**
   * Gets called by default when the component is destroyed.
   * We unsubscribe from real estate document in this method.
   */
  ngOnDestroy() {
    // Fixing memory leak / permission error thrownif uses leaves open edit tab and logs out
    if (this.formSub !== undefined) {
      this.formSub.unsubscribe();
    }

    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.ws.unsubscribeFromRealEstateSuborders();
  }
  /**
   * The below getters are called when the form is rendered. For each input element there is a get function.
   */
  get title() {
    return this.realestateForm.get('title');
  }
  get objectNumber() {
    return this.realestateForm.get('objectNumber');
  }
  get street() {
    return this.realestateForm.get('street');
  }
  get streetnumber() {
    return this.realestateForm.get('streetnumber');
  }
  get postalcode() {
    return this.realestateForm.get('postalcode');
  }
  get city() {
    return this.realestateForm.get('city');
  }

  /**
   * Resets the real estate add/edit form. Is called when user try to add a new real estate
   * or cancel/exits the real estate form (during add/edit)
   */
  resetForm() {
    this.realestateForm.reset();
    this.realestateservice.add = false;
    this.realestateservice.edit = false;
    if (this.formSub !== undefined) {
      this.formSub.unsubscribe();
    }
  }

  skipTutorial() {
    this.auth.tutorialSkipped = true;
    this.ngxSmartModalService.close('firstImmoCreatedModal');
  }

  navigateToServices() {
    this.router.navigate(['/createorder']);
  }
  /**
   * Is triggered when user submits the form to add a new realstate. Stores the new property in the database.
   */
  async submitNewHandler() {
    this.realestateservice.add = false;
    this.loading = true;
    const data = {
      ...this.realestateForm.value,
      ownerID: this.userRef
    };

    try {
      await this.realestateservice.addRealEstate(data).toPromise();
      this.realestateForm.reset();
      this.realestateForm.markAsPristine();
      this.success = true;
      setTimeout(() => {
        console.log('Continueing Tutorial!');
        this.success = false;

        console.log('Realestates erhalten');
        if (this.auth.role === 'Kunde' && this.realestateservice.realEstateList.length === 1) {
          this.ngxSmartModalService.getModal('firstImmoCreatedModal').open();
        }
      },
        1000);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }
  /**
   * Is triggered when user finishes editing a property (clicks Änderungen speichern button)
   * Updates the realstate document in the database
   */
  async submitUpdateHandler(realestateIdParam: string) {
    // console.log(realestateIdParam);
    this.loading = true;
    const data = {
      ...this.realestateForm.value,
      id: realestateIdParam
    };

    try {
      await this.realestateservice.updateRealEstate(data).toPromise();
      this.success = true;
      setTimeout(() => {
        this.realestateForm.reset();
        this.realestateservice.edit = false;
        this.success = false;
        this.realestateForm.markAsPristine();
      },
        1000);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }
  /**
   * Triggered when user edits a property (clicks Bearbeiten button).
   * Subscribes to the real state document which is edited
   */
  loadEditData(realestateIdParam: string) {
    // console.log(realestateIdParam);
    this.mySelectedRealestate = realestateIdParam;
    this.realestateservice.edit = true;
    this.formSub = this.realestateservice.getRealestate(realestateIdParam).subscribe(data => {
      this.realestateForm.patchValue(data);
    });
  }
  /**
   * Triggered when user deletes a real estate
   * Deletes the real estate document from the database
   */
  async deleteRealestate(realestateIdParam: string) {
    this.realestateservice.deleteRealestate(realestateIdParam).subscribe(() => {
      this.ngxSmartModalService.close('confirmRealEstateRemovalModal');
    });
  }

  /**
   * Show the Suborders of the selected real estate
   * @param id Id of the selected real estate
   * @param realEstateName name of the selected real estate
   */
  showSuborders(id: string, realEstateName: string) {
    this.mySelectedRealestate = id;
    this.mySelectedRealestateName = realEstateName;
    this.realestateservice.showSuborderList = true;
    this.realestateservice.add = false;
    this.realestateservice.edit = false;

    this.ws.subOrdersOfRealEstate.next([]);
    this.subscriptions.push(this.ws.subOrdersOfRealEstate.subscribe(data => {
      this.suborders = data;
      this.realEstateDataSrc = new MatTableDataSource(this.suborders);
      if (this.realEstateDataSrc) {
        this.setTableFilter();
      }
    }));
    this.ws.getSubordersOfRealEstate(this.mySelectedRealestate);
  }

  setTableFilter(): void {
    this.realEstateDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValues).forEach(key => {
        if (!object[key] || this.filterValues[key] && object[key].toLowerCase().indexOf(this.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    let query = '';
    try {
      console.log(event.currentTarget['value'].trim());
      query = event.currentTarget['value'].trim().toLowerCase();
      console.log(query);

    } catch (e) {
      query = '';
      console.log(e);
    }
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.realEstateDataSrc.filter = 'test';
  }

  /**
   * @param event: The click event
   * Prevents sorting when user clicks on the filter input.
   */
  noSort(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * @param id: Suborder Id
   * Sets the realestate view to the overview-mode and routes to the selected suborder.
   */
  showSuborder(id: string) {
    this.realestateservice.showSuborderList = false;
    this.realestateservice.add = false;
    this.realestateservice.edit = false;
    this.router.navigate(['orderoverview/suborder/' + id], { queryParams: { route: 'realestate' } });
  }

  onListViewChange(selectedListViewId: ListViewEnum) {
    this.selectedListViewId = selectedListViewId;
    this.setRealEstateList(this.realestateservice.realEstateList);
  }
}
