import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.css']
})
export class BrowserSupportComponent implements OnInit {

  canLoad = false;

  constructor() { }

  ngOnInit() {
    this.canLoad = false;
    const ua = window.navigator.userAgent;
    if(ua.indexOf('MSIE ') < 0 && ua.indexOf('Trident/') < 0){
      this.canLoad = true;
    }
  }

}
