import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAuthModel } from '@app/models/user-auth.model';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user: UserAuthModel = JSON.parse(localStorage.getItem('user') || '{}');
    let authRequest = req.clone();
    if(user && user.idToken){
      authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + user.idToken)
      });
    }
    return next.handle(authRequest);
  }
}