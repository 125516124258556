import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { ThreeSixtyView } from './model/threesixtyview.model';
import { FileUploader } from 'ng2-file-upload';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-threesixty-view-manager',
  templateUrl: './threesixty-view-manager.component.html',
  styleUrls: ['./threesixty-view-manager.component.css']
})
export class ThreesixtyViewManagerComponent implements OnInit {

  @Input('view') view: ThreeSixtyView;
  @Input('createMode') createMode: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("threesixtyimgs") planImgs: ElementRef<any>;
  uploaderOne: FileUploader = new FileUploader({});

  constructor() { }

  ngOnInit() {
  }

  onChange(): void {
    let fileListLength = this.uploaderOne.queue.length;
    let lastFile = this.uploaderOne.queue[fileListLength - 1];
    for (let i = 0; i < fileListLength - 1; i++) {
      if (lastFile.file.name === this.uploaderOne.queue[i].file.name && lastFile.file.size === this.uploaderOne.queue[i].file.size) {
        lastFile.remove();
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.uploaderOne.queue, event.previousIndex, event.currentIndex);
  }

  closeDetailPage() {
    this.close.emit();
  }
}
