import { DiscountType } from "@app/interfaces/discount-type.interface";

export enum DiscountTypeEnum {
  Percentage = 'Percentage',
  Monetary = 'Monetary'
}

export const discountTypeList: DiscountType[] = [
  {
    id: DiscountTypeEnum.Percentage,
    name: 'Percentage Discount'
  },
  {
    id: DiscountTypeEnum.Monetary,
    name: 'Monetary Discount'
  }
];
